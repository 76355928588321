import { useEffect, useMemo, useState } from 'react';

import { Grid, CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  arrowLeft,
  Bell,
  BellDisabled,
  Clock,
  ShareArrow,
  UploadSimpleWhite,
} from '../../../assets/images/icons';
import { GradientButton, ThemedButton } from '../../../components';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { Colors } from '../../../config/colors';
import { ROUTES } from '../../../config/constants';
import { useShareStyles } from './styles';
import {
  acceptShare,
  cancelShare,
  getMyShared,
  SharedObject,
} from '../../../api/share';
import { useRequest, useSharerInformation } from '../../../app/hooks';
import SharedWithMe from './components/SharedWithMe';
import ShareNotification from './components/ShareNotification';
import { setNotificationNumber } from './shareNotificationSlice';
import { useDispatch } from 'react-redux';
import { compareCreatedDate } from './utils';
import { useAppSelector } from '../../../app/hooks';
import { selectCurrentUser } from '../../login/loginSlice';

export interface ShareItem extends SharedObject {
  photoUri?: string;
  sharerName: string;
  expired?: string;
  id: string;
}

interface ShareNotificationProps extends SharedObject {
  sharerName: string;
  id: string;
  name: string;
}
export const ShareHome: React.FC = () => {
  const [isOnWhoIShare, setIsOnWhoIShare] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { isDependent, id } = useAppSelector(selectCurrentUser);
  const classes = useShareStyles({ isOnWhoIShare, showNotifications });
  const history = useHistory();
  const dispatch = useDispatch();

  const { request: requestShare, status: statusShare } =
    useRequest(acceptShare);

  const {
    request: getMySharedRequest,
    data: getMySharedData,
    loading: getMySharedLoading,
  } = useRequest(getMyShared);

  const { request: cancelShareRequest, status: cancelShareStatus } =
    useRequest(cancelShare);

  useEffect(() => {
    isDependent ? getMySharedRequest(id) : getMySharedRequest();
  }, [isDependent]);

  useEffect(() => {
    if (statusShare === 200) {
      isDependent ? getMySharedRequest(id) : getMySharedRequest();
    }
  }, [statusShare]);

  useEffect(() => {
    if (cancelShareStatus === 200) {
      isDependent ? getMySharedRequest(id) : getMySharedRequest();
    }
  }, [cancelShareStatus]);

  const mutateItem = (item: SharedObject) => {
    let photoUri = '';
    let sharerName = '';
    const { sharer, contract, receiver } = item;
    if (typeof sharer === 'object' && sharer?.info) {
      photoUri = sharer?.info?.fhirData?.photo?.[0]?.url || '';
      sharerName = `${sharer?.info?.fhirData?.name?.[0]?.given?.[0]} ${sharer?.info?.fhirData?.name?.[0]?.family}`;
    }
    if (typeof receiver === 'object' && receiver?.info) {
      photoUri = receiver?.info?.fhirData?.photo?.[0]?.url || '';
      sharerName = `${receiver?.info?.fhirData?.name?.[0]?.given?.[0]} ${receiver?.info?.fhirData?.name?.[0]?.family}`;
    }
    const itemResult: ShareItem = {
      ...item,
      photoUri,
      sharerName,
      id: contract,
    };
    return itemResult;
  };

  const getItems = (list: SharedObject[], showEverything = false) =>
    list
      .reduce((previusValue: ShareItem[], currentValue) => {
        if (
          (showEverything || !!currentValue.accepted) &&
          dayjs(currentValue.expires).isAfter(dayjs()) &&
          !currentValue.canceled
        ) {
          const item = mutateItem(currentValue);
          previusValue.push(item);
        }
        return previusValue;
      }, [])
      .sort((a, b) => compareCreatedDate(a.created, b.created));

  const getNotifications = (list: SharedObject[]) =>
    list
      .reduce((previusValue: ShareNotificationProps[], item) => {
        const { expires, accepted, contract, sharer, name, canceled } = item;
        if (dayjs(expires).isAfter(dayjs()) && accepted === null && !canceled) {
          let sharerName = '';
          if (typeof sharer === 'object' && sharer?.username) {
            sharerName = `@${sharer?.username}` || '';
          }
          const itemResult: ShareNotificationProps = {
            ...item,
            id: contract,
            sharerName,
            name,
          };
          previusValue.push(itemResult);
        }
        return previusValue;
      }, [])
      .sort((a, b) => compareCreatedDate(a.created, b.created));

  const { receivedItems, sharedItems, notificationItems } = useMemo(() => {
    const { received, shared } = getMySharedData?.with || {
      received: [],
      shared: [],
    };
    const notificationItems = getNotifications(received);
    if (getMySharedData) {
      dispatch(setNotificationNumber(notificationItems.length));
    }
    return {
      receivedItems: getItems(received),
      sharedItems: getItems(shared, true),
      notificationItems,
    };
  }, [getMySharedData]);

  const { request: getSharerInformation, loading: getSharerLoading } =
    useSharerInformation({
      onError: console.log,
      onSucess: () => history.push(ROUTES.dashboard),
    });

  return (
    <Grid container xs={8} className={classes.container}>
      <Grid item xs={12} className={classes.backContainer}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.dashboardHome)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_action_center')}</AboutMeTitle>
        </div>
      </Grid>
      <Grid item xs={12} marginTop="1.625rem">
        <div className={classes.sectionName}>
          <div className={classes.imgContainer}>
            <img
              src={UploadSimpleWhite}
              alt="idIconWhite"
              className={classes.iconImage}
            />
          </div>
          <InfoHeader>{t('data_constellation')}</InfoHeader>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.newShareButtonContainer}>
        <div>
          <GradientButton
            title={t('new_data_share')}
            onClick={() => {
              history.push(ROUTES.newShare);
            }}
            gradientStart={Colors.linkPurple}
            gradientEnd={Colors.gradientDarkPurple}
            icon={ShareArrow}
            isSmall
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.infoContainer}>
        <Grid container xs={12} rowGap={3}>
          <Grid container xs={5}>
            <Grid item xs>
              <div
                className={classes.whoIShareWithSwitch}
                onClick={() => {
                  setIsOnWhoIShare(true);
                  setShowNotifications(false);
                }}
              >
                {t('who_i_share_with')}
              </div>
            </Grid>
            <Grid item xs>
              <div
                className={classes.whoSharesWithMeSwitch}
                onClick={() => {
                  setIsOnWhoIShare(false);
                  setShowNotifications(false);
                }}
              >
                {t('who_shares_with_me')}
              </div>
            </Grid>
          </Grid>
          <Grid container xs={7} display="flex" justifyContent="flex-end">
            <Grid item xs={5}>
              <div
                className={classes.shareButton}
                onClick={() => {
                  setShowNotifications(true);
                }}
              >
                {!!notificationItems.length && (
                  <div className={classes.notificationContainer}>
                    {notificationItems.length}
                  </div>
                )}
                <img
                  src={showNotifications ? Bell : BellDisabled}
                  alt="bellIcon"
                  className={classes.bellIcon}
                />
                {t('share_notifications')}
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.dataContainer}>
            {getMySharedLoading ? (
              <CircularProgress size={20} />
            ) : showNotifications ? (
              notificationItems.length ? (
                notificationItems.map(item => (
                  <Grid
                    key={item.id}
                    container
                    xs={12}
                    className={classes.dataSubContainer}
                  >
                    <ShareNotification
                      {...item}
                      onDeny={() =>
                        requestShare({
                          accept: false,
                          contract: item.id,
                          lastName: item.name,
                        })
                      }
                      onViewShare={() =>
                        history.push(ROUTES.viewShareConfirmation, {
                          itemSelected: item,
                        })
                      }
                    />
                  </Grid>
                ))
              ) : (
                t('no_data_shared')
              )
            ) : !isOnWhoIShare ? (
              receivedItems.length ? (
                receivedItems.map(item => {
                  const { photoUri, sharerName, id } = item;
                  return (
                    <Grid
                      key={id}
                      container
                      xs={12}
                      className={classes.dataSubContainer}
                    >
                      <SharedWithMe
                        photoUri={photoUri}
                        sharerName={sharerName}
                        id={id}
                        hideThreeDotMenu
                        onShareDetails={() =>
                          history.push(ROUTES.shareDetail, {
                            itemSelected: item,
                          })
                        }
                        onViewData={() =>
                          getSharerInformation({
                            contract: item.contract,
                            lastName: item.name,
                          })
                        }
                        onViewDataLoading={getSharerLoading}
                      />
                    </Grid>
                  );
                })
              ) : (
                t('no_data_shared')
              )
            ) : sharedItems.length ? (
              sharedItems.map(item => {
                const { sharerName, id, photoUri } = item;
                return (
                  <Grid
                    key={id}
                    container
                    xs={12}
                    className={classes.dataSubContainer}
                  >
                    <SharedWithMe
                      sharerName={sharerName}
                      id={id}
                      photoUri={photoUri}
                      onShareDetails={() =>
                        history.push(ROUTES.shareDetail, {
                          itemSelected: item,
                        })
                      }
                      onEditShare={() =>
                        history.push(ROUTES.createEditShare, {
                          itemSelected: item,
                          isEdit: true,
                        })
                      }
                      onCancelShare={() =>
                        cancelShareRequest({ contract: id, name: item.name })
                      }
                    />
                  </Grid>
                );
              })
            ) : (
              t('no_data_shared')
            )}
          </Grid>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
              <ThemedButton
                onClick={() => history.push(ROUTES.quickShareCode)}
                title={t('have_a_quick_share_code')}
                isInverted
              />
            </Grid>
            {!isDependent && (
              <Grid item xs={4}>
                <ThemedButton
                  onClick={() => history.push(ROUTES.createDependent)}
                  title={t('create_a_star')}
                  isInverted
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        className={classes.viewExpiredContainer}
        justifyContent="flex-start"
      >
        <div
          className={classes.viewExpiredSubContainer}
          onClick={() => history.push(ROUTES.expired)}
        >
          <div className={classes.clockIconContainer}>
            <img src={Clock} alt="clockIcon" className={classes.clockIcon} />
          </div>
          {t('view_expired_access')}
        </div>
      </Grid>
    </Grid>
  );
};
