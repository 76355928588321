import { LinkComponent } from '../LinkComponent/LinkComponent';
import { CardDetail1, CardDetail2, CardHeader1 } from '../TextComponents';
import { useState } from 'react';
import { Modal } from '../Modal/Modal';
import FirstAidKit from '../../assets/images/FirstAidKit.svg';
import { Lock } from '../../assets/images/icons';
import { ThreeDotMenu } from '../ThreeDotMenu/ThreeDotMenu';
import { HealthDetail, MenuOption } from '../../config/SharedTypes';
import { useTranslation } from 'react-i18next';
import { useHealthDetailCardStyles } from './styles';
import { SecureImageLoader } from '../SecureImageLoader/SecureImageLoader';

interface HealthDetailProps {
  detail: HealthDetail;
  menuOptions?: MenuOption[];
  icon?: string;
  secureImage?: boolean;
}

export const HealthDetailCard: React.FC<HealthDetailProps> = ({
  detail,
  icon = FirstAidKit,
  menuOptions = [],
  secureImage,
}) => {
  const classes = useHealthDetailCardStyles();
  const { t } = useTranslation();

  const { title, subtitle, detailItems, imageLink, sensitive } = detail;
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img width="29px" src={icon} alt={title} />
        </div>
        <div className={classes.title}>
          <div className={classes.header}>
            <CardHeader1>
              {title}
              {sensitive && (
                <img src={Lock} alt="sensitive" className={classes.lockIcon} />
              )}
            </CardHeader1>
            {imageLink && (
              <LinkComponent
                label={t('view_image')}
                onClick={() => setOpenModal(true)}
              />
            )}
          </div>
          <div className={classes.subHeader}>
            <CardDetail1>{subtitle}</CardDetail1>
          </div>
          <div className={classes.detailItems}>
            {detailItems?.map((lineItems, index) => {
              return (
                <div className={classes.detailItemLine} key={index}>
                  {lineItems.map((item, index) => (
                    <CardDetail2 key={item + index}>
                      {!!index && item.length && '●'}
                      {
                        <div
                          className={classes.detailText}
                          style={{ paddingLeft: index > 0 ? '' : '0' }}
                        >
                          {item}
                        </div>
                      }
                    </CardDetail2>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.dotMenu}>
          <ThreeDotMenu options={menuOptions} />
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={title}
        icon={icon}
      >
        {secureImage ? (
          <SecureImageLoader
            imageId={imageLink}
            className={classes.imageModal}
          />
        ) : (
          <img src={imageLink} alt={title} style={{ maxWidth: '70%' }} />
        )}
      </Modal>
    </>
  );
};
