import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { acceptShare, viewShared } from '../../../api/share';
import { useRequest } from '../../../app/hooks';
import { Switch, ThemedButton } from '../../../components';
import {
  arrowLeft,
  CarretDown,
  LockOrange,
  Star,
  ShareHourglassGreen,
  Info,
} from '../../../assets/images/icons';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { ROUTES, sharedNameObject } from '../../../config/constants';
import { ShareItem } from './ShareHome';
import { confirmationShareStyles } from './styles';
import { getItems } from './utils';
dayjs.extend(relativeTime);

interface SharedItemProp {
  label: string;
  items?: { label: string; id: string }[];
  id: string;
}

const SharedItem = ({ label, items = [] }: SharedItemProp) => {
  const classes = confirmationShareStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.sharedItemContainer}>
      <div className={classes.sharedItemLabel}>
        {label}
        {!!items.length && (
          <img
            onClick={() => setOpen(!open)}
            src={CarretDown}
            alt={'down-arrow'}
            className={open ? classes.downArrowInverted : classes.downArrow}
          />
        )}
      </div>
      {open && (
        <>
          <div className={classes.sharedItemLine} />
          <div className={classes.sharedSubItemsContainer}>
            {items.map(({ label, id }) => (
              <div key={id} className={classes.sharedSubItem}>
                ● {label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export const ViewShareConfirmation: React.FC = () => {
  const classes = confirmationShareStyles();
  const history = useHistory();
  const { itemSelected } = (useLocation().state || {}) as {
    itemSelected: ShareItem;
  };
  const [optionSelected, setOptionSelected] = useState<
    'accept' | 'deny' | undefined
  >();
  const {
    error: viewSharedError,
    data: viewSharedData,
    loading: viewDataIsLoading,
    request: viewDataRequest,
  } = useRequest(viewShared);

  // TODO: Show history when api call is fixed
  // const {
  //   data: historyData,
  //   loading: historyIsLoading,
  //   request: historyRequest,
  // } = useRequest(shareHistory);

  useEffect(() => {
    if (viewSharedError) history.push(ROUTES.share);
  }, [viewSharedError]);

  const {
    request: requestShare,
    status: statusShare,
    error,
    loading,
  } = useRequest(acceptShare);

  useEffect(() => {
    if (statusShare === 200) {
      if (optionSelected === 'accept') {
        history.push(ROUTES.newShareConfirmation, { itemSelected });
      }
      if (optionSelected === 'deny') {
        history.push(ROUTES.share);
      }
      setOptionSelected(undefined);
    }
  }, [statusShare]);
  useEffect(() => {
    if (itemSelected) {
      viewDataRequest({
        contract: itemSelected.id,
        lastName: itemSelected.name,
      });
      // TODO: Uncomment when api is fixed
      // historyRequest({ contract: itemSelected.id });
    }
  }, []);

  const acceptDenyRequest = (accept: boolean) => {
    requestShare({
      accept,
      contract: itemSelected.id,
      lastName: itemSelected.name,
    });
    setOptionSelected(accept ? 'accept' : 'deny');
  };

  const { username, neverExpires, expiresDate, includeSensitive, items } =
    useMemo(() => {
      const infoApi = viewSharedData?.with;
      if (!infoApi) {
        return {};
      }
      const { info, basicInfo, expiration, fields } = infoApi;
      const username = basicInfo?.username || '';
      const expiresDate = dayjs(expiration).fromNow();
      const neverExpires = dayjs(expiration).year() > 9000;
      const fieldsFormated = getItems(info);
      const includeSensitive = !!(fields || []).find(
        item => !!item.includeSensitive
      );
      const items = fields.reduce((acc: SharedItemProp[], item) => {
        const sharedResult = sharedNameObject[item.name];
        if (sharedResult) {
          const label = sharedResult.title;
          let resultSubItems: {
            label: string;
            id: string;
            sensitive: boolean;
          }[] = fieldsFormated[sharedResult.key];
          if (!includeSensitive) {
            resultSubItems = resultSubItems.filter(item => !item?.sensitive);
          }
          acc.push({ label, items: resultSubItems, id: sharedResult.key });
        }
        return acc;
      }, []);

      return {
        username: `@${username}`,
        expiresDate,
        neverExpires,
        includeSensitive,
        items,
      };
    }, [viewSharedData]);

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.share)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_action_center')}</AboutMeTitle>
        </div>
      </div>

      {viewDataIsLoading ? (
        <div className={classes.subContainer}>
          <div className={classes.centerLoading}>
            <CircularProgress size={40} />
          </div>
        </div>
      ) : (
        <div className={classes.subContainer}>
          <div className={classes.sectionName}>
            <InfoHeader>{t('new_data_share')}</InfoHeader>
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.username}>{username}</div>
            <div className={classes.line} />
            <div className={classes.subInfoContainer}>
              <div className={classes.topSubContainer2}>
                <img src={Star} alt="star" className={classes.star} />
                {t('new_data_share')}
              </div>
              <div className={classes.topSubContainer}>
                <div className={classes.iconLabel}>
                  <img
                    src={ShareHourglassGreen}
                    alt="expireTime"
                    className={classes.icon}
                  />
                  {t(neverExpires ? 'share_never_expires' : 'share_expires', {
                    date: expiresDate,
                  })}
                </div>
                {!includeSensitive && (
                  <div className={classes.iconLabel}>
                    <img
                      src={LockOrange}
                      alt="sensitive"
                      className={classes.icon}
                    />
                    {t('sensitive_data')}
                  </div>
                )}
              </div>
              <div className={classes.bottomSubContainer}>
                {t('data_being_shared')}
                {items?.map(item => (
                  <SharedItem {...item} key={item.id} />
                ))}
              </div>
            </div>
            <div className={classes.subscribeContainer}>
              {t('subscribe_to_updates')}
              <img src={Info} alt="info" />
              <Switch defaultValue={false} disabled />
            </div>
            <div className={classes.buttonsContainer}>
              <ThemedButton
                title={t('accept_share')}
                onClick={() => acceptDenyRequest(true)}
                isLoading={loading}
              />
              <div
                className={classes.linkContainer}
                onClick={() => !loading && acceptDenyRequest(false)}
              >
                {loading ? <CircularProgress size={20} /> : <>{t('deny')}</>}
              </div>
              {!!error && <div className={classes.errorText}>{error}</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
