import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const styles = {
  cardContainer: {
    display: 'flex',
    margin: '30px 10px',
    width: '80%',
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '15px 26px',
      width: 'unset',
    },
  },
  message: {
    textAlign: 'left',
    gridArea: 'message',
  },
  progressBar: {
    backgroundColor: Colors.white,
    height: '.1em',
    marginBottom: '32px',
    borderRadius: '9px',
  },
  welcomeText: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '29px',
    margin: '32px 0 55px',
  },
  checklist: {
    textAlign: 'left',
  },
  asteriskNote: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    marginLeft: '35px',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  ids: {
    [DESKTOP_VIEW_BREAKPOINT]: {
      display: 'flex',
      gap: '65px',
    },
    margin: '26px 0 34px',
  },
  idSideArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
  },
  reUploadContainer: {
    marginTop: '13px',
  },
  buttonContainer: {
    marginBottom: '33px',
  },
};
