import { APP_CONSTANTS } from '../../config/constants';

export const ConfigVars: React.FC = () => {
  return (
    <>
      <p>
        You are running this app in:{' '}
        {`${process.env.REACT_APP_ENVIRONMENT_NAME}`} tier.
      </p>
      <p>The base API endpoint is set to: {APP_CONSTANTS.apiBaseUrl}</p>
    </>
  );
};
