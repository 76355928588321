import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemedButton } from '../../../../components';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmModal';
import { notificationStyles } from '../styles';

interface ShareNotificationProps {
  sharerName: string;
  id: string;
  name: string;

  onViewShare: () => void;
  onDeny: () => void;
}

const ShareNotification = ({
  sharerName,
  onViewShare,
  onDeny,
}: ShareNotificationProps) => {
  const classes = notificationStyles();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.profileName}>
          <div className={classes.notificationNumberContainer}>1</div>
          {t('notification_title', { username: sharerName })}
        </div>
      </div>

      <div className={classes.buttonsContainer}>
        <ThemedButton
          title={t('view_data_share')}
          onClick={onViewShare}
          isInverted
          small
        />
        <div
          className={classes.linkContainer}
          onClick={() => setShowModal(true)}
        >
          {t('deny')}
        </div>
      </div>
      <ConfirmDeleteModal
        open={showModal}
        title={t('are_you_sure')}
        handleNo={() => {
          setShowModal(false);
        }}
        handleYes={() => {
          onDeny();
          setShowModal(false);
        }}
        message={t('deny_share')}
      />
    </div>
  );
};

export default ShareNotification;
