import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Switch, useRouteMatch } from 'react-router-dom';
import { LinkComponent } from '../../components/LinkComponent/LinkComponent';
import { SubRoute } from '../../config/SharedTypes';
import Route from '../../routes/route';
import { styles } from './styles';
import { VerifyCode } from './verify-code/VerifyCode';

const useCreateAccountPageStyles = createUseStyles(styles);

const subRoutes: (path: string) => SubRoute[] = path => [
  {
    path: `${path}/verifyCode`,
    exact: true,
    isPrivate: false,
    component: VerifyCode,
  },
];

export const GuestAccess = () => {
  const { t } = useTranslation();
  const classes = useCreateAccountPageStyles();
  let { path } = useRouteMatch();

  return (
    <div className={classes.root}>
      <div className={classes.formGridContainer}>
        <div className={classes.formFlex}>
          <div className={classes.form}>
            <Switch>
              {subRoutes(path).map(({ path, component, isPrivate, exact }) => (
                <Route
                  path={path}
                  key={path}
                  component={component}
                  isPrivate={isPrivate}
                  exact={exact}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <LinkComponent
          label={t('privacy')}
          onClick={() => {
            /*ToDo: Add Route*/
          }}
          secondary
        />
        <LinkComponent
          label={t('security')}
          onClick={() => {
            /*ToDo: Add Route*/
          }}
          secondary
        />
        <LinkComponent
          label={t('terms_of_service')}
          onClick={() => {
            /*ToDo: Add Route*/
          }}
          secondary
        />
      </div>
    </div>
  );
};
