import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  detailsButtonText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '0.015em',
  },
});
export const DetailsButtonText: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.detailsButtonText}>{children}</div>;
};
