import React, { useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import { useTextAreaStyles } from './styles';

interface TextAreaProps {
  placeholder: string;
  name?: string;
  formErrors?: any;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  label?: any;
  defaultValue?: string;
}

export const TextArea: React.FC<TextAreaProps> = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(
  (
    {
      placeholder,
      name = 'textarea',
      defaultValue,
      onChange = () => {},
      onBlur = () => {},
      formErrors,
    },
    ref
  ) => {
    const [formValue, setFormValue] = useState(defaultValue || '');
    const [isFocused, setIsFocused] = useState(false);
    const showPlaceholder = isFocused || formValue.length > 0;
    let errorMessage, isError;
    if (formErrors?.hasOwnProperty(name)) {
      errorMessage = formErrors[name].message;
      isError = true;
    }

    const classes = useTextAreaStyles({
      showPlaceholder,
      isError,
      theme: {},
    });
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <textarea
            name={name}
            placeholder={placeholder}
            className={classes.textInputField}
            rows={4}
            value={formValue}
            onChange={e => {
              setFormValue(e.target.value);
              onChange(e);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={e => {
              setIsFocused(false);
              onBlur(e);
            }}
            ref={ref}
          />
          <label
            className={`${classes.label} ${
              showPlaceholder && classes.translate
            }`}
          >
            {placeholder}
          </label>
        </div>
        <span className={classes.errorMessage}>{errorMessage}</span>
      </div>
    );
  }
);
