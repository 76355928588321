import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { flattenAllergy } from '../../allergies/allergyUtils';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const AllergyShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.ALLERGY)
  );
  const flatList = detailList?.map(alg => flattenAllergy(alg));

  const optionsList: ShareDetailLine[] = flatList?.map(
    ({ allergyType, sensitive, id }) => {
      return {
        label: allergyType || '',
        sensitive: sensitive,
        id: id,
      };
    }
  );
  return (
    <ShareSectionSelector
      title={t('allergies')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
