import { useTranslation } from 'react-i18next';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { pill } from '../../../assets/images/icons';
import { HealthDetailCard, ThemedButton } from '../../../components';
import {
  CardDetail2,
  FormBody,
  FormHeader,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { Medication } from '../../../config/SharedTypes';
import Route from '../../../routes/route';
import {
  DashboardData,
  deleteFhirItem,
  dismissFhirSection,
  selectMedications,
} from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { EditMedication } from './EditMedication';
import { useMedicationsStyles } from './styles';
import { flattenMedication } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

export const defaultMedications: DashboardData = {
  dismissed: false,
  notificationCount: 0,
};

type MedicationsProps = {
  details?: Medication[];
  listOnly?: boolean;
};

export const Medications: React.FC<MedicationsProps> = ({ listOnly }) => {
  const classes = useMedicationsStyles();
  const { t } = useTranslation();
  const { detailList, dismissed } = useAppSelector(selectMedications);
  const medications = detailList?.map(med => flattenMedication(med));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const medicationMenuOptions = detail => [
    {
      title: t('edit'),
      onClick: () =>
        history.push({
          pathname: `${path}/edit`,
          search: `?id=${detail.id}`,
        }),
    },
    {
      title: t('delete'),
      onClick: () =>
        dispatch(
          deleteFhirItem({
            section: DashboardSections.MEDICATIONS,
            id: detail.id,
          })
        ),
    },
  ];

  if (listOnly) {
    // Only return the list of medications, used for share
    return (
      <div className={classes.listView}>
        {!!medications?.length && !dismissed && (
          <div className={classes.currentMedsTitle}>
            <SubHeader3>{t('current_meds_all_caps')}</SubHeader3>
          </div>
        )}
        <div className={classes.cardList}>
          {medications?.map(medication => {
            if (medication.current) {
              return (
                <div key={medication.id} className={classes.detailCard}>
                  <HealthDetailCard detail={medication} icon={pill} />
                </div>
              );
            }
            return null;
          })}
        </div>
        {!!medications?.length && !dismissed && (
          <div className={classes.pastMedsTitle}>
            <SubHeader3>{t('past_meds_all_caps')}</SubHeader3>
          </div>
        )}
        <div className={classes.cardList}>
          {medications?.map(medication => {
            if (!medication.current) {
              return (
                <div key={medication.id} className={classes.detailCard}>
                  <HealthDetailCard detail={medication} icon={pill} />
                </div>
              );
            }
            return null;
          })}
          {!medications?.length && <FormBody>{t('nothing_to_share')}</FormBody>}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.contentBox}>
        <FormHeader>{t('medications_title_case')}</FormHeader>
        <Switch>
          <Route
            path={`${path}/edit`}
            component={EditMedication}
            isPrivate={false}
          />
          <Route
            isPrivate={false}
            component={() => (
              <>
                {!!medications?.length && !dismissed && (
                  <div className={classes.currentMedsTitle}>
                    <SubHeader3>{t('current_meds_all_caps')}</SubHeader3>
                  </div>
                )}
                <div className={classes.cardList}>
                  {medications?.map(medication => {
                    if (medication.current) {
                      return (
                        <div key={medication.id} className={classes.detailCard}>
                          <HealthDetailCard
                            detail={medication}
                            icon={pill}
                            menuOptions={medicationMenuOptions(medication)}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                {!!medications?.length && !dismissed && (
                  <div className={classes.pastMedsTitle}>
                    <SubHeader3>{t('past_meds_all_caps')}</SubHeader3>
                  </div>
                )}
                <div className={classes.cardList}>
                  {medications?.map(medication => {
                    if (!medication.current) {
                      return (
                        <div key={medication.id} className={classes.detailCard}>
                          <HealthDetailCard
                            detail={medication}
                            icon={pill}
                            menuOptions={medicationMenuOptions(medication)}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                {!isShareUser && (
                  <>
                    <div className={classes.buttonContainer}>
                      <ThemedButton
                        title={t('add_med')}
                        isInverted
                        onClick={() => history.push(ROUTES.medicationsEdit)}
                      />
                      {!medications && (
                        <ThemedButton
                          title={t('no_thing_to_add', {
                            thing: 'medications or supplements',
                          })}
                          isInverted
                          onClick={() => {
                            dispatch(
                              dismissFhirSection({
                                section: DashboardSections.MEDICATIONS,
                              })
                            );
                          }}
                        />
                      )}
                    </div>
                    <div className={classes.editAnytime}>
                      <CardDetail2>{t('can_add_edit_all_caps')}</CardDetail2>
                    </div>
                  </>
                )}
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
};
