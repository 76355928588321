import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const useImmunizationsStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '25px 0 0 70px',
      maxWidth: '70%',
    },
  },
  addPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Colors.primaryText,
    width: '100%',
  },
  subheader: {
    margin: '12px 0 19px 0',
    textAlign: 'left',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.white,
    borderRadius: '6px',
    padding: '25px 25px 25px 0',
  },
  immunizations: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
    width: '38rem',
  },
  detailCard: {
    display: 'flex',
  },
  buttonContainer: {
    width: '21.2rem',
    height: '3.2rem',
    borderRadius: '10px',
  },
  editAnytime: {
    color: Colors.disabledGrey,
    marginTop: '18px',
  },
  searchBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  title: {
    color: Colors.accent5Purple,
  },
  line: {
    height: '1px',
    width: '100%',
    margin: '25px 0 24px',
    background: Colors.notificationBorder,
  },
  doseDates: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  doseDateContainer: {
    width: '22rem',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    color: Colors.secondaryText,
    marginTop: '10px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '33%',
    },
  },
});

export const useImmunizationCardStyles = createUseStyles({
  immunizationCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '26px',
    padding: '15px 20px 20px 20px',
    borderRadius: '6px',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.notificationBorder}`,
  },
  topSection: {
    margin: '0 -20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
  },
  cardTitle: {
    height: '32px',
  },
  middleSection: {
    margin: '0 -20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
    textAlign: 'left',
  },
  doseDates: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '15px',
    width: '45rem',
    marginBottom: '21px',
  },
  doseDateContainer: {
    width: '22rem',
  },
  inputFieldContainer: {
    width: '100%',
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },
  imageUploadedOptions: {
    display: 'flex',
    gap: '30px',
  },
});

export const useEditImmunizationStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '6px',
    padding: '25px 25px 25px 0',
  },
  pastAllergies: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '34px',
    color: Colors.secondaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '33%',
    },
  },
  editAnytime: {
    color: Colors.disabledGrey,
    marginTop: '18px',
  },
});
