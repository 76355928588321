import { SingleInsurance } from '../../../typings/vecna_types/vecnaPatient';
import { getUUID } from '../../../config/utilities';

export const formToInsurance = data => {
  const result: SingleInsurance = {
    fhirData: {
      id: data?.id || getUUID(),
      resourceType: 'Coverage',
      status: 'draft',
      payor: [{ display: data.provider }],
      class: [
        {
          type: { text: data?.type?.value || data?.type },
          value: data.groupNumber,
        },
      ],
      beneficiary: {
        display: data.subName,
        identifier: { value: data.subNumber },
      },
      subscriberId: data.payerId,
    },
    additionalData: {
      active: true,
      portalUrl: data.portalUrl,
      photo: [
        { fhirData: { url: data.frontImage } },
        { fhirData: { url: data.backImage } },
      ],
    },
  };

  return result;
};

export const flattenInsurance = (data: SingleInsurance) => {
  if (!data) {
    return {};
  }
  return {
    id: data.fhirData.id,
    status: data.fhirData.status,
    provider: data.fhirData.payor[0].display,
    type: data.fhirData.class[0].type.text,
    groupNumber: data.fhirData.class[0].value,
    subName: data.fhirData.beneficiary.display,
    subNumber: data.fhirData.beneficiary.identifier.value,
    frontImage: data.additionalData.photo[0].fhirData.url,
    backImage: data.additionalData.photo[1].fhirData.url,
    payerId: data.fhirData.subscriberId,
    portalUrl: data.additionalData.portalUrl,
    portalUsername: '',
    portalPassword: '',
    active: data.additionalData.active,
    verified: false, //ToDo: update when we have verification of insurance
    verificationPending: true, //ToDo: update when we have verification of insurance
  };
};

export const insuranceTypes = [
  { label: 'Medical', value: 'M' },
  { label: 'Dental', value: 'D' },
  { label: 'Vision', value: 'V' },
  { label: 'Supplemental', value: 'S' },
  { label: 'Perscription', value: 'P' },
  { label: 'Other', value: 'O' },
];

export const insTypeToLabel = val => {
  let result = 'Other';
  insuranceTypes.forEach(obj => {
    if (obj.value === val) {
      result = obj.label;
    }
  });
  return result;
};
