import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkComponent, Modal } from '..';
import { getVaultDocURL } from '../../api/vaultUtils';
import {
  DarkPurpleTrash,
  Document,
  DownloadIcon,
  EditIcon,
  Lock,
  Printer,
  PurpleCheckCircle,
  ShareIcon,
} from '../../assets/images/icons';
import { StyledDisplayTag } from '../StyledDisplayTag/StyledDisplayTag';
import { CardBody, CardHeader1, FormPrompt } from '../TextComponents';
import { HorizontalBreak } from '../TextComponents/HorizontalBreak';
import { ThreeDotIconsMenu } from '../ThreeDotIconsMenu/ThreeDotIconsMenu';
import { ConfirmDeleteDocumentModal } from './ConfirmDeleteModal';
import { ShowVaultItem } from './ShowVaultItem';
import { useVaultDocTableCardStyles } from './styles';
import { downloadFile } from './utils';
import { ViewModalActions } from './ViewModalActions';

type VaultDocTableCardProps = {
  document: any;
  displayOnly?: boolean;
};

export const VaultDocTableCard: React.FC<VaultDocTableCardProps> = ({
  document,
  displayOnly,
}) => {
  const { title, creation, tags, sensitive, deathNote, id, contentType, url } =
    document || {};
  const uploadDate = dayjs(creation).format('MM-DD-YYYY');
  const { t } = useTranslation();
  const classes = useVaultDocTableCardStyles();
  const [openModal, setOpenModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  useState(() => {
    const doAsync = async () => {
      const url = await getVaultDocURL(id);
      setDownloadUrl(url);
    };
    doAsync();
  });

  const handleClose = () => {
    setShowDelete(false);
  };

  const vaultDocsMenuOptions = docId => [
    // TODO: add onClick functions to each option
    {
      title: t('edit'),
      onClick: () => console.log('edit', docId),
      // TODO: add onClick function
      icon: EditIcon,
    },
    {
      title: t('remove'),
      onClick: () => {
        setShowDelete(true);
      },
      // TODO: add onClick function
      icon: DarkPurpleTrash,
    },
    {
      title: t('share'),
      onClick: () => console.log('share'),
      // TODO: add onClick function
      icon: ShareIcon,
    },
    {
      title: t('download'),
      onClick: () => {
        downloadFile(downloadUrl);
      },
      // TODO: add onClick function
      icon: DownloadIcon,
    },
    {
      title: t('print'),
      onClick: () => console.log('print'),
      // TODO: add onClick function
      icon: Printer,
    },
  ];

  if (displayOnly) {
    return (
      <div className={classes.displayCard}>
        <div className={classes.displayCardTitle}>
          <div
            style={{ display: 'flex', alignItems: 'flex-start', gap: '5px' }}
          >
            <CardHeader1>{title}</CardHeader1>
            {sensitive && <img src={Lock} alt="lock" width="19px" />}
          </div>
          <LinkComponent
            label={t('view_document')}
            onClick={() => setOpenModal(true)}
            // TODO: navigate to PDF view page
          />
        </div>
        <div className={classes.displayCardUploaded}>
          <span>
            {t('uploaded_on_date', { uploadDate }).replaceAll('-', '/')}
          </span>
        </div>
        <div className={classes.displayCardTags}>
          {tags?.map((tag, index) => (
            <StyledDisplayTag tagName={tag} key={index} />
          ))}
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={title}
          icon={Document}
          actions={() => (
            <ViewModalActions
              actions={{
                onDownload: () => {
                  downloadFile(downloadUrl);
                },
              }}
              createDate={creation}
              tags={tags}
            />
          )}
        >
          <ShowVaultItem id={id} type={contentType} url={url} />
          <ConfirmDeleteDocumentModal
            open={showDelete}
            handleClose={handleClose}
            document={document}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.tableRow}>
        <div className={classes.nameColumn}>
          <FormPrompt>{title}</FormPrompt>
          {sensitive && <img src={Lock} alt="lock" width="19px" />}
        </div>
        <div className={classes.uploadDateColumn}>
          <CardBody>{uploadDate}</CardBody>
        </div>
        <div className={classes.tagsColumn}>
          {tags?.map(tag => (
            <StyledDisplayTag tagName={tag} />
          ))}
        </div>
        <div className={classes.deathNoteColumn}>
          {deathNote ? (
            <img src={PurpleCheckCircle} alt="dnCheck" width="20px" />
          ) : (
            <div style={{ width: '20px' }} />
          )}
        </div>
        <div className={classes.viewAndMenu}>
          <LinkComponent
            label={t('view')}
            onClick={() => setOpenModal(true)}
            // TODO: navigate to PDF view page
          />
          {!displayOnly && (
            <ThreeDotIconsMenu options={vaultDocsMenuOptions(id)} />
          )}
        </div>
      </div>
      <ConfirmDeleteDocumentModal
        open={showDelete}
        handleClose={handleClose}
        document={document}
      />
      <HorizontalBreak />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={title}
        icon={Document}
        actions={() => (
          <ViewModalActions
            actions={{
              onDelete: () => {
                setShowDelete(true);
              },
              onDownload: () => {
                downloadFile(downloadUrl);
              },
            }}
            createDate={creation}
            tags={tags}
          />
        )}
      >
        <ShowVaultItem id={id} type={contentType} url={url} />
        <ConfirmDeleteDocumentModal
          open={showDelete}
          handleClose={handleClose}
          document={document}
        />
      </Modal>
    </div>
  );
};
