import { useHistory } from 'react-router-dom';
import { Colors } from '../../config/colors';
import { CardDetail2, CardHeader } from '../TextComponents';
import { DrawerNotifications } from '../TextComponents/DrawerNotifications';
import { useNavigationTileStyles } from './styles';

interface NavigationTileProps {
  title: string;
  description: string;
  icon: string;
  notificationCount: number;
  path: string;
  disabled?: boolean;
  iconBg?: string;
}

export const NavigationTile: React.FC<NavigationTileProps> = ({
  title,
  icon,
  notificationCount,
  path,
  description,
  iconBg = Colors.accent5Purple,
  disabled,
}) => {
  const classes = useNavigationTileStyles({ disabled, iconBg, theme: {} });
  const history = useHistory();
  return (
    <div className={classes.container} onClick={() => history.push(path)}>
      <div className={classes.imageContainer}>
        {!!notificationCount && (
          <div className={classes.notifContainer}>
            <DrawerNotifications>{notificationCount}</DrawerNotifications>
          </div>
        )}
        <div className={classes.iconBackground}>
          <img width="34px" src={icon} alt={title} />
        </div>
      </div>
      <div className={classes.title}>
        <div className={classes.header}>
          <CardHeader>{title}</CardHeader>
        </div>
        <CardDetail2>{description}</CardDetail2>
      </div>
    </div>
  );
};
