import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useDashboardDrawerStyles: (
  data?: { isInDrawer: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  'container' | 'imageContainer' | 'notifContainer' | 'title' | 'notifications'
> = createUseStyles({
  container: ({ isInDrawer }) => ({
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    boxShadow: isInDrawer ? `0px 0px 7px 2px ${Colors.linkPurple}` : '',
    height: '3.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '15px 0 15px 0',
  }),
  imageContainer: {
    display: 'flex',
    marginRight: '20px',
  },
  notifContainer: {
    display: 'flex',
    paddingBottom: '25px',
    paddingLeft: '35px',
    position: 'absolute',
  },
  title: {
    marginLeft: '25px',
  },
  notifications: {
    color: Colors.white,
    position: 'absolute',
    paddingLeft: '5.1px',
    paddingTop: '2px',
  },
});
