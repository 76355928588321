import { Colors } from '../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../config/constants';
import { badgeContainer, badgeImg } from '../../config/sharedStyles';

// This cardContainer style can be reused by the subpages
export const cardContainer = {
  display: 'flex',
  margin: '15px 10px',
  width: '80%',
  [DESKTOP_VIEW_BREAKPOINT]: {
    margin: '15px 26px',
    width: 'unset',
  },
};

export const styles = {
  badgeContainer,
  badgeImg,
  root: {
    color: 'white',
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto 1fr 0.05fr',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: `'message'
    'form'
    'footer'`,
    padding: '20px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      gridTemplateRows: '0.05fr 1fr 0.05fr',
      gridTemplateColumns: '1fr 1fr 1.3fr',
      gridTemplateAreas: `'gutter gutter gutter'
                  'message message form'
                  'footer footer footer'`,
      height: '100%',
      padding: '0 60px',
    },
  },
  message: {
    display: 'flex',
    gridArea: 'message',
    flexDirection: 'column',
    [DESKTOP_VIEW_BREAKPOINT]: {
      justifyContent: 'unset',
      paddingTop: '45px',
      height: '68%',
      minWidth: 'unset',
      maxWidth: '660px',
      textAlign: 'left',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: Colors.black,
    borderRadius: '10px',
    alignItems: 'center',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '10px 0',
      backgroundColor: Colors.formBackgroundWhite,
      alignItems: 'unset',
      maxWidth: '500px',
      minWidth: '400px',
    },
  },
  formGridContainer: {
    gridArea: 'form',
  },

  footer: {
    display: 'flex',
    gridArea: 'footer',
    justifyContent: 'space-evenly',
    margin: '10px 0',
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: 'unset',
      justifyContent: 'flex-start',
      gap: '30px',
    },
    height: '2.5rem',
  },
  alive: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: '48px',
    letterSpacing: '0.015em',
    paddingBottom: '6px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      fontSize: '100px',
      lineHeight: '121px',
      paddingBottom: '14px',
    },
  },
  secondText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '0.015em',
    paddingBottom: '15px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      fontSize: '48px',
      lineHeight: '58px',
      paddingBottom: '25px',
    },
  },
  thirdText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.015em',
    paddingBottom: '30px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      fontSize: '24px',
      lineHeight: '29px',
      paddingBottom: '55px',
    },
  },
};
