import { APP_CONSTANTS } from '../config/constants';
import { CreateAccountData } from '../config/SharedTypes';

const EndpointPaths = {
  createLogin: '/users/me/signup',
  login: '/users/me/login',
};

export const login = (username: string, password: string) => {
  const data = {
    username,
    password,
  };

  const config = {
    method: 'post',
    url: APP_CONSTANTS.apiBaseUrl + EndpointPaths.login,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  return config;
};

export const createAccountClientConfig = (
  accountInfo: CreateAccountData,
  initialData
) => {
  const firstName = initialData.fhirData.name[0].given[0];
  const lastName = initialData.fhirData.name[0].family;
  const data = {
    firstName,
    lastName,
    ...accountInfo,
    initialData,
  };

  const config = {
    method: 'post',
    url: APP_CONSTANTS.apiBaseUrl + EndpointPaths.createLogin,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  return config;
};
