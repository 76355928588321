import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { SingleImmunization } from '../../../typings/vecna_types/vecnaPatient';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ThemedButton } from '../../../components';
import { SubHeader3 } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import { getUUID } from '../../../config/utilities';
import { selectImmunizations, updateImmunization } from '../dashboardSlice';
import { ImmunizationCard } from './ImmunizationCard';
import { useEditImmunizationStyles } from './styles';
import { editFormToImmunizations, flattenImmunizationList } from './utils';

type HealthDetailProps = {
  detail?: HealthDetail;
};

export type EditImmuizationForm = {
  title: string;
  doseDates: Array<{
    doseDate: string;
  }>;
  subtitle: string;
  imageLink: string;
  codeString: string;
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditImmunization: React.FC<HealthDetailProps> = () => {
  const classes = useEditImmunizationStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList } = useAppSelector(selectImmunizations);
  const immunizations = flattenImmunizationList(detailList);

  const queryId = useQuery().get('id');

  const [immunizatonId] = useState(queryId || getUUID());
  const history = useHistory();
  const immunizationData: SingleImmunization = immunizations.find(
    el => el.fhirData.id === immunizatonId
  );
  const immunizationGroupIdentifier =
    immunizationData?.fhirData?.identifier[0]?.value;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditImmuizationForm>();

  const onSubmit = data => {
    const result = editFormToImmunizations(data);
    const update = {
      oldIdentifier: immunizationGroupIdentifier,
      newItems: result,
    };
    dispatch(updateImmunization(update));
    history.push(ROUTES.immunizationsDashboard);
  };

  const onError = errors => {
    console.error(errors);
  };

  return (
    <>
      <SubHeader3>{t('please_add_additionals')}</SubHeader3>
      <div className={classes.container}>
        <div className={classes.editForm}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ImmunizationCard
              immunization={immunizationData}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <div
              style={{
                paddingTop: '12px',
                maxWidth: '222px',
                display: 'flex',
                gap: '20px',
              }}
            >
              <ThemedButton title={t('save')} type="submit" />
              <ThemedButton
                title={t('cancel', { thing: 'immunization' })}
                isInverted
                onClick={() => {
                  history.push(ROUTES.immunizationsDashboard);
                }}
              />
            </div>
          </form>
        </div>
        <div className={classes.editAnytime}>
          <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
        </div>
      </div>
    </>
  );
};
