import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import { SideTextLessBold } from '../../../components/TextComponents/SideTextLessBold';

const useAccountSelectTextStyles = createUseStyles(styles);

export const AccountSelectText = () => {
  const { t } = useTranslation();
  const classes = useAccountSelectTextStyles();

  return (
    <div className={classes.message}>
      <div className={classes.title}>{t('getting_started')}</div>
      <ProgressBar progressWidth={2} />
      <div className={classes.textLeft}>
        <SideTextLessBold>{t('account_select_text')}</SideTextLessBold>
      </div>
    </div>
  );
};
