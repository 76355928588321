import { Patient, VecnaPatient } from '../../../typings';
import { getUUID } from '../../../config/utilities';
import { LoginFormType } from '../create-login/CreateLogin';
import { ConfirmInformationFormData } from './ConfirmInformation';

export const createNewUser = (
  userData: ConfirmInformationFormData,
  accountData: LoginFormType,
  userId?: string
): VecnaPatient => {
  const fhir: Patient = {
    resourceType: 'Patient',
    address: [
      {
        id: getUUID(),
        state: userData.state,
        city: userData.city,
        line: [userData.address],
        isMailingAddress: true,
      },
    ],
    birthDate: userData.dateOfBirth,
    gender: getGender(userData.gender),
    contact: [
      // { //example for later
      //   name: {
      //     family: 'Peterson',
      //     given: ['Jhon'],
      //   },
      //   relationship: [],
      //   telecom: [{ value: '8342321234' }],
      // },
    ],
    name: [
      {
        family: userData.lastName,
        given: [userData.firstName],
        use: 'official',
      },
    ],
    photo: [{ url: '' }],
    telecom: [{ value: accountData?.phone || '' }],
    maritalStatus: { text: '' },
  };

  const newUser: VecnaPatient = {
    id: userId || getUUID(),
    email: accountData.email,
    fhirData: fhir,
    healthData: {
      medicalEncounters: undefined,
      medications: undefined,
      immunizations: undefined,
      allergies: undefined,
      conditions: undefined,
    },
    additionalData: {
      ssn4: userData.ssn,
      idNumber: userData.idNumber,
      idExpiration: userData.idExpiration,
      idType: userData.idType,
      username: accountData.username,
      preferredName: '',
      identityGender: '', //ToDo Is this the right place for this?
      military: {
        status: { fhirData: { text: '' } }, //Possible Values? V
        retirementDate: '', //Should this be undefinded on create?
        identifier: '',
      },
      physical: {
        height: NaN, // TODO: no input yet
        heightUnit: 'in', // TODO: no input yet
        eyeColor: { fhirData: { text: '' } }, // TODO: no input yet
        ethnicity: { fhirData: { text: '' } }, //ToDo No intake input yet
      },
      householdIncome: { fhirData: { text: '' } },
      stateIdPhoto: [
        {
          fhirData: { url: '' }, // Front
        },
        {
          fhirData: { url: '' }, // Back
        },
      ],
    },
    familyHistory: undefined,
    insuranceData: undefined,
    vaultData: [],
  };

  return newUser;
};

export const getGender = gender => {
  const { value } = gender;
  if (value.toLowerCase() === 'female') {
    return 'female';
  } else if (value.toLowerCase() === 'male') {
    return 'male';
  } else return 'other';
};
