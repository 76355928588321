import { cardContainer } from '../styles';
import {
  textPageStyles,
  centeredContent,
  bottomButton,
  errorText,
} from '../../../config/sharedStyles';
import { Colors } from '../../../config/colors';
import { TABLET_VIEW_BREAKPOINT } from '../../../config/constants';

export const styles = {
  cardContainer,
  ...textPageStyles,
  centeredContent,
  errorText,
  subTitle: {
    color: Colors.primaryText,
  },
  bottomButton: {
    ...bottomButton,
    marginTop: '20px',
  },
  codeInputArea: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    [TABLET_VIEW_BREAKPOINT]: {
      gap: '26px',
      margin: '32px 0 34px',
    },
    margin: '16px 0 17px',
  },
  digitInputBox: {
    border: `1px solid ${Colors.checkboxGray}`,
    borderRadius: '10px',
    [TABLET_VIEW_BREAKPOINT]: {
      width: '74px',
      height: '96px',
      fontSize: '60px',
    },
    width: '37px',
    height: '48px',
    fontSize: '24px',
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontWeight: '500',
    color: Colors.primaryText,
    textTransform: 'uppercase',
  },
};
