import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';

export const useNotificationStyles = createUseStyles({
  container: {
    backgroundColor: Colors.notificationBackground,
    borderRadius: '10px',
    border: `1px solid ${Colors.notificationBorder}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleArea: {
    display: 'flex',
    color: Colors.notificationTitles,
    alignItems: 'center',
    margin: '10px 0 12px 0',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginLeft: '10px',
  },
  lineContainer: {
    width: '100%',
  },
  line: {
    backgroundColor: Colors.notificationBorder,
    height: '1px',
    margin: '0 1rem 0 1rem',
  },
  bottomHalf: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subTitle: {
    color: Colors.notificationTitles,
    fontSize: '1rem',
    fontWeight: '500',
    margin: '10px 0 12px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    width: '60%',
    height: '50%',
    margin: '0 233px 0 233px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '13px 0 8px',
    fontSize: '1rem',
  },
});
