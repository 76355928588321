import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';
import { actionButtons } from '../../config/sharedStyles';
import { Classes } from 'jss';

export const useFamilyDetailCardStyles: (data?: {
  displayOnly: boolean;
  theme?: Jss.Theme | undefined;
}) => Classes<any> = createUseStyles({
  actionButtons,
  container: ({ displayOnly }) => ({
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    maxWidth: '700px',
    width: '100%',
    margin: '15px 0 15px 0',
    position: 'relative',
    boxShadow: displayOnly ? 'none' : `0px 2px 7px 2px ${Colors.shadow}`,
    border: displayOnly ? `1px solid ${Colors.lightPurple}` : 'none',
    paddingBottom: displayOnly ? '0' : '20px',
  }),
  additionalContainer: {
    padding: '10px 0',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 21px 6px 22px',
  },
  iconAndTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  conditionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  diseaseTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  controls: {
    display: 'flex',
    gap: '28px',
    alignItems: 'center',
  },
  editContainer: ({ displayOnly }) => ({
    padding: displayOnly ? '0 0 0 65px' : '0 36px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  }),
  conditionContainer: ({ displayOnly }) => ({
    borderTop: `1px solid ${Colors.lightPurple}`,
    borderBottom: displayOnly ? 'none' : `1px solid ${Colors.lightPurple}`,
    boxSizing: 'border-box',
    padding: '16px 0',
    marginTop: '16px',
  }),
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '29px',
    paddingRight: '12px',
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dotMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-7px',
    right: '-14px',
  },
  newCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '45px',
    padding: '9px 0 0 65px',
  },
  subHeader: {
    marginBottom: '9px',
  },
  leftIndent: {
    paddingLeft: '65px',
  },
  infoSection: {
    paddingLeft: '65px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  addAnother: {
    margin: '21px 0 4px 65px',
  },
  noInfo: {
    margin: '16px 0 16px 65px',
  },
});

export const useFamilyConditionCardStyles = createUseStyles({
  conditionCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '26px',
    paddingTop: '15px',
    backgroundColor: Colors.white,
  },
  cardTitle: {
    height: '32px',
    margin: '0 -20px',
    paddingLeft: '20px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
  },
  middleSection: {
    paddingBottom: '8px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
  },
  currentToggle: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
  },
  dateFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  inputFieldContainer: {
    width: '100%',
  },
  break: {
    margin: '0 -20px',
  },
});

export const useCheckboxStyles = createUseStyles({
  label: {
    fontSize: '16px',
    fontWeight: '400',
  },
  container: () => ({
    display: 'flex',
    margin: '10px 0',
    position: 'relative',
    paddingLeft: '25px',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    color: Colors.primaryText,
    '& input': {
      position: 'absolute',
      opacity: '0',
      cursor: 'pointer',
      height: '0',
      width: '0',
      '&:checked': {
        '& ~ $checkmark': {
          /* When the checkbox is checked, add a blue background */
          backgroundColor: '#5C74EC',
          '&:after': {
            display: 'block',
          },
        },
      },
    },
    '& $checkmark': {
      '&:after': {
        top: '2px',
        left: '6px',
        width: '5px',
        height: '9px',
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(45deg)',
      },
    },

    /* On mouse-over, add a grey background color */
    '&:hover': {
      '& input': {
        '& ~ $checkmark': {
          backgroundColor: '#ccc',
        },
      },
    },
  }),
  checkmark: () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    borderRadius: '4px',
    backgroundColor: '#C4C4C4',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  }),
});

export const useAddFamConditionStyles = createUseStyles({
  actionButtons,
  searchBox: {
    margin: '-18px -16px',
  },
  addSection: {
    padding: '10px 20px 0 20px',
  },
  newConditonCardContainer: {
    position: 'relative',
  },
  newConditionCard: {},
  trashButtonContainer: {
    position: 'absolute',
    right: '10px',
  },
  trashButton: {
    padding: '10px',
    '&:hover': {
      borderRadius: '25px',
      border: `1px ${Colors.iconHoverBorder} solid`,
    },
  },
});
