import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { Lock } from '../../assets/images/icons';
import {
  deleteFamHistItem,
  editFamHistItem,
} from '../../features/dashboard/dashboardSlice';
import { CardDetail1, CardDetail2 } from '../TextComponents';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import { ThreeDotMenu } from '../ThreeDotMenu/ThreeDotMenu';
import { EditFamilyConditionCard } from './FamilyConditionCard';
import { useFamilyDetailCardStyles } from './styles';
import { condFromFormData, flattenConditions } from './utils';

type props = {
  condition: any;
  famId: string;
  displayOnly?: boolean;
  isDeceased?: Boolean;
};

export const ConditionDetail: React.FC<props> = ({
  condition,
  famId,
  displayOnly,
  isDeceased,
}) => {
  const { t } = useTranslation();
  const classes = useFamilyDetailCardStyles({ displayOnly });
  const flatCondition = flattenConditions([condition])[0];
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = data => {
    const update = {
      famId,
      itemId: flatCondition.id,
      condition: condFromFormData({ id: flatCondition.id, ...data.conditions }),
    };
    dispatch(editFamHistItem(update));
    unregister();
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      setValue(
        'conditions.causeDeath',
        flatCondition.contributedToDeath ? t('yes') : t('no')
      );
    } else {
      unregister();
    }
    return () => {
      unregister();
    };
  }, [isEditing]);

  return (
    <>
      <div className={classes.editContainer}>
        {isEditing ? (
          <form>
            <EditFamilyConditionCard
              register={register}
              condition={flatCondition}
              isDeceased={isDeceased}
              index={0}
              errors={errors}
            />
            <div className={classes.actionButtons}>
              <ThemedButton
                type="button"
                title={t('save')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            </div>
          </form>
        ) : (
          <>
            <div className={classes.conditionHeader}>
              <div className={classes.diseaseTitle}>
                {flatCondition.sensitive && (
                  <img src={Lock} alt="sensitive" width="24px" />
                )}
                <CardDetail1>{flatCondition.title}</CardDetail1>
              </div>
              {!displayOnly && (
                <ThreeDotMenu
                  options={[
                    {
                      title: t('edit'),
                      onClick: () => {
                        setIsEditing(true);
                      },
                    },
                    {
                      title: t('delete'),
                      onClick: () => {
                        dispatch(
                          deleteFamHistItem({
                            famId,
                            itemId: flatCondition.id,
                          })
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
            <CardDetail2>
              {`${t('diagnosed_date')} ● ${flatCondition.onsetPeriod}`}
            </CardDetail2>
          </>
        )}
      </div>
    </>
  );
};
