import { t } from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ShowCodeBackground } from '../../../assets/images';
import { arrowLeft } from '../../../assets/images/icons';
import { ThemedButton } from '../../../components';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { showCode } from './styles';

export const ShowCode: React.FC = () => {
  const classes = showCode();
  const history = useHistory();
  const {
    isQuickShare = false,
    email = '',
    code = '',
  } = useLocation().state || ({} as any);
  return (
    <div className={classes.container}>
      <div
        className={classes.backToAction}
        onClick={() => history.push(ROUTES.share)}
      >
        <div className={classes.arrowContainer}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
      </div>
      <div className={classes.shareHeader}>
        <div className={classes.sectionName}>
          <InfoHeader>
            {t(
              isQuickShare ? 'quick_share_code' : 'data_shared_for',
              isQuickShare
                ? { code }
                : {
                    shareIdentifier: email,
                  }
            )}
          </InfoHeader>
        </div>
      </div>
      <div className={classes.shadowContainer}>
        <img
          src={ShowCodeBackground}
          alt="showBackgroundHeader"
          className={classes.showCodeHeaderImage}
        />
        <div className={classes.header}>{t('your_share_code')}</div>
        <div className={classes.codeContainer}>{code}</div>
        <div className={classes.codeInfoContainer}>
          <div className={classes.codeInfo}>{t('code_info')}</div>
          <div className={classes.codeInfoItem}>
            <div>●</div>
            {t('code_info_item_1')}
          </div>
          <div className={classes.codeInfoItem}>
            <div>●</div>
            {t('code_info_item_2')}
          </div>
        </div>
        {/* TODO: add share code */}
        <div className={classes.shareCodeButton}>
          <ThemedButton
            title={t('share_code')}
            small
            isInverted
            onClick={() => {
              window.location.href = `mailto:?subject=${t(
                'share_email_subject'
              )}&body=${t('share_email_body', {
                code,
                link: encodeURIComponent(
                  `${process.env.REACT_APP_WEB_URL}${ROUTES.shareEnterCode}`
                ),
              })}`;
            }}
          />
        </div>
        <div
          className={classes.doneButton}
          onClick={() => history.push(ROUTES.share)}
        >
          {t('done')}
        </div>
      </div>
    </div>
  );
};
