import { useEffect } from 'react';
import { OneLineTextInput } from '../../../components';
import { sharedValidators, noSpaceInput } from '../../../config/constants';
import i18n from '../../../translations';
import { useTranslation } from 'react-i18next';

type Props = {
  contact: any;
  formControls: any;
  classes: any;
  index: number;
};
export const EmergencyContact: React.FC<Props> = ({
  contact,
  index,
  formControls,
  classes,
}) => {
  const { register, unregister, errors } = formControls;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      unregister(`emergencyContact.${index}`);
    };
  }, []);

  return (
    <>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('first_name')}
          type="text"
          formErrors={errors}
          defaultValue={contact.firstName}
          {...register(`emergencyContact.${index}.firstName`, {
            required: `${t('provide_first_name')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('provide_first_name')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('last_name')}
          type="text"
          formErrors={errors}
          defaultValue={contact.lastName}
          {...register(`emergencyContact.${index}.lastName`, {
            required: `${t('provide_last_name')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('provide_last_name')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('relationship')}
          type="text"
          formErrors={errors}
          defaultValue={contact.relationship}
          {...register(`emergencyContact.${index}.relationship`, {
            required: `${t('provide_relation')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('provide_relation')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('phone_number')}
          type="text"
          formErrors={errors}
          defaultValue={contact.phoneNumber}
          {...register(`emergencyContact.${index}.phoneNumber`, {
            required: `${t('provide_number')}`,
            ...sharedValidators.phoneNumber,
          })}
        />
      </div>
    </>
  );
};
