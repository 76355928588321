import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ENDPOINTS } from '../../api/axios';
import {
  APP_CONSTANTS,
  cleanup,
  getLocalStorageItem,
  LocalStorageKeys,
  setLocalStorageItem,
} from '../../config/constants';
import { DashboardSections } from './DashboardSections';
import {
  apiResponseToFlatUser,
  apiResponseToState,
  flattenDependentList,
  setTimeoutHandler,
  stateToApiResponse,
} from './utils';

import { store } from '../../app/store';
import { FlatUser } from '../login/loginSlice';

let localStorageState = getLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA);

export enum InfoSections {
  PHONE = 'PHONE',
  PREFERRED_NAME = 'PREFERRED_NAME',
  RACE = 'RACE',
  INCOME = 'INCOME',
  GENDER_IDENTITY = 'GENDER_IDENTITY',
  MARITAL_STATUS = 'MARITAL_STATUS',
  EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
  RETIREMENT_STATUS = 'RETIREMENT_STATUS',
  VETERAN = 'VETERAN',
  PROFILE_PHOTO = 'PROFILE_PHOTO',
  ADDRESS = 'ADDRESS',
}

export interface DashboardData {
  dismissed: boolean;
  notificationCount: number;
  detailList?: any;
}

export const getProfileThunk = createAsyncThunk(
  'dashboard/getProfile',
  async (dispatch, thunkAPI) => {
    if (!axios.defaults.headers.common['Authorization']) {
      const accessToken = getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    setTimeoutHandler(axios);

    const { getState } = thunkAPI;
    const { isDependent, id } = getState()['session']['selectedUser'];
    if (isDependent) {
      const dependentId = id;
      const newState = await axios
        .get(ENDPOINTS.dependentDataGet(dependentId))
        .then(response => {
          const res = apiResponseToState(response.data);
          return res;
        })
        .catch(err => {
          console.error(`Get Request failed, `, err);
          throw err;
        });
      return { userData: { ...newState } };
    } else {
      const newState = await axios
        .get(`${APP_CONSTANTS.apiBaseUrl}/info/me`)
        .then(response => {
          const res = apiResponseToState(response.data);
          const mainUser: FlatUser = apiResponseToFlatUser(response.data);
          return { res, mainUser };
        })
        .catch(err => {
          console.error(`Get Request failed, `, err);
          throw err;
        });

      const dependentList = await axios
        .get(ENDPOINTS.getDependents)
        .then(response => {
          const depList = flattenDependentList(response.data);
          return [...depList];
        })
        .catch(err => {
          console.error(`Get Request failed, `, err);
          throw err;
        });
      return {
        userData: { ...newState.res },
        dependentList,
        mainUser: newState.mainUser,
      };
    }
  }
);

export const setProfileThunk = createAsyncThunk(
  'dashboard/setProfile',
  async (dispatch, thunkAPI) => {
    const { getState } = thunkAPI;
    const dashboard = getState()['dashboard'];
    const update = stateToApiResponse(dashboard);
    const accessToken = getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);
    const { isDependent, id } = getState()['session']['selectedUser'];
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    setTimeoutHandler(axios);
    if (isDependent) {
      const response = await axios
        .post(ENDPOINTS.updateDependentDataPost(id), update)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.error(`Set Dependent Request failed, `, err);
          throw err;
        });
      return response;
    } else {
      const response = await axios
        .post(`${APP_CONSTANTS.apiBaseUrl}/info/me`, update)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          console.error(`Set Request failed, `, err);
          throw err;
        });
      return response;
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: localStorageState || {
    loading: false,
  },
  reducers: {
    updateItem: (state, { payload }) => {
      const { detailItem, section } = payload;
      const { id } = detailItem;
      const { detailList: currentList = [] } = state[section];
      const indexToReplace = currentList.findIndex(item => item.id === id);
      if (indexToReplace > -1) {
        state[section].detailList.splice(indexToReplace, 1, detailItem);
      } else {
        state[section].detailList = [...currentList, detailItem];
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    updateFhirItem: (state, { payload }) => {
      const { detailItem, section } = payload;
      const { id } = detailItem.fhirData;
      const { detailList: currentList = [] } = state[section];
      const indexToReplace = currentList.findIndex(
        item => item.fhirData.id === id
      );
      if (section === DashboardSections.FAMILY_HISTORY) {
        state[section].added = true;
      }
      if (indexToReplace > -1) {
        state[section].detailList.splice(indexToReplace, 1, detailItem);
      } else {
        state[section].detailList = [...currentList, detailItem];
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    updateImmunization: (state, { payload }) => {
      const { newItems, oldIdentifier } = payload;
      const { detailList: currentList = [] } =
        state[DashboardSections.IMMUNIZATIONS];
      const newList = currentList.filter(
        item => item.fhirData.identifier[0].value !== oldIdentifier
      );
      state[DashboardSections.IMMUNIZATIONS].detailList = [
        ...newList,
        ...newItems,
      ];
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    updateAddress: (state, { payload }) => {
      const { id } = payload;
      const { address: currentAddrList } =
        state[DashboardSections.ABOUT_ME].data.fhirData;
      const indexToReplace = currentAddrList.findIndex(item => item.id === id);
      if (indexToReplace > -1) {
        state[DashboardSections.ABOUT_ME].data.fhirData.address.splice(
          indexToReplace,
          1,
          payload
        );
      } else {
        state[DashboardSections.ABOUT_ME].data.fhirData.address = [
          ...currentAddrList,
          payload,
        ];
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    updateInfoSection: (state, { payload }) => {
      const { section, update } = payload;
      if (section === InfoSections.PHONE) {
        state[DashboardSections.ABOUT_ME].data.fhirData.telecom = [update];
      } else if (section === InfoSections.PREFERRED_NAME) {
        state[DashboardSections.ABOUT_ME].data.additionalData.preferredName =
          update;
      } else if (section === InfoSections.RACE) {
        state[
          DashboardSections.ABOUT_ME
        ].data.additionalData.physical.ethnicity.fhirData.text = update;
      } else if (section === InfoSections.INCOME) {
        state[
          DashboardSections.ABOUT_ME
        ].data.additionalData.householdIncome.fhirData.text = update;
      } else if (section === InfoSections.GENDER_IDENTITY) {
        state[DashboardSections.ABOUT_ME].data.additionalData.identityGender =
          update;
      } else if (section === InfoSections.MARITAL_STATUS) {
        state[DashboardSections.ABOUT_ME].data.fhirData.maritalStatus.text =
          update;
      } else if (section === InfoSections.ADDRESS) {
        const newList = update?.map(formItem => ({
          line: [formItem.line],
          city: [formItem.city],
          state: [formItem.state],
          postalCode: [formItem.postalCode],
        }));
        state[DashboardSections.ABOUT_ME].data.fhirData.address = newList;
      } else if (section === InfoSections.EMERGENCY_CONTACTS) {
        const newList = update?.map(formItem => ({
          name: {
            family: formItem.lastName,
            given: [formItem.firstName],
          },
          relationship: [{ text: formItem.relationship }],
          telecom: [{ value: formItem.phoneNumber }],
        }));
        state[DashboardSections.ABOUT_ME].data.fhirData.contact = newList;
      } else if (section === InfoSections.RETIREMENT_STATUS) {
        state[DashboardSections.ABOUT_ME].data.additionalData.retirementDate =
          update;
      } else if (section === InfoSections.VETERAN) {
        state[DashboardSections.ABOUT_ME].data.additionalData.military = update;
      } else if (section === InfoSections.PROFILE_PHOTO) {
        const photo = [{ url: update }];
        state[DashboardSections.ABOUT_ME].data.fhirData.photo = photo;
      } else {
        console.error(`Update failed, section not found: ${section}`);
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    addItems: (state, { payload }) => {
      const { detailItems, section } = payload;
      const { detailList: currentList = [] } = state[section];
      state[section].detailList = [...currentList, ...detailItems];
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    deleteItem: (state, { payload }) => {
      const { detailItem, section } = payload;
      const { id } = detailItem;
      const { detailList: currentList = [] } = state[section];
      const indexToReplace = currentList.findIndex(item => item.id === id);
      if (indexToReplace > -1) {
        state[section].detailList.splice(indexToReplace, 1);
      } else {
        console.error(
          `Item to delete not found, ID: ${id}, section: ${section} `
        );
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    deleteFhirItem: (state, { payload }) => {
      const { id, section } = payload;
      const { detailList: currentList = [] } = state[section];
      const indexToReplace = currentList.findIndex(
        item => item.fhirData.id === id
      );
      if (indexToReplace > -1) {
        state[section].detailList.splice(indexToReplace, 1);
      } else {
        console.error(
          `Item to delete not found, ID: ${id}, section: ${section} `
        );
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    deleteImmunization: (state, { payload }) => {
      const { groupIdentifier } = payload;
      const { detailList: currentList = [] } =
        state[DashboardSections.IMMUNIZATIONS];
      const newList = currentList.filter(
        item => item.fhirData.identifier[0].value !== groupIdentifier
      );
      state[DashboardSections.IMMUNIZATIONS].detailList = newList;
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    dismissSection: (state, { payload }) => {
      const { section } = payload;
      state[section].dismissed = true;
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    dismissFhirSection: (state, { payload }) => {
      const { section } = payload;
      if (section === DashboardSections.FAMILY_HISTORY) {
        const famList = state[section]?.detailList;
        famList.splice(0, 1, {
          fhirData: { ...famList[0]?.fhirData, condition: [] },
        });
        debugger;
        state[section].detailList = famList;
      } else {
        state[section].detailList = [];
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    deleteFamHistItem: (state, { payload }) => {
      const { famId, itemId } = payload;
      const { detailList: currentList = [] } =
        state[DashboardSections.FAMILY_HISTORY];
      const familyMemberIndex = currentList.findIndex(
        item => item.fhirData.id === famId
      );
      const indexToReplace = currentList[
        familyMemberIndex
      ].fhirData.condition.findIndex(item => item.id === itemId);
      if (indexToReplace > -1) {
        state[DashboardSections.FAMILY_HISTORY].detailList[
          familyMemberIndex
        ].fhirData.condition.splice(indexToReplace, 1);
      } else {
        console.error(
          `Family History Item to delete not found, Family Member ID: ${famId}, Item ID: ${itemId} `
        );
      }
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    setMainUser: (state, { payload }) => {
      state[DashboardSections.MAIN_USER] = payload;
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
    editFamHistItem: (state, { payload }) => {
      const { famId, itemId, condition } = payload;
      const { detailList: currentList = [] } =
        state[DashboardSections.FAMILY_HISTORY];
      const familyMemberIndex = currentList.findIndex(
        item => item.fhirData.id === famId
      );
      if (familyMemberIndex > -1) {
        const indexToReplace = currentList[
          familyMemberIndex
        ].fhirData.condition.findIndex(item => item.id === itemId);
        if (indexToReplace > -1) {
          state[DashboardSections.FAMILY_HISTORY].detailList[
            familyMemberIndex
          ].fhirData.condition.splice(indexToReplace, 1, condition);
        }
        console.error(
          `Family History Item to edit not found, Family Member ID: ${famId}, Item ID: ${itemId} `
        );
      } else {
        console.error(
          `Family History Member edit not found, Family Member ID: ${famId}`
        );
      }
    },
    setOnboarding: (state, { payload }) => {
      const currentState = state[DashboardSections.ONBOARDING];
      state[DashboardSections.ONBOARDING] = {
        ...currentState,
        ...payload,
      };
      setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, state);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfileThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getProfileThunk.fulfilled, (state, action) => {
        const update = action?.payload?.dependentList
          ? {
              ...action.payload.userData,
              [DashboardSections.DEPENDENTS]: action.payload.dependentList,
              [DashboardSections.MAIN_USER]: action.payload.mainUser,
              loading: false,
            }
          : {
              ...state,
              ...action.payload.userData,
              loading: false,
            };
        //Set it in case refresh
        setLocalStorageItem(LocalStorageKeys.DASHBOARD_DATA, {
          ...update,
        });
        return { ...update }; // need to return to replace the entire state object.
      })
      .addCase(getProfileThunk.rejected, () => {
        console.error('Failed GetProfile Call');
        cleanup();
        return {};
      })
      .addCase(setProfileThunk.rejected, state => {
        state.loading = false;
        console.error('Failed SetProfile Call');
        cleanup();
        return {};
      })
      .addCase(setProfileThunk.fulfilled, (state, action) => {
        state.apiResponse = action.payload;
        state.loading = false;
      });
  },
});
export default dashboardSlice.reducer;

const getDashboardShareUser = () => {
  const shareUser = store.getState().session?.shareUser;
  if (shareUser) {
    return {
      ...apiResponseToState({ with: shareUser }),
      [DashboardSections.MAIN_USER]: apiResponseToFlatUser({ with: shareUser }),
    };
  }
  return null;
};

// Selectors for different health details
export const selectSection = (section: DashboardSections) => state => {
  const dashboardShareUser = getDashboardShareUser();
  return dashboardShareUser
    ? dashboardShareUser[section]
    : state.dashboard[section];
};
export const selectAll = state => {
  const dashboardShareUser = getDashboardShareUser();
  return dashboardShareUser || state.dashboard;
};
export const selectOnboardingProgress = state => {
  return state.dashboard?.[DashboardSections.ONBOARDING]?.progressPercent || 0;
};

export const selectUserName = (id: string) => state => {
  const dependent = state.dashboard[DashboardSections.DEPENDENTS]?.find(
    dep => dep.id === id
  );
  return dependent ? dependent.firstName : '';
};

export const selectLoading = state => {
  return state.dashboard.loading;
};
export const selectAllergies = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.ALLERGY];
};
export const selectConditions = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.HEALTH_CONDITIONS];
};
export const selectMedicalEvents = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.MEDICAL_EVENTS];
};
export const selectMedications = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.MEDICATIONS];
};
export const selectImmunizations = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.IMMUNIZATIONS];
};
export const selectInsurances = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.INSURANCES];
};
export const selectVaultDocs = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.VAULT_DOCS];
};
export const selectFamilyHistory = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.FAMILY_HISTORY];
};
export const selectProfilePhoto = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return (
    dashboard[DashboardSections.ABOUT_ME]?.data?.fhirData?.photo[0]?.url || ''
  );
};
export const selectOnboarding = state => {
  const dashboard = getDashboardShareUser() || state.dashboard;
  return dashboard[DashboardSections.ONBOARDING];
};

export const {
  dismissSection,
  dismissFhirSection,
  updateItem,
  updateAddress,
  updateInfoSection,
  updateImmunization,
  deleteItem,
  updateFhirItem,
  deleteFhirItem,
  deleteImmunization,
  addItems,
  deleteFamHistItem,
  editFamHistItem,
  setOnboarding,
} = dashboardSlice.actions;
