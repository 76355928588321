import { useTranslation } from 'react-i18next';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { HeartBeat } from '../../../assets/images/icons';
import { HealthDetailCard, ThemedButton } from '../../../components';
import {
  FormBody,
  InfoHeader,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import Route from '../../../routes/route';
import {
  DashboardData,
  deleteFhirItem,
  dismissFhirSection,
  selectConditions,
} from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { AddHealthConditions } from './AddHealthConditions';
import { EditHealthCondition } from './EditHealthCondition';
import { useConditionsStyles } from './styles';
import { flattenHealthConditions, healthConditionToDetail } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

export const defaultHealthConditions: DashboardData = {
  dismissed: false,
  notificationCount: 0,
};

type HealthConditionsProps = {
  details?: HealthDetail[];
  listOnly?: boolean;
};

export const HealthConditions: React.FC<HealthConditionsProps> = ({
  listOnly,
}) => {
  const classes = useConditionsStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectConditions);
  const healthConditions = flattenHealthConditions(detailList);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const conditionMenuOptions = detail => [
    {
      title: t('edit'),
      onClick: () =>
        history.push({
          pathname: `${path}/edit`,
          search: `?id=${detail.id}`,
        }),
    },
    {
      title: t('delete'),
      onClick: () =>
        dispatch(
          deleteFhirItem({
            section: DashboardSections.HEALTH_CONDITIONS,
            id: detail.id,
          })
        ),
    },
  ];

  const currentHealthConditions = healthConditions?.filter(
    item => item.current
  );
  const pastHealthConditions = healthConditions?.filter(item => !item.current);

  if (listOnly) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!!currentHealthConditions?.length && (
          <div className={classes.currentConditions}>
            <SubHeader3>{t('current_hlth_cndts')}</SubHeader3>
            {currentHealthConditions?.map(condition => {
              const detail = healthConditionToDetail(condition);
              return (
                <div className={classes.detailCard}>
                  <HealthDetailCard detail={detail} icon={HeartBeat} />
                </div>
              );
            })}
          </div>
        )}
        {!!pastHealthConditions?.length && (
          <div className={classes.pastConditions}>
            <SubHeader3>{t('past_hlth_cndts')}</SubHeader3>
            {pastHealthConditions?.map(condition => {
              const detail = healthConditionToDetail(condition);
              return (
                <div className={classes.detailCard}>
                  <HealthDetailCard detail={detail} icon={HeartBeat} />
                </div>
              );
            })}
          </div>
        )}
        {!pastHealthConditions?.length && !currentHealthConditions?.length && (
          <FormBody>{t('nothing_to_share')}</FormBody>
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <InfoHeader>{t('health_cndts')}</InfoHeader>
      <Switch>
        <Route
          path={`${path}/add`}
          component={AddHealthConditions}
          isPrivate={false}
        />
        <Route
          path={`${path}/edit`}
          component={EditHealthCondition}
          isPrivate={false}
        />
        <Route
          isPrivate={false}
          component={() => (
            <>
              {!!currentHealthConditions?.length && (
                <div className={classes.currentConditions}>
                  <SubHeader3>{t('current_hlth_cndts')}</SubHeader3>
                  {currentHealthConditions?.map(condition => {
                    const detail = healthConditionToDetail(condition);
                    return (
                      <div className={classes.detailCard}>
                        <HealthDetailCard
                          detail={detail}
                          icon={HeartBeat}
                          menuOptions={conditionMenuOptions(detail)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!!pastHealthConditions?.length && (
                <div className={classes.pastConditions}>
                  <SubHeader3>{t('past_hlth_cndts')}</SubHeader3>
                  {pastHealthConditions?.map(condition => {
                    const detail = healthConditionToDetail(condition);

                    return (
                      <div className={classes.detailCard}>
                        <HealthDetailCard
                          detail={detail}
                          icon={HeartBeat}
                          menuOptions={conditionMenuOptions(detail)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!isShareUser && (
                <>
                  <div className={classes.actionButtons}>
                    {!healthConditions && (
                      <ThemedButton
                        title={t('no_thing_to_add', {
                          thing: 'Health Conditions',
                        })}
                        isInverted
                        onClick={() => {
                          dispatch(
                            dismissFhirSection({
                              section: DashboardSections.HEALTH_CONDITIONS,
                            })
                          );
                        }}
                      />
                    )}
                    <ThemedButton
                      title={t('add_hlth_cndt')}
                      isInverted
                      onClick={() => {
                        history.push(ROUTES.conditionsAdd);
                      }}
                    />
                  </div>
                  <div className={classes.addMessage}>
                    <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
                  </div>
                </>
              )}
            </>
          )}
        />
      </Switch>
    </div>
  );
};
