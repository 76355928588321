import { FamilyMemberHistory } from '../../typings';
import { getUUID } from '../../config/utilities';

export const flattenConditions = condList => {
  return condList.map(cond => {
    try {
      return {
        ...cond,
        title: cond.code.text,
        onsetPeriod: cond.onsetPeriod.start,
      };
    } catch (error) {
      console.error('Problem flattening condition');
      return null;
    }
  });
};

export const flattenFamilyHistory = obj => {
  if (!obj.fhirData.condition) {
    //new history object
    return {
      ...obj.fhirData,
      relationship: obj.fhirData.relationship?.text,
    };
  }
  return {
    ...obj.fhirData,
    dismissed: obj.dismissed,
    relationship: obj.fhirData.relationship?.text,
  };
};

export const condFromFormData = obj => {
  return {
    id: obj?.id || getUUID(),
    code: { text: obj.title },
    contributedToDeath: obj.causeDeath?.toLowerCase().includes('yes'),
    onsetPeriod: {
      start: obj.onsetDate,
    },
    sensitive: obj.sensitive,
  };
};

const conditionsFromFormArr = condArr => {
  if (!condArr) {
    return [];
  }
  return condArr.map(obj => condFromFormData(obj));
};

export const mergeConditions = (newData, oldArray) => {
  const newConditions = conditionsFromFormArr(newData.conditions);
  return [...oldArray, ...newConditions];
};

export const formToFamilyCondition = (data, flatDetail) => {
  const { member, conditions, title: relationship } = data;
  let name, dob, dod, relation;
  if (member) {
    name = member?.name;
    dob = member?.dob;
    dod = member?.dod;
    relation = member?.title;
  } else {
    name = flatDetail?.name;
    dob = flatDetail?.bornDate;
    dod = flatDetail?.deceasedDate;
  }
  if (!!data?.relation) {
    relation = data?.relation.value;
  } else {
    relation = relationship;
  }

  const conditionsArr = conditionsFromFormArr(conditions);
  //Adding to member with existing items?
  if (flatDetail?.condition) {
    conditionsArr.push(...flatDetail.condition);
  }
  const fhir: FamilyMemberHistory = {
    resourceType: 'FamilyMemberHistory',
    patient: {},
    status: 'partial',
    id: flatDetail?.id || getUUID(),
    condition: conditionsArr,
    name: name,
    deceasedDate: dod,
    bornDate: dob,
    relationship: {
      text: relation,
    },
  };

  return {
    fhirData: fhir,
  };
};

export const newRelation = (formData, additional?) => {
  const { name, deceased, relation, dob, dod } = formData;
  return {
    fhirData: {
      id: getUUID(),
      resourceType: 'FamilyMemberHistory',
      patient: {},
      status: 'partial',
      name,
      relationship: { text: relation.value },
      bornDate: dob,
      ...(deceased && { deceasedDate: dod }),
      condition: [],
    },
    additional: additional || false,
  };
};
