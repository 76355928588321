import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { ThemedButton } from '../../../components';
import { CheckboxList } from '../../../components/CheckboxList/CheckboxList';
import { FormPrompt } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { DashboardSections } from '../DashboardSections';
import { addItems } from '../dashboardSlice';
import { NewImmunizationCard } from './ImmunizationCard';
import { useImmunizationsStyles } from './styles';
import { formDataToImmunizations } from './utils';
import { vaccinationCodes } from './vaccinationCodes';

const immunizationsList = vaccinationCodes.map(item => item.text);

export const AddImmunizations: React.FC = () => {
  const classes = useImmunizationsStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [chosenImmunes, setChosenImmunes] = useState([]);
  const history = useHistory();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleListSubmit = (immunizations: string[]) => {
    const chosenCodes = vaccinationCodes.filter(
      code => immunizations.indexOf(code.text) > -1
    );
    setChosenImmunes(chosenCodes);
    setSubmitted(true);
  };

  const submitImmunizations = data => {
    const immunizationsList = formDataToImmunizations(data);
    dispatch(
      addItems({
        section: DashboardSections.IMMUNIZATIONS,
        detailItems: immunizationsList,
      })
    );
    history.push(ROUTES.immunizationsDashboard);
  };

  return (
    <>
      <div className={classes.addPageContainer}>
        {submitted ? (
          <>
            <div className={classes.subheader}>
              <FormPrompt>{t('please_add_additionals')}</FormPrompt>
            </div>
            <form onSubmit={handleSubmit(submitImmunizations)}>
              {chosenImmunes.map((immunization, idx) => {
                return (
                  <NewImmunizationCard
                    immunizationCode={immunization}
                    index={idx}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                );
              })}
              <div className={classes.actionButtons}>
                <ThemedButton title={t('save')} type="submit" />
              </div>
            </form>
          </>
        ) : (
          <>
            <div className={classes.subheader}>
              <FormPrompt>{t('add_immunizations')}</FormPrompt>
            </div>
            <div className={classes.searchBox}>
              <CheckboxList
                optionsList={immunizationsList}
                searchPlaceholder={t('search_for_thing', {
                  thing: 'Immunizations',
                })}
                onSubmit={handleListSubmit}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
