import { VeteranStatusEnum } from '../../../config/SharedTypes';
import { DateFormats, toDateString } from '../../../config/utilities';

const flattenAddress = addrArr =>
  addrArr?.map(item => {
    return {
      ...item,
      oneLine: `${item.line.join(' ')} ${item?.city || ''}, ${
        item?.state || ''
      } ${item?.postalCode || ''}`,
    };
  });

export const formDataToAddress = data => {
  return {
    ...data,
    line: [data.line],
  };
};

const militaryToVet = military => {
  const status = VeteranStatusEnum[military.status.fhirData.text];
  return {
    status,
  };
};
export const flattenUserData = (data, isShareUser = false) => {
  if (!data) {
    return {};
  } else if (!data.additionalData && !isShareUser) return {};

  const { email, fhirData, additionalData } = data;
  const {
    name = [],
    gender,
    telecom = [],
    maritalStatus,
    address,
    birthDate,
    contact = [],
    photo,
  } = fhirData;
  const {
    ssn4,
    username,
    preferredName,
    military,
    physical,
    householdIncome,
    stateIdPhoto,
    identityGender,
    retirementDate,
  } = additionalData;
  return {
    fullName: {
      first: name?.[0]?.given[0],
      middle: name?.[0]?.given.slice(1).join(' '),
      family: name?.[0]?.family,
      full: `${name?.[0]?.given[0]} ${name?.[0]?.given.slice(1).join(' ')} ${
        name?.[0]?.family
      }`,
    },
    preferredName,
    email,
    gender,
    address,
    flatAddress: flattenAddress(address),
    birthDate: toDateString(birthDate, DateFormats.MMDDYYYY),
    contact,
    emergencyContacts: [
      ...contact?.map(cont => ({
        fullName: `${cont?.name.given[0]} ${cont?.name.family}`,
        firstName: cont?.name.given[0],
        lastName: cont?.name.family,
        phoneNumber: cont?.telecom[0].value,
        relationship: cont?.relationship?.[0]?.text,
      })),
    ],
    telecom: telecom?.[0]?.value,
    maritalStatus: maritalStatus?.text,
    photo: photo?.[0]?.url,
    ssn4,
    username,
    military,
    veteran: military ? militaryToVet(military) : {},
    physical,
    identityGender,
    householdIncome,
    stateIdPhoto,
    retirementDate: toDateString(retirementDate, DateFormats.MMDDYYYY),
  };
};
