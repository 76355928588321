import { useStyledDisplayTagStyles } from './styles';
import { TagText } from '../TextComponents/';
import { t } from 'i18next';

interface StyledDisplayTagProps {
  tagName: string;
}

export const StyledDisplayTag: React.FC<StyledDisplayTagProps> = ({
  tagName,
}) => {
  const classes = useStyledDisplayTagStyles();

  const getClass = name => {
    const lower = name?.toLowerCase() || '';
    if (lower.includes('receipt')) return classes.receiptTag;
    if (lower.includes('care')) return classes.careSummaryTag;
    if (lower.includes('policy')) return classes.policyTag;
    if (lower.includes('legal')) return classes.legalTag;
    if (lower.includes('account info')) return classes.accountInfoTag;
    else {
      return classes.otherTag;
    }
  };

  return (
    <div className={getClass(tagName)}>
      <TagText>{t(`${tagName}`)}</TagText>
    </div>
  );
};
