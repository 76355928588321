import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { AccountChecklist } from '../../../components/AccountChecklist/AccountChecklist';
import { CardH2 } from '../../../components/TextComponents/CardH2';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

const useIdCaptureTextStyles = createUseStyles(styles);

export const IdCaptureText = () => {
  const { t } = useTranslation();
  const classes = useIdCaptureTextStyles();
  const checklistItems = [
    { step: t('account_checklist_step1'), completed: true, highlighted: true },
    {
      step: t('account_checklist_step2'),
      completed: false,
      highlighted: false,
    },
    {
      step: t('account_checklist_step3'),
      completed: false,
      highlighted: false,
    },
    {
      step: `${t('account_checklist_step4')}*`,
      completed: false,
      highlighted: false,
    },
    {
      step: `${t('account_checklist_step5')}*`,
      completed: false,
      highlighted: false,
    },
  ];
  return (
    <div className={classes.message}>
      <CardH2>{t('getting_started')}</CardH2>
      <ProgressBar progressWidth={2} />
      <div className={classes.welcomeText}>
        {t('welcome_to_alive')} <br />
        <br />
        {t('id_capture_explanation')}
      </div>
      <div className={classes.checklist}>
        <AccountChecklist checklistItems={checklistItems} />
      </div>
      <div className={classes.asteriskNote}>{t('asterisk_note')}</div>
    </div>
  );
};
