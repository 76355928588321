import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useStyledDisplayTagStyles = createUseStyles({
  tagsColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginRight: '25px',
    width: '35%',
  },
  receiptTag: {
    color: Colors.accent8,
    background: Colors.accent8Transparent,
    padding: '3px 11px 4px 11px',
    borderRadius: '4px',
  },
  careSummaryTag: {
    color: Colors.accent5Purple,
    background: Colors.accent5Transparent,
    padding: '3px 5px 4px 5px',
    borderRadius: '4px',
  },
  policyTag: {
    color: Colors.gradientLighterOrange,
    background: Colors.accent1Transparent,
    padding: '3px 15px 4px 17px',
    borderRadius: '4px',
  },
  legalTag: {
    color: Colors.yellowTagText,
    background: Colors.yellowTagTextTransparent,
    padding: '3px 17px 4px 18px',
    borderRadius: '4px',
  },
  accountInfoTag: {
    color: Colors.accent2,
    background: Colors.accent2Transparent,
    padding: '3px 12px 4px 10px',
    borderRadius: '4px',
  },
  otherTag: {
    color: Colors.accent4,
    background: Colors.accent4Transparent,
    padding: '3px 8px 4px 10px',
    borderRadius: '4px',
  },
});
