import { createUseStyles } from 'react-jss';
import { ContentBox, LinkComponent, ThemedButton } from '../../../components';
import { FormSecondary } from '../../../components/TextComponents';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const useVerifyPhoneStyles = createUseStyles(styles);

export const VerifyPhone = () => {
  const { t } = useTranslation();
  const classes = useVerifyPhoneStyles();
  const history = useHistory();
  const codeLength = new Array(6).fill('');
  const [enteredCode, setEnteredCode] = useState([...codeLength]);

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('verify_phone')} slimTitle>
        <div className={classes.subTitle}>
          <FormSecondary>{t('we_texted_you')}</FormSecondary>
        </div>
        <div className={classes.codeInputArea}>
          {codeLength.map((box, idx) => {
            return (
              <input
                type="text"
                maxLength={1}
                key={idx}
                value={enteredCode[idx]}
                className={classes.digitInputBox}
                onChange={e => {
                  let replaceAtIndex = [...enteredCode];
                  replaceAtIndex[idx] = e.target.value;
                  setEnteredCode([...replaceAtIndex]);
                }}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            );
          })}
        </div>
        <div className={classes.linkContainer}>
          <LinkComponent
            label={t('resend_code')}
            onClick={() => setEnteredCode([...codeLength])}
          />
        </div>
        <div className={classes.bottomButton}>
          <ThemedButton
            title={t('verify')}
            onClick={() => history.push('/createAccount/profilePhoto')}
          />
        </div>
      </ContentBox>
    </div>
  );
};
