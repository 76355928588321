import { cardContainer } from '../styles';
import { Colors } from '../../../config/colors';
import { textPageStyles } from '../../../config/sharedStyles';

export const styles = {
  cardContainer,
  ...textPageStyles,
  detailText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: Colors.primaryText,
  },
  buttonContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    fontSize: '12px',
    color: Colors.errorRed,
  },
  resendContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    marginBottom: '20px',
  },
  skipLink: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  asteriskNote: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    marginLeft: '35px',
  },
};
