import { Colors } from '../../../config/colors';
import { createUseStyles } from 'react-jss';
import { badgeContainer, badgeImg } from '../../../config/sharedStyles';

export const useViewShareStyles = createUseStyles({
  badgeContainer,
  badgeImg,
  container: {
    backgroundColor: Colors.backgroundGrey,
    height: '100%',
    color: Colors.primaryText,
  },
  columns: {
    display: 'flex',
    gap: '5%',
    padding: '20px 50px 0 50px',
  },
  column: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '30px',
    marginBottom: '30px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 85px 0 30px',
    height: '186px',
    left: '66px',
    top: '272px',
    background: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
  },
  detailRows: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  createAccountContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
  },
});
