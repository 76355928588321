import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useOneLineTextInputStyles: (
  data?: {
    showPlaceholder: boolean;
    isError: boolean;
    hasIcon: boolean;
    small: boolean;
    theme: Jss.Theme;
  } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'container'
  | 'textInputField'
  | 'label'
  | 'eyeButton'
  | 'leftIcon'
  | 'translate'
  | 'errorMessage'
  | 'root'
> = createUseStyles({
  root: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
  },
  container: ({ isError, small }) => ({
    position: 'relative',
    color: Colors.primaryText,
    borderRadius: '5px',
    border: `1px solid ${isError ? Colors.errorRed : Colors.textInputBorder}`,
    '&:hover': {
      borderColor: isError ? Colors.errorRed : Colors.buttonPurple,
    },
    width: '100%',
    height: small ? '36px' : '48px',
    margin: '7px 0',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'space-between',
    '&:focus-within': {
      border: `2px solid ${isError ? Colors.errorRed : Colors.linkPurple}`,
      height: small ? '34px' : '46px',
    },
  }),
  textInputField: ({ showPlaceholder, isError, hasIcon, small }) => ({
    border: 'none',
    outline: 'none',
    width: '100%',
    fontSize: small ? '12px' : '16px',
    color: isError ? Colors.errorRed : Colors.primaryText,
    backgroundColor: Colors.clear,
    lineHeight: '18px',
    paddingLeft: hasIcon ? '10px' : '19px',
    marginBottom: showPlaceholder ? '-12px' : 0,
    transition: 'all 150ms ease-out',
    '&:focus': {
      paddingLeft: hasIcon ? '9px' : '18px',
    },
    '&::placeholder': {
      color: isError ? Colors.errorRed : Colors.primaryText,
      fontSize: small ? '12px' : '16px',
      fontWeight: 400,
      opacity: showPlaceholder ? '0' : '1',
    },
  }),
  eyeButton: {
    backgroundColor: Colors.clear,
    border: 'none',
    width: '30px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  leftIcon: () => ({
    backgroundColor: Colors.clear,
    display: 'flex',
    border: 'none',
    width: '30px',
    marginRight: '2px',
    paddingLeft: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 150ms ease-out',
  }),
  label: ({ isError, hasIcon, small }) => ({
    position: 'absolute',
    color: isError ? Colors.errorRed : Colors.primaryText,
    top: '18px',
    fontSize: small ? '10px' : '12px',
    left: '19px',
    paddingLeft: hasIcon ? '25px' : '0',
    opacity: '0',
    pointerEvents: 'none',
  }),
  translate: ({ isError, small }) => ({
    transition: 'all 150ms ease-out',
    transform: 'translateY(-16px)',
    opacity: '1',
    fontSize: small ? '10px' : '12px',
    left: '18',
    top: '16',
    color: isError ? Colors.errorRed : Colors.primaryText,
  }),
  errorMessage: {
    fontSize: '12px',
    color: Colors.errorRed,
    textAlign: 'left',
  },
});
