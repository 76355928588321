import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { searchForUser } from '../../../api/share';
import { arrowLeft } from '../../../assets/images/icons';
import { OneLineTextInput, ThemedButton } from '../../../components';
import {
  AboutMeTitle,
  FormSecondary,
  InfoHeader,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { addShareStyles } from './styles';

export const AddShare: React.FC = () => {
  const classes = addShareStyles();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = async data => {
    const userFound = await searchForUser(data.query);

    if (!userFound) {
      setError('query', {
        type: 'custom',
        message: `${t('add_share_not_found')}`,
      });
    } else {
      const { email } = userFound;
      if (email) {
        history.push(ROUTES.createEditShare, { email });
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.navContent}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.share)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
        </div>
        <div className={classes.shareHeader}>
          <div className={classes.sectionName}>
            <InfoHeader>{t('data_sharing')}</InfoHeader>
          </div>
        </div>
        <div className={classes.bodyText}>
          <FormSecondary>{t('add_share_1')}</FormSecondary>
          <FormSecondary>{t('add_share_2')}</FormSecondary>
          <FormSecondary>{t('add_share_3')}</FormSecondary>
        </div>
        <div className={classes.card}>
          <div className={classes.title}>
            <FormSecondary>{t('add_share_4')}</FormSecondary>
            <OneLineTextInput
              placeholder={t('add_share_placeholder')}
              formErrors={errors}
              {...register('query', {
                required: `${t('please_enter_username_email')}`,
              })}
            />
          </div>
          <div className={classes.actionButtons}>
            <ThemedButton
              title={t('continue')}
              onClick={handleSubmit(onSubmit)}
            />
            <ThemedButton
              title={t('create_quick_share')}
              isInverted
              onClick={() => {
                history.push(ROUTES.createEditShare, { isQuickShare: true });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
