import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkComponent, OneLineTextInput } from '../../../components';
import { CardHeader1 } from '../../../components/TextComponents';
import { useImmunizationCardStyles } from './styles';
import { ImageUploadOnLink } from '../../../components/ImageUploadOnLink/ImageUploadOnLink';
import { sharedValidators, noSpaceInput } from '../../../config/constants';
import { DateFormats, toDateString } from '../../../config/utilities';
import { getFileExtension, uploadInsuranceCard } from '../../../api/vaultUtils';

type ImmunizationCardProps = {
  immunization: any;
  register: any;
  setValue?: any;
  errors: any;
};

const getUploadImageFunction =
  (setPhotoUrl, setValue) => async (formname, value) => {
    if (!value) {
      setValue(formname, '');
      setPhotoUrl('');
    } else {
      const imageData = { fileExtension: getFileExtension(value), data: value };
      const imageUrl = await uploadInsuranceCard({
        fileExtension: imageData.fileExtension,
        imageData: imageData.data,
      });
      setValue(formname, imageUrl?.id);
      setPhotoUrl(imageUrl?.id);
    }
  };

export const ImmunizationCard: React.FC<ImmunizationCardProps> = ({
  immunization,
  register,
  errors,
  setValue,
}) => {
  const { additionalDates, photo } = immunization || {};
  const initialDates = additionalDates || [];
  const title = immunization?.fhirData?.vaccineCode?.text;
  const codeString = JSON.stringify(immunization?.fhirData?.vaccineCode);
  const { t } = useTranslation();
  const classes = useImmunizationCardStyles();
  const [photoUrl, setPhotoUrl] = useState(photo?.url || '');
  const [doseDates, setDoseDates] = useState([
    toDateString(
      immunization?.fhirData?.occurrenceDateTime,
      DateFormats.MMYYYY
    ),
    ...initialDates.map(date => toDateString(date, DateFormats.MMYYYY)),
  ]);

  const uploadImage = getUploadImageFunction(setPhotoUrl, setValue);

  if (!immunization) return null;

  return (
    <div className={classes.immunizationCard}>
      <div className={classes.topSection}>
        {title === 'Other...' ? (
          <OneLineTextInput
            placeholder={t('immunization_name')}
            type="text"
            defaultValue={immunization?.title}
            {...register(`title`, {
              required: `${t('immune_name_required')}`,
              pattern: {
                value: noSpaceInput,
                message: `${t('immune_name_required')}`,
              },
            })}
            formErrors={errors}
          />
        ) : (
          <div className={classes.cardTitle}>
            <CardHeader1>{title}</CardHeader1>
          </div>
        )}
      </div>
      <div className={classes.middleSection}>
        <div className={classes.doseDates}>
          {doseDates.map((doseObj, idx) => (
            <div className={classes.doseDateContainer}>
              <OneLineTextInput
                placeholder={idx === 0 ? t('first_dose_date') : t('dose_date')}
                type="text"
                defaultValue={doseObj}
                {...register(`${idx}-doseDate`, {
                  required: idx === 0 ? `${t('dose_date_required')}` : false,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            </div>
          ))}
        </div>
        <LinkComponent
          label={t('add_another_date')}
          onClick={() => setDoseDates([...doseDates, ''])}
        />
      </div>
      <OneLineTextInput
        placeholder={t('provider_examples')}
        type="text"
        {...register(`subtitle`, {
          required: `${t('provider_required')}`,
          pattern: {
            value: noSpaceInput,
            message: `${t('provider_required')}`,
          },
        })}
        formErrors={errors}
        defaultValue={immunization.fhirData.performer[0]?.actor?.display}
      />
      <ImageUploadOnLink
        defaultValue={photoUrl}
        register={register}
        setValue={uploadImage}
      />

      <div style={{ height: '0px', width: '0px', opacity: '0' }}>
        {title !== 'Other...' && (
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`title`, {})}
            formErrors={errors}
          />
        )}
        <OneLineTextInput
          type="text"
          defaultValue={codeString}
          {...register(`codeString`, {})}
          formErrors={errors}
        />
      </div>
    </div>
  );
};

export const NewImmunizationCard = ({
  immunizationCode,
  register,
  setValue,
  errors,
  index,
}) => {
  const { t } = useTranslation();
  const classes = useImmunizationCardStyles();
  const [doseDates, setDoseDates] = useState(['', '']);
  const [photoUrl, setPhotoUrl] = useState('');
  const { text: title } = immunizationCode;
  const codeString = JSON.stringify(immunizationCode);

  const uploadImage = getUploadImageFunction(setPhotoUrl, setValue);

  return (
    <div className={classes.immunizationCard}>
      <div className={classes.topSection}>
        {title === 'Other...' ? (
          <OneLineTextInput
            placeholder="Immunization name"
            type="text"
            {...register(`${index}-title`, {
              required: `${t('immune_name_required')}`,
              pattern: {
                value: noSpaceInput,
                message: `${t('immune_name_required')}`,
              },
            })}
            formErrors={errors}
          />
        ) : (
          <div className={classes.cardTitle}>
            <CardHeader1>{title}</CardHeader1>
          </div>
        )}
      </div>
      <div className={classes.middleSection}>
        <div className={classes.doseDates}>
          {doseDates.map((doseNumber, idx) => (
            <div className={classes.doseDateContainer}>
              <OneLineTextInput
                placeholder={idx === 0 ? t('first_dose_date') : t('dose_date')}
                type="text"
                {...register(`${index}-${idx}-doseDate`, {
                  required: idx === 0 ? `${t('dose_date_required')}` : false,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            </div>
          ))}
        </div>
        <LinkComponent
          label={t('add_another_date')}
          onClick={() => setDoseDates([...doseDates, ''])}
        />
      </div>
      <OneLineTextInput
        placeholder={t('provider_examples')}
        type="text"
        {...register(`${index}-subtitle`, {
          required: `${t('provider_required')}`,
          pattern: {
            value: noSpaceInput,
            message: `${t('provider_required')}`,
          },
        })}
        formErrors={errors}
      />
      <ImageUploadOnLink
        index={index}
        defaultValue={photoUrl}
        register={register}
        setValue={uploadImage}
      />
      <div style={{ height: '0px', width: '0px', opacity: '0' }}>
        {title !== 'Other...' && (
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`${index}-title`, {})}
            formErrors={errors}
          />
        )}
        <OneLineTextInput
          type="text"
          defaultValue={codeString}
          {...register(`${index}-codeString`, {})}
          formErrors={errors}
        />
      </div>
    </div>
  );
};
