import { LinkComponent } from '../LinkComponent/LinkComponent';
import { useNotificationStyles } from './styles';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import defaultIcon from '../../assets/clipboard.png';
import { useTranslation } from 'react-i18next';

interface NotificationProps {
  icon?: string;
  title: string;
  subTitle: string;
  buttonTitle: string;
  onClick: () => void;
  onDismiss?: () => void;
}

export const Notification: React.FC<NotificationProps> = ({
  icon = defaultIcon,
  title,
  subTitle,
  buttonTitle,
  onClick,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const classes = useNotificationStyles();
  return (
    <div className={classes.container}>
      <div className={classes.titleArea}>
        <img width="27rem" src={icon} alt={title} />
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.lineContainer}>
        <div className={classes.line} />
      </div>
      <div className={classes.bottomHalf}>
        <div className={classes.subTitle}>{subTitle}</div>
        <div className={classes.buttonContainer}>
          <ThemedButton
            title={buttonTitle}
            onClick={onClick}
            isInverted={true}
          />
        </div>
        <div className={classes.linkContainer}>
          <LinkComponent label={t('dismiss')} onClick={onDismiss} />
        </div>
      </div>
    </div>
  );
};
