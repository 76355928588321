import { CircularProgress } from '@mui/material';
import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

interface ThemedButtonProps {
  title: string;
  onClick?: () => void;
  isInverted?: boolean;
  type?: 'reset' | 'button' | 'submit';
  isDisabled?: boolean;
  isLoading?: boolean;
  small?: boolean;
}

const useButtonStyles: (
  data?: { isInverted: boolean; small: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'button' | 'disabledButton'> = createUseStyles({
  button: ({ isInverted, small }) => ({
    cursor: 'pointer',
    borderRadius: '5px',
    border: `2px solid ${Colors.buttonPurple}`,
    backgroundColor: isInverted ? Colors.white : Colors.buttonPurple,
    color: isInverted ? Colors.buttonPurple : Colors.white,
    minHeight: small ? '36px' : '48px',
    minWidth: small ? '50px' : '200px',
    height: '100%',
    width: '100%',
    fontSize: small ? '14px' : '1rem',
    lineHeight: small ? '17px' : '1rem',
    display: small ? 'flex' : 'block',
    justifyContent: small ? 'center' : 'unset',
    alignItems: small ? 'center' : 'unset',
    fontWeight: '600',
    boxShadow: '0px 3px 2px rgba(183, 183, 183, 0.25)',
  }),
  disabledButton: ({ isInverted, small }) => ({
    borderRadius: '5px',
    border: `2px solid ${Colors.checkboxGray}`,
    backgroundColor: isInverted ? Colors.white : Colors.checkboxGray,
    color: isInverted ? Colors.checkboxGray : Colors.white,
    minHeight: small ? '36px' : '48px',
    minWidth: small ? '75px' : '200px',
    fontSize: small ? '14px' : '1rem',
    display: small ? 'flex' : 'block',
    justifyContent: small ? 'center' : 'unset',
    alignItems: small ? 'center' : 'unset',
    height: '100%',
    width: '100%',
    fontWeight: '600',
  }),
});

export const ThemedButton: React.FC<ThemedButtonProps> = ({
  isInverted,
  onClick = () => {},
  isDisabled,
  isLoading = false,
  type = 'button',
  title,
  small,
}) => {
  const classes = useButtonStyles({ isInverted, small, theme: {} });
  return (
    <button
      className={isDisabled ? classes.disabledButton : classes.button}
      onClick={() => !isLoading && onClick()}
      type={type}
      disabled={isDisabled}
    >
      {isLoading ? <CircularProgress color="inherit" /> : title}
    </button>
  );
};
