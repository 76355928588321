import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';

export const useHealthDetailCardStyles = createUseStyles({
  container: {
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    maxWidth: '700px',
    width: '100%',
    display: 'flex',
    margin: '18px 0 18px 0',
    padding: '17px',
    position: 'relative',
    border: `1px solid ${Colors.notificationBorder}`,
  },
  imageModal: {
    width: '70%',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    paddingRight: '12px',
  },
  title: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    textAlign: 'left',
  },
  header: {
    marginBottom: '6px',
    display: 'flex',
    width: '100%',
    gap: '30px',
  },
  subHeader: {
    marginBottom: '6px',
  },
  detailItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  detailItemLine: {
    display: 'flex',
  },
  detail1: {
    marginRight: '0.5rem',
  },
  detail2: {
    marginLeft: '0.5rem',
  },
  detailText: {
    padding: '0 12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '250px',
  },
  lockIcon: {
    paddingLeft: '10px',
  },
  dotMenu: {
    position: 'absolute',
    right: '17px',
    top: '2px',
  },
});
