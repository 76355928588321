import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  linkText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.015em',
  },
});
export const LinkText: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.linkText}>{children}</div>;
};
