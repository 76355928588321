import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useAppDispatch, useRequest } from '../../../app/hooks';
import { CheckboxList, ThemedButton } from '../../../components';
import { SubHeader3 } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { addItems } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { NewHealthConditionCard } from './HealthConditionCard';
import { useAddConditionsStyles } from './styles';
import { newHealthCondition } from './utils';
import { getDefaultConditions, searchConditions } from '../../../api/info';

const formDataToConditions = data => {
  const numberOfConditions = Object.keys(data).length / 5;
  const conditionsArr = [];
  for (let i = 0; i < numberOfConditions; i++) {
    const current = data[`${i}-current`];
    const resolutionDate = current ? null : data[`${i}-resolutionDate`];
    const condition = newHealthCondition(
      data[`${i}-title`],
      data[`${i}-sensitive`],
      data[`${i}-onsetDate`],
      resolutionDate
    );
    conditionsArr.push(condition);
  }
  return conditionsArr;
};

export const AddHealthConditions: React.FC = () => {
  const classes = useAddConditionsStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [conditionsList, setConditionsList] = useState([]);
  const history = useHistory();

  const {
    request: getDefaultConditionsRequest,
    data: getDefaultConditionsData,
  } = useRequest(getDefaultConditions);
  const { request: searchConditionsRequest, data: searchConditionsData } =
    useRequest(searchConditions);

  useEffect(() => {
    getDefaultConditionsRequest();
  }, []);

  useEffect(() => {
    if (!!searchConditionsData?.with?.length) {
      setConditionsList(searchConditionsData?.with);
    } else {
      setConditionsList(getDefaultConditionsData?.with || []);
    }
  }, [searchConditionsData?.with, getDefaultConditionsData?.with]);

  const resetConditionsList = () =>
    setConditionsList(getDefaultConditionsData?.with);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleListSubmit = (conditions: string[]) => {
    setSelectedConditions(conditions);
    setSubmitted(true);
  };

  const searchOptionsList = debounce((term: string) => {
    if (term.length < 4) {
      resetConditionsList();
    } else {
      searchConditionsRequest(term);
    }
  }, 400);

  const submitConditions = data => {
    const conditionsList = formDataToConditions(data);
    // Save to state
    dispatch(
      addItems({
        section: DashboardSections.HEALTH_CONDITIONS,
        detailItems: conditionsList,
      })
    );
    history.push(ROUTES.conditionsDashboard);
  };

  return (
    <div className={classes.container}>
      <SubHeader3>{t('add_hlth_cndts')}</SubHeader3>
      {submitted ? (
        <>
          <form onSubmit={handleSubmit(submitConditions)}>
            {selectedConditions.map((condition, index) => {
              return (
                <NewHealthConditionCard
                  title={condition}
                  index={index}
                  register={register}
                  errors={errors}
                />
              );
            })}
            <div className={classes.actionButtons}>
              <ThemedButton title="Save" type="submit" />
            </div>
          </form>
        </>
      ) : (
        <div className={classes.searchBox}>
          <CheckboxList
            optionsList={conditionsList}
            searchPlaceholder={t('search_for_thing', {
              thing: 'Health Conditions',
            })}
            searchOptionsList={searchOptionsList}
            showOther
            onSubmit={handleListSubmit}
          />
        </div>
      )}
      <div className={classes.addMessage}>
        <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
      </div>
    </div>
  );
};
