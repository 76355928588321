import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Switch } from '../Switch/Switch';
import { CardDetail1 } from '../TextComponents';

export type MarkCurrentProps = {
  register?: any;
  defaultValue?: boolean;
  onChange?: any;
};

const useCurrentStyles = createUseStyles({
  container: { display: 'flex', alignItems: 'center', gap: '20px' },
});

export const MarkCurrent: React.FC<MarkCurrentProps> = ({
  register = () => {},
  defaultValue,
  onChange = () => {},
}) => {
  const classes = useCurrentStyles();
  const { t } = useTranslation();

  return (
    <div
      className={classes.container}
      style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
    >
      <CardDetail1>{t('mark_as_current')}</CardDetail1>
      <Switch {...register} defaultValue={defaultValue} onChange={onChange} />
    </div>
  );
};
