import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const styles = {
  container: {
    height: '100%',
    position: 'relative',
  },
  contentBox: {
    paddingTop: '88px',
    margin: '0 0 80px 80px',
    textAlign: 'left',
  },
  pageTitle: {
    marginBottom: '25px',
  },
  title: {
    marginTop: '22px',
  },
  info: {
    margin: '10px 0 25px',
    color: Colors.secondaryText,
  },
  line: {
    height: '0.5px',
    width: '100%',
    margin: '25px 0 24px',
    background: Colors.checkboxGray,
  },
  linkContainer: {
    margin: '30px 0 70px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '339px',
    height: '51px',
    borderRadius: '10px',
    border: `2px solid ${Colors.logoutBorderGray}`,
    backgroundColor: Colors.white,
    color: Colors.logoutButtonText,
    cursor: 'pointer',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 0 80px',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '2.5rem',
    width: '100%',
    maxWidth: '48vw',
    [DESKTOP_VIEW_BREAKPOINT]: {
      justifyContent: 'space-between',
      margin: '0 0 30px 0',
    },
  },
};
