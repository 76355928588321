import React from 'react';
import { useState } from 'react';
import { useRadioListStyles } from './styles';

interface RadioListProps {
  title?: string;
  optionsList?: string[];
  optionsArr?: Array<{ value: string; label: string }>;
  onChange?: any;
  onBlur?: any;
  isHorizontal?: boolean;
  defaultValue?: string;
  groupNumber?: string;
  register?: any;
}

export const RadioList: React.FC<RadioListProps> = React.forwardRef<
  HTMLInputElement,
  RadioListProps
>(
  ({
    title = '',
    optionsList,
    optionsArr,
    onChange = () => {},
    onBlur = () => {},
    isHorizontal,
    defaultValue,
    groupNumber,
    register,
  }) => {
    const classes = useRadioListStyles({ isHorizontal, theme: {} });
    const [selected, setSelected] = useState(defaultValue);
    const registerFunction = register?.register;
    const registerName = register?.name;
    const handleChange = e => {
      setSelected(e.target.value);
      onChange(e);
    };
    return (
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div className={classes.optionsList}>
          {optionsArr ? (
            <>
              {optionsArr.map(option => (
                <div key={option.label}>
                  <label className={classes.option}>
                    {register ? (
                      <input
                        type="radio"
                        value={option.value}
                        onBlur={onBlur}
                        className={classes.option}
                        {...registerFunction(registerName, {})}
                      />
                    ) : (
                      <input
                        type="radio"
                        name={`radioButtonChoice${groupNumber}`}
                        value={option.value}
                        onChange={handleChange}
                        className={classes.option}
                        checked={option.value === selected}
                      />
                    )}

                    {option.label}
                  </label>
                  <br />
                </div>
              ))}
            </>
          ) : (
            <>
              {optionsList.map(option => (
                <div key={option}>
                  <label className={classes.option}>
                    {register ? (
                      <input
                        type="radio"
                        value={option}
                        onBlur={onBlur}
                        className={classes.option}
                        {...registerFunction(registerName, {})}
                      />
                    ) : (
                      <input
                        type="radio"
                        name={`radioButtonChoice${groupNumber}`}
                        value={option}
                        onChange={handleChange}
                        className={classes.option}
                        checked={option === selected}
                      />
                    )}

                    {option}
                  </label>
                  <br />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
);
