import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  AppStoreBadge,
  GooglePlayBadge,
  ShareArtwork,
} from '../../../assets/images';
import {
  ShareCalendarGreen,
  ShareHourglassGreen,
  ShareLockGreen,
} from '../../../assets/images/icons';
import {
  ContentBox,
  ThemedButton,
  UserProfileSwitcher,
} from '../../../components';
import {
  FormSecondary,
  SectionHeader,
} from '../../../components/TextComponents';
import {
  getLocalStorageItem,
  LocalStorageKeys,
} from '../../../config/constants';

import { DateFormats, toDateString } from '../../../config/utilities';
import { profileDataStart } from '../../dashboard/about/sampleProfileData';
import { flattenUserData } from '../../dashboard/about/utils';
import { Allergies } from '../../dashboard/allergies/Allergies';
import { DashboardSections } from '../../dashboard/DashboardSections';
import { selectSection } from '../../dashboard/dashboardSlice';
import { FamilyHistory } from '../../dashboard/family-history/FamilyHistory';
import { HealthConditions } from '../../dashboard/health-conditions/HealthConditions';
import { Immunizations } from '../../dashboard/immunizations/Immunizations';
import { MedicalEvents } from '../../dashboard/medical-events/MedicalEvents';
import { Medications } from '../../dashboard/medications/Medications';
import { VaultDocsDash } from '../../dashboard/vault/VaultDocsDash';
import { setContract, setShareUser } from '../../login/loginSlice';
import { CentipedeList } from './CentipedeList';
import { useViewShareStyles } from './styles';

export const ViewSharePublic: React.FC = () => {
  const classes = useViewShareStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [beginDate, setBeginDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [neverExpires, setNeverExpires] = useState(false);
  const [includesSensitive, setIncludesSensitive] = useState(false);

  useEffect(() => {
    const shareInfo = getLocalStorageItem(LocalStorageKeys.SHARE_DATA);
    const { info, created, expiration, basicInfo, fields, contractDetails } =
      shareInfo;
    const update: any = {
      ...info,
      fhirData: {
        ...info?.fhirData,
        name: basicInfo?.info?.fhirData?.name,
        photo: basicInfo?.info?.fhirData?.photo,
      },
    };
    setBeginDate(created);
    setExpiryDate(expiration);
    setNeverExpires(expiration?.includes('9999-'));
    setIncludesSensitive(fields?.some(obj => obj?.includeSensitive));
    dispatch(setShareUser(update));
    dispatch(setContract(contractDetails));
    setIsLoading(false);
  }, []);

  const { data } = useAppSelector(
    selectSection(DashboardSections.ABOUT_ME)
  ) || { data: {} };

  if (isLoading) {
    return <CircularProgress />;
  }

  const flatData = flattenUserData(data);
  const profileData: any = flatData?.fullName ? profileDataStart(flatData) : [];

  // TODO: Change hardcoded data to use data from the API
  const { fullName } = flatData;

  const renderItems = (
    {
      children,
      title,
    }: {
      children: JSX.Element;
      title: string;
    },
    index: number
  ) => (
    <ContentBox
      key={`${title}${index}`}
      title={title}
      dropshadow
      showCollapse
      largeTitle
    >
      {children}
    </ContentBox>
  );

  const items = [
    { title: t('immunizations'), children: <Immunizations listOnly /> },
    {
      title: t('medications'),
      children: (
        <div>
          <Medications listOnly />
        </div>
      ),
    },
    {
      title: t('allergies'),
      children: (
        <div>
          <Allergies listOnly />
        </div>
      ),
    },
    {
      title: t('health_conditions'),
      children: (
        <div>
          <HealthConditions listOnly />
        </div>
      ),
    },
    {
      title: t('hospitalizations'),
      children: <MedicalEvents listOnly />,
    },
    {
      title: t('family_history'),
      children: <FamilyHistory listOnly />,
    },
    {
      title: t('vault'),
      // TODO: change user
      children: <VaultDocsDash user="jon" displayOnly />,
    },
  ];

  return (
    <div className={classes.container}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <UserProfileSwitcher hideSettings />
          <div className={classes.columns}>
            <div className={classes.column}>
              <div className="shareDetails">
                <ContentBox title={t('share_details')} dropshadow largeTitle>
                  <div className={classes.detailRows}>
                    <div className={classes.detailRow}>
                      <img src={ShareCalendarGreen} alt="" />
                      <FormSecondary>
                        {t('share_began', {
                          date: toDateString(beginDate, DateFormats.YYYYMMDD),
                        })}
                      </FormSecondary>
                    </div>
                    <div className={classes.detailRow}>
                      <img src={ShareHourglassGreen} alt="" />
                      <FormSecondary>
                        {neverExpires
                          ? 'This Share Never Expires'
                          : t('share_expires', {
                              date: toDateString(
                                expiryDate,
                                DateFormats.YYYYMMDD
                              ),
                            })}
                      </FormSecondary>
                    </div>
                    {includesSensitive && (
                      <div className={classes.detailRow}>
                        <img src={ShareLockGreen} alt="" />
                        <FormSecondary>{t('sensitive_data')}</FormSecondary>
                      </div>
                    )}
                  </div>
                </ContentBox>
              </div>
              <ContentBox
                title={`About ${fullName?.full}`}
                dropshadow
                showCollapse
                largeTitle
              >
                <div className={classes.detailRows}>
                  <div className={classes.detailRow}>
                    <CentipedeList
                      items={profileData?.map(item => ({
                        title: item.title,
                        value: item.info,
                      }))}
                    />
                  </div>
                </div>
              </ContentBox>
            </div>
            <div className={classes.column}>{items.map(renderItems)}</div>
            <div className={classes.column}>
              <ContentBox title="" dropshadow>
                <div className={classes.createAccountContent}>
                  <img
                    src={ShareArtwork}
                    alt="starburst"
                    style={{ width: '100%' }}
                  />
                  <SectionHeader>{t('share_message_1')}</SectionHeader>
                  <FormSecondary>{t('share_message_2')}</FormSecondary>
                  <div className="tags">
                    <div className={classes.badgeContainer}>
                      <img
                        src={AppStoreBadge}
                        alt=""
                        className={classes.badgeImg}
                      />
                      <img
                        src={GooglePlayBadge}
                        alt=""
                        className={classes.badgeImg}
                      />
                    </div>
                  </div>
                  <ThemedButton title={t('create_an_account')} />
                </div>
              </ContentBox>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
