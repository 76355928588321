import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { AccountChecklist } from '../../../components/AccountChecklist/AccountChecklist';
import { CardH2 } from '../../../components/TextComponents/CardH2';
import { useTranslation } from 'react-i18next';
import { SideTextMoreBold } from '../../../components/TextComponents/SideTextMoreBold';
import { SideTextLessBold } from '../../../components/TextComponents/SideTextLessBold';

const useVerifyPhoneStyles = createUseStyles(styles);

const checklistItems = [
  { step: "Scan your driver's license", completed: true, highlighted: false },
  { step: 'Verify your identity', completed: true, highlighted: false },
  {
    step: 'Create login credentials',
    completed: true,
    highlighted: true,
  },
  { step: 'Add additional details*', completed: false, highlighted: false },
  { step: 'Add profile photo*', completed: false, highlighted: false },
];

export const VerifyPhoneText = () => {
  const classes = useVerifyPhoneStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.message}>
      <CardH2>{t('getting_started')}</CardH2>
      <ProgressBar progressWidth={40} />
      <div className={classes.textLeft}>
        <div className={classes.smallText}>
          <SideTextMoreBold>{t('confirm_account')}</SideTextMoreBold>
          <br /> <br />
          <SideTextLessBold>{t('we_texted_you')}</SideTextLessBold>
        </div>
        <div className={classes.checklist}>
          <AccountChecklist checklistItems={checklistItems} />
        </div>
        <div className={classes.asteriskNote}>{t('asterisk_note')}</div>
      </div>
    </div>
  );
};
