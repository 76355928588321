import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './route';
import Posts from '../features/posts/Posts';
import { Counter } from '../features/counter/Counter';
import { ConfigVars } from '../features/config-vars/ConfigVars';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import ComponentsDemo from '../components/ComponentsDemo';
import { LandingPage } from '../features/landing-page/LandingPage';
import { CreateAccountPage } from '../features/create-account/CreateAccountPage';
import { DashboardRedesign } from '../features/dashboard/DashboardRedesign';
import { GuestAccess } from '../features/guest-access/GuestAccess';
import { ROUTES } from '../config/constants';
import { ViewSharePublic } from '../features/guest-access/view-share/ViewSharePublic';

const ErrorComponent = () => {
  throw new Error('Im gonna screw up your React code!');
};
const Routes = () => {
  return (
    <ErrorBoundary supportText="Something Bad Happened, Refresh the Page">
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/posts" component={Posts} isPrivate />
        <Route path="/counter" component={Counter} />
        <Route path="/config-vars" component={ConfigVars} />
        <Route path="/errorExample" component={ErrorComponent} />
        <Route path="/components" component={ComponentsDemo} />
        <Route path="/dashboard" component={DashboardRedesign} isPrivate />
        <Route path={ROUTES.shareViewPublic} component={ViewSharePublic} />
        <Route path="/guestAccess" component={GuestAccess} />
        <Route path="/createAccount" component={CreateAccountPage} />
        <Route component={LandingPage} />
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
