import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { Colors } from '../../config/colors';
import { ROUTES } from '../../config/constants';
import { Typography } from '../../config/typography';
import { DashboardSections } from '../../features/dashboard/DashboardSections';
import {
  selectSection,
  selectUserName,
} from '../../features/dashboard/dashboardSlice';
import { redesignStyles } from '../../features/dashboard/styles';
import { selectCurrentUser } from '../../features/login/loginSlice';

const useBannerStyles = createUseStyles({
  ...redesignStyles,
  youAreViewingStarContainer: {
    ...Typography.navSubHeader1,
    width: '100%',
    backgroundColor: Colors.accent8,
    color: Colors.white,
    padding: '15px 0 10px 0',
    textAlign: 'center',
  },
  unverifiedContainer: {
    ...Typography.navSubHeader1,
    width: '100%',
    backgroundColor: Colors.unverifiedRed,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    color: Colors.white,
    padding: '15px 0 10px 0',
    textAlign: 'center',
  },
  uploadDocButton: {
    border: `1.5px solid ${Colors.white}`,
    borderRadius: '15px',
    maxWidth: '290px',
    padding: '10px 45px',
    cursor: 'pointer',
  },
});

export const DependentBanner = () => {
  const { isDependent, id } = useAppSelector(selectCurrentUser);
  const history = useHistory();
  const dependentName = useAppSelector(selectUserName(id));
  const isVerified = useAppSelector(
    selectSection(DashboardSections.VAULT_DOCS)
  )?.detailList?.some(doc => doc?.tags?.includes('Verification Document'));
  const classes = useBannerStyles();

  return (
    isDependent && (
      <>
        {isVerified ? (
          <div className={classes.youAreViewingStarContainer}>
            {t('you_are_viewing_user', { user: dependentName })}
          </div>
        ) : (
          <div className={classes.unverifiedContainer}>
            <div>{t('need_to_verify_user', { user: dependentName })}</div>
            <div>{t('upload_a_verification_doc')}</div>
            <div
              className={classes.uploadDocButton}
              onClick={() =>
                history.push({
                  pathname: ROUTES.addVaultDocs,
                  state: { tags: ['Verification Document'] },
                })
              }
            >
              {t('upload_a_document')}
            </div>
          </div>
        )}
      </>
    )
  );
};
