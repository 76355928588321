import { Controller } from 'react-hook-form';
import { useAppDispatch } from '../../../app/hooks';
import {
  Dropdown,
  LinkComponent,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import { FormPrompt } from '../../../components/TextComponents/FormPrompt';
import { sharedValidators, noSpaceInput } from '../../../config/constants';
import i18n from '../../../translations';
import { useTranslation } from 'react-i18next';
import { InfoSections, updateInfoSection } from '../dashboardSlice';
import { VeteranStatus } from './VeteranStatus';
import { useState } from 'react';
import { RetirementStatus } from './RetirementStatus';
import { EmergencyContact } from './EmergencyContact';
import { FormBody, HorizontalBreak } from '../../../components/TextComponents';
import { Trash } from '../../../assets/images/icons';
import { Address } from './Address';

export enum profileDataType {
  NAME = 'NAME',
  DOB = 'DOB',
  SSN = 'SSN',
  ADDRESS = 'ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  BIOLOGICAL_GENDER = 'BIOLOGICAL_GENDER',
  RACE = 'RACE',
  VETERAN_STATUS = 'VETERAN_STATUS',
  HOUSEHOLD_INCOME = 'HOUSEHOLD_INCOME',
  GENDER_IDENTITY = 'GENDER_IDENTITY',
  MARITAL_STATUS = 'MARITAL_STATUS',
  RETIREMENT_STATUS = 'RETIREMENT_STATUS',
  EMERGENCY_CONTACTS = 'EMERGENCY_CONTACTS',
}

export const profileDataForms = {
  [profileDataType.NAME]: {},
  [profileDataType.DOB]: {},
  [profileDataType.SSN]: {},
  [profileDataType.ADDRESS]: {},
  [profileDataType.PHONE_NUMBER]: {},
  [profileDataType.BIOLOGICAL_GENDER]: {},
  [profileDataType.RACE]: {},
  [profileDataType.VETERAN_STATUS]: {},
  [profileDataType.HOUSEHOLD_INCOME]: {},
  [profileDataType.GENDER_IDENTITY]: {},
  [profileDataType.MARITAL_STATUS]: {},
  [profileDataType.RETIREMENT_STATUS]: {},
  [profileDataType.EMERGENCY_CONTACTS]: {},
};

export type ProfileDataSection = {
  title: string;
  info: any;
  canEdit: boolean;
  type: profileDataType;
  onSubmit?: (any) => void;
  Form: any;
};

export const profileDataStart: (any) => Array<ProfileDataSection> = data => [
  {
    title: i18n.t('legal_first_name'),
    info: data?.fullName?.first,
    canEdit: false,
    type: profileDataType.NAME,
    Form: () => {
      return (
        <>
          <OneLineTextInput
            placeholder={i18n.t('legal_first_name')}
            type="text"
          />
          <ThemedButton
            title={i18n.t('save')}
            onClick={() => {}}
            // TODO: add info saving func in onClick after info is set to local
          />
        </>
      );
    },
  },
  {
    title: i18n.t('legal_last_name'),
    info: data?.fullName?.family,
    canEdit: false,
    type: profileDataType.NAME,
    Form: () => {
      return (
        <>
          <OneLineTextInput
            placeholder={i18n.t('legal_last_name')}
            type="text"
          />
          <ThemedButton
            title={i18n.t('save')}
            onClick={() => {}}
            // TODO: add info saving func in onClick after info is set to local
          />
        </>
      );
    },
  },
  {
    title: i18n.t('dob'),
    info: data?.birthDate,
    canEdit: false,
    type: profileDataType.DOB,
    Form: () => {
      return <OneLineTextInput placeholder={i18n.t('dob')} type="text" />;
    },
  },
  {
    title: i18n.t('last_4_of_ssn'),
    info: data?.ssn4,
    canEdit: false,
    type: profileDataType.SSN,
    Form: () => {
      return (
        <OneLineTextInput placeholder={i18n.t('last_4_of_ssn')} type="text" />
      );
    },
  },
  {
    title: i18n.t('biological_gender'),
    info: data?.gender,
    canEdit: false,
    type: profileDataType.BIOLOGICAL_GENDER,
    Form: classes => {
      return (
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <OneLineTextInput
              placeholder={i18n.t('biological_gender')}
              type="text"
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {}}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('address'),
    info: data?.flatAddress?.map(addressObj => addressObj.oneLine),
    canEdit: true,
    type: profileDataType.ADDRESS,
    Form: ({ classes, formControls, onClose }) => {
      const { register, handleSubmit } = formControls;
      const [addedAddresses, setAddedAddresses] = useState([
        ...data.flatAddress,
      ]);

      const deleteAddress = index => {
        addedAddresses.splice(index, 1);
        dispatch(
          updateInfoSection({
            section: InfoSections.ADDRESS,
            update: addedAddresses,
          })
        );
      };

      const addressData = data.flatAddress[0];
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = [...data.address];
        dispatch(
          updateInfoSection({
            section: InfoSections.ADDRESS,
            update,
          })
        );
        onClose();
      };

      return (
        <div className={classes.formContainer}>
          {addedAddresses.map((address, idx) => (
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <FormBody>Address {idx + 1}</FormBody>
                <img
                  src={Trash}
                  alt="trash"
                  onClick={() => deleteAddress(idx)}
                  width="25px"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <Address
                address={address}
                formControls={formControls}
                classes={classes}
                index={idx}
              />
              <HorizontalBreak />
            </>
          ))}
          <LinkComponent
            label={i18n.t('add_another_address')}
            onClick={() =>
              setAddedAddresses([
                ...addedAddresses,
                {
                  city: '',
                  id: '',
                  line: [''],
                  oneLine: '',
                  state: '',
                  postalCode: '',
                },
              ])
            }
          />
          <div style={{ height: '0px', width: '0px', opacity: '0' }}>
            <OneLineTextInput
              type="text"
              defaultValue={addressData?.id}
              {...register('id', {})}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('phone_number'),
    info: data?.telecom,
    canEdit: true,
    type: profileDataType.PHONE_NUMBER,
    Form: ({ classes, formControls, onClose }) => {
      const { register, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = { value: data.phone };
        dispatch(updateInfoSection({ section: InfoSections.PHONE, update }));
        onClose();
      };
      return (
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <OneLineTextInput
              placeholder={i18n.t('phone_number')}
              defaultValue={data.telecom}
              type="text"
              formErrors={errors}
              {...register('phone', {
                required: 'Please provide a phone number',
                ...sharedValidators.phoneNumber,
              })}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('preferred_name'),
    info: data?.preferredName,
    canEdit: true,
    type: profileDataType.NAME,
    Form: ({ classes, formControls, onClose }) => {
      const { register, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = data.name;
        dispatch(
          updateInfoSection({ section: InfoSections.PREFERRED_NAME, update })
        );
        onClose();
      };

      return (
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <OneLineTextInput
              placeholder={i18n.t('preferred_name')}
              defaultValue={data.preferredName}
              type="text"
              formErrors={errors}
              {...register('name', {
                required: `'Please provide a preferred name'`,
                pattern: {
                  value: noSpaceInput,
                  message: 'Please provide a preferred name',
                },
              })}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('veteran_status'),
    info: data?.veteran?.status,
    canEdit: true,
    type: profileDataType.VETERAN_STATUS,
    Form: ({ classes, formControls, onClose }) => {
      const { register, handleSubmit, setValue } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = {
          status: { fhirData: { text: data.veteranStatus } },
          identifier: data.veteranId || '',
        };
        dispatch(updateInfoSection({ section: InfoSections.VETERAN, update }));
        onClose();
      };
      return (
        <VeteranStatus
          setValue={setValue}
          classes={classes}
          defaultValue={{
            value: data.military.status.fhirData.text || 'N',
            identifier: data.military.identifier,
          }}
          register={register}
          onSave={() => {
            handleSubmit(onSubmit)();
          }}
        />
      );
    },
  },
  {
    title: i18n.t('race'),
    info: data?.physical?.ethnicity?.fhirData?.text,
    canEdit: true,
    type: profileDataType.RACE,
    Form: ({ classes, formControls, onClose }) => {
      const { control, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = data?.race?.value || data.race;
        dispatch(updateInfoSection({ section: InfoSections.RACE, update }));
        onClose();
      };
      return (
        <div className={classes.formContainer}>
          <div className={classes.prompt}>
            <FormPrompt>{i18n.t('race_prompt')}</FormPrompt>
          </div>
          <div className={classes.inputContainer}>
            <Controller
              name="race"
              control={control}
              defaultValue={data?.physical?.ethnicity?.fhirData?.text}
              shouldUnregister={true}
              rules={{ required: 'Please select a race/ethnicity.' }}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={i18n.t('select_one')}
                  optionsList={[
                    i18n.t('american_indian'),
                    i18n.t('asian'),
                    i18n.t('african_american'),
                    i18n.t('pacific_islander'),
                    i18n.t('white'),
                    i18n.t('two_plus_races'),
                    i18n.t('no_response'),
                  ]}
                  formErrors={errors}
                />
              )}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('household_income'),
    info: data?.householdIncome?.fhirData?.text,
    canEdit: true,
    type: profileDataType.HOUSEHOLD_INCOME,
    Form: ({ classes, formControls, onClose }) => {
      const { control, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = data?.income?.value || data.income;
        dispatch(updateInfoSection({ section: InfoSections.INCOME, update }));
        onClose();
      };
      return (
        <div className={classes.formContainer}>
          <div className={classes.prompt}>
            <FormPrompt>{i18n.t('household_income_prompt')}</FormPrompt>
          </div>
          <div className={classes.inputContainer}>
            <Controller
              name="income"
              control={control}
              rules={{ required: 'Please select an income bracket' }}
              defaultValue={data?.householdIncome?.fhirData?.text}
              shouldUnregister={true}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={i18n.t('select_one')}
                  optionsList={[
                    'income level 1',
                    'income level 2',
                    'income level 3',
                  ]}
                  formErrors={errors}
                />
              )}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('gender_identity'),
    info: data?.identityGender,
    canEdit: true,
    type: profileDataType.GENDER_IDENTITY,
    Form: ({ classes, formControls, onClose }) => {
      const { control, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();
      const { t } = useTranslation();

      const onSubmit = data => {
        const update = data?.genderId?.value || data.genderId;
        dispatch(
          updateInfoSection({ section: InfoSections.GENDER_IDENTITY, update })
        );
        onClose();
      };
      return (
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <Controller
              name="genderId"
              control={control}
              rules={{ required: `${t('gender_id_required')}` }}
              defaultValue={data?.identityGender}
              shouldUnregister={true}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={i18n.t('select_one')}
                  optionsList={[
                    i18n.t('gender_male'),
                    i18n.t('gender_female'),
                    i18n.t('other'),
                  ]}
                  formErrors={errors}
                />
              )}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('marital_status'),
    info: data?.maritalStatus,
    canEdit: true,
    type: profileDataType.MARITAL_STATUS,
    Form: ({ classes, formControls, onClose }) => {
      const { control, errors, handleSubmit } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        const update = data?.status?.value || data.status;
        dispatch(
          updateInfoSection({ section: InfoSections.MARITAL_STATUS, update })
        );
        onClose();
      };
      return (
        <div className={classes.formContainer}>
          <div className={classes.prompt}>
            <FormPrompt>{i18n.t('marital_status_prompt')}</FormPrompt>
          </div>
          <div className={classes.inputContainer}>
            <Controller
              name="status"
              control={control}
              defaultValue={data?.maritalStatus}
              shouldUnregister={true}
              rules={{ required: 'Please select a marital status.' }}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={i18n.t('select_one')}
                  optionsList={[
                    i18n.t('single'),
                    i18n.t('married'),
                    i18n.t('separated'),
                    i18n.t('divorced'),
                    i18n.t('widowed'),
                  ]}
                  formErrors={errors}
                />
              )}
            />
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={i18n.t('save')}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              // TODO: add info saving func in onClick after info is set to local
            />
          </div>
        </div>
      );
    },
  },
  {
    title: i18n.t('retirement_date'),
    info: data?.retirementDate,
    canEdit: true,
    type: profileDataType.RETIREMENT_STATUS,
    Form: ({ classes, formControls, onClose }) => {
      const { register, handleSubmit, setValue } = formControls;
      const dispatch = useAppDispatch();

      const onSubmit = data => {
        let update = data.retirementDate;
        if (data.retirementDate === 'N') update = '12/31/9999';
        dispatch(
          updateInfoSection({ section: InfoSections.RETIREMENT_STATUS, update })
        );
        onClose();
      };

      return (
        <RetirementStatus
          setValue={setValue}
          classes={classes}
          defaultValue={data.retirementDate}
          register={register}
          onSave={() => {
            handleSubmit(onSubmit)();
          }}
        />
      );
    },
  },
  {
    title: i18n.t('emergency_contacts'),
    info: data?.emergencyContacts?.map(cont => cont.fullName).join(', '),
    canEdit: true,
    type: profileDataType.EMERGENCY_CONTACTS,
    Form: ({ classes, formControls, onClose }) => {
      const { handleSubmit } = formControls;
      const dispatch = useAppDispatch();
      const contactList: Array<any> = data?.emergencyContacts || [];

      const addEmergencyContact = () => {
        const update = [...contactList, {}];
        dispatch(
          updateInfoSection({
            section: InfoSections.EMERGENCY_CONTACTS,
            update,
          })
        );
      };

      const deleteContact = index => {
        contactList.splice(index, 1);
        dispatch(
          updateInfoSection({
            section: InfoSections.EMERGENCY_CONTACTS,
            update: contactList,
          })
        );
      };

      const onSubmit = data => {
        const update = [...data?.emergencyContact];
        dispatch(
          updateInfoSection({
            section: InfoSections.EMERGENCY_CONTACTS,
            update,
          })
        );
        onClose();
      };

      return (
        <div className={classes.formContainer}>
          {contactList.map((cont, index) => (
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <FormBody>
                  {index === 0
                    ? i18n.t('primary_contact_short')
                    : i18n.t('secondary_contact_short')}
                </FormBody>
                <img
                  src={Trash}
                  alt=""
                  onClick={() => deleteContact(index)}
                  width="25px"
                />
              </div>
              <EmergencyContact
                index={index}
                contact={cont}
                formControls={formControls}
                classes={classes}
              />
              <HorizontalBreak />
            </>
          ))}
          <LinkComponent
            label={i18n.t('add_emergency_contact')}
            onClick={addEmergencyContact}
          />
          {!!contactList?.length && (
            <div className={classes.buttonContainer}>
              <ThemedButton
                title={i18n.t('save')}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          )}
        </div>
      );
    },
  },
];
