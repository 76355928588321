import { DateFormats, toDateString } from '../../../config/utilities';

const eventCodeToTitle = string => {
  if (string === 'S') return 'Surgery';
  else if (string === 'H') return 'Hospitalization';
  else if (string === 'P') return 'Procedure';
  else if (string === 'I') return 'Major Injury';
  else if (string === 'M') return 'Major Injury';
};

export const flattenMedicalEvent = event => {
  if (!event) {
    return undefined;
  }
  const title = eventCodeToTitle(event.fhirData.class.code);
  const detailItems = [
    [
      event?.fhirData?.diagnosis[0]?.condition?.display,
      toDateString(event?.fhirData?.period?.start, DateFormats.MMYYYY),
    ],
  ];
  return {
    id: event.fhirData.id,
    eventName: event?.fhirData?.diagnosis[0]?.condition?.display,
    title,
    date: event?.fhirData?.period?.start,
    detailItems,
    sensitive: event.sensitive,
    code: event.fhirData.class.code,
  };
};
