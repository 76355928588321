import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { getVaultDocURL } from '../../api/vaultUtils';

type props = {
  imageId: string;
  className?: string;
  alt?: string;
};
export const SecureImageLoader: React.FC<props> = ({
  imageId,
  className,
  alt = '',
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const getUrl = async () => {
      const url = await getVaultDocURL(imageId);
      setImageUrl(url);
      setIsLoading(false);
    };
    getUrl();
  });

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <img src={imageUrl} alt={alt} className={className} />
      )}
    </>
  );
};
