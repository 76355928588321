import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useAppDispatch } from '../../app/hooks';
import { DashboardSections } from '../../features/dashboard/DashboardSections';
import { deleteFhirItem } from '../../features/dashboard/dashboardSlice';

type props = {
  open: boolean;
  handleClose: () => void;
  document: any;
};
export const ConfirmDeleteDocumentModal: React.FC<props> = ({
  open,
  handleClose,
  document,
}) => {
  const dispatch = useAppDispatch();
  const confirmMessage = `Are you sure you want to delete ${
    document?.title || 'this document'
  }?`;
  const deleteDocument = () => {
    dispatch(
      deleteFhirItem({
        section: DashboardSections.VAULT_DOCS,
        id: document?.id || '',
      })
    );
    handleClose();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {confirmMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteDocument}>Yes</Button>
        <Button onClick={handleClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};
