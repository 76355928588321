import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ROUTES } from '../../../config/constants';
import { Insurance } from '../../../config/SharedTypes';
import { useState } from 'react';
import { dismissSection, selectInsurances } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { ThemedButton } from '../../../components';
import {
  FormHeader,
  FormPrompt,
  PageHeader,
} from '../../../components/TextComponents';
import { useInsuranceScanCardStyles } from './styles';
import ScanCardImage from '../../../assets/images/ScanCardImage.svg';
import { ImageUpload } from '../../../components/ImageUpload/ImageUpload';
import idFrontIcon from '../../../assets/idFrontIcon.png';
import idBackIcon from '../../../assets/idBackIcon.png';
import { LinkComponent } from '../../../components';
import { getUUID } from '../../../config/utilities';
import { uploadInsuranceCard } from '../../../api/vaultUtils';

type InsuranceScanCardProps = {
  details?: Insurance[];
};

export const InsuranceScanCard: React.FC<InsuranceScanCardProps> = () => {
  const classes = useInsuranceScanCardStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectInsurances);
  const dismissed = !!detailList;
  const insurances = detailList || [];
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isFrontUploaded, setIsFrontUploaded] = useState(false);
  const [isBackUploaded, setIsBackUploaded] = useState(false);
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const nextPage = async (frontImage, backImage) => {
    const newId = getUUID();
    history.push({
      pathname: ROUTES.insuranceEdit,
      search: `?id=${newId}&front=${frontImage}&back=${backImage}`,
    });
  };

  const editInsuranceCard = async (side: 'back' | 'front', imageData: any) => {
    if (side === 'front') {
      const frontImageUrl = await uploadInsuranceCard({
        fileExtension: imageData.fileExtension,
        imageData: imageData.data,
      });
      setFrontImage(frontImageUrl.id);
    } else if (side === 'back') {
      const backImageUrl = await uploadInsuranceCard({
        fileExtension: imageData.fileExtension,
        imageData: imageData.data,
      });
      setBackImage(backImageUrl.id);
    }
  };

  return (
    <>
      <FormHeader>{t('health_insurance_title_case')}</FormHeader>
      <div className={classes.container}>
        <img
          src={ScanCardImage}
          alt="ScanCardImage"
          className={classes.scanCardImg}
        />
        <div className={classes.scanInstructions}>
          <PageHeader>{t('lets_add_insurance_info')}</PageHeader>
          <div className={classes.paragragh}>
            <FormPrompt>{t('insurance_upload_explain')}</FormPrompt>
          </div>
          <FormPrompt>{t('id_capture_subtitle')}</FormPrompt>
        </div>
        <div className={classes.cardUploadContainer}>
          <div className={classes.cardSide}>
            <ImageUpload
              imgType="front"
              iconSrc={idFrontIcon}
              isUploaded={isFrontUploaded}
              setIsUploaded={setIsFrontUploaded}
              imageLink={frontImage}
              setImageLink={data => editInsuranceCard('front', data)}
              isSecure
            />
            {isFrontUploaded && (
              <div className={classes.reUploadContainer}>
                <LinkComponent
                  label={t('reupload_front')}
                  onClick={() => setIsFrontUploaded(false)}
                />
              </div>
            )}
          </div>
          <div className={classes.cardSide}>
            <ImageUpload
              imgType="back"
              iconSrc={idBackIcon}
              isUploaded={isBackUploaded}
              setIsUploaded={setIsBackUploaded}
              imageLink={backImage}
              setImageLink={data => editInsuranceCard('back', data)}
              isSecure
            />
            {isBackUploaded && (
              <div className={classes.reUploadContainer}>
                <LinkComponent
                  label={t('reupload_back')}
                  onClick={() => setIsBackUploaded(false)}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.continueButton}>
          <ThemedButton
            title={t('continue')}
            isDisabled={!(isFrontUploaded && isBackUploaded)}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              nextPage(frontImage, backImage);
            }}
          />
        </div>
        {!insurances.length && !dismissed && isFrontUploaded && isBackUploaded && (
          <div className={classes.noInsuranceButton}>
            <ThemedButton
              title={t('no_insurance')}
              isInverted
              onClick={() => {
                dispatch(
                  dismissSection({
                    section: DashboardSections.INSURANCES,
                  })
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
