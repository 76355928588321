import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { notificationCircle } from '../../assets/images/icons';
import { TileHeader } from '../TextComponents';
import { DrawerNotifications } from '../TextComponents/DrawerNotifications';
import { styles } from './styles';

interface QuickLinkTileProps {
  title: string;
  icon: string;
  notificationCount: number;
  path: string;
  disabled?: boolean;
}

const useQuickLinkTileStyles = createUseStyles(styles);

export const QuickLinkTile: React.FC<QuickLinkTileProps> = ({
  title,
  icon,
  notificationCount,
  path,
}) => {
  const classes = useQuickLinkTileStyles();
  const history = useHistory();
  return (
    <div className={classes.container} onClick={() => history.push(path)}>
      <div className={classes.imageContainer}>
        <img width="62px" src={icon} alt={title} />
      </div>
      {notificationCount > 0 && (
        <div className={classes.notifContainer}>
          <img width="18px" src={notificationCircle} alt={title} />
          <div className={classes.notifications}>
            <DrawerNotifications>{notificationCount}</DrawerNotifications>
          </div>
        </div>
      )}
      <div className={classes.title}>
        <div className={classes.header}>
          <TileHeader>{title}</TileHeader>
        </div>
      </div>
    </div>
  );
};
