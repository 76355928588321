import { DashboardData } from '../dashboardSlice';

export const defaultInsurances: DashboardData = {
  dismissed: false,
  notificationCount: 0,
  detailList: [
    {
      id: '0',
      frontImage: '',
      backImage: '',
      provider: 'Blue Cross Blue Shield',
      type: 'Medical',
      subName: 'Jane Doe',
      subNumber: 'W123456789000',
      groupNumber: '08928361728000',
      payerId: '',
      portalURL: 'bcbsglobal.com/member-login',
      portalUsername: 'janepeterson112',
      portalPassword: 'password123',
      verificationPending: false,
      verified: true,
      active: true,
    },
    {
      id: '1',
      frontImage: '',
      backImage: '',
      provider: 'Blue Cross Blue Shield',
      type: 'Medical',
      subName: 'John Doe',
      subNumber: 'W000123456789',
      groupNumber: '12345678901234',
      payerId: '',
      portalURL: 'bcbsglobal.com/member-login',
      portalUsername: 'johnDoe321',
      portalPassword: 'password321',
      verificationPending: true,
      verified: false,
      active: true,
    },
    {
      id: '2',
      frontImage: '',
      backImage: '',
      provider: 'Delta Dental',
      type: 'Dental',
      subName: 'Jane Doe',
      subNumber: 'W98654321000',
      groupNumber: '010203040506',
      payerId: '',
      portalURL: 'deltadental.com/us/en/login.html',
      portalUsername: 'janepeterson112',
      portalPassword: 'password123',
      verificationPending: false,
      verified: false,
      active: false,
    },
  ],
};
