import { useEffect, useState } from 'react';
import { ContentBox, LinkComponent, ThemedButton } from '../../../components';
import { AboutMeInfo } from '../../../components/AboutMeInfo/AboutMeInfo';
import { profileDataStart } from './sampleProfileData';
import { DescriptionText } from '../../../components/TextComponents';
import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { X } from '../../../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  getProfileThunk,
  InfoSections,
  selectProfilePhoto,
  selectSection,
  updateInfoSection,
} from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { flattenUserData } from './utils';
import { Modal } from '../../../components/Modal/Modal';
import Dropzone from 'react-dropzone';
import { getFileExtension, uploadPublicImage } from '../../../api/vaultUtils';
import { ProfileImage } from '../../../assets/images';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

const useAboutMeStyles = createUseStyles(styles);

export const About: React.FC = () => {
  const classes = useAboutMeStyles();
  const { t } = useTranslation();
  const { data } = useAppSelector(selectSection(DashboardSections.ABOUT_ME));
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showReminder, setShowReminder] = useState(true);
  const flatData = flattenUserData(data);
  const profileData = profileDataStart(flatData);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const photo = useAppSelector(selectProfilePhoto);
  const [isLoadingProfileImage, setIsLoadingProfileImage] = useState(false);
  const [imgLink, setImgLink] = useState(photo?.length ? photo : ProfileImage);
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  useEffect(() => {
    setImgLink(photo?.length ? photo : ProfileImage);
  }, [photo]);

  const uploadProfilePhoto = async ({ fileExtension, data }) => {
    setIsLoadingProfileImage(true);
    const uploadedPhoto = await uploadPublicImage({
      fileExtension,
      imageData: data,
    });
    setImgLink(uploadedPhoto.url);
    setIsLoadingProfileImage(false);
  };

  const saveProfilePhoto = () => {
    dispatch(
      updateInfoSection({
        section: InfoSections.PROFILE_PHOTO,
        update: imgLink,
      })
    );
  };

  return (
    <div className={classes.contentBox}>
      <ContentBox title={t('about_me_title_case')}>
        {showReminder && !isShareUser && (
          <div className={classes.purpleBox}>
            <div className={classes.titleAndX}>
              <DescriptionText>{t('can_add_details')}</DescriptionText>
              <div
                className={classes.xBox}
                onClick={() => {
                  setShowReminder(false);
                }}
              >
                <img src={X} alt="x" />
              </div>
            </div>
          </div>
        )}
        <div className={classes.editProfilePicBox}>
          <div className={classes.inner}>
            <img
              src={imgLink}
              alt="Profile"
              className={classes.profilePicture}
            />
            {!isShareUser && (
              <LinkComponent
                label={t('edit_profile_photo')}
                onClick={() => setOpenModal(true)}
              />
            )}
          </div>
        </div>
        <div className={classes.firstDividerLine} />
        {profileData.map(data => {
          return (
            <AboutMeInfo
              data={{ ...data, canEdit: !isShareUser && data.canEdit }}
              setIsInEditMode={setIsInEditMode}
              isInEditMode={isInEditMode}
              notSpecified={!isShareUser && !data?.info?.length}
              onSubmit={data.onSubmit}
              isRetirementInfo={data.type === 'RETIREMENT_STATUS'}
            />
          );
        })}
      </ContentBox>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t('edit_profile_photo')}
      >
        <div className={classes.modalContent}>
          <img
            src={imgLink}
            alt="profile"
            className={classes.modalProfilePic}
          />
          <Dropzone
            accept={'image/jpeg, image/png'}
            onDrop={acceptedFiles => {
              acceptedFiles.forEach(file => {
                const reader = new FileReader();
                const fileExtension = getFileExtension(file?.type);
                reader.onload = () => {
                  const dataURL = reader.result as string;
                  localStorage.setItem(`profilePicture`, dataURL);
                  uploadProfilePhoto({ fileExtension, data: dataURL });
                };
                reader.readAsDataURL(file);
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <LinkComponent label={t('upload_different_photo')} />
                </div>
              </section>
            )}
          </Dropzone>
          <div className={classes.saveButton}>
            <ThemedButton
              title={t('save')}
              isLoading={isLoadingProfileImage}
              isDisabled={isLoadingProfileImage}
              onClick={() => {
                // TODO: update profile data w/ new pic when About Me info is being saved
                saveProfilePhoto();
                setOpenModal(false);
              }}
            />
          </div>
          <LinkComponent
            label={t('cancel')}
            onClick={() => setOpenModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
};
