import { createUseStyles } from 'react-jss';

const useHeaderStyles = createUseStyles({
  descriptionText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },
});
export const DescriptionText: React.FC = ({ children }) => {
  const classes = useHeaderStyles();
  return <div className={classes.descriptionText}>{children}</div>;
};
