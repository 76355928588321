import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

const useStyles = createUseStyles({
  style: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '-0.3px',
    color: Colors.accent5Purple,
    display: 'flex',
  },
});
export const CardHeader1: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.style}>{children}</div>;
};
