import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  Dropdown,
  MarkSensitive,
  OneLineTextInput,
  TextArea,
  ThemedButton,
} from '../../../components';
import {
  CardDetail1,
  SubHeader3,
  FormPrompt,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { EditAllergyForm, HealthDetail } from '../../../config/SharedTypes';
import { getUUID } from '../../../config/utilities';
import { selectAllergies, updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { useEditAllergiesStyles } from './styles';
import { DashboardUpdate } from '../utils';
import { SingleAllergy } from '../../../typings/vecna_types/vecnaPatient';
import {
  allergyToHealthDetail,
  allergyToFHIR,
  severityOptions,
} from './allergyUtils';
import { noSpaceInput } from '../../../config/constants';

type HealthAllergiesProps = {
  detail?: HealthDetail;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditAllergy: React.FC<HealthAllergiesProps> = () => {
  const classes = useEditAllergiesStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList } = useAppSelector(selectAllergies);
  const allergies = detailList?.map(el => allergyToHealthDetail(el)) || [];
  const queryId = useQuery().get('id');
  const [allergyId] = useState(queryId || getUUID());
  const history = useHistory();
  const allergyData: HealthDetail = allergies.find(el => el.id === allergyId);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditAllergyForm>();

  if (allergyData) {
    const severity =
      severityOptions.find(opt => opt.label === allergyData?.detailItems[0][0])
        ?.value || '';
    setValue('severity', severity);
  }

  const onSubmit = data => {
    const severityText = data.severity.value || data.severity;
    const dataModelObject = allergyToFHIR(
      { ...data, severity: severityText },
      allergyId
    );
    const update: DashboardUpdate<SingleAllergy> = {
      section: DashboardSections.ALLERGY,
      detailItem: dataModelObject,
    };
    dispatch(updateFhirItem(update));
    history.goBack();
  };

  const onError = errors => {
    console.error(errors);
  };

  return (
    <>
      <div className={classes.subheader}>
        <FormPrompt>{t('allergy_add_any_message')}</FormPrompt>
      </div>
      <div className={classes.container}>
        <div className={classes.editForm}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardDetail1>{t('allergy_search_message')}</CardDetail1>
            <OneLineTextInput
              placeholder={t('allrgy_type')}
              type="text"
              defaultValue={allergyData?.title}
              {...register('allergyType', {
                required: `${t('allrgy_type_required')}`,
                pattern: {
                  value: noSpaceInput,
                  message: `${t('allrgy_type_required')}`,
                },
              })} //TODO: Resolve type issue and use translations string
              formErrors={errors}
            />
            <div
              style={{
                height: '0.5px',
                borderTop: '.5px #bdbdbd solid',
                margin: '10px 0',
              }}
            />
            <Controller
              name="severity"
              control={control}
              rules={{ required: 'Please select the severity of the allergy' }}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={t('select_svrty')}
                  optionsArr={severityOptions}
                  formErrors={errors}
                />
              )}
            />

            <TextArea
              placeholder={t('exposure_action')}
              defaultValue={allergyData?.detailItems[0][1]}
              {...register('courseOfAction', {
                required: 'Please enter what course of action is needed.',
              })} //TODO: Resolve type issue and use translations string
              formErrors={errors}
            />
            <div
              style={{
                height: '0.5px',
                borderTop: '.5px #bdbdbd solid',
                margin: '10px 0',
              }}
            />
            <MarkSensitive
              register={{ ...register('sensitive') }}
              defaultValue={allergyData?.sensitive}
            />
            <div
              style={{
                paddingTop: '12px',
                maxWidth: '222px',
                display: 'flex',
                gap: '20px',
              }}
            >
              <ThemedButton title={t('save')} type="submit" />
              <ThemedButton
                title={t('cancel', { thing: 'allergies' })}
                isInverted
                onClick={() => {
                  history.push(ROUTES.allergyDashboard);
                }}
              />
            </div>
          </form>
        </div>
        <div className={classes.addMessage}>
          <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
        </div>
      </div>
    </>
  );
};
