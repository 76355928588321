import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { Colors } from '../../config/colors';

export const useProgressBarStyles: (
  data?: { progressWidth: number; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'container' | 'barBackground' | 'progressBar'> = createUseStyles({
  container: {
    margin: '8px 0 32px',
  },
  barBackground: {
    backgroundColor: Colors.white,
    height: '.1em',
    borderRadius: '9px',
    width: '100%',
  },
  progressBar: ({ progressWidth }) => ({
    width: `${progressWidth}%`,
    backgroundColor: Colors.progressYellow,
    height: '.1em',
    borderRadius: '9px',
    position: 'relative',
    top: '-0.1em',
  }),
});
