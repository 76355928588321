import { SingleVaultData } from '../../../typings/vecna_types/vecnaPatient';

export const flattenVaultDoc = (doc: SingleVaultData) => {
  if (!doc) {
    return undefined;
  }
  return {
    ...doc.fhirData,
    tags: doc.tags,
    sensitive: doc.sensitive,
    deathNote: doc.deathNote,
  };
};

export const createVaultDoc = ({
  id,
  title,
  url,
  tags,
  sensitive,
  deathNote,
  contentType,
}) => {
  const createDate = new Date().toISOString();
  const res: SingleVaultData = {
    fhirData: {
      id: id,
      title: title,
      contentType: contentType,
      url: url,
      creation: createDate,
    },
    tags: [...tags],
    sensitive: sensitive,
    deathNote: deathNote,
  };
  return res;
};
