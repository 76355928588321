import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { actionButtons } from '../../../config/sharedStyles';

export const useConditionsStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '25px 0 0  70px',
    },
  },
  currentConditions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  pastConditions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  actionButtons,
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19px',
    color: Colors.accent10,
  },
  detailCard: {
    display: 'flex',
  },
});

export const useAddConditionsStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
    marginTop: '15px',
    gap: '15px',
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '19px',
    color: Colors.accent10,
  },
  actionButtons,
});

export const useEditAllergiesStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '6px',
    padding: '25px',
  },
  pastAllergies: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '34px',
    color: Colors.linkPurple,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '21.2rem',
      height: '3.2rem',
    },
  },
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19px',
    color: Colors.accent10,
  },
});

export const useHealthConditionCardStyles = createUseStyles({
  conditionCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '26px',
    padding: '15px 20px 20px 20px',
    borderRadius: '6px',
    backgroundColor: Colors.white,
  },
  cardTitle: {
    height: '32px',
    margin: '0 -20px',
    paddingLeft: '20px',
  },
  topSection: {
    margin: '0 -20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
  },
  middleSection: {
    margin: '0 -20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${Colors.notificationBorder}`,
    marginBottom: '8px',
  },
  currentToggle: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
  },
  dateFields: {
    display: 'flex',
    gap: '15px',
  },
  inputFieldContainer: {
    width: '100%',
  },
});
