import { getUUID } from '../../../config/utilities';

export const healthConditionToDetail = condition => {
  const detailItems = [];
  if (condition.onsetPeriod?.length) {
    detailItems.push(...condition.detailItems);
  }
  if (condition.onsetPeriod?.start?.length) {
    detailItems.push(['Onset', condition.onsetPeriod.start]);
  }
  if (condition.onsetPeriod?.end?.length) {
    detailItems.push(['Resolution', condition.onsetPeriod.end]);
  }
  return {
    ...condition,
    title: condition.code.text,
    detailItems,
  };
};

export const flattenHealthConditions = arr => {
  if (!arr) {
    return undefined;
  }
  return arr.map(item => {
    return {
      ...item,
      ...item.fhirData,
      sensitive: item.sensitive,
      current: !item.fhirData.onsetPeriod.hasOwnProperty('end'),
    };
  });
};

export const newHealthCondition = (
  conditionName: string,
  sensitive: boolean,
  startDate?: string,
  endDate?: string
) => {
  return {
    fhirData: {
      id: getUUID(),
      subject: {},
      resourceType: 'Condition',
      code: { text: conditionName },
      onsetPeriod: {
        ...(startDate && { start: startDate }),
        ...(endDate && { end: endDate }),
      },
    },
    sensitive: sensitive,
  };
};
