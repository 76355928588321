import { cardContainer } from '../styles';
import {
  textPageStyles,
  centeredContent,
  bottomButton,
} from '../../../config/sharedStyles';
import { Colors } from '../../../config/colors';

export const styles = {
  cardContainer,
  ...textPageStyles,
  centeredContent,
  bottomButton,
  subTitle: {
    color: Colors.primaryText,
  },
  codeInputArea: {
    display: 'flex',
    justifyContent: 'center',
    gap: '26px',
    margin: '32px 0 34px',
  },
  digitInputBox: {
    border: `1px solid ${Colors.checkboxGray}`,
    borderRadius: '10px',
    width: '74px',
    height: '96px',
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontSize: '60px',
    fontWeight: '500',
    color: Colors.primaryText,
  },
  linkContainer: {
    marginBottom: '26px',
    width: '200px',
  },
  asteriskNote: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    marginLeft: '35px',
  },
};
