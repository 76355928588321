import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';

export const useInsuranceCardStyles = createUseStyles({
  container: {
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    maxWidth: '700px',
    width: '700px',
    margin: '18px 0 18px 0',
    padding: '8px 28px 35px 22px',
    position: 'relative',
    border: `1px solid ${Colors.notificationBorder}`,
  },
  logoContainer: {
    width: '71px',
    height: '44px',
  },
  logoImg: {
    width: '97%',
    height: '97%',
    objectFit: 'contain',
    backgroundSize: 'cover',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  providerAndStatus: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0 16px 0',
  },
  verifiedStatus: {
    display: 'flex',
    marginLeft: '20px',
    gap: '5px',
  },
  arrowRight: {
    cursor: 'pointer',
  },
});
