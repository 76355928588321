import { SingleAllergy } from '../../../typings/vecna_types/vecnaPatient';
import { HealthDetail, EditAllergyForm } from '../../../config/SharedTypes';
import { getUUID } from '../../../config/utilities';
import i18n from '../../../translations';

enum ResourceTypes {
  ALLERGY = 'AllergyIntolerance',
}

export const severityOptions = [
  { label: i18n.t('very_mild'), value: 'V' },
  { label: i18n.t('mild'), value: 'M' },
  { label: i18n.t('moderate'), value: 'MD' },
  { label: i18n.t('severe'), value: 'S' },
  { label: i18n.t('extreme'), value: 'E' },
];

//Allergy Utilities
export const allergyToHealthDetail = (a: SingleAllergy) => {
  const obj: HealthDetail = {
    id: a.fhirData.id,
    title: a.fhirData.code.text,
    detailItems: [
      [
        severityOptions.find(
          opt => opt.value === a.fhirData.reaction[0].manifestation[0].text
        )?.label || '',
        a.courseOfActionUponExposure,
      ],
    ],
    sensitive: a.sensitive,
  };
  return obj;
};

export const flattenAllergy = (a: SingleAllergy) => {
  const obj = {
    id: a.fhirData.id,
    allergyType: a.fhirData.code.text,
    severity: a.fhirData.reaction[0].manifestation[0].text,
    sensitive: a.sensitive,
    courseOfAction: a.courseOfActionUponExposure,
  };
  return obj;
};

export const allergyToFHIR = (
  a: EditAllergyForm,
  id: string
): SingleAllergy => {
  const conversion: SingleAllergy = {
    fhirData: {
      id: id || getUUID(),
      resourceType: ResourceTypes.ALLERGY,
      patient: {},
      code: {
        text: a.allergyType,
      },
      reaction: [
        {
          manifestation: [{ text: a.severity }],
        },
      ],
    },
    sensitive: a.sensitive,
    courseOfActionUponExposure: a.courseOfAction,
  };
  return conversion;
};
