import { Colors } from './colors';
import { DESKTOP_VIEW_BREAKPOINT } from './constants';

// This cardContainer style can be reused by the subpages
export const cardContainer = {
  display: 'flex',
  margin: '30px 10px',
  width: '80%',
  [DESKTOP_VIEW_BREAKPOINT]: {
    margin: '15px 26px',
    width: 'unset',
  },
};
export const textPageStyles = {
  message: {
    textAlign: 'left',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: '500',
    marginBottom: '10px',
  },
  textLeft: {
    fontSize: '1.5rem',
  },
  subheader: {
    fontWeight: '700',
  },
  smallText: {
    fontWeight: '500',
    marginBottom: '46px',
  },
  checklist: {
    textAlign: 'left',
    display: 'none',
    [DESKTOP_VIEW_BREAKPOINT]: {
      display: 'block',
    },
  },
};

export const centeredContent = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
};

export const bottomButton = {
  display: 'flex',
  flexDirection: 'column',
  [DESKTOP_VIEW_BREAKPOINT]: {
    padding: '0 20%',
  },
};

export const errorText = {
  fontSize: '14px',
  color: Colors.errorRed,
};

export const actionButtons = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  color: Colors.secondaryText,
  marginTop: '10px',
  [DESKTOP_VIEW_BREAKPOINT]: {
    width: '21.2rem',
  },
};

export const badgeContainer = {
  display: 'flex',
  justifyContent: 'space-evenly',
  [DESKTOP_VIEW_BREAKPOINT]: {
    justifyContent: 'unset',
    flexWrap: 'wrap',
    gap: '30px',
  },
};

export const badgeImg = {
  height: '48px',
  [DESKTOP_VIEW_BREAKPOINT]: {
    height: '64px',
  },
};
