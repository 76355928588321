import { AxiosResponse } from 'axios';

import { setCustomApiError } from '../config/utilities';
import i18n from '../translations';
import api from './axios';

interface UserResponse {
  id: string;
  username: string;
  email: string;
  enabled: boolean;
  info: {
    fhirData: {
      photo: {
        '0': {
          url: string;
        };
      };
      name: {
        '0': {
          family: string;
          given: string[];
        };
      };
      address: {
        '0': {
          city: string;
          state: string;
        };
      };
    };
  };
}
interface GetSharedApiResponse {
  status: string;
  with: UserResponse;
}
export const getUserInformation = (username: string) =>
  api
    .get(`/users/lookup/${username || '_'}`)
    .then((res: AxiosResponse<GetSharedApiResponse, any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });
