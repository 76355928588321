import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { Colors } from '../../config/colors';

export const useImageUploadStyles: (
  data?: { isUploaded: boolean; isProfileType: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'idBox' | 'secondaryText' | 'uploadedImg'> = createUseStyles({
  idBox: ({ isUploaded, isProfileType }) => ({
    border: isUploaded
      ? `2px solid ${Colors.linkPurple}`
      : isProfileType
      ? 'none'
      : `2px dotted ${Colors.linkPurple}`,
    borderRadius: isProfileType ? '99px' : '10px',
    width: isProfileType ? '192px' : '15rem',
    height: isProfileType ? '192px' : '9.3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  secondaryText: {
    color: Colors.secondaryText,
    margin: '9px 0 13px 0',
  },
  uploadedImg: ({ isProfileType }) => ({
    width: '97%',
    height: '97%',
    objectFit: 'contain',
    backgroundSize: 'cover',
    borderRadius: isProfileType ? '99px' : '10px',
  }),
});
