import React, { useState } from 'react';
import eyeSlashedIcon from '../../assets/images/icons/eye-slash.svg';
import eyeIcon from '../../assets/images/icons/eye.svg';
import { useOneLineTextInputStyles } from './styles';
import get from 'lodash/get';
import has from 'lodash/has';

interface OneLineTextInputProps {
  placeholder: string;
  type?: 'text' | 'password' | 'tel';
  name?: string;
  id?: string;
  formErrors?: any;
  onChange?: any;
  onBlur?: any;
  label?: any;
  defaultValue?: string;
  icon?: string;
  small?: boolean;
  maxLength?: number;
}

export const OneLineTextInput: React.FC<OneLineTextInputProps> =
  React.forwardRef<HTMLInputElement, OneLineTextInputProps>(
    (
      {
        placeholder,
        type = 'text',
        name = 'input',
        id,
        defaultValue,
        onChange = () => {},
        onBlur = () => {},
        formErrors,
        icon,
        small,
        maxLength,
      },
      ref
    ) => {
      const [showText, setShowText] = useState(false);
      const [formValue, setFormValue] = useState(defaultValue || '');
      const [isFocused, setIsFocused] = useState(false);
      const showPlaceholder = isFocused || formValue.length > 0;
      let errorMessage, isError;
      if (has(formErrors, name)) {
        errorMessage = get(formErrors, `${name}.message`);
        isError = true;
      }

      const classes = useOneLineTextInputStyles({
        showPlaceholder,
        isError,
        hasIcon: !!icon,
        small,
        theme: {},
      });
      return (
        <div className={classes.root}>
          <div className={classes.container}>
            {icon && (
              <div
                className={classes.leftIcon}
                onClick={() => {
                  showText ? setShowText(false) : setShowText(true);
                }}
              >
                <img width="17px" src={icon} alt={placeholder} />
              </div>
            )}
            <input
              id={id}
              name={name}
              placeholder={placeholder}
              type={showText ? 'text' : type}
              className={classes.textInputField}
              maxLength={maxLength}
              value={formValue}
              autoComplete="off"
              onChange={e => {
                setFormValue(e.target.value);
                onChange(e);
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={e => {
                setIsFocused(false);
                onBlur(e);
              }}
              ref={ref}
            />
            {type === 'password' && (
              <div
                className={classes.eyeButton}
                onClick={() => {
                  showText ? setShowText(false) : setShowText(true);
                }}
              >
                <img
                  width="20px"
                  src={showText ? eyeSlashedIcon : eyeIcon}
                  alt="show hide password"
                />
              </div>
            )}
            <label
              className={`${classes.label} ${
                showPlaceholder && classes.translate
              }`}
            >
              {placeholder}
            </label>
          </div>
          <span className={classes.errorMessage}>{errorMessage}</span>
        </div>
      );
    }
  );
