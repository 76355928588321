import { ThemedButton } from './ThemedButton/ThemedButton';
import { IconButton } from './IconButton/IconButton';
import { ContentBox } from './ContentBox/ContentBox';
import { OneLineTextInput } from './OneLineTextInput/OneLineTextInput';
import { RadioList } from './RadioList/RadioList';
import { Dropdown } from './Dropdown/Dropdown';
import healthDetailsIcon from '../assets/images/icons/IdentificationCard.svg';
import immunizationCard from '../assets/images/immunization.jpg';
import { Notification } from './Notification/Notification';
import { CheckboxList, GradientButton } from '.';
import { Colors } from '../config/colors';
import Icon from '../assets/images/icons/target_white.svg';
import Syringe from '../assets/images/icons/SyringePurple.svg';
import PresenseLogo from '../assets/images/Presense_Logo.svg';
import { createUseStyles } from 'react-jss';
import { NavigationTile } from './NavigationTile/NavigationTile';
import { QuickLinkTile } from './QuickLinkTile/QuickLinkTile';
import { HealthDetailCard } from './HealthDetailCard/HealthDetailCard';
import { MagnifyingGlass } from '../assets/images/icons';

const useDemoStyles = createUseStyles({
  buttonContainer: {
    width: '300px',
  },
});

function ComponentsDemo() {
  const buttonTitle: string = 'temp title';
  const classes = useDemoStyles();
  const buttonFunc = () => {
    console.log('button function called');
  };
  const settingsIcon =
    'https://p1.hiclipart.com/preview/342/983/769/material-design-skin-gray-setting-icon-png-clipart.jpg';
  const iconFunc = () => {
    console.log('icon function called');
  };
  const radioChoices = ['Option A', 'Option B', 'Option C'];
  const radioChangeFunc = () => {
    console.log('radio button chosen');
  };
  const dropdownOptions = [
    'BlueCross BlueShield',
    'United Healthcare',
    'Fingers Crossed',
  ];

  const contentToGoInBox = (
    <div>
      <button>a</button>
      <button>b</button>
      <button>c</button>
    </div>
  );

  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#EFF1F5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
      }}
    >
      <h3>Components Demo:</h3>
      <HealthDetailCard
        detail={{
          id: '0',
          title: 'Diphteria (DTaP)',
          subtitle: 'Dr. Paul Smith, MD',
          detailItems: [
            ['2/13/1985', '2/13/1985', '2/13/1985', '2/13/1985'],
            ['a', 'second', 'list'],
          ],
          imageLink: immunizationCard,
          sensitive: true,
        }}
        icon={Syringe}
        menuOptions={[
          { title: 'Edit', onClick: () => {} },
          { title: 'Delete', onClick: () => {} },
        ]}
      />
      <HealthDetailCard
        detail={{
          id: '1',
          title: 'Diphteria (DTaP) Simple',
          subtitle: 'Dr. Paul Smith, MD',
        }}
      />
      <CheckboxList
        optionsList={['pick', 'your', 'poison']}
        onChange={e => console.log('Checkbox List OnChange, ', e)}
        onSubmit={e => console.log('Checkbox List OnSubmit, ', e)}
        showOther
      />
      <ThemedButton
        title={buttonTitle}
        onClick={buttonFunc}
        isInverted={false}
      />
      <ThemedButton
        title={buttonTitle}
        onClick={buttonFunc}
        isInverted={true}
      />
      <br />
      <IconButton
        icon={settingsIcon}
        onClick={iconFunc}
        alt="settings button"
      />
      <br />
      <ContentBox title="Today's Appointment Queue">
        {contentToGoInBox}
      </ContentBox>
      <br />
      <OneLineTextInput placeholder={'Enter Password'} type={'password'} />
      <OneLineTextInput placeholder={'Enter Text'} type={'text'} />
      <OneLineTextInput
        type="text"
        placeholder="Search"
        icon={MagnifyingGlass}
      />
      <br />
      <RadioList
        title={'Options to choose from:'}
        optionsList={radioChoices}
        onChange={radioChangeFunc}
      />
      <br />
      <Dropdown
        placeholder={'Select an insurance provider...'}
        optionsList={dropdownOptions}
      />
      <br />
      <Notification
        icon={healthDetailsIcon}
        title="Add Your Health Details"
        subTitle="Add your personal health data & family history."
        buttonTitle="Add Health Details"
        onClick={() => console.log('notification clicked')}
      />
      <br />
      <div className={classes.buttonContainer}>
        <GradientButton
          title={'Gradient Button'}
          onClick={() => {}}
          type="submit"
          isDisabled={false}
          gradientStart={Colors.gradientLighterOrange}
          gradientEnd={Colors.gradientOrange}
          icon={Icon}
        />
      </div>
      <NavigationTile
        title="Data Sharing"
        description="See incoming data requests or request data from others"
        icon={healthDetailsIcon}
        notificationCount={0}
        path="/healthDetails"
        iconBg="red"
      />
      <QuickLinkTile
        title="Presence Health"
        icon={PresenseLogo}
        notificationCount={0}
        path="/healthDetails"
      />
    </div>
  );
}

export default ComponentsDemo;
