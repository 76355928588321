import { createUseStyles } from 'react-jss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ContentBox } from '../../../components/ContentBox/ContentBox';
import { RadioList } from '../../../components/RadioList/RadioList';
import { ThemedButton } from '../../../components/ThemedButton/ThemedButton';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const useAccountSelectStyles = createUseStyles(styles);

export const AccountSelect = () => {
  const { t } = useTranslation();
  const classes = useAccountSelectStyles();
  const history = useHistory();
  let { path } = useRouteMatch();

  const accountOptions = [t('personal'), t('business'), t('provider')];
  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('create_an_account')} slimTitle>
        <div className={classes.radioList}>
          <RadioList
            title={t('choose_account_type')}
            optionsList={accountOptions}
            onChange={() => console.log('account selected')}
            // TODO: change account type based on selection in the onClick
            isHorizontal={true}
          />
        </div>
        <ThemedButton
          title={t('continue')}
          onClick={() => history.push(path + '/idCapture')}
        />
      </ContentBox>
    </div>
  );
};
