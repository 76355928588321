import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import { FormSecondary, SubHeader1 } from '../../../components/TextComponents';
import { Colors } from '../../../config/colors';

const useCentipedeListStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `2px solid ${Colors.lightPurple}`,
    borderRadius: '6px',
    borderBottom: 'none',
    color: Colors.primaryText,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '20px 67px 23px 43px',
    borderBottom: `2px solid ${Colors.lightPurple}`,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  value: {
    color: Colors.secondaryText,
    textAlign: 'left',
  },
});

type props = {
  items: Array<{ title: string; value: string }>;
};

export const CentipedeList: React.FC<props> = ({ items }) => {
  const classes = useCentipedeListStyles();
  return (
    <div className={classes.container}>
      {items
        .filter(item => !!item?.value)
        .map(item => (
          <div className={classes.card}>
            <div className={classes.title}>
              <SubHeader1>{item.title}</SubHeader1>
            </div>
            <div className={classes.value}>
              <FormSecondary>{item?.value || t('not_specified')}</FormSecondary>
            </div>
          </div>
        ))}
    </div>
  );
};
