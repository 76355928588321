import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useDropdownStyles: (
  data?: { showPlaceholder: boolean; isError: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'container' | 'label' | 'translate' | 'root' | 'errorMessage'> =
  createUseStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: '7px 0',
      position: 'relative',
    },
    label: ({ isError }) => ({
      position: 'absolute',
      color: isError ? Colors.errorRed : Colors.primaryText,
      top: '19px',
      fontSize: '12px',
      left: '19px',
      opacity: '0',
      zIndex: '1',
      pointerEvents: 'none',
    }),
    translate: ({ isError }) => ({
      transition: 'all 150ms ease-out',
      transform: 'translateY(-16px)',
      color: isError ? Colors.errorRed : Colors.primaryText,
      opacity: '1',
      fontSize: '12px',
      left: '18',
      top: '16',
    }),
    errorMessage: ({ isError }) => ({
      fontSize: '12px',
      color: isError ? Colors.errorRed : Colors.primaryText,
    }),
  });

export const customSelectTheme = isError => theme => ({
  ...theme,
  controlHeight: 48,
  borderRadius: 5,
  colors: {
    ...theme.colors,
    neutral80: Colors.primaryText,
    neutral90: Colors.primaryText,
    neutral70: Colors.primaryText,
    primary: Colors.buttonPurple,
    primary50: Colors.lightPurple, // hovered item background not selected
    primary25: Colors.lightPurple, // hovered item background not selected
    neutral20: isError ? Colors.errorRed : Colors.textInputBorder, // Border
    neutral30: isError ? Colors.errorRed : Colors.buttonPurple, // Hover Border
    neutral40: Colors.buttonPurple, // Chevron Hover not selected
    neutral60: Colors.primaryText, // Chevron Selected
  },
});

export const customSelectStyles = (
  isError: boolean,
  showPlaceholder: boolean
) => ({
  placeholder: provided => ({
    ...provided,
    color: isError ? Colors.errorRed : Colors.primaryText,
  }),
  container: provided => ({
    ...provided,
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '18px',
  }),
  control: provided => ({
    ...provided,
    fontFamily: 'Inter',
    height: '48px',
    paddingLeft: '8px',
    backgroundColor: Colors.white,
  }),
  option: provided => ({
    ...provided,
  }),
  singleValue: provided => ({
    ...provided,
    color: isError ? Colors.errorRed : Colors.primaryText,
  }),
  valueContainer: provided => ({
    ...provided,
    marginBottom: showPlaceholder ? '-12px' : '0',
  }),
  menu: provided => ({
    ...provided,
    zIndex: '2',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    opacity: '0',
  }),
  indicatorContainer: provided => ({
    ...provided,
    '&:hover': {
      color: Colors.buttonPurple,
    },
  }),
});
