import { VecnaPatient } from '../../typings';
import {
  APP_CONSTANTS,
  getLocalStorageItem,
  LocalStorageKeys,
  setLocalStorageItem,
} from '../../config/constants';
import i18n from '../../translations';
import { DashboardSections } from './DashboardSections';
import { defaultFamilyHistory } from './family-history/defaultFamilyHistory';
import { defaultHealthConditions } from './health-conditions/HealthConditions';
import { defaultInsurances } from './health-insurance/defaultInsurances';
import { defaultMedicalEvents } from './medical-events/MedicalEvents';
import { defaultMedications } from './medications/Medications';
import { store as storeApp } from '../../app/store';
import { flattenUserData } from './about/utils';
import { profileDataStart } from './about/sampleProfileData';

export const calculateCompletion = state => {
  let completeSections = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const value in DashboardSections) {
    if (!!state[value]?.detailList) {
      completeSections++;
    }
  }
  const calc = Math.round((completeSections / 8) * 100);
  return calc;
};

export type DashboardUpdate<T> = {
  section: DashboardSections;
  detailItem: T;
};

export const stateToApiResponse = state => {
  const result: VecnaPatient = {
    id: state[DashboardSections.ABOUT_ME].data?.id,
    email: state[DashboardSections.ABOUT_ME].data?.email,
    fhirData: {
      ...state[DashboardSections.ABOUT_ME].data.fhirData,
    },
    healthData: {
      allergies: state[DashboardSections.ALLERGY].detailList,
      conditions: state[DashboardSections.HEALTH_CONDITIONS].detailList,
      medications: state[DashboardSections.MEDICATIONS].detailList,
      immunizations: state[DashboardSections.IMMUNIZATIONS].detailList,
      medicalEncounters: state[DashboardSections.MEDICAL_EVENTS].detailList,
    },
    familyHistory: state[DashboardSections.FAMILY_HISTORY].detailList, //ToDo: need to persist the dismissed state somehow.
    insuranceData: state[DashboardSections.INSURANCES].detailList,
    additionalData: {
      ...state[DashboardSections.ABOUT_ME].data.additionalData,
    },
    vaultData: state[DashboardSections.VAULT_DOCS].detailList,
  };

  return result;
};

export const flattenDependentList = data => {
  const { with: dependentList } = data;

  return dependentList
    ? dependentList.map(dep => {
        const photo = dep?.info?.fhirData?.photo[0]?.url;
        return {
          id: dep?.dependentId || '',
          fullName: `${dep?.info?.fhirData?.name[0].given[0]} ${dep?.info?.fhirData?.name[0].family}`,
          firstName: dep?.info?.fhirData?.name[0].given[0],
          photo,
        };
      })
    : [];
};

export const apiResponseToFlatUser = data => {
  const { fhirData, additionalData, id } = data.with;

  const { name, photo } = fhirData;
  const { preferredName } = additionalData;

  return {
    id: id,
    firstName: `${name?.[0]?.given[0]}`,
    fullName: `${name?.[0]?.given?.[0]} ${name?.[0]?.given
      .slice(1)
      .join(' ')} ${name?.[0]?.family}`,
    photo: photo[0]?.url,
    preferredName,
  };
};

export const apiResponseToState = data => {
  const {
    fhirData,
    healthData,
    insuranceData,
    additionalData,
    familyHistory,
    id,
    email,
    vaultData,
  } = data.with;
  return {
    [DashboardSections.ABOUT_ME]: {
      dismissed: false,
      data: {
        id,
        email,
        fhirData: fhirData,
        additionalData: additionalData,
      },
    },
    [DashboardSections.ALLERGY]: {
      ...(healthData?.allergies ? { detailList: healthData.allergies } : {}),
    },
    [DashboardSections.HEALTH_CONDITIONS]: {
      ...(healthData?.conditions ? { detailList: healthData.conditions } : {}),
    },
    [DashboardSections.MEDICAL_EVENTS]: {
      ...(healthData?.medicalEncounters
        ? { detailList: healthData.medicalEncounters }
        : {}),
    },
    [DashboardSections.MEDICATIONS]: {
      ...(healthData?.medications
        ? { detailList: healthData.medications }
        : {}),
    },
    [DashboardSections.IMMUNIZATIONS]: {
      ...(healthData?.immunizations
        ? { detailList: healthData.immunizations }
        : {}),
    },
    [DashboardSections.INSURANCES]: {
      ...(insuranceData ? { detailList: insuranceData } : {}),
    },

    [DashboardSections.FAMILY_HISTORY]: {
      ...(familyHistory
        ? { detailList: familyHistory }
        : { detailList: defaultFamilyHistory.detailList }),
    },
    [DashboardSections.VAULT_DOCS]: {
      ...(vaultData ? { detailList: vaultData } : { detailList: [] }),
    },
  };
};

export const familyHistoryNotifications = store => {
  const hasFamilyData = store?.detailList?.some(
    famHist => !!famHist?.fhirData?.condition
  );

  if (hasFamilyData) {
    return false;
  } else {
    return true;
  }
};

const aboutMeNotifications = ({ data }) => {
  const flatData = flattenUserData(data);
  const profileData = profileDataStart(flatData);
  const hasUnfinished = profileData.some(item => !item?.info);

  return hasUnfinished;
};

export const generateSidebarItems = (path, store) => {
  const isShareUser = !!storeApp.getState().session.shareUser;
  type DrawerInfo = {
    title: string;
    notificationCount: boolean;
    path: string;
  };
  if (!store) {
    return [];
  }

  const validateObject = object =>
    isShareUser
      ? typeof object === 'object' &&
        !!Object.keys(object).length &&
        !!object?.detailList?.length
      : true;

  const drawerInfo: DrawerInfo[] = [
    {
      title: i18n.t('about_me'),
      notificationCount:
        !isShareUser && aboutMeNotifications(store[DashboardSections.ABOUT_ME]),
      path: `${path}/about`,
    },
    validateObject(store[DashboardSections.INSURANCES]) && {
      title: i18n.t('health_insurance'),
      notificationCount:
        !isShareUser && !store[DashboardSections.INSURANCES].detailList,
      path: `${path}/healthInsurance`,
    },
    validateObject({}) && {
      title: i18n.t('providers'),
      notificationCount: false,
      path: `${path}/providers`,
    },
    validateObject(store[DashboardSections.IMMUNIZATIONS]) && {
      title: i18n.t('immunizations'),
      notificationCount:
        !isShareUser && !store[DashboardSections.IMMUNIZATIONS].detailList,
      path: `${path}/immunizations`,
    },
    validateObject(store[DashboardSections.MEDICATIONS]) && {
      title: i18n.t('medications'),
      notificationCount:
        !isShareUser && !store[DashboardSections.MEDICATIONS].detailList,
      path: `${path}/medications`,
    },
    validateObject(store[DashboardSections.ALLERGY]) && {
      title: i18n.t('allergies'),
      notificationCount:
        !isShareUser && !store[DashboardSections.ALLERGY].detailList,
      path: `${path}/allergies`,
    },
    validateObject(store[DashboardSections.HEALTH_CONDITIONS]) && {
      title: i18n.t('health_conditions'),
      notificationCount:
        !isShareUser && !store[DashboardSections.HEALTH_CONDITIONS].detailList,
      path: `${path}/healthConditions`,
    },
    validateObject(store[DashboardSections.MEDICAL_EVENTS]) && {
      title: i18n.t('hospitalizations'),
      notificationCount:
        !isShareUser && !store[DashboardSections.MEDICAL_EVENTS].detailList,
      path: `${path}/medicalEvents`,
    },
    validateObject(store[DashboardSections.FAMILY_HISTORY]) && {
      title: i18n.t('family_history'),
      notificationCount:
        !isShareUser &&
        familyHistoryNotifications(store[DashboardSections.FAMILY_HISTORY]),
      path: `${path}/familyHistory`,
    },
  ].filter(item => !!item);

  return drawerInfo;
};

export const defaultStateForTesting = {
  [DashboardSections.ABOUT_ME]: {
    dismissed: false,
    notificationCount: 0,
    data: {
      id: '1',
      email: 'janepeterson@gmail.com',
      fhirData: {
        resourceType: 'Patient',
        address: [
          {
            id: 'asdf-1',
            line: ['123 Main St'],
            city: 'Chicago',
            state: 'IL',
            postalCode: '60640',
          },
          {
            id: 'asdf-2',
            line: ['000 Test St'],
            city: 'Chicago',
            state: 'IL',
            postalCode: '60606',
          },
        ],
        birthDate: '1999-06-17',
        contact: [
          {
            name: {
              family: 'Peterson',
              given: ['Jhon'],
            },
            relationship: [],
            telecom: [{ value: '8342321234' }],
          },
          {
            name: {
              family: 'King',
              given: ['Jessica'],
            },
            relationship: [],
            telecom: [{ value: '2434342354' }],
          },
        ],
        gender: 'female',
        name: [
          {
            family: 'Peterson22',
            given: ['Jon', 'MiddleName1', 'MiddleName2'],
          },
        ],
        photo: [{ url: '' }],
        telecom: [{ value: '4055856004' }],
        maritalStatus: { text: '' },
      },
      additionalData: {
        ssn4: '0004',
        username: '@janepete1',
        preferredName: 'Jane',
        military: {
          status: { fhirData: { text: 'V' } },
          retirementDate: '2000-01-10',
          identifier: '',
        },
        physical: {
          height: NaN, // TODO: no input yet
          heightUnit: 'in', // TODO: no input yet
          eyeColor: { fhirData: { text: '' } }, // TODO: no input yet
          ethnicity: { fhirData: { text: 'W' } },
        },
        householdIncome: { fhirData: { text: '' } },
        stateIdPhoto: [
          {
            fhirData: { url: '' }, // Front
          },
          {
            fhirData: { url: '' }, // Back
          },
        ],
      },
    },
  },
  [DashboardSections.ALLERGY]: {
    dismissed: false,
    notificationCount: 0,
    detailList: [
      {
        fhirData: {
          id: '007',
          resourceType: 'Allergy',
          patient: {},
          code: {
            text: 'Shellfish',
          },
          reaction: [
            {
              manifestation: [{ text: 'mild' }],
            },
          ],
        },
        vecnaData: {
          sensitive: false,
          courseOfAction: 'Benadryl upon exposure',
        },
      },
    ],
  },
  [DashboardSections.HEALTH_CONDITIONS]: defaultHealthConditions,
  [DashboardSections.MEDICAL_EVENTS]: defaultMedicalEvents,
  [DashboardSections.MEDICATIONS]: defaultMedications,
  [DashboardSections.INSURANCES]: defaultInsurances,
  [DashboardSections.FAMILY_HISTORY]: defaultFamilyHistory,
  [DashboardSections.ONBOARDING]: {},
};

export const setTimeoutHandler = axios => {
  axios.interceptors.response.use(
    response => {
      //return response if good
      return response;
    },
    error => {
      //Handle refresh token if 403
      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
          .post(`${APP_CONSTANTS.apiBaseUrl}/users/me/refreshToken`, {
            refreshToken: getLocalStorageItem(LocalStorageKeys.REFRESH_TOKEN),
          })
          .then(res => {
            if (res.status === 200) {
              // 1) put token in LocalStorage
              const accessToken = res.data.with.access_token;
              const refreshToken = res.data.with.refresh_token;
              setLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);
              setLocalStorageItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken);

              // 2) Change Authorization header
              axios.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${accessToken}`;
              originalRequest.headers[
                'Authorization'
              ] = `Bearer ${accessToken}`;
              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
          });
      }
    }
  );
};
