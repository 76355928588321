import dayjs from 'dayjs';
import i18n from '../../../translations';
import { CodeableConcept, Encounter, VecnaPatient } from '../../../typings';

export type ShareDetailLine = {
  sensitive: boolean;
  label: string;
  id: string;
};

export type ShareDetail = {
  sectionTitle: string;
  expandable: boolean;
};

export const dateOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

// Getted from Mobile to generate the share logic
// IMMUNIZATIONS
interface ReducedImmunizations extends Immunization {
  identifier: string;
}

type StateImmunizationList = VecnaPatient['healthData']['immunizations'];
type StateImmunization = NonNullable<StateImmunizationList>[number];
type StateVaccineCode = StateImmunization['fhirData']['vaccineCode'];
interface Immunization {
  id: string;
  identifier: string; // Group identifier - same vaccine, different dates
  label: string;
  doctor: string;
  doses: string[];
  imageURI: string;
  imageType: string;
  fhirImmunization: StateVaccineCode;
  sensitive: boolean;
}

const immunizationsToForm = (
  immunizationsList: StateImmunizationList
): Immunization[] =>
  immunizationsList?.reduce(
    (previous: ReducedImmunizations[], { fhirData, photo, sensitive }) => {
      const identifier = fhirData?.identifier?.[0]?.value || '';
      const isoDate = fhirData?.occurrenceDateTime || '';
      const imageURI = photo?.url ?? '';

      const dateString = dayjs(isoDate).isValid()
        ? dayjs(isoDate).format('MM/DD/YYYY')
        : '';

      const immIndex = previous.findIndex(
        ({ identifier: id }) => id === identifier
      );

      // Same immunization but with another date
      if (immIndex > -1) {
        const updatedList = [...previous];
        if (dateString) {
          const updatedDates = [
            ...updatedList[immIndex].doses,
            dateString,
          ].sort();
          updatedList[immIndex].doses = updatedDates;
          updatedList[immIndex].imageURI =
            updatedList[immIndex].imageURI || imageURI;
        }
        return updatedList;
      }

      const { id = '', vaccineCode, performer } = fhirData || {};
      const label = vaccineCode?.text || '';
      const doctor = performer?.[0].actor?.display || '';

      const fhirImmunization: CodeableConcept =
        vaccineCode?.text && vaccineCode?.coding
          ? {
              text: vaccineCode.text,
              coding: vaccineCode.coding,
            }
          : {};

      return [
        ...previous,
        {
          id,
          label,
          doctor,
          doses: [dateString],
          imageURI,
          imageType: '',
          identifier,
          fhirImmunization,
          sensitive,
        },
      ];
    },
    []
  ) || [];

interface ShareInfoCardItems {
  label: string;
  id: string;
  sensitive: boolean;
}
type Constant<T extends string> = {
  value: T;
  label: string;
};
const defaultRelationships: Constant<DefaultRelationshipTypes>[] = [
  { label: 'Mother', value: 'Mother' },
  { label: 'Father', value: 'Father' },
  { label: 'Grandmother (Maternal)', value: 'Grandmother (Maternal)' },
  { label: 'Grandfather (Maternal)', value: 'Grandfather (Maternal)' },
  { label: 'Grandmother (Paternal)', value: 'Grandmother (Paternal)' },
  { label: 'Grandfather (Paternal)', value: 'Grandfather (Paternal)' },
  { label: 'Sibling', value: 'Sibling' },
];
type DefaultRelationshipTypes =
  | 'Mother'
  | 'Father'
  | 'Grandmother (Maternal)'
  | 'Grandfather (Maternal)'
  | 'Grandmother (Paternal)'
  | 'Grandfather (Paternal)'
  | 'Sibling';

type RelationshipTypes =
  | 'Child'
  | 'Cousin'
  | 'Aunt'
  | 'Uncle'
  | 'Half - Sibling(Maternal)'
  | 'Half - Sibling(Paternal)';

const relationships: Constant<RelationshipTypes>[] = [
  { label: 'Child', value: 'Child' },
  { label: 'Cousin', value: 'Cousin' },
  { label: 'Aunt', value: 'Aunt' },
  { label: 'Uncle', value: 'Uncle' },
  { label: 'Half - Sibling(Maternal)', value: 'Half - Sibling(Maternal)' },
  { label: 'Half - Sibling(Paternal)', value: 'Half - Sibling(Paternal)' },
];

export const compareCreatedDate = (a: string, b: string) => {
  const timeA = dayjs(a || '')
    .toDate()
    .getTime();
  const timeB = dayjs(b || '')
    .toDate()
    .getTime();
  return timeB - timeA;
};

export const getItems = ({
  healthData,
  insuranceData,
  familyHistory,
  vaultData,
}: VecnaPatient) => ({
  aboutMe: [],
  healthInsurance:
    insuranceData?.map(({ fhirData }) => ({
      label: fhirData?.payor?.[0]?.display || '',
      id: fhirData?.id || '',
      sensitive: false,
    })) || [],
  providers: [],
  immunizations:
    immunizationsToForm(healthData?.immunizations)?.map(
      ({ label, id, sensitive }) => ({
        label: label || '',
        id: id || '',
        sensitive,
      })
    ) || [],
  medications:
    healthData?.medications?.map(({ fhirData, sensitive }) => ({
      label: fhirData?.medicationCodeableConcept?.text || '',
      id: fhirData?.id || '',
      sensitive,
    })) || [],
  allergies:
    healthData?.allergies?.map(({ fhirData, sensitive }) => ({
      label: fhirData?.code?.text || '',
      id: fhirData?.id || '',
      sensitive,
    })) || [],
  conditions:
    healthData?.conditions?.map(({ fhirData, sensitive }) => ({
      label: fhirData?.code?.text || '',
      id: fhirData?.id || '',
      sensitive,
    })) || [],
  hospitalizations:
    healthData?.medicalEncounters?.map(({ fhirData, sensitive }) => ({
      label: (fhirData as Encounter)?.diagnosis?.[0]?.condition?.display || '',
      id: fhirData?.id || '',
      sensitive,
    })) || [],
  familyHistory:
    familyHistory?.reduce((previous, { fhirData, sensitive }) => {
      const { relationship, name = '' } = fhirData || {};
      const { text = '' } = relationship || {};

      const relationshipString =
        [...defaultRelationships, ...relationships].find(
          ({ value }) => value === text
        )?.label || i18n.t('other');

      if (name && relationshipString) {
        return [
          ...previous,
          {
            label: `${relationshipString} - ${name}`,
            id: fhirData?.id || '',
            sensitive,
          },
        ];
      }
      return previous;
    }, [] as ShareInfoCardItems[]) || [],
  vault:
    [...(vaultData || [])]
      ?.sort((item1, item2) =>
        compareCreatedDate(item1?.fhirData?.creation, item2?.fhirData?.creation)
      )
      ?.map(({ fhirData, sensitive }) => ({
        label: fhirData?.title || '',
        id: fhirData?.id || '',
        sensitive,
      })) || [],
});

export const isValidCode = str => /^[0-9a-z]{6}$/.test(str);
