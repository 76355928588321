import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { actionButtons } from '../../../config/sharedStyles';

export const useInsuranceStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '25px 0 0 70px',
      maxWidth: '70%',
    },
  },
  insurancesContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  actionButtons,
  noInfo: {
    color: Colors.checkboxGray,
    background: Colors.white,
    borderRadius: '20px',
    width: '48rem',
    padding: '60px 0 60px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '25px 0 42px',
  },
});

export const useInsuranceSummaryStyles = createUseStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '792px',
  },
  dotMenu: {
    position: 'relative',
    top: '-8px',
  },
  noImages: {
    color: Colors.secondaryText,
  },
  photosBox: {
    background: Colors.white,
    borderRadius: '20px',
    width: '733px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    margin: '41px 0 24px 0',
    textAlign: 'left',
    padding: '19px 35px 32px',
  },
  photosRow: {
    display: 'flex',
    marginTop: '14px',
    gap: '11px',
  },
  cardPhotoContainer: {
    width: '188px',
    height: '114px',
    borderRadius: '10px',
    color: Colors.secondaryText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryBox: {
    background: Colors.white,
    borderRadius: '20px',
    width: '767px',
    height: '450px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    textAlign: 'left',
    padding: '19px 0 35px 36px',
  },
  infoLabel: {
    color: Colors.secondaryText,
    marginTop: '35px',
  },
  infoData: {
    color: Colors.notificationTitles,
    marginTop: '11px',
  },
  uploadedImg: {
    width: '97%',
    height: '97%',
    objectFit: 'contain',
    backgroundSize: 'cover',
  },
  bottomButton: {
    width: '21.2rem',
    height: '3.2rem',
    marginTop: '32px',
  },
});

export const useInsuranceScanCardStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  scanCardImg: {
    marginTop: '37px',
  },
  scanInstructions: {
    textAlign: 'left',
    width: '700px',
    padding: '39px 100px 40px 80px',
  },
  paragragh: {
    margin: '26px 0 44px',
  },
  cardUploadContainer: {
    [DESKTOP_VIEW_BREAKPOINT]: {
      display: 'flex',
      gap: '65px',
    },
  },
  cardSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
  },
  reUploadContainer: {
    marginTop: '13px',
  },
  continueButton: {
    width: '21.2rem',
    height: '3.2rem',
    margin: '47px 0 29px',
  },
  noInsuranceButton: {
    width: '21.2rem',
    height: '3.2rem',
  },
});

export const useEditInsuranceStyles = createUseStyles({
  text: {
    textAlign: 'left',
  },
  photosText: {
    marginTop: '19px',
  },
  confirmInfoSubtitle: {
    margin: '12px 0 15px',
  },
  portalTitle: {
    marginTop: '40px',
  },
  portalSubtitle: {
    margin: '9px 0 14px',
  },
  bottomButton: {
    margin: '35px 0 20px',
  },
  saveButton: {
    margin: 'auto',
    width: '21.2rem',
    height: '3.2rem',
  },
  cardUploadContainer: {
    margin: '13px 0 35px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      display: 'flex',
      gap: '65px',
    },
  },
  cardSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
  },
  reUploadContainer: {
    marginTop: '13px',
  },
  editCard: {
    background: Colors.white,
    borderRadius: '20px',
    width: '767px',
    height: '450px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    textAlign: 'left',
    padding: '19px 0 35px 36px',
  },
});
