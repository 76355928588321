import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import postsReducer from '../features/posts/postsSlice';
import sessionReducer from '../features/login/loginSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import shareNotificationReducer from '../features/dashboard/share/shareNotificationSlice';
import { listenerMiddleware } from '../features/dashboard/dashboardMiddleware';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    posts: postsReducer,
    session: sessionReducer,
    notification: notificationReducer,
    dashboard: dashboardReducer,
    shareNotification: shareNotificationReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
