import { ENDPOINTS, getConfiguredAxios } from '../../../api/axios';
import { DateFormats, toDateString } from '../../../config/utilities';

export interface VerifyIdentityResponse {
  question: string;
  answersArr: {
    option: string;
    correct: boolean;
  }[];
}

const getFourRandom = (length: number): number[] => {
  let q1 = Math.floor(Math.random() * (length + 1));
  q1 = q1 === length ? q1 - 1 : q1; // {0, ... ,length-1}
  const q2 = (q1 + 1) % length;
  const q3 = (q2 + 1) % length;
  const q4 = (q3 + 1) % length;

  return [q1, q2, q3, q4];
};

const getFourAnswers = (
  question: VerifyIdentityResponse
): VerifyIdentityResponse => {
  if (question.answersArr?.length > 4) {
    const answers = [...question.answersArr];
    const correctIndex = answers.findIndex(({ correct }) => !!correct);
    if (correctIndex !== -1) {
      const res: VerifyIdentityResponse['answersArr'] = [];
      const correctAnswer = answers.splice(correctIndex, 1)[0];
      // Inserts three random wrong answers
      Array(3)
        .fill('')
        .forEach(() => {
          res.push(
            answers.splice(
              Math.floor(Math.random() * (answers.length - 0.01)),
              1
            )[0]
          );
        });
      // Inserts correct randomly
      res.splice(Math.floor(Math.random() * 3.99), 0, correctAnswer);
      return { ...question, answersArr: res };
    }
  }
  return question;
};

export const getQuestions = async (apiInfo: any) => {
  const axiosClient = getConfiguredAxios({ isPublic: true });
  //Build Request Body
  const {
    firstName,
    lastName,
    dateOfBirth,
    address,
    city,
    idNumber,
    state,
    zipCode,
    ssn,
  } = apiInfo;
  const requestBody = {
    PlatformRequest: {
      CustomerReference: '',
      Identity: {
        //Required according to data sheet
        FirstName: firstName,
        LastName: lastName,
        Street: address,
        ZipCode: zipCode,
        //Not Required
        DateOfBirth: toDateString(dateOfBirth, DateFormats.YYYYMMDD),
        DriverLicenseNumber: idNumber,
        DriverLicenseState: state,
        Ssn: ssn,
        City: city,
        State: state,
        SpouseFirstName: '',
        PhoneNumber: '',
        EmailAddress: '',
        MiddleName: '',
        Generation: '',
      },
    },
  };
  //Call to Get Questions and format for use
  const questionsFromApi = await axiosClient
    .post(ENDPOINTS.verificationQuestionsPost, requestBody)
    .then(response => {
      if (response?.data?.with) {
        return response?.data?.with;
      }
    })
    .catch(error => {
      //set error message
      console.error('Error with get questions call', error);
      throw error;
    });

  const indexes: number[] = getFourRandom(questionsFromApi?.length);

  const fourQuestions = indexes.map(questionIndex => {
    return {
      questionText: questionsFromApi[questionIndex].question,
      answerOptions: getFourAnswers(
        questionsFromApi[questionIndex]
      ).answersArr.map(opt => {
        return { label: opt.option, value: `${opt.correct}` };
      }),
    };
  });

  return fourQuestions;
};
