import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arrowLeft } from '../../../assets/images/icons';
import {
  ContentBox,
  ImageUpload,
  LinkComponent,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import {
  AboutMeTitle,
  CardDescriptorSmall,
  FormHeader,
  FormSecondary,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { DashboardSections } from '../DashboardSections';
import { updateFhirItem } from '../dashboardSlice';

import { CircularProgress } from '@mui/material';
import { uploadPublicImage, uploadVaultDoc } from '../../../api/vaultUtils';
import profilePicIcon from '../../../assets/profilePicIcon.svg';
import { useCreateHandleStyles } from './styles';
import {
  createDependentCall,
  createNewDependentUser,
  isUsernameTaken,
} from './utils';
import { createVaultDoc } from '../vault/utils';
import {
  setDependentData,
  selectCreateDependent,
} from '../../login/loginSlice';

export const CreateDependentHandle: React.FC = () => {
  const classes = useCreateHandleStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isUploaded, setIsUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const dispatch = useAppDispatch();
  const { dependentInfo, verifyInfo } = useAppSelector(selectCreateDependent);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const uploadDocument = async () => {
    const { documentData } = verifyInfo;
    const { firstName } = dependentInfo;
    if (!documentData) {
      return false;
    }
    try {
      const awsId = await uploadVaultDoc(documentData);
      //save result to storage
      const update = {
        detailItem: createVaultDoc({
          id: awsId.id,
          tags: ['Verification Document'],
          url: awsId.url,
          title: `${firstName}'s Star Verification Document`,
          contentType: 'application/pdf',
          sensitive: false,
          deathNote: false,
        }),
        section: DashboardSections.VAULT_DOCS,
      };
      dispatch(updateFhirItem(update));
      return update;
    } catch (error) {
      //Do something if upload fails
      console.log(`Upload Failed`, error);
    }
  };

  const onSubmit = async data => {
    const { firstName, lastName, dob, address, state, city, zip, gender } =
      dependentInfo;
    setIsLoading(true);
    const uploadResult = verifyInfo?.documentData
      ? await uploadDocument()
      : false;
    const newDependent = createNewDependentUser(
      {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dob,
        address: address,
        state: state,
        city: city,
        postalCode: zip,
        idType: '',
        gender: gender,
        ssn: verifyInfo?.ssn || '',
      },
      {
        email: '',
        username: data.username,
        firstName: firstName,
        lastName: lastName,
        password: '',
        confirmPassword: '',
      },
      profilePhoto,
      uploadResult && { ...uploadResult.detailItem }
    );

    const createDependentResult = await createDependentCall({
      username: data.username,
      id: newDependent.id,
      healthData: newDependent,
    });
    dispatch(
      setDependentData({
        createResult: createDependentResult,
      })
    );

    history.push(ROUTES.dependentSuccess);

    //Call Create Endpoint
  };

  const handleProfileImage = async ({ data, fileExtension }) => {
    const uploadedPhoto = await uploadPublicImage({
      fileExtension,
      imageData: data,
    });
    setProfilePhoto(uploadedPhoto.url);
  };

  const onClearFile = () => {
    setIsUploaded(false);
    setProfilePhoto('');
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.dashboardHome)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_data_constellation')}</AboutMeTitle>
        </div>
        <div className={classes.buttonsBox}>
          <div
            onClick={() => {
              history.push(ROUTES.dashboardHome);
            }}
          >
            <AboutMeTitle>{t('cancel')}</AboutMeTitle>
          </div>
        </div>
      </div>
      <div className={classes.pageTitle}>
        <FormHeader>{t('almost_done')}</FormHeader>
      </div>
      <div className={classes.contentBox}>
        <ContentBox title={''} dropshadow>
          <div className={classes.cardContent}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <form className={classes.form}>
                  <FormSecondary>{t('add_handle_and_photo')}</FormSecondary>
                  <div>
                    <OneLineTextInput
                      placeholder={t('at_username')}
                      type="text"
                      {...register('username', {
                        required: 'Please enter a username',
                        validate: async value => {
                          const taken = await isUsernameTaken(value);
                          if (taken) {
                            return 'This username is taken, please select another';
                          } else return true;
                        },
                      })} //TODO: Resolve type issue and use translations string
                      formErrors={errors}
                    />
                    <CardDescriptorSmall>
                      {t('username_explanation')}
                    </CardDescriptorSmall>
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <div className={classes.imgUploadContainer}>
                      <ImageUpload
                        imgType="photo"
                        iconSrc={profilePicIcon}
                        isUploaded={isUploaded}
                        setIsUploaded={setIsUploaded}
                        setImageLink={val => handleProfileImage(val)}
                        isProfileType
                      />
                      {isUploaded ? (
                        <div className={classes.reUploadContainer}>
                          <LinkComponent
                            label={t('upload_different_photo')}
                            onClick={onClearFile}
                          />
                        </div>
                      ) : (
                        <div className={classes.uploadLater}>
                          <FormSecondary>
                            {t('add_photo_another_time')}
                          </FormSecondary>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <div className={classes.continueButton}>
                  <ThemedButton
                    title={t('continue')}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </>
            )}
          </div>
        </ContentBox>
      </div>
    </div>
  );
};
