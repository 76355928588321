import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileImage } from '../../../../assets/images';
import { CarretDown, NotePencil, Trash } from '../../../../assets/images/icons';
import { ThreeDotMenu } from '../../../../components';
import { ConfirmDeleteModal } from '../../../../components/ConfirmDeleteModal/ConfirmModal';
import { sharedWithMeStyles } from '../styles';

export interface SharedWithMeProps {
  photoUri?: string;
  sharerName: string;
  expired?: string;
  id: string;
  hideThreeDotMenu?: boolean;
  onViewData?: () => void;
  onShareDetails: () => void;
  onCancelShare?: () => void;
  onEditShare?: () => void;
  onViewDataLoading?: boolean;
}

const SharedWithMe = ({
  id,
  expired,
  photoUri = '',
  sharerName,
  hideThreeDotMenu = false,
  onViewData,
  onShareDetails,
  onCancelShare,
  onEditShare,
  onViewDataLoading = false,
}: SharedWithMeProps) => {
  const classes = sharedWithMeStyles(!!expired);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <img
          src={(photoUri || '').startsWith('https') ? photoUri : ProfileImage}
          alt={`${id}-profile`}
          className={classes.imageProfile}
        />
        <div className={classes.profileName}>{sharerName}</div>
        <div className={classes.leftContainer}>
          <div className={classes.ongoingText}>
            {t(!!expired ? 'expired' : 'ongoing', {
              expired: !!expired ? expired.replaceAll('/', '-') : '',
            }).replaceAll('-', '/')}
          </div>
          {!hideThreeDotMenu && (
            <ThreeDotMenu
              options={[
                {
                  title: t('update_share_settings'),
                  onClick: onEditShare,
                  icon: NotePencil,
                },
                {
                  title: t('remove_share'),
                  onClick: () => setShowModal(true),
                  icon: Trash,
                },
              ]}
            />
          )}
        </div>
      </div>
      <div className={classes.bottomContainer} onClick={onShareDetails}>
        <div className={classes.sharedText}>{t('share_details')}</div>
        <img
          src={CarretDown}
          alt={`${id}-down-arrow`}
          className={classes.downArrow}
        />
      </div>
      {onViewData && (
        <div className={classes.linkContainer} onClick={onViewData}>
          {onViewDataLoading ? (
            <CircularProgress size={20} />
          ) : (
            t('view_data', { user: sharerName })
          )}
        </div>
      )}
      <ConfirmDeleteModal
        open={showModal}
        title={t('are_you_sure')}
        handleNo={() => {
          setShowModal(false);
        }}
        handleYes={() => {
          onCancelShare();
          setShowModal(false);
        }}
        message={t('cancel_share')}
      />
    </div>
  );
};

export default SharedWithMe;
