import { LinkComponent } from '..';
import { useAboutMeInfoStyles } from './styles';
import { FormSecondary } from '../TextComponents/FormSecondary';
import { AboutMeTitle } from '../TextComponents/AboutMeTitle';
import { useState } from 'react';
import redDot from '../../assets/redDot.svg';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

type FormProps = {
  classes?: any;
  formControls?: any;
  onClose: () => void;
};

interface AboutMeInfoProps {
  data: {
    title: string;
    info: any;
    canEdit?: boolean;
    Form: React.FC<FormProps>;
  };
  setIsInEditMode: (boolean) => void;
  isInEditMode: boolean;
  notSpecified?: boolean;
  onSubmit: any;
  isRetirementInfo?: boolean;
}

export const AboutMeInfo: React.FC<AboutMeInfoProps> = ({
  data,
  setIsInEditMode,
  isInEditMode,
  notSpecified,
  isRetirementInfo,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const classes = useAboutMeInfoStyles({ isInEditMode, isEditing, theme: {} });
  const { title, info, canEdit, Form } = data;

  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const formControls = {
    register,
    setValue,
    control,
    errors,
    handleSubmit,
    unregister,
  };

  return (
    <div className={classes.container}>
      <div className={classes.firstRow}>
        {notSpecified ? (
          <img className={classes.redDot} src={redDot} alt="redDot" />
        ) : (
          <div style={{ width: '25px' }} />
        )}
        <div className={classes.titleAndEdit}>
          <div className={classes.title}>
            <AboutMeTitle>{title}</AboutMeTitle>
          </div>
          {canEdit && (
            <div className={classes.canEdit}>
              <LinkComponent
                label={t(isEditing ? 'cancel' : 'edit')}
                onClick={() => {
                  setIsEditing(!isEditing);
                  setIsInEditMode(!isInEditMode);
                }}
                isDisabled={!(!isInEditMode || isEditing)}
              />
            </div>
          )}
        </div>
      </div>
      {isRetirementInfo ? (
        <>
          {(!canEdit || !isEditing) && (
            <div
              className={classes.info}
              style={{ textTransform: 'capitalize' }}
            >
              {info ? (
                <FormSecondary>
                  {info === '12/31/9999' ? t('not_retired') : info}
                </FormSecondary>
              ) : (
                <FormSecondary>{t('not_specified')}</FormSecondary>
              )}
            </div>
          )}
          {canEdit && isEditing && (
            <form>
              <Form
                classes={classes}
                formControls={formControls}
                onClose={() => {
                  setIsEditing(false);
                  setIsInEditMode(!isInEditMode);
                }}
              />
            </form>
          )}
        </>
      ) : (
        <>
          {(!canEdit || !isEditing) && (
            <div
              className={classes.info}
              style={{ textTransform: 'capitalize' }}
            >
              {info instanceof Array ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {info.map(infoBit => (
                    <FormSecondary>{infoBit}</FormSecondary>
                  ))}
                </div>
              ) : (
                <FormSecondary>
                  {info ? info : t('not_specified')}
                </FormSecondary>
              )}
            </div>
          )}
          {canEdit && isEditing && (
            <form>
              <Form
                classes={classes}
                formControls={formControls}
                onClose={() => {
                  setIsEditing(false);
                  setIsInEditMode(!isInEditMode);
                }}
              />
            </form>
          )}
        </>
      )}
      <div className={classes.line} />
    </div>
  );
};
