export enum DashboardSections {
  ABOUT_ME = 'ABOUT_ME',
  ALLERGY = 'ALLERGY',
  HEALTH_CONDITIONS = 'HEALTH_CONDITIONS',
  MEDICAL_EVENTS = 'MEDICAL_EVENTS',
  MEDICATIONS = 'MEDICATIONS',
  IMMUNIZATIONS = 'IMMUNIZATIONS',
  INSURANCES = 'INSURANCES',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  ONBOARDING = 'ONBOARDING',
  CREATE_DEPENDENT = 'CREATE_DEPENDENT',
  VAULT_DOCS = 'VAULT_DOCS',
  DEPENDENTS = 'DEPENDENTS',
  MAIN_USER = 'MAIN_USER',
}
