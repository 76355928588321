import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { flattenMedicalEvent } from '../../medical-events/utils';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const HospitalizationsShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.MEDICAL_EVENTS)
  );
  const flatList = detailList?.map(event => flattenMedicalEvent(event));

  const optionsList: ShareDetailLine[] = flatList?.map(item => {
    return {
      label: item?.eventName || '',
      sensitive: item?.sensitive,
      id: item?.id,
    };
  });
  return (
    <ShareSectionSelector
      title={t('hospitalizations')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
