import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { createUseStyles } from 'react-jss';

export const useCreateDependentStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      marginTop: '25px',
      width: '90%',
    },
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    display: 'flex',
    marginRight: '2px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: '700px',
  },
  titleAndIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  iconBackground: {
    background: Colors.progressYellow,
    borderRadius: '4px',
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    gap: '22px',
  },
  buttonContainer: {
    width: '348px',
    height: '58px',
  },
  contentBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    marginTop: '25px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  starImage: {
    width: '50%',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '10px',
  },
  continueButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  header: {
    margin: '30px 0 21px 0',
  },
});

export const useConfirmDependentStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '90%',
    },
  },
  pageTitle: {
    width: '100%',
    maxWidth: '700px',
    marginTop: '25px',
    textAlign: 'left',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    display: 'flex',
    marginRight: '2px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: '700px',
  },
  titleAndIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  iconBackground: {
    background: Colors.progressYellow,
    borderRadius: '4px',
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    gap: '22px',
  },
  buttonContainer: {
    width: '348px',
    height: '58px',
  },
  contentBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    marginTop: '14px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  starImage: {
    width: '50%',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '10px',
  },
  continueButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  header: {
    margin: '30px 0 21px 0',
  },
  form: {
    width: '100%',
  },
  inputPair: {
    display: 'flex',
    gap: '20px',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  relationshipSection: {
    width: '51%',
  },
  radioQuestions: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
});

export const useVerifyDependentStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '90%',
    },
  },
  pageTitle: {
    width: '100%',
    maxWidth: '700px',
    marginTop: '25px',
    textAlign: 'left',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    display: 'flex',
    marginRight: '2px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: '700px',
  },
  titleAndIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  iconBackground: {
    background: Colors.progressYellow,
    borderRadius: '4px',
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    gap: '22px',
  },
  buttonContainer: {
    width: '348px',
    height: '58px',
  },
  contentBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    marginTop: '14px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  starImage: {
    width: '50%',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '10px',
  },
  continueButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: '30px',
    gap: '16px',
  },
  header: {
    margin: '30px 0 21px 0',
  },
  form: {
    width: '100%',
  },
  inputPair: {
    display: 'flex',
    gap: '20px',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  relationshipSection: {
    width: '51%',
  },
  radioQuestions: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  uploadDocButton: {
    marginTop: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadedDocDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '40px',
    marginTop: '30px',
    gap: '10px',
  },
  dropzone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  last4Container: {
    paddingLeft: '20%',
    paddingRight: '20%',
  },
});

export const useCreateHandleStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '90%',
    },
  },
  pageTitle: {
    width: '100%',
    maxWidth: '700px',
    marginTop: '25px',
    textAlign: 'left',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    display: 'flex',
    marginRight: '2px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: '700px',
  },
  titleAndIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  iconBackground: {
    background: Colors.progressYellow,
    borderRadius: '4px',
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    gap: '22px',
  },
  buttonContainer: {
    width: '348px',
    height: '58px',
  },
  contentBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    marginTop: '14px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  starImage: {
    width: '50%',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '10px',
  },
  continueButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: '10px',
    gap: '16px',
  },
  header: {
    margin: '30px 0 21px 0',
  },
  form: {
    width: '100%',
    textAlign: 'left',
  },
  inputPair: {
    display: 'flex',
    gap: '20px',
    width: '100%',
  },
  inputContainer: {
    width: '100%',
  },
  relationshipSection: {
    width: '51%',
  },
  radioQuestions: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  uploadDocButton: {
    marginTop: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadedDocDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '40px',
    marginTop: '30px',
    gap: '10px',
  },
  dropzone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  last4Container: {
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  reUploadContainer: {
    marginTop: '12px',
  },
  imgUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0 42px',
  },
  uploadLater: {
    color: Colors.accent10,
    marginTop: '19px',
  },
});

export const useCreateSuccessStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '90%',
    },
  },

  contentBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    marginTop: '14px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  continueButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: '10px',
    gap: '16px',
  },

  shareArtwork: {
    width: '100%',
    marginBottom: '30px',
  },
  successText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginBottom: '30px',
  },
});
