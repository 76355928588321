import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { getMyShared, SharedObject } from '../../api/share';
import { useAppSelector, useRequest } from '../../app/hooks';
import { RootState } from '../../app/store';
import { arrowLeft, VaultIcon } from '../../assets/images/icons';
import Calendar from '../../assets/images/icons/CalendarWhite.svg';
import Currency from '../../assets/images/icons/CurrencyCircleDollar.svg';
import IdCard from '../../assets/images/icons/IdentificationCard.svg';
import Messaging from '../../assets/images/icons/Messaging.svg';
import UploadSimple from '../../assets/images/icons/UploadSimpleWhite.svg';
import PresenseLogo from '../../assets/images/Presense_Logo.svg';
import {
  GradientButton,
  NavigationTile,
  PlusButton,
  QuickLinkTile,
  UserProfileSwitcher,
} from '../../components';
import {
  AboutMeTitle,
  NavSubheader2,
  PageHeader,
} from '../../components/TextComponents';
import { Colors } from '../../config/colors';
import { ROUTES } from '../../config/constants';
import Route from '../../routes/route';
import i18n from '../../translations';
import { selectCurrentUser, setShareUser } from '../login/loginSlice';
import { flattenUserData } from './about/utils';
import { Dashboard } from './Dashboard';
import { DashboardSections } from './DashboardSections';
import { selectSection } from './dashboardSlice';
import { CreateDependent } from './dependents/CreateDependent';
import { Share } from './share';
import { setNotificationNumber } from './share/shareNotificationSlice';
import { redesignStyles } from './styles';
import { Vault } from './vault/Vault';

const useDashboardStyles = createUseStyles(redesignStyles);

type DashboardProps = {
  user: string; //Eventually we'll have a user object
};

type NavigationTiles = {
  title: string;
  description: string;
  disabled?: boolean;
  icon: string;
  iconBg?: string;
  notificationCount?: number;
  path: string;
};

const navigationTiles: (
  path: string,
  isShareUser: boolean
) => NavigationTiles[] = (path, isShareUser) => [
  {
    title: i18n.t('personal_info'),
    description: i18n.t('personal_info_description'),
    icon: IdCard,
    iconBg: Colors.accent5Purple,
    path: `${path}/personalInfo`,
  },
  !isShareUser && {
    title: i18n.t('data_sharing'),
    description: i18n.t('data_sharing_description'),
    icon: UploadSimple,
    iconBg: Colors.gradientLighterOrange,
    path: ROUTES.share,
  },
  !isShareUser && {
    title: i18n.t('messaging'),
    description: i18n.t('coming_soon'),
    disabled: true,
    icon: Messaging,
    iconBg: '',
    path: '/healthDetails',
  },
  {
    title: i18n.t('vault'),
    description: i18n.t('vault_description'),
    icon: VaultIcon,
    iconBg: Colors.gradientLighterYellow,
    path: `${path}/vault/docs`,
  },
  {
    title: i18n.t('payments'),
    description: i18n.t('coming_soon'),
    disabled: true,
    icon: Currency,
    path: '/healthDetails',
  },
  {
    title: i18n.t('appointments'),
    description: i18n.t('coming_soon'),
    disabled: true,
    icon: Calendar,
    path: '/healthDetails',
  },
];

const gradientButtons = [
  {
    onClick: () => {},
    // TODO: add function to onClick
    gradientStart: Colors.gradientLighterOrange,
    gradientEnd: Colors.gradientOrange,
    title: i18n.t('new_data_share'),
    icon: UploadSimple,
  },
  {
    onClick: () => {},
    // TODO: add function to onClick
    gradientStart: Colors.disabledGrey,
    gradientEnd: Colors.disabledGrey,
    title: i18n.t('new_payment'),
    icon: Currency,
  },
  {
    onClick: () => {},
    // TODO: add function to onClick
    gradientStart: Colors.gradientLighterYellow,
    gradientEnd: Colors.gradientYellow,
    title: i18n.t('upload_a_document'),
    icon: Currency,
  },
];

export const DashboardRedesign: React.FC<DashboardProps> = () => {
  const classes = useDashboardStyles();
  const { t } = useTranslation();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shareNotificationNumber, isShareUser } = useSelector(
    (store: RootState) => ({
      shareNotificationNumber: !!store.session.shareUser
        ? 0
        : store.shareNotification.notificationNumber,
      isShareUser: !!store.session.shareUser,
    })
  );
  const { isDependent } = useAppSelector(selectCurrentUser);
  const data =
    useAppSelector(selectSection(DashboardSections.ABOUT_ME))?.data || {};
  const flatData = flattenUserData(data, isShareUser);
  const user = flatData?.preferredName || flatData?.fullName?.first || 'Jane';

  const { request: getMySharedRequest, data: getMySharedData } =
    useRequest(getMyShared);

  useEffect(() => {
    getMySharedRequest();
  }, []);

  const getNotifications = (list: SharedObject[]) =>
    list.filter(
      ({ accepted, expires }) =>
        !dayjs(expires).isBefore(dayjs()) && accepted === null
    );

  useEffect(() => {
    const { received } = getMySharedData?.with || {
      received: [],
    };
    dispatch(setNotificationNumber(getNotifications(received).length));
  }, [getMySharedData]);

  return (
    <div className={classes.dashboard}>
      <div className={classes.header}>
        <UserProfileSwitcher notifications={shareNotificationNumber} />
      </div>
      <div className={classes.content}>
        <Switch>
          <Route
            path={path}
            exact
            isPrivate={false}
            component={() => (
              <div className={classes.actionContent}>
                {!isShareUser && !isDependent && (
                  <div style={{ marginLeft: '7.3%', marginRight: '4.3%' }}>
                    <div className={classes.welcomeMessage}>
                      <NavSubheader2>
                        {t('welcome_back_name', { name: user })}
                      </NavSubheader2>
                    </div>
                    <div className={classes.actions}>
                      {gradientButtons.map(
                        ({
                          onClick,
                          gradientStart,
                          gradientEnd,
                          title,
                          icon,
                        }) => (
                          <div key={title} className="buttonContainer">
                            <GradientButton
                              key={title}
                              onClick={onClick}
                              gradientStart={gradientStart}
                              gradientEnd={gradientEnd}
                              title={title}
                              icon={icon}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {isShareUser && (
                  <>
                    <div style={{ marginLeft: '7.3%', marginRight: '4.3%' }}>
                      <div
                        className={classes.backToAction}
                        onClick={() => {
                          dispatch(setShareUser(null));
                          history.push(ROUTES.share);
                        }}
                      >
                        <div className={classes.arrowContainer}>
                          <img src={arrowLeft} alt="arrowLeft" />
                        </div>
                        <AboutMeTitle>{t('back_to_my_profile')}</AboutMeTitle>
                      </div>
                    </div>
                    <div className={classes.youAreViewingContainer}>
                      {t('you_are_viewing_user', { user })}
                    </div>
                  </>
                )}
                <div style={{ marginLeft: '7.3%', marginRight: '4.3%' }}>
                  <div className={classes.actionCenter}>
                    {!isShareUser && (
                      <PageHeader>{t('action_center')}</PageHeader>
                    )}
                    <div className={classes.actionTiles}>
                      {navigationTiles(path, isShareUser)
                        .filter(item => !!item)
                        .map(
                          ({
                            title,
                            description,
                            disabled = false,
                            icon,
                            iconBg = '',
                            notificationCount = 0,
                            path,
                          }) => (
                            <NavigationTile
                              key={title}
                              title={title}
                              description={description}
                              disabled={disabled}
                              icon={icon}
                              iconBg={iconBg}
                              notificationCount={
                                title === i18n.t('data_sharing')
                                  ? shareNotificationNumber
                                  : notificationCount
                              }
                              path={path}
                            />
                          )
                        )}
                    </div>
                  </div>
                </div>
                {!isShareUser && (
                  <div style={{ marginLeft: '7.3%', marginRight: '4.3%' }}>
                    <div className={classes.actionCenter}>
                      <PageHeader>{t('quick_links')}</PageHeader>
                      <div className={classes.quickLinkContainer}>
                        <QuickLinkTile
                          title={t('presense_health')}
                          icon={PresenseLogo}
                          notificationCount={0}
                          path="/healthDetails"
                        />
                        <PlusButton size={64} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
          <Route
            path={`${path}/personalInfo`}
            isPrivate={false}
            component={Dashboard}
          />
          <Route path={`${path}/vault`} isPrivate={false} component={Vault} />
          <Route path={`${path}/share`} isPrivate={false} component={Share} />
          <Route
            path={ROUTES.createDependent}
            isPrivate={false}
            component={CreateDependent}
          />
        </Switch>
      </div>
    </div>
  );
};
