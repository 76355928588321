import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  infoHeader: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '0.015em',
  },
});

export const InfoHeader: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.infoHeader}>{children}</div>;
};
