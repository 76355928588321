import { Colors } from '../../config/colors';

export const HorizontalBreak = () => {
  return (
    <div
      style={{
        height: '0.5px',
        borderTop: '.5px solid',
        borderColor: Colors.horizontalBreakGray,
        margin: '10px 0',
      }}
    />
  );
};
