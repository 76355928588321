import { getUUID } from '../../../config/utilities';
import { DashboardData } from '../dashboardSlice';
import i18n from '../../../translations';

export const defaultFamilyHistory: DashboardData = {
  dismissed: false,
  notificationCount: 0,
  detailList: [
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('mother') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('father') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('grandmother_maternal') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('grandfather_maternal') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('grandmother_paternal') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('grandfather_paternal') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('sibling') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('child') },
      },
    },
    {
      fhirData: {
        id: getUUID(),
        resourceType: 'FamilyMemberHistory',
        patient: {},
        status: 'partial',
        relationship: { text: i18n.t('other') },
      },
    },
  ],
};
