import { useImageUploadStyles } from './styles';
import { SectionSecondary } from '../TextComponents/SectionSecondary';
import { FieldSubtitle } from '../TextComponents/FieldSubtitle';
import Dropzone from 'react-dropzone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecureImageLoader } from '../SecureImageLoader/SecureImageLoader';
import { getFileExtension } from '../../api/vaultUtils';
import { UserCirclePlus } from '../../assets/images/icons';
import { ThemedButton } from '../ThemedButton/ThemedButton';

interface ImageUploadProps {
  imgType: string;
  iconSrc: string;
  isUploaded?: boolean;
  setIsUploaded?: (boolean) => void;
  isProfileType?: boolean;
  imageLink?: string;
  setImageLink?: (string) => void;
  isSecure?: boolean;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  imgType,
  iconSrc,
  isUploaded,
  setIsUploaded,
  isProfileType,
  imageLink = '',
  setImageLink,
  isSecure,
}) => {
  const { t } = useTranslation();
  const [idImage, setIdImage] = useState(imageLink);
  const classes = useImageUploadStyles({
    isUploaded,
    isProfileType,
    theme: {},
  });

  useEffect(() => {
    setIdImage(imageLink);
  }, [imageLink]);

  return (
    <>
      <Dropzone
        accept={'image/jpeg, image/png'}
        onDrop={acceptedFiles => {
          acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
              const dataURL = reader.result as string;
              const fileExtension = getFileExtension(file?.type);
              if (!isSecure) {
                localStorage.setItem(`${imgType}Img`, dataURL);
                setIdImage(reader.result as string);
                setIsUploaded(true);
                if (isProfileType) {
                  setImageLink({ data: dataURL, fileExtension: fileExtension });
                } else {
                  setImageLink(dataURL);
                }
              } else {
                setIsUploaded(true);
                setImageLink({ data: dataURL, fileExtension: fileExtension });
              }
            };
            reader.readAsDataURL(file);
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={classes.idBox}>
                {isUploaded ? (
                  isSecure ? (
                    <SecureImageLoader
                      imageId={idImage}
                      className={classes.uploadedImg}
                    />
                  ) : (
                    <img
                      className={classes.uploadedImg}
                      src={idImage}
                      alt={imgType}
                    />
                  )
                ) : isProfileType ? (
                  <>
                    <img src={UserCirclePlus} alt={imgType} height="192" />
                  </>
                ) : (
                  <>
                    <img width="30px" src={iconSrc} alt={imgType} />
                    <div className={classes.secondaryText}>
                      <SectionSecondary>Upload {imgType}</SectionSecondary>
                    </div>
                    <FieldSubtitle>
                      {t('image_upload_description')}
                    </FieldSubtitle>
                  </>
                )}
              </div>
              {isProfileType && !isUploaded && (
                <>
                  <ThemedButton isInverted title="Upload A Photo" />
                </>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </>
  );
};
