import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  h1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '100px',
    lineHeight: '121px',
    letterSpacing: '0.015em',
  },
});
export const H1Header: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.h1}>{children}</div>;
};
