import { createUseStyles } from 'react-jss';
import { ThemedButton } from '../../../components';
import { ContentBox } from '../../../components/ContentBox/ContentBox';
import { ImageUpload } from '../../../components/ImageUpload/ImageUpload';
import { LinkComponent } from '../../../components/LinkComponent/LinkComponent';
import { styles } from './styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import profilePicIcon from '../../../assets/profilePicIcon.svg';
import { useTranslation } from 'react-i18next';

const useProfilePhotoStyles = createUseStyles(styles);

export const ProfilePhoto = () => {
  const { t } = useTranslation();
  const classes = useProfilePhotoStyles();
  const history = useHistory();
  const [profilePicUploaded, setProfilePicUploaded] = useState(false);

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('account_checklist_step5')} slimTitle>
        <div className={classes.imgUploadContainer}>
          <ImageUpload
            imgType="photo"
            iconSrc={profilePicIcon}
            isUploaded={profilePicUploaded}
            setIsUploaded={setProfilePicUploaded}
            isProfileType
          />
          {profilePicUploaded && (
            <div className={classes.reUploadContainer}>
              <LinkComponent
                label={t('upload_different_photo')}
                onClick={() => setProfilePicUploaded(false)}
              />
            </div>
          )}
        </div>
        <div className={classes.centeredContent}>
          <div className={classes.bottomButton}>
            <ThemedButton
              title={t('continue')}
              onClick={() => history.push('/dashboard')}
              isDisabled={!profilePicUploaded}
            />
          </div>
        </div>
        <div className={classes.linkContainer}>
          <LinkComponent
            label={t('skip_for_now')}
            onClick={() => history.push('/dashboard')}
          />
        </div>
      </ContentBox>
    </div>
  );
};
