import { SingleImmunization } from '../../../typings/vecna_types/vecnaPatient';
import { DosageDate, HealthDetail } from '../../../config/SharedTypes';
import {
  DateFormats,
  getUUID,
  toDateString,
  toIsoString,
} from '../../../config/utilities';
import { EditImmuizationForm } from './EditImmunization';

export const formDataToDosage = data => {
  const result = Object.keys(data)
    .filter(key => key.includes('doseDate') && data[key].length)
    .map(key => {
      return {
        order: Number(key[0]),
        date: data[key],
      };
    });
  return result;
};

export const addFormToImmunizations = data => {
  //Take an array of new immunization cards and convert to data model
  const newImmunes = data.flatMap(flatImmune => {
    const immueGroupIdentifier = getUUID();
    const hasImageLink = !!flatImmune?.imageLink?.length;

    return flatImmune.doseDates.map(date => {
      const result: SingleImmunization = {
        fhirData: {
          id: getUUID(),
          resourceType: 'Immunization',
          patient: {},
          status: 'completed',
          vaccineCode: flatImmune.code,
          performer: [{ actor: { display: flatImmune.subtitle } }],
          occurrenceDateTime: toIsoString(date.date, DateFormats.MMYYYY),
          identifier: [{ value: immueGroupIdentifier }],
        },
        sensitive: false,
        ...(hasImageLink && {
          photo: {
            url: flatImmune.imageLink,
          },
        }),
        title: flatImmune.title,
      };
      return result;
    });
  });
  return newImmunes;
};

export const immunizationToDetail = immunization => {
  const firstDate = toDateString(
    immunization?.fhirData?.occurrenceDateTime,
    DateFormats.MMYYYY
  );
  const detailItems = [[firstDate]];
  if (immunization.detailItems?.length > 0) {
    detailItems.push(...immunization.detailItems);
  }
  if (immunization.additionalDates?.length) {
    immunization.additionalDates.forEach(doseObj => {
      const formattedDate = toDateString(doseObj, DateFormats.MMYYYY);
      if (Array.isArray(detailItems[0])) {
        detailItems[0]?.push(formattedDate);
      } else {
        detailItems.push([formattedDate]);
      }
    });
  }
  let vaxTitle = immunization?.fhirData?.vaccineCode?.text;
  if (immunization?.fhirData?.vaccineCode?.text === 'Other...')
    vaxTitle = immunization?.title;
  return {
    ...immunization,
    title: vaxTitle,
    subtitle: immunization?.fhirData?.performer?.[0]?.actor?.display,
    imageLink: immunization?.photo?.url,
    detailItems,
  };
};

export const editFormToImmunizations = (
  imm: EditImmuizationForm
): SingleImmunization => {
  const doseDates = formDataToDosage(imm);
  const code = JSON.parse(imm.codeString);

  const immunization = {
    id: getUUID(),
    title: imm[`title`],
    doseDates,
    subtitle: imm[`subtitle`],
    imageLink: imm[`imageLink`],
    code,
  };
  const res = addFormToImmunizations([immunization]);
  return res;
};

export const flattenImmunizationList = (imnArr: any[]) => {
  // Reduce the list using the identifier, group the dates
  //Figure out how editing single dates is possible, probably easier to delete all related items and regenerate the list
  //This probably warrants it's own reducer method to make sure we do it right
  if (!imnArr) {
    return [];
  }
  const result = imnArr.reduce((previous, current) => {
    const currentIdentifier = current?.fhirData?.identifier?.[0].value;
    if (!currentIdentifier) {
      return [...previous, current];
    }
    const prevIndex = previous.findIndex(
      el => el?.fhirData?.identifier?.[0]?.value === currentIdentifier
    );
    if (prevIndex > -1) {
      //This Coding already exists in the reduced list
      const previousAdditionalDates =
        previous[prevIndex]?.additionalDates || [];
      previous[prevIndex] = {
        ...previous[prevIndex],
        additionalDates: [
          ...previousAdditionalDates,
          current?.fhirData?.occurrenceDateTime,
        ],
      };
      return previous;
    } else {
      //This coding does not exist in the reduced list
      return [...previous, { ...current, additionalDates: [] }];
    }
  }, []);

  return result;

  // with the reduced list, generate cards with the appropriate doseDates Array
};

const getNumberDoseDates = (index, data) => {
  const keys = Object.keys(data);
  const indexString = index.toString();
  const numberOfDates = keys.reduce((previous, current) => {
    if (current[0] === indexString && current.includes('doseDate')) {
      return previous + 1;
    } else {
      return previous;
    }
  }, 0);
  return numberOfDates;
};

export const formDataToImmunizations = data => {
  let highestIndex = 0;
  for (let i = 0; i < Object.keys(data).length; i++) {
    const key = Object.keys(data)[i];
    const firstIndex = Number(key[0]);
    if (highestIndex < firstIndex) highestIndex = firstIndex;
  }

  const immunesArr: HealthDetail[] = [];
  for (let i = 0; i <= highestIndex; i++) {
    const doseDates = [];
    const numberOfDates = getNumberDoseDates(i, data);
    const code = JSON.parse(data[`${i}-codeString`]);
    for (let j = 0; j < numberOfDates; j++) {
      const dosage: DosageDate = {
        order: j,
        date: data[`${i}-${j}-doseDate`],
      };
      if (dosage.date.length > 0) {
        doseDates.push(dosage);
      }
    }
    const immunization = {
      id: getUUID(),
      title: data[`${i}-title`],
      doseDates,
      subtitle: data[`${i}-subtitle`],
      imageLink: data[`${i}-imageLink`],
      code,
    };
    immunesArr.push(immunization);
  }
  const dataModel = addFormToImmunizations(immunesArr);
  return dataModel;
};
