import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../config/colors';

export const useContentBoxStyles: (
  data?: {
    dropshadow: boolean;
    collapsed: boolean;
    largeTitle: boolean;
    theme?: Jss.Theme;
  } & {}
) => Classes<any> = createUseStyles({
  container: ({ dropshadow, largeTitle }) => ({
    display: 'flex',
    flexFlow: 'column',
    alignContent: 'flex-start',
    width: '100%',
    padding: largeTitle ? '30px 32px 36px 39px' : '17px 18px 24px 18px',
    borderRadius: '10px',
    boxSizing: 'border-box',
    background: Colors.white,
    boxShadow: dropshadow ? `0px 4px 22px 5px ${Colors.shadow2}` : 'none',
  }),
  slimContainer: {
    padding: '17px 29px 24px 29px',
  },
  title: ({ largeTitle }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: Colors.contentTitleBlue,
    fontWeight: '800',
    fontSize: largeTitle ? '24px' : '1.3rem',
    lineHeight: largeTitle ? '29px' : 'unset',
    textAlign: 'left',
    marginBottom: '14px',
  }),
  slimTitle: {
    color: Colors.contentTitleBlue,
    fontWeight: '600',
    fontSize: '24px',
    textAlign: 'left',
    marginBottom: '10px',
  },
  content: ({ collapsed }) => ({
    backgroundColor: Colors.white,
    clear: 'left',
    display: 'flex',
    flexDirection: 'column',
    height: collapsed ? '0px' : 'unset',
    width: collapsed ? '0px' : 'unset',
    overflow: collapsed ? 'hidden' : 'unset',
  }),
});

export const useIconStyles = createUseStyles({
  iconButton: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    border: `1px solid ${Colors.sidebarBackground}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${Colors.iconHoverBorder}`,
    },
    '&:active': {
      backgroundColor: Colors.lightgray,
    },
    cursor: 'pointer',
  },
  img: {
    width: '90%',
    height: '70%',
  },
});

export const useCheckboxStyles = createUseStyles({
  label: {
    fontSize: '16px',
    fontWeight: '400',
  },
  container: {
    display: 'flex',
    margin: '10px 0',
    position: 'relative',
    paddingLeft: '25px',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    color: Colors.primaryText,
    '& input': {
      position: 'absolute',
      opacity: '0',
      cursor: 'pointer',
      height: '0',
      width: '0',
      '&:checked': {
        '& ~ $checkmark': {
          /* When the checkbox is checked, add a blue background */
          backgroundColor: '#5C74EC',
          '&:after': {
            display: 'block',
          },
        },
      },
    },
    '& $checkmark': {
      '&:after': {
        top: '2px',
        left: '6px',
        width: '5px',
        height: '9px',
        border: 'solid white',
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(45deg)',
      },
    },

    /* On mouse-over, add a grey background color */
    '&:hover': {
      '& input': {
        '& ~ $checkmark': {
          backgroundColor: '#ccc',
        },
      },
    },
  },
  checkmark: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    borderRadius: '4px',
    backgroundColor: '#C4C4C4',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
});
export const useManagedCheckboxStyles = createUseStyles({
  label: {
    fontSize: '16px',
    fontWeight: '400',
  },
  container: {
    display: 'flex',
    pointerEvents: 'none',
    margin: 'none',
    position: 'relative',
    paddingLeft: '0',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    color: Colors.primaryText,
    '& input': {
      position: 'absolute',
      opacity: '0',
      cursor: 'pointer',
      height: '0',
      width: '0',
      '&:checked': {
        '& ~ $checkmark': {
          /* When the checkbox is checked, add a blue background */
          backgroundColor: Colors.buttonPurple,
          '&:after': {
            display: 'block',
          },
        },
      },
    },
    '& $checkmark': {
      '&:after': {
        top: '2px',
        left: '6px',
        width: '5px',
        height: '9px',
        border: `solid ${Colors.white}`,
        borderWidth: '0 3px 3px 0',
        transform: 'rotate(45deg)',
      },
    },

    /* On mouse-over, add a grey background color */
    '&:hover': {
      '& input': {
        '& ~ $checkmark': {
          backgroundColor: Colors.grey,
        },
      },
    },
  },
  checkmark: {
    position: 'relative',
    top: 0,
    left: 0,
    height: '20px',
    width: '20px',
    borderRadius: '4px',
    backgroundColor: Colors.accent10,
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
});

export const useAccountChecklistStyles = createUseStyles({
  checklistLine: {
    display: 'flex',
    margin: '22px 0 22px 0',
  },
  highlighted: {
    fontSize: '26px',
    fontWeight: '600',
    color: Colors.white,
    lineHeight: '31px',
    marginLeft: '5px',
  },
  notHighlighted: {
    fontSize: '24px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    lineHeight: '29px',
    marginLeft: '5px',
  },
});

export const useImageUploadStyles = createUseStyles({
  idBox: {
    border: `2px dotted ${Colors.linkPurple}`,
    borderRadius: '10px',
    width: '12rem',
    height: '9.2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
