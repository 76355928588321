import Dropzone from 'react-dropzone';
import { useImageUploadOnLinkStyles } from './styles';
import { LinkComponent } from '../index';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SecureImageLoader } from '../SecureImageLoader/SecureImageLoader';

interface ImageUploadOnLinkProps {
  index?: string;
  defaultValue?: any;
  register: (string, any) => void;
  setValue: any;
}

export const ImageUploadOnLink: React.FC<ImageUploadOnLinkProps> = ({
  index = undefined,
  defaultValue = '',
  register,
  setValue,
}) => {
  const classes = useImageUploadOnLinkStyles();
  const { t } = useTranslation();
  const [isImageUploaded, setIsImageUploaded] = useState(
    defaultValue?.length !== 0
  );
  const [imageLink, setImageLink] = useState(defaultValue);
  const formName = index !== undefined ? `${index}-imageLink` : `imageLink`;

  useEffect(() => {
    setImageLink(defaultValue);
  }, [defaultValue]);
  return (
    <div className={classes.imageSection}>
      {!!imageLink.length && (
        // <img width="70px" src={imageLink} alt={'immuneImg'} />
        <SecureImageLoader imageId={imageLink} className={classes.imageView} />
      )}
      <div className={classes.imageUploadedOptions}>
        <Dropzone
          accept={'image/jpeg, image/png'}
          onDrop={acceptedFiles => {
            acceptedFiles.forEach(file => {
              const reader = new FileReader();
              reader.onabort = () => console.log('file reading was aborted');
              reader.onerror = () => console.log('file reading has failed');
              reader.onload = () => {
                const dataURL = reader.result as string;
                setImageLink(reader.result as string);
                setIsImageUploaded(true);
                setValue(formName, dataURL);
              };
              reader.readAsDataURL(file);
            });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isImageUploaded ? (
                  <LinkComponent
                    label={t('reupload_image')}
                    onClick={() => setIsImageUploaded(true)}
                  />
                ) : (
                  <LinkComponent label={t('upload_image')} />
                )}
              </div>
            </section>
          )}
        </Dropzone>
        {isImageUploaded && (
          <LinkComponent
            label={t('remove_image')}
            onClick={() => {
              setIsImageUploaded(false);
              setImageLink('');
              setValue(formName, undefined);
            }}
          />
        )}
      </div>
      <input
        style={{ display: 'none' }}
        {...register(formName, {})}
        value={imageLink}
      />
    </div>
  );
};
