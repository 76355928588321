import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useLinkComponentStyles: (
  data?: { secondary: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'label' | 'disabledLabel'> = createUseStyles({
  label: ({ secondary }) => ({
    fontSize: '16px',
    fontWeight: '500',
    color: secondary ? Colors.white : Colors.linkPurple,
    textDecoration: 'underline',
    cursor: 'pointer',
  }),
  disabledLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.checkboxGray,
    textDecoration: 'underline',
    cursor: 'default',
  },
});
