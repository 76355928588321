import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arrowLeft, PDFIcon, XCircle } from '../../../assets/images/icons';
import {
  ContentBox,
  LinkComponent,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import {
  AboutMeTitle,
  FormHeader,
  FormSecondary,
  SectionHeader,
} from '../../../components/TextComponents';
import { Colors } from '../../../config/colors';
import { ROUTES, sharedValidators } from '../../../config/constants';
import {
  setDependentData,
  selectCreateDependent,
} from '../../login/loginSlice';
import { useVerifyDependentStyles } from './styles';

export const VerifyDependent: React.FC = () => {
  const classes = useVerifyDependentStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isUploaded, setIsUploaded] = useState(false);
  const [dataUrlString, setDataUrlString] = useState('');
  const [fileName, setFileName] = useState('');
  const dispatch = useAppDispatch();
  const { dependentInfo } = useAppSelector(selectCreateDependent);
  const { firstName, lastName } = dependentInfo;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    dispatch(
      setDependentData({
        verifyInfo: { ssn: data.ssn, documentData: dataUrlString },
      })
    );
    history.push(ROUTES.dependentHandle);
  };

  const onClearFile = () => {
    setIsUploaded(false);
    setFileName('');
    setDataUrlString('');
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.dashboardHome)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_data_constellation')}</AboutMeTitle>
        </div>
        <div className={classes.buttonsBox}>
          <div
            onClick={() => {
              history.push(ROUTES.dashboardHome);
            }}
          >
            <AboutMeTitle>{t('cancel')}</AboutMeTitle>
          </div>
        </div>
      </div>
      <div className={classes.pageTitle}>
        <FormHeader>{t('verify_your_relationship')}</FormHeader>
        <div className="directions" style={{ margin: '20px 0' }}>
          <FormSecondary>
            {t('verify_detail_1', { firstName, lastName })}
          </FormSecondary>
        </div>
        <FormSecondary>{t('verify_detail_2', { firstName })}</FormSecondary>
      </div>
      <div className={classes.contentBox}>
        <ContentBox title={''} dropshadow>
          <div className={classes.cardContent}>
            <form className={classes.form}>
              <SectionHeader>{t('verify_detail_3')}</SectionHeader>
              <div>
                <Dropzone
                  accept={'application/pdf'}
                  multiple={false}
                  onDrop={acceptedFiles => {
                    acceptedFiles.forEach((file: any) => {
                      const reader = new FileReader();
                      reader.onabort = () =>
                        console.log('file reading was aborted');
                      reader.onerror = () =>
                        console.log('file reading has failed');
                      reader.onload = async () => {
                        const dataURL = reader.result as string;
                        setIsUploaded(true);
                        setFileName(file?.path || 'Uploaded File');
                        setDataUrlString(dataURL);
                      };
                      reader.readAsDataURL(file);
                    });
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className={classes.dropzone}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isUploaded ? (
                          <div className={classes.uploadedDocDetails}>
                            <img src={PDFIcon} alt="PDF Icon" />
                            <LinkComponent label={fileName} />
                            <div
                              onClick={e => {
                                e.preventDefault();
                                onClearFile();
                              }}
                            >
                              <img src={XCircle} alt="Remove File" />
                            </div>
                          </div>
                        ) : (
                          <div className={classes.uploadDocButton}>
                            <div style={{ padding: '0 10px' }}>
                              <ThemedButton
                                title={t('upload_a_document')}
                                isInverted
                              />
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            color: Colors.accent10,
                            marginTop: '24px',
                          }}
                        >
                          <FormSecondary>{t('verify_detail_4')}</FormSecondary>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div style={{ marginTop: '24px' }}>
                <SectionHeader>{t('verify_detail_5')}</SectionHeader>
              </div>
              <div className={classes.last4Container}>
                <OneLineTextInput
                  placeholder={t('last_4_of_ssn')}
                  type="text"
                  {...register('ssn', {
                    required: 'Please enter the four digit SSN',
                    ...sharedValidators.last4SSN,
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>
            </form>
            <div className={classes.continueButton}>
              <ThemedButton
                title={t('continue')}
                onClick={() => handleSubmit(onSubmit)()}
                isDisabled={!isUploaded}
              />
              <LinkComponent
                label={t('ill_do_this_later')}
                onClick={() => history.push(ROUTES.dependentHandle)}
              />
            </div>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};
