import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { flattenHealthConditions } from '../../health-conditions/utils';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const HealthConditionsShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.HEALTH_CONDITIONS)
  );
  const flatList = flattenHealthConditions(detailList);

  const optionsList: ShareDetailLine[] = flatList?.map(item => {
    return {
      label: item?.code?.text || '',
      sensitive: item?.sensitive,
      id: item?.id,
    };
  });
  return (
    <ShareSectionSelector
      title={t('health_conditions')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
