import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Switch } from '../Switch/Switch';
import { CardDetail1 } from '../TextComponents';
import { Info, LockDarkPurple } from '../../assets/images/icons';

export type MarkDeathNoteProps = {
  register?: any;
  defaultValue?: boolean;
  onChange?: any;
};

const useCurrentStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '17px',
  },
});

export const MarkDeathNote: React.FC<MarkDeathNoteProps> = ({
  register = () => {},
  defaultValue,
  onChange = () => {},
}) => {
  const classes = useCurrentStyles();
  const { t } = useTranslation();

  return (
    <div
      className={classes.container}
      style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
    >
      <CardDetail1>
        <img
          src={LockDarkPurple}
          alt="sensitive"
          width="19"
          style={{ paddingRight: '10px' }}
        />
        {t('is_death_note')}
      </CardDetail1>
      <div className="infoIcon">
        <img src={Info} alt="info" />
      </div>
      <Switch {...register} defaultValue={defaultValue} onChange={onChange} />
    </div>
  );
};
