import axios from 'axios';
import { APP_CONSTANTS } from '../config/constants';
import { getUUID } from '../config/utilities';
import { ENDPOINTS, getConfiguredAxios } from './axios';

export const uploadVaultDoc = async docData => {
  const axiosClient = getConfiguredAxios();
  const fileId = getUUID();
  const response = await axiosClient
    .get(`${ENDPOINTS.getPost}/` + fileId)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Get Request failed, `, err);
      throw err;
    });
  const { fields, url } = response?.with;

  const postFormData = new FormData();
  const blob = await (await fetch(docData)).blob();
  Object.keys(fields).forEach(key => {
    postFormData.append(key, fields[key]);
  });
  postFormData.append('file', blob);
  const uploadClient = axios.create({ headers: {} });
  delete uploadClient.defaults.headers.common['Authorization'];

  await uploadClient
    .post(url, postFormData)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Post to S3 Failed`, err);
      throw err;
    });

  return { id: fileId, url: fields.key };
};
export const uploadInsuranceCard = async ({ fileExtension, imageData }) => {
  const axiosClient = getConfiguredAxios();
  const fileId = `${getUUID()}${fileExtension}`;
  const response = await axiosClient
    .get(`${ENDPOINTS.getPost}/` + fileId)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Get Request failed, `, err);
      throw err;
    });
  const { fields, url } = response?.with;

  const postFormData = new FormData();
  const blob = await (await fetch(imageData)).blob();
  Object.keys(fields).forEach(key => {
    postFormData.append(key, fields[key]);
  });
  postFormData.append('file', blob);
  const uploadClient = axios.create({ headers: {} });
  delete uploadClient.defaults.headers.common['Authorization'];

  await uploadClient
    .post(url, postFormData)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Post to S3 Failed`, err);
      throw err;
    });

  return { id: fileId, url: fields.key };
};

export const uploadPublicImage = async ({ fileExtension, imageData }) => {
  const axiosClient = getConfiguredAxios();
  const fileId = `${getUUID()}${fileExtension}`;
  const response = await axiosClient
    .get(`${ENDPOINTS.getPost}/${fileId}?insecure=true`)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Get Request failed, `, err);
      throw err;
    });
  const { fields, url } = response?.with;

  const postFormData = new FormData();
  const blob = await (await fetch(imageData)).blob();
  Object.keys(fields).forEach(key => {
    postFormData.append(key, fields[key]);
  });
  postFormData.append('file', blob);
  const uploadClient = axios.create({ headers: {} });
  delete uploadClient.defaults.headers.common['Authorization'];

  await uploadClient
    .post(url, postFormData)
    .then(response => {
      const res = response.data;
      return res;
    })
    .catch(err => {
      console.error(`Post to S3 Failed`, err);
      throw err;
    });

  const publicUrl = getPublicVaultDocURL(fields.key);

  return { id: fileId, url: publicUrl };
};

export const getPublicVaultDocURL = url => {
  // ToDo: Fix the get vault doc endpoint to return the correct bucket with public URLS
  return `${APP_CONSTANTS.s3PublicBucketUrl}${url}`;
};

export const getVaultDocURL = async (
  id,
  isPublic = false,
  contractDetails?: { name: string; contractId: string }
) => {
  const axiosClient = getConfiguredAxios({ isPublic });

  const response = await axiosClient
    .get(ENDPOINTS.getGet(id), {
      params: isPublic
        ? { id: contractDetails.contractId, name: contractDetails.name }
        : {},
    })
    .then(response => {
      const res = response?.data?.with;
      return res;
    })
    .catch(err => {
      console.error(`Get vault doc url failed, `, err);
      throw err;
    });

  return response;
};

export const getFileExtension = (mimeType: string): string => {
  if (mimeType.includes('image/png')) {
    return '.png';
  } else if (mimeType.includes('image/jpeg')) {
    return '.jpeg';
  } else if (mimeType.includes('application/pdf')) {
    return '.pdf';
  } else {
    return '';
  }
};
