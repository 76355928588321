import BackgroundImage from '../../assets/images/user-profile-background.png';
import { Colors } from '../../config/colors';
import { Typography } from '../../config/typography';

export const styles = {
  userProfileComponent: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '18px',
    justifyContent: 'space-between',
    background: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  plusButtonContainer: {
    position: 'relative',
    left: '10%',
    top: '-22px',
  },
  profilePicture: {
    borderRadius: '100px',
    border: `5px ${Colors.white} solid`,
    height: '69px',
    width: '69px',
    marginBottom: '6px',
  },
  username: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.primaryText,
    backgroundColor: Colors.white,
    padding: '0 20px',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    cursor: 'pointer',
  },
  notificationContainer: {
    ...Typography.cardHeader,
    position: 'absolute',
    alignSelf: 'flex-end',
    margin: '3px 3px 0 0',
    width: '24px',
    height: '24px',
    backgroundColor: Colors.errorRed,
    color: Colors.white,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
