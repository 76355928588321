import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

import { MenuOption } from '../../config/SharedTypes';
import { useThreeDotMenuStyles } from './styles';

interface ThreeDotMenuProps {
  options: MenuOption[];
}

const MenuOptionButton: React.FC<any> = ({ option }) => {
  const classes = useThreeDotMenuStyles();
  return (
    <div onClick={option.onClick} className={classes.popperOption}>
      {!!option.icon && (
        <img src={option.icon} alt="icon" className={classes.icon} />
      )}
      {option.title}
    </div>
  );
};

export const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({ options }) => {
  const classes = useThreeDotMenuStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    event.preventDefault();
  };

  const handleClose = e => {
    setIsOpen(false);
    setAnchorEl(null);
    e.preventDefault();
  };

  return (
    <>
      {options.length > 0 && !isShareUser && (
        <>
          <button className={classes.button} onClick={handleClick}>
            ...
          </button>
          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {options.map(option => (
              <MenuOptionButton
                option={{
                  ...option,
                  onClick: e => {
                    handleClose(e);
                    option.onClick();
                  },
                }}
                key={option}
              />
            ))}
          </Popover>
        </>
      )}
    </>
  );
};
