import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Xdark } from '../../assets/images/icons';
import { Colors } from '../../config/colors';
import { ModalHeader } from '../TextComponents';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  hideControls: boolean;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, hideControls, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && !hideControls ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: Colors.accent5Purple,
          }}
        >
          <img src={Xdark} alt="close" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export interface ModalProps {
  children?: React.ReactNode;
  title?: string;
  onClose?: () => void;
  open?: boolean;
  icon?: string;
  actions?: any;
  hideControls?: boolean;
}

const useModalStyles = createUseStyles({
  actions: {},
  actionsRow: {
    width: '100%',
  },
});

export const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  title,
  icon,
  actions,
  hideControls,
}) => {
  const classes = useModalStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          hideControls={hideControls}
        >
          {icon ? (
            <div style={{ display: 'flex' }}>
              <img
                width="25px"
                alt=""
                src={icon}
                style={{ paddingRight: '10px' }}
              />
              <ModalHeader>{title}</ModalHeader>
            </div>
          ) : (
            <>{title}</>
          )}
        </BootstrapDialogTitle>
        {actions && <div className={classes.actionsRow}>{actions()}</div>}
        <DialogContent dividers={!hideControls}>
          <div className="content" style={{ padding: '10px' }}>
            {children}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
