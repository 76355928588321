import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { ThemedButton } from '../../../components';
import { InsuranceCard } from '../../../components/InsuranceCard/InsuranceCard';
import {
  CardBody,
  FormHeader,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { Insurance } from '../../../config/SharedTypes';
import { DashboardSections } from '../DashboardSections';
import { dismissFhirSection, selectInsurances } from '../dashboardSlice';
import { useInsuranceStyles } from './styles';
import { flattenInsurance } from './utils';

type InsuranceDashboardProps = {
  details?: Insurance[];
};

export const InsuranceDashboard: React.FC<InsuranceDashboardProps> = () => {
  const classes = useInsuranceStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectInsurances);
  const dismissed = !!detailList;
  const insurances = !!detailList
    ? detailList.map(ins => flattenInsurance(ins))
    : [];
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const activeInsurances = insurances?.filter(item => !!item.active);
  const archivedInsurances = insurances?.filter(item => !item.active);

  const insuranceGroups = [
    {
      object: activeInsurances,
      sectionTitle: t('active_coverage_all_caps'),
    },
    {
      object: archivedInsurances,
      sectionTitle: t('archived_coverage_all_caps'),
    },
  ];

  return (
    <>
      <FormHeader>{t('health_insurance')}</FormHeader>
      {!!insurances?.length &&
        insuranceGroups.map(group =>
          !!group.object?.length ? (
            <div className={classes.insurancesContainer}>
              <SubHeader3>{group.sectionTitle}</SubHeader3>
              {group.object?.map(insurance => {
                return <InsuranceCard detail={insurance} />;
              })}
            </div>
          ) : null
        )}
      {!insurances.length && (
        <div className={classes.noInfo}>
          <CardBody>{t('no_info_available')}</CardBody>
        </div>
      )}
      {!isShareUser && (
        <div className={classes.actionButtons}>
          {!insurances.length && !dismissed && (
            <ThemedButton
              title={t('no_insurance')}
              isInverted
              onClick={() => {
                dispatch(
                  dismissFhirSection({
                    section: DashboardSections.INSURANCES,
                  })
                );
              }}
            />
          )}

          <ThemedButton
            title={
              !insurances.length
                ? t('add_insurance_coverage')
                : t('add_additional_insurance')
            }
            isInverted
            onClick={() => {
              history.push(ROUTES.insuranceScanCard);
            }}
          />
        </div>
      )}
    </>
  );
};
