import { Colors } from '../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../config/constants';

// This cardContainer style can be reused by the subpages
export const cardContainer = {
  display: 'flex',
  margin: '30px 10px',
  width: '95%',
  [DESKTOP_VIEW_BREAKPOINT]: {
    margin: '15px 26px',
    width: 'unset',
  },
};

export const styles = {
  root: {
    color: 'white',
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto 1fr 0.05fr',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: `'message'
    'form'
    'footer'`,
    padding: '20px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      gridTemplateRows: '0.05fr 1fr 0.05fr',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateAreas: `'gutter gutter'
                  'message form'
                  'footer footer'`,
      height: '100%',
      padding: '0 60px',
    },
  },
  message: {
    display: 'flex',
    gridArea: 'message',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [DESKTOP_VIEW_BREAKPOINT]: {
      height: '68%',
      minWidth: 'unset',
      maxWidth: '680px',
      textAlign: 'left',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: Colors.black,
    borderRadius: '10px',
    alignItems: 'center',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '10px 0',
      backgroundColor: Colors.formBackgroundWhite,
      alignItems: 'unset',
      minWidth: '400px',
      maxWidth: '800px',
      width: '100%',
    },
  },
  formFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGridContainer: {
    gridArea: 'form',
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '75%',
      justifyContent: 'space-between',
    },
  },
  badgeImg: {
    height: '48px',
    [DESKTOP_VIEW_BREAKPOINT]: {
      height: '64px',
    },
  },
  footer: {
    display: 'none',
    gridArea: 'footer',
    justifyContent: 'space-evenly',
    margin: '10px 0',
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: 'unset',
      justifyContent: 'flex-start',
      gap: '30px',
      display: 'flex',
    },
    height: '2.5rem',
  },
};
