import { useLinkComponentStyles } from './styles';
import { FormSecondary } from '../TextComponents';

interface LinkComponentProps {
  label: string;
  onClick?: () => void;
  secondary?: boolean;
  isDisabled?: boolean;
}

export const LinkComponent: React.FC<LinkComponentProps> = ({
  label,
  onClick,
  secondary,
  isDisabled,
}) => {
  const classes = useLinkComponentStyles({ secondary, theme: {} });
  return (
    <FormSecondary>
      {isDisabled ? (
        <div className={classes.disabledLabel}>{label}</div>
      ) : (
        <div className={classes.label} onClick={() => onClick()}>
          {label}
        </div>
      )}
    </FormSecondary>
  );
};
