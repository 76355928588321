import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useCheckboxListStyles = createUseStyles({
  container: {
    height: '600px',
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
  },
  searchBar: {
    padding: '20px 16px 0px 16px',
  },
  optionsList: {
    display: 'flex',
    maxHeight: '70%',
    overflow: 'scroll',
  },
  actionButton: {
    display: 'flex',
    margin: 'auto 0 32px 20px',
  },
  buttonContainer: {
    maxWidth: '340px',
  },
});
