import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const RouteWrapper = ({ component: Component, isPrivate, ...rest }) => {
  const signedIn = useSelector(state => state.session.loggedIn);
  if (isPrivate && !signedIn) {
    return <Redirect to="/" />;
  }
  // if (!isPrivate && signedIn) { //What is the point of this?
  //   return <Redirect to="/dashboard" />;
  // }
  return <Route {...rest} component={Component} />;
};

export default RouteWrapper;

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
