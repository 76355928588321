import { createUseStyles } from 'react-jss';
import { Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { DashboardDrawer } from '../../components';
import { ProgressLabel, InfoHeader } from '../../components/TextComponents';
import { SubRoute } from '../../config/SharedTypes';
import Route from '../../routes/route';
import { About } from './about/About';
import { HealthInsurance } from './health-insurance/HealthInsurance';
import { Allergies } from './allergies/Allergies';
import { FamilyHistory } from './family-history/FamilyHistory';
import { HealthConditions } from './health-conditions/HealthConditions';
import { MedicalEvents } from './medical-events/MedicalEvents';
import { Immunizations } from './immunizations/Immunizations';
import { Medications } from './medications/Medications';
import { Providers } from './providers/Providers';
import { styles } from './styles';
import { idIconWhite } from '../../assets/images/icons';
import { arrowLeft } from '../../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { AboutMeTitle } from '../../components/TextComponents/AboutMeTitle';
import { ROUTES } from '../../config/constants';
import { generateSidebarItems } from './utils';
import { selectAll } from './dashboardSlice';
import { useAppSelector } from '../../app/hooks';
import { CreateDependent } from './dependents/CreateDependent';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { StarProgress } from '../../components/StarProgress/StarProgress';
import { AccountSettings } from './account-settings/AccountSettings';
import { ChangePassword } from './change-password/ChangePassword';

const useDashboardStyles = createUseStyles(styles);

const subRoutes: (path: string) => SubRoute[] = path => [
  { path: `${path}/about`, exact: true, isPrivate: false, component: About },
  {
    path: `${path}/healthInsurance`,
    exact: false,
    isPrivate: false,
    component: HealthInsurance,
  },
  {
    path: `${path}/providers`,
    exact: true,
    isPrivate: false,
    component: Providers,
  },
  {
    path: `${path}/immunizations`,
    exact: false,
    isPrivate: false,
    component: Immunizations,
  },
  {
    path: `${path}/medications`,
    exact: false,
    isPrivate: false,
    component: Medications,
  },
  {
    path: `${path}/allergies`,
    exact: false,
    isPrivate: false,
    component: Allergies,
  },
  {
    path: `${path}/healthConditions`,
    exact: false,
    isPrivate: false,
    component: HealthConditions,
  },
  {
    path: `${path}/medicalEvents`,
    exact: false,
    isPrivate: false,
    component: MedicalEvents,
  },
  {
    path: `${path}/familyHistory`,
    exact: false,
    isPrivate: false,
    component: FamilyHistory,
  },
  {
    path: `${path}/createDependent`,
    exact: false,
    isPrivate: false,
    component: CreateDependent,
  },
  {
    path: `${path}/accountSettings`,
    exact: false,
    isPrivate: false,
    component: AccountSettings,
  },
  {
    path: `${path}/changePassword`,
    exact: false,
    isPrivate: false,
    component: ChangePassword,
  },
];

type DashboardProps = {
  user: string; //Eventually we'll have a user object
};

export const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const classes = useDashboardStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  const store = useAppSelector(selectAll);
  const currentPath = history.location.pathname;
  const [showReminder, setShowReminder] = useState(true);

  const sidebarItems = generateSidebarItems(path, store);
  const notifCounter = sidebarItems.filter(
    ({ notificationCount }) => notificationCount
  );
  const starPercent = 100 - (notifCounter.length / 8) * 100;
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  return (
    <div className={classes.dashboard}>
      <div className={classes.nav}>
        <div className={classes.navContent}>
          <div
            className={classes.backToAction}
            onClick={() => history.push(ROUTES.dashboardHome)}
          >
            <div className={classes.arrowContainer}>
              <img src={arrowLeft} alt="arrowLeft" />
            </div>
            <AboutMeTitle>{t('back_to_action_center')}</AboutMeTitle>
          </div>
          <div className={classes.personalInfoHeader}>
            <div className={classes.idIcon}>
              <img src={idIconWhite} alt="idIconWhite" />
            </div>
            <InfoHeader>{t('personal_info')}</InfoHeader>
          </div>
        </div>
        {showReminder && !isShareUser && (
          <>
            <StarProgress
              percent={starPercent === 0 ? 4 : starPercent}
              setShowReminder={setShowReminder}
            />
          </>
        )}
        <div className={classes.navContent}>
          <div className={classes.allDataTitle}>
            <ProgressLabel>{t('all_data_all_caps')}</ProgressLabel>
          </div>
          {sidebarItems.map(({ title, notificationCount, path }) => (
            <>
              <DashboardDrawer
                key={title}
                title={title}
                notificationCount={notificationCount}
                path={path}
                isInDrawer={currentPath === path}
              />
            </>
          ))}
          {!isShareUser && (
            <DashboardDrawer
              title={t('account_settings')}
              notificationCount={false}
              path={ROUTES.accountSettings}
            />
          )}
        </div>
      </div>
      <div className={classes.content}>
        <Switch>
          {subRoutes(path).map(({ path, component, isPrivate, exact }) => (
            <Route
              path={path}
              component={component}
              isPrivate={isPrivate}
              exact={exact}
            />
          ))}
          <Route isPrivate={false} component={About} />
        </Switch>
      </div>
    </div>
  );
};
