import { useTranslation } from 'react-i18next';
import { Insurance } from '../../../config/SharedTypes';
import { useEditInsuranceStyles } from './styles';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectInsurances, updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { ROUTES } from '../../../config/constants';
import {
  FormHeader,
  AboutMeTitle,
  CardBody,
} from '../../../components/TextComponents';
import {
  OneLineTextInput,
  ThemedButton,
  ImageUpload,
  LinkComponent,
  Dropdown,
} from '../../../components';
import idFrontIcon from '../../../assets/idFrontIcon.png';
import idBackIcon from '../../../assets/idBackIcon.png';
import { uploadInsuranceCard } from '../../../api/vaultUtils';
import { flattenInsurance, formToInsurance, insuranceTypes } from './utils';
import { SingleInsurance } from '../../../typings/vecna_types/vecnaPatient';
import { getUUID } from '../../../config/utilities';

type EditInsuranceProps = {
  details?: Insurance[];
};

type EditInsuranceForm = {
  frontImage: string;
  backImage: string;
  provider: string;
  type: string;
  subName: string;
  subNumber: string;
  groupNumber: string;
  payerId: string;
  portalURL: string;
  portalUsername: string;
  portalPassword: string;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditInsurance: React.FC<EditInsuranceProps> = () => {
  const classes = useEditInsuranceStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList: insurances } = useAppSelector(selectInsurances);
  const queryId = useQuery().get('id');
  const frontUrl = useQuery().get('front');
  const backUrl = useQuery().get('back');
  const [insuranceId] = useState(queryId || getUUID());
  const insuranceFhirData: SingleInsurance = insurances?.find(
    el => el.fhirData.id === insuranceId
  );
  const insuranceData = flattenInsurance(insuranceFhirData);
  const [frontImage, setFrontImage] = useState(
    insuranceData?.frontImage || frontUrl
  );
  const [backImage, setBackImage] = useState(
    insuranceData?.backImage || backUrl
  );
  const [isFrontUploaded, setIsFrontUploaded] = useState(
    !!insuranceData?.frontImage?.length || !!frontUrl?.length
  );
  const [isBackUploaded, setIsBackUploaded] = useState(
    !!insuranceData?.backImage?.length || !!backUrl?.length
  );

  const editInsuranceCard = async (side: 'back' | 'front', imageData: any) => {
    if (side === 'front') {
      const frontImageUrl = await uploadInsuranceCard({
        fileExtension: imageData.fileExtension,
        imageData: imageData.data,
      });
      setFrontImage(frontImageUrl.id);
    } else if (side === 'back') {
      const backImageUrl = await uploadInsuranceCard({
        fileExtension: imageData.fileExtension,
        imageData: imageData.data,
      });
      setBackImage(backImageUrl.id);
    }
  };

  type confInfoTypes = {
    placeholder: string;
    defaultValue: string;
    registerString: keyof EditInsuranceForm;
    requiredString?: string;
  };

  type portalInfoTypes = {
    placeholder: string;
    type: 'text' | 'password' | 'tel';
    defaultValue: string;
    registerString: keyof EditInsuranceForm;
  };

  const confirmedInfoFields: confInfoTypes[] = [
    {
      placeholder: t('provider'),
      defaultValue: insuranceData?.provider,
      registerString: 'provider',
      requiredString: 'Please enter your insurance provider.',
    },
    {
      placeholder: t('sub_name'),
      defaultValue: insuranceData?.subName,
      registerString: 'subName',
      requiredString: 'Please enter the subscriber name.',
    },
    {
      placeholder: t('sub_number'),
      defaultValue: insuranceData?.subNumber,
      registerString: 'subNumber',
      requiredString: 'Please enter the subscriber number.',
    },
    {
      placeholder: t('group_number'),
      defaultValue: insuranceData?.groupNumber,
      registerString: 'groupNumber',
      requiredString: 'Please enter the group number.',
    },
    {
      placeholder: t('payer_id_optional'),
      defaultValue: insuranceData?.payerId,
      registerString: 'payerId',
    },
  ];

  const portalInfoFields: portalInfoTypes[] = [
    {
      placeholder: t('portal_url'),
      type: 'text',
      defaultValue: insuranceData?.portalUrl,
      registerString: 'portalURL',
    },
    {
      placeholder: t('username'),
      type: 'text',
      defaultValue: insuranceData?.portalUsername,
      registerString: 'portalUsername',
    },
    {
      placeholder: t('password'),
      type: 'password',
      defaultValue: insuranceData?.portalPassword,
      registerString: 'portalPassword',
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditInsuranceForm>();

  const onSubmit = data => {
    const fhirItem = formToInsurance({
      id: insuranceId,
      frontImage,
      backImage,
      provider: data.provider,
      type: data.type,
      subName: data.subName,
      subNumber: data.subNumber,
      groupNumber: data.groupNumber,
      payerId: data.payerId,
      portalURL: data.portalURL,
      portalUsername: data.portalUsername,
      portalPassword: data.portalPassword,
      verificationPending: insuranceData.verificationPending,
      verified: insuranceData.verified,
      active: true,
    });
    const update = {
      section: DashboardSections.INSURANCES,
      detailItem: fhirItem,
    };
    dispatch(updateFhirItem(update));
    history.push(ROUTES.insuranceDashboard);
  };

  const onError = errors => {
    console.error(errors);
  };

  return (
    <>
      <FormHeader>{t('add_your_insurance_info')}</FormHeader>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className={classes.text}>
          <div className={classes.photosText}>
            <AboutMeTitle>{t('your_insurance_pics')}</AboutMeTitle>
          </div>
          <div className={classes.cardUploadContainer}>
            <div className={classes.cardSide}>
              <ImageUpload
                imgType="front"
                iconSrc={idFrontIcon}
                isUploaded={isFrontUploaded}
                setIsUploaded={setIsFrontUploaded}
                imageLink={frontImage}
                setImageLink={data => editInsuranceCard('front', data)}
                isSecure
              />
              {isFrontUploaded && (
                <div className={classes.reUploadContainer}>
                  <LinkComponent
                    label={t('reupload_front')}
                    onClick={() => setIsFrontUploaded(false)}
                  />
                </div>
              )}
            </div>
            <div className={classes.cardSide}>
              <ImageUpload
                imgType="back"
                iconSrc={idBackIcon}
                isUploaded={isBackUploaded}
                setIsUploaded={setIsBackUploaded}
                imageLink={backImage}
                setImageLink={data => editInsuranceCard('back', data)}
                isSecure
              />
              {isBackUploaded && (
                <div className={classes.reUploadContainer}>
                  <LinkComponent
                    label={t('reupload_back')}
                    onClick={() => setIsBackUploaded(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.text}>
          <AboutMeTitle>{t('confirm_info')}</AboutMeTitle>
          <div className={classes.confirmInfoSubtitle}>
            <CardBody>{t('check_info_directions')}</CardBody>
          </div>
          <Controller
            name="type"
            control={control}
            defaultValue={insuranceData?.type}
            shouldUnregister={true}
            rules={{
              required: 'Please enter what type this insurance is.',
            }} //TODO: Resolve type issue and use translations string
            render={({ field }) => (
              <Dropdown
                field={field}
                placeholder={'Type'}
                optionsArr={insuranceTypes}
                formErrors={errors}
              />
            )}
          />
          {confirmedInfoFields.map(field => (
            <OneLineTextInput
              placeholder={field.placeholder}
              type="text"
              defaultValue={field.defaultValue}
              {...register(field.registerString, {
                ...(field.requiredString && {
                  required: field.requiredString,
                }),
              })} //TODO: Resolve type issue and use translations string
              formErrors={errors}
            />
          ))}
          <div className={classes.portalTitle}>
            <AboutMeTitle>{t('add_your_portal_url')}</AboutMeTitle>
          </div>
          <div className={classes.portalSubtitle}>
            <CardBody>{t('add_portal_info_directions')}</CardBody>
          </div>
          {portalInfoFields.map(field => (
            <OneLineTextInput
              placeholder={field.placeholder}
              type={field.type}
              defaultValue={field.defaultValue}
              {...register(field.registerString)}
            />
          ))}
        </div>
        <div className={classes.bottomButton}>
          <div className={classes.saveButton}>
            <ThemedButton title={t('save')} type="submit" />
          </div>
        </div>
      </form>
    </>
  );
};
