import { createUseStyles } from 'react-jss';
import { ContentBox, ThemedButton, LinkComponent } from '../../../components';
import { CardH2 } from '../../../components/TextComponents/CardH2';
import { styles } from './styles';
import { ImageUpload } from '../../../components/ImageUpload/ImageUpload';
import { Colors } from '../../../config/colors';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import idFrontIcon from '../../../assets/idFrontIcon.png';
import idBackIcon from '../../../assets/idBackIcon.png';
import { useTranslation } from 'react-i18next';

const useIdCaptureStyles = createUseStyles(styles);

export const IdCapture = () => {
  const { t } = useTranslation();
  const classes = useIdCaptureStyles();
  const history = useHistory();
  const [frontUploaded, setFrontUploaded] = useState(false);
  const [backUploaded, setBackUploaded] = useState(false);

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('id_capture_title')} slimTitle>
        <div style={{ color: Colors.primaryText }}>
          <CardH2>{t('id_capture_subtitle')}</CardH2>
        </div>
        <div className={classes.centered}>
          <div className={classes.ids}>
            <div className={classes.idSideArea}>
              <ImageUpload
                imgType="front"
                iconSrc={idFrontIcon}
                isUploaded={frontUploaded}
                setIsUploaded={setFrontUploaded}
              />
              {frontUploaded && (
                <div className={classes.reUploadContainer}>
                  <LinkComponent
                    label={t('reupload_front')}
                    onClick={() => setFrontUploaded(false)}
                  />
                </div>
              )}
            </div>
            <div className={classes.idSideArea}>
              <ImageUpload
                imgType="back"
                iconSrc={idBackIcon}
                isUploaded={backUploaded}
                setIsUploaded={setBackUploaded}
              />
              {backUploaded && (
                <div className={classes.reUploadContainer}>
                  <LinkComponent
                    label={t('reupload_back')}
                    onClick={() => setBackUploaded(false)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={t('continue')}
              onClick={() => history.push('confirmInfo')}
              isDisabled={!(frontUploaded && backUploaded)}
            />
          </div>
          <LinkComponent
            label={t('enter_info_manually')}
            onClick={() => history.push(`confirmInformation`)}
          />
        </div>
      </ContentBox>
    </div>
  );
};
