import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MarkSensitive } from '../MarkSensitive/MarkSensitive';
import { OneLineTextInput } from '../OneLineTextInput/OneLineTextInput';
import { RadioList } from '../RadioList/RadioList';
import { CardDetail1, HorizontalBreak } from '../TextComponents';
import { useFamilyConditionCardStyles } from './styles';
import { sharedValidators } from '../../config/constants';

export const EditFamilyConditionCard = ({
  register,
  errors,
  index,
  condition,
  isDeceased,
}) => {
  const { title, sensitive, onsetPeriod } = condition;
  const { t } = useTranslation();
  const classes = useFamilyConditionCardStyles();
  const prefix = `conditions.`;
  return (
    <>
      <div className={classes.conditionCard}>
        <div className={classes.cardTitle}>
          <CardDetail1>{title}</CardDetail1>
        </div>
        <div className={classes.middleSection}>
          <div className={classes.dateFields}>
            <div className={classes.inputFieldContainer}>
              <OneLineTextInput
                placeholder={t('approx_diagnosis_date')}
                defaultValue={onsetPeriod}
                type="text"
                {...register(`${prefix}onsetDate`, {
                  required: `${t('diagnosis_date_required')}`,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            </div>

            {isDeceased && (
              <div className={classes.inputFieldContainer}>
                <CardDetail1>{t('cause_of_death')}</CardDetail1>
                <RadioList
                  optionsList={[t('yes'), t('no')]}
                  register={{
                    name: `${prefix}causeDeath`,
                    register: register,
                  }}
                  groupNumber={index}
                />
              </div>
            )}
          </div>
        </div>
        <MarkSensitive
          register={{ ...register(`${prefix}sensitive`) }}
          defaultValue={sensitive}
        />
        <div style={{ height: '0px', width: '0px', opacity: '0' }}>
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`${prefix}title`, {})}
            formErrors={errors}
          />
        </div>
      </div>

      <div className={classes.break}>
        <HorizontalBreak />
      </div>
    </>
  );
};

export const NewFamilyConditionCard = ({
  title,
  register,
  unregister,
  errors,
  index,
  isDeceased,
}) => {
  const { t } = useTranslation();
  const classes = useFamilyConditionCardStyles();
  const registerPrefix = `conditions.${index}.`;

  useEffect(() => {
    return () => {
      unregister(registerPrefix);
    };
  }, []);

  return (
    <>
      <div className={classes.conditionCard}>
        <div className={classes.cardTitle}>
          <CardDetail1>{title}</CardDetail1>
        </div>
        <div className={classes.middleSection}>
          <div className={classes.dateFields}>
            <div className={classes.inputFieldContainer}>
              <OneLineTextInput
                placeholder={t('approx_diagnosis_date')}
                type="text"
                {...register(`${registerPrefix}onsetDate`, {
                  required: `${t('diagnosis_date_required')}`,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            </div>

            {isDeceased && (
              <div className={classes.inputFieldContainer}>
                <CardDetail1>{t('cause_of_death')}</CardDetail1>
                <RadioList
                  optionsList={[t('yes'), t('no')]}
                  register={{
                    name: `${registerPrefix}causeDeath`,
                    register: register,
                  }}
                  groupNumber={index}
                />
              </div>
            )}
          </div>
        </div>
        <MarkSensitive
          register={{ ...register(`${registerPrefix}sensitive`) }}
        />
        <div style={{ height: '0px', width: '0px', opacity: '0' }}>
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`${registerPrefix}title`, {})}
            formErrors={errors}
          />
        </div>
      </div>
      <div className={classes.break}>
        <HorizontalBreak />
      </div>
    </>
  );
};
