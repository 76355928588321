import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useThreeDotIconsMenuStyles = createUseStyles({
  button: {
    cursor: 'pointer',
    border: `none`,
    backgroundColor: Colors.clear,
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '5px',
    color: Colors.primaryText,
  },
  popperOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '25px',
    cursor: 'pointer',
    backgroundColor: Colors.white,
    color: Colors.primaryText,
    width: '108px',
    height: '39px',
    fontSize: '1rem',
    fontWeight: '600',
    padding: '0 17px 0 18px',
    '&:hover': {
      backgroundColor: Colors.disabledGrey,
      color: Colors.white,
    },
  },
});
