import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { viewShared } from '../api/share';
import { setShareUser } from '../features/login/loginSlice';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useRequest = <
  T extends object | string | undefined,
  R extends object
>(
  apiRequest: (params: T) => Promise<AxiosResponse<R, unknown>>
) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [data, setData] = useState<R | null>(null);
  const [status, setStatus] = useState<number>(NaN);

  const request = async (params?: T) => {
    setLoading(true);
    setError('');
    setData(null);
    setStatus(NaN);

    try {
      const { data: responseData, status: responseStatus } = await apiRequest(
        params as T
      );

      setStatus(responseStatus);
      setData(responseData);
    } catch (e: unknown) {
      const { status: errorStatus = NaN, data } =
        (e as AxiosError<any, unknown>)?.response || {};
      setError(data?.because || t('something_wrong'));
      setStatus(errorStatus);
    }

    setLoading(false);
  };

  return { request, loading, error, data, status };
};

export const useSharerInformation = ({
  onSucess,
  onError,
}: {
  onSucess: () => void;
  onError: (error: string) => void;
}) => {
  const {
    data: viewSharedData,
    request: viewDataRequest,
    error,
    loading,
  } = useRequest(viewShared);
  const dispatch = useDispatch();
  useEffect(() => {
    const infoApi = viewSharedData?.with?.info;
    const basicInfo = viewSharedData?.with?.basicInfo;

    const update: any = {
      ...infoApi,
      fhirData: {
        name: basicInfo?.info?.fhirData?.name,
        ...infoApi?.fhirData,
      },
    };

    if (infoApi) {
      dispatch(setShareUser(update));
      onSucess();
    }
    if (!!error) {
      onError(error);
    }
  }, [viewSharedData]);

  return { request: viewDataRequest, loading };
};
