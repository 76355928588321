import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Insurance } from '../../../config/SharedTypes';
import { selectInsurances, updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { useInsuranceSummaryStyles } from './styles';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  CardHeader,
  FormHeader,
  FormPrompt,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { ThemedButton, ThreeDotMenu } from '../../../components';
import { useLocation, useHistory } from 'react-router-dom';
import { SecureImageLoader } from '../../../components/SecureImageLoader/SecureImageLoader';
import { flattenInsurance, insTypeToLabel } from './utils';
import { getUUID } from '../../../config/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

type InsuranceSummaryProps = {
  detail?: Insurance;
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const InsuranceSummary: React.FC<InsuranceSummaryProps> = () => {
  const classes = useInsuranceSummaryStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList: insurances } = useAppSelector(selectInsurances);
  const queryId = useQuery().get('id');

  const [insuranceId] = useState(queryId || getUUID());
  const history = useHistory();
  const insuranceData = insurances.find(el => el.fhirData.id === insuranceId);
  const flatInsurance = flattenInsurance(insuranceData);

  const infoSections = [
    { label: t('insurance_provider_all_caps'), data: flatInsurance.provider },
    {
      label: t('insurance_type_all_caps'),
      data: insTypeToLabel(flatInsurance.type),
    },
    { label: t('sub_name').toUpperCase(), data: flatInsurance.subName },
    { label: t('sub_number').toUpperCase(), data: flatInsurance.subNumber },
    { label: t('group_number').toUpperCase(), data: flatInsurance.groupNumber },
  ];

  const insuranceMenuOptions = insuranceData => [
    {
      title: t('refresh_benefits'),
      onClick: () => {
        console.log('refresh');
        // TODO: add refresh benefits once connected to portal
      },
    },
    {
      title: t('update_coverage'),
      onClick: () => {
        history.push({
          pathname: ROUTES.insuranceEdit,
          search: `?id=${insuranceId}`,
        });
      },
    },
    {
      title: t('archive_coverage'),
      onClick: () => {
        const update = {
          section: DashboardSections.INSURANCES,
          detailItem: {
            ...insuranceData,
            additionalData: { ...insuranceData.additionalData, active: false },
          },
        };
        dispatch(updateFhirItem(update));
        history.push(ROUTES.insuranceDashboard);
      },
    },
  ];

  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  return (
    <>
      <div className={classes.title}>
        <FormHeader>{flatInsurance.provider}</FormHeader>
        {!isShareUser && (
          <div className={classes.dotMenu}>
            <ThreeDotMenu
              options={
                flatInsurance.active
                  ? insuranceMenuOptions(insuranceData)
                  : insuranceMenuOptions(insuranceData).slice(0, 2)
              }
            />
          </div>
        )}
      </div>
      <div className={classes.photosBox}>
        <CardHeader>{t('insurance_card_pics')}</CardHeader>
        <div className={classes.photosRow}>
          {!!flatInsurance.frontImage.length && (
            <div className={classes.cardPhotoContainer}>
              <SecureImageLoader
                imageId={flatInsurance.frontImage}
                alt="frontImage"
                className={classes.uploadedImg}
              />
            </div>
          )}
          {!!flatInsurance.backImage.length && (
            <div className={classes.cardPhotoContainer}>
              <SecureImageLoader
                imageId={flatInsurance.backImage}
                alt="backImage"
                className={classes.uploadedImg}
              />
            </div>
          )}
          {!flatInsurance.frontImage.length && !flatInsurance.backImage.length && (
            <div className={classes.noImages}>
              <FormPrompt>{t('images_not_found')}</FormPrompt>
            </div>
          )}
        </div>
      </div>
      <div className={classes.summaryBox}>
        <CardHeader>{t('insurance_summary')}</CardHeader>
        {infoSections.map(section => (
          <>
            <div className={classes.infoLabel}>
              <SubHeader3>{section.label}</SubHeader3>
            </div>
            <div className={classes.infoData}>
              <FormPrompt>{section.data}</FormPrompt>
            </div>
          </>
        ))}
      </div>
      <div className={classes.bottomButton}>
        <ThemedButton title={t('view_coverage')} isInverted isDisabled />
      </div>
    </>
  );
};
