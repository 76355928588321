import { useStarProgressStyles } from './styles';
import { yellowStar } from '../../assets/images/icons';
import { useTranslation } from 'react-i18next';
import { TagText } from '../TextComponents';
import { X } from '../../assets/images/icons';

interface StarProgressProps {
  percent: number;
  setShowReminder: (boolean) => void;
}

export const StarProgress: React.FC<StarProgressProps> = ({
  percent,
  setShowReminder,
}) => {
  const classes = useStarProgressStyles({ percent, theme: {} });
  const { t } = useTranslation();
  return (
    <div className={classes.purpleBox}>
      <div className={classes.left}>
        <div className={classes.title}>
          <TagText>{t('data_needs_completion')}</TagText>
        </div>
        <div className={classes.barBox}>
          <div className={classes.backgroundBar}>
            <div className={classes.filledBar} />
            <div className={classes.starBox}>
              <img src={yellowStar} alt="progressStar" />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.xBox} onClick={() => setShowReminder(false)}>
          <img width="14px" src={X} alt="Xicon" />
        </div>
      </div>
    </div>
  );
};
