import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { SyringePurple } from '../../../assets/images/icons';
import { HealthDetailCard, ThemedButton } from '../../../components';
import {
  CardDetail2,
  FormBody,
  FormHeader,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import Route from '../../../routes/route';
import { DashboardSections } from '../DashboardSections';
import {
  deleteImmunization,
  dismissFhirSection,
  selectImmunizations,
} from '../dashboardSlice';
import { AddImmunizations } from './AddImmunizations';
import { EditImmunization } from './EditImmunization';
import { useImmunizationsStyles } from './styles';
import { flattenImmunizationList, immunizationToDetail } from './utils';

type ImmunizationsProps = {
  details?: HealthDetail[];
  listOnly?: boolean;
};

export const Immunizations: React.FC<ImmunizationsProps> = ({ listOnly }) => {
  const classes = useImmunizationsStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectImmunizations);
  const dismissed = !!detailList;
  const immunizations = detailList || [];
  const flatImmunizations = flattenImmunizationList(immunizations);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const immunizationMenuoptions = detail => [
    {
      title: t('edit'),
      onClick: () =>
        history.push({
          pathname: `${path}/edit`,
          search: `?id=${detail.fhirData.id}`,
        }),
    },
    {
      title: t('delete'),
      onClick: () =>
        dispatch(
          deleteImmunization({
            groupIdentifier: detail?.fhirData?.identifier[0]?.value,
          })
        ),
    },
  ];

  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  if (listOnly) {
    // Only return the list of immunizations, used for share
    return (
      <div>
        {flatImmunizations.length ? (
          flatImmunizations?.map(immunization => {
            const detail = immunizationToDetail(immunization);
            return (
              <div className={classes.detailCard}>
                <HealthDetailCard detail={detail} icon={SyringePurple} />
              </div>
            );
          })
        ) : (
          <FormBody>{t('nothing_to_share')}</FormBody>
        )}
      </div>
    );
  } else {
    // Return the full component with options and actions
    return (
      <div className={classes.container}>
        <FormHeader>{t('immunizations')}</FormHeader>
        <Switch>
          <Route
            path={`${path}/add`}
            component={AddImmunizations}
            isPrivate={false}
          />
          <Route
            path={`${path}/edit`}
            component={EditImmunization}
            isPrivate={false}
          />
          <Route
            isPrivate={false}
            component={() => (
              <>
                <div className={classes.immunizations}>
                  {flatImmunizations?.map((immunization, index) => {
                    // Pick Up here, need to change to health detail card obj
                    const detail = immunizationToDetail(immunization);
                    return (
                      <div key={index} className={classes.detailCard}>
                        <HealthDetailCard
                          detail={detail}
                          icon={SyringePurple}
                          menuOptions={immunizationMenuoptions(detail)}
                          secureImage
                        />
                      </div>
                    );
                  })}
                </div>

                {!isShareUser && (
                  <>
                    <div className={classes.actionButtons}>
                      {!immunizations.length && !dismissed && (
                        <ThemedButton
                          title={t('no_thing_to_add', {
                            thing: 'Immunizations',
                          })}
                          isInverted
                          onClick={() => {
                            dispatch(
                              dismissFhirSection({
                                section: DashboardSections.IMMUNIZATIONS,
                              })
                            );
                          }}
                        />
                      )}
                      <ThemedButton
                        title={t('add_immunization')}
                        isInverted
                        onClick={() => {
                          history.push(ROUTES.immunizationAdd);
                        }}
                      />
                    </div>
                    <div className={classes.editAnytime}>
                      <CardDetail2>{t('can_add_edit_all_caps')}</CardDetail2>
                    </div>
                  </>
                )}
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
};
