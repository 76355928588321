export type SubRoute = {
  path: string;
  exact: boolean;
  component: React.FC;
  textComponent?: React.FC;
  isPrivate?: boolean;
};

export enum InputType {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
}

export type CreateAccountData = {
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  phone?: string;
  initialData?: any;
};

export interface MenuOption {
  title: string;
  onClick: () => void;
  icon?: string;
}

export const defaultMenuOptions: MenuOption[] = [
  { title: 'Edit', onClick: () => alert('Edit Coming Soon!') },
  { title: 'Delete', onClick: () => alert('Delete Coming Soon!') },
];

export interface HealthDetail {
  id: string;
  title: string;
  subtitle?: string;
  detailItems?: string[][];
  imageLink?: string;
  sensitive?: boolean;
  current?: boolean;
}

export interface Medication extends HealthDetail {
  startDate: string;
  endDate: string;
  dosage: string;
  dosageForm: string;
  regimen: string;
}

export interface HealthCondition extends HealthDetail {
  onsetDate: string;
  resolutionDate: string;
}

export interface MedicalEvent extends HealthDetail {
  eventName: string;
  date: string;
}

export type EditAllergyForm = {
  allergyType: string;
  severity: string;
  courseOfAction: string;
  sensitive: boolean;
};
export type DosageDate = {
  order: number;
  date: string;
};

export interface Immunization extends HealthDetail {
  doseDates: DosageDate[];
}

export interface Insurance {
  id: string;
  frontImage: string;
  backImage: string;
  provider: string;
  type: string;
  subName: string;
  subNumber: string;
  groupNumber: string;
  payerId: string;
  portalURL: string;
  portalUsername: string;
  portalPassword: string;
  verificationPending: boolean;
  verified: boolean;
  active: boolean;
}

export type FamilyHealthCondition = {
  name: string;
  diagnosisDate: string;
  sensitive: boolean;
};

export interface FamilyMemberInfo extends HealthDetail {
  dob: string;
  deathDate: string;
  healthConditions: FamilyHealthCondition[];
  detailsAdded: boolean;
  memberDismissed: boolean;
}

export interface VaultDocument {
  id: string;
  name: string;
  uploadDate: string;
  tags: string[];
  docLink: string;
  sensitive: boolean;
  deathNote: boolean;
}

export enum VeteranStatusEnum {
  N = 'None',
  A = 'Active Duty',
  V = 'Veteran',
}

export enum RetireStatusEnum {
  N = 'No',
  Y = 'Yes',
}
