import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

const useStyles = createUseStyles({
  fieldSubtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.015em',
    color: Colors.idTextGray,
  },
});
export const FieldSubtitle: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.fieldSubtitle}>{children}</div>;
};
