import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  cancelOptionText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '0.015em',
  },
});
export const CancelOptionText: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cancelOptionText}>{children}</div>;
};
