import axios from 'axios';
import { store } from '../app/store';
import {
  APP_CONSTANTS,
  getLocalStorageItem,
  LocalStorageKeys,
} from '../config/constants';
import { setTimeoutHandler } from '../features/dashboard/utils';

export const ENDPOINTS = {
  getPost: '/files/post',
  getGet: id => `/files/get/${id}`,
  sharing: {
    viewShare: (name, id) => `/sharing/view/${name}/${id}`,
  },
  getUsernameExists: username => `/users/exists/${username}`,
  createDependent: `/info/dependent/add`,
  updateDependentDataPost: dependentId => `/info/dependent/${dependentId}`,
  verificationQuestionsPost: `/info/verificationId`,
  dependentDataGet: dependentId => `/info/dependent/${dependentId}`,
  getDependents: `/info/dependents`,
  lookupUsername: query => `/users/lookup/${query}`,
};

const select = state => {
  // Selects the access token if it exists
  return state.session.user?.accessToken;
};

type ConfiguredAxiosOptions = {
  isPublic?: boolean;
};

export const getConfiguredAxios = (opts?: ConfiguredAxiosOptions) => {
  const token = getLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN);
  const { isPublic = false } = opts || {};
  const client = axios.create({
    baseURL: APP_CONSTANTS.apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...(!isPublic && { Authorization: `Bearer ${token}` }),
    },
  });

  setTimeoutHandler(client);

  return client;
};

const api = getConfiguredAxios();

const listener = () => {
  //Subscribes to updates on the store
  let token = select(store.getState());
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization'];
  }
};

const initConfig = () => {
  axios.defaults.baseURL = APP_CONSTANTS.apiBaseUrl;
  const accessToken = getLocalStorageItem(LocalStorageKeys.SESSION_DATA);
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  store.subscribe(listener);
  listener();
};

//We need to call this to make sure it grabs the token on page refresh
initConfig();

export default api;
