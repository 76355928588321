import { Colors } from '../../../config/colors';
import { createUseStyles } from 'react-jss';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const useMedicationsStyles = createUseStyles({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '26px 0 0 78px',
      maxWidth: '70%',
    },
  },
  listView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
  },
  dates: {
    display: 'flex',
    gap: '20px',
  },
  dateContainer: {
    width: '50%',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.white,
    borderRadius: '6px',
  },
  subheader: {
    marginTop: '12px',
    textAlign: 'left',
  },
  currentMedsTitle: {
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  pastMedsTitle: {
    marginTop: '31px',
    color: Colors.secondaryText,
  },
  description: {
    margin: '12px 0 20px',
  },
  addMedContainer: {
    background: Colors.white,
    borderRadius: '6px',
    width: '43.8rem',
    height: '31.5rem',
    margin: '20px 0 22px',
    padding: '23px 24px 0 24px',
  },
  currentToggle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  dosage: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  dosageTextinput: {
    width: '50%',
  },
  dropdownContainer: {
    width: '50%',
  },
  sensitiveToggle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
  },
  sensitiveToggleTitle: {
    margin: '0 10px 0 10px',
  },
  buttonContainer: {
    marginBottom: '6.5rem',
    width: '21.2rem',
    height: '3.2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  editAnytime: {
    color: Colors.disabledGrey,
    marginTop: '18px',
  },
  cardList: {
    width: '100%',
    maxWidth: '600px',
  },
  detailCard: {
    display: 'flex',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    color: Colors.secondaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '21.2rem',
      height: '3.2rem',
    },
  },
});
