import CircularProgress from '@mui/material/CircularProgress';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getVaultDocURL } from '../../api/vaultUtils';
import { useAppSelector } from '../../app/hooks';
import {
  selectContractDetails,
  selectIsPublic,
} from '../../features/login/loginSlice';
import { SecureImageLoader } from '../SecureImageLoader/SecureImageLoader';

type props = {
  id: string;
  type: string;
  url?: string;
};

const useShowVaultStyles = createUseStyles({
  documentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: { minWidth: '10vw' },
  vaultImage: {
    width: '50%',
  },
});

const getPages = numPages => {
  const result = [];
  for (let i = 0; i < numPages; i++) {
    result.push(<Page pageIndex={i} />);
  }
  return result;
};

export const ShowVaultItem: React.FC<props> = ({ id, type, url }) => {
  const [pdfLink, setPdfLink] = useState('');
  const [numPages, setNumPages] = useState(null);
  const isImage = !!type?.includes('image');
  const isPublic = useAppSelector(selectIsPublic);
  const contractDetails = useAppSelector(selectContractDetails);
  const [isError, setIsError] = useState(false);

  let imageUrl = url;
  if (isImage && url.includes('/')) {
    // We can use the id instead
    imageUrl = id;
  }

  const onDocumentLoadSuccess = data => {
    const { numPages } = data;
    setNumPages(numPages);
  };

  useEffect(() => {
    const asyncFunction = async () => {
      const publicUrl = await getVaultDocURL(id, isPublic, {
        name: contractDetails?.name,
        contractId: contractDetails?.contractId,
      });
      if (!publicUrl) {
        setIsError(true);
      }
      setPdfLink(publicUrl);
    };
    asyncFunction();
  }, [id]);

  const classes = useShowVaultStyles();
  return (
    <div className="container">
      {isError ? (
        <div>{t('sorry_error')}</div>
      ) : isImage ? (
        <div className={classes.documentContainer}>
          <SecureImageLoader
            imageId={imageUrl}
            className={classes.vaultImage}
          />
        </div>
      ) : !pdfLink?.length ? (
        <CircularProgress />
      ) : (
        <div className={classes.documentContainer}>
          <Document
            file={pdfLink}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            {getPages(numPages)}
          </Document>
        </div>
      )}
    </div>
  );
};
