import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { Bandaids } from '../../../assets/images/icons';
import { HealthDetailCard, ThemedButton } from '../../../components';
import {
  FormBody,
  FormHeader,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import Route from '../../../routes/route';
import { DashboardSections } from '../DashboardSections';
import {
  DashboardData,
  deleteItem,
  dismissFhirSection,
  selectMedicalEvents,
} from '../dashboardSlice';
import { EditMedicalEvent } from './EditMedicalEvent';
import { useMedicalEventsStyles } from './styles';
import { flattenMedicalEvent } from './utils';

export const defaultMedicalEvents: DashboardData = {
  dismissed: false,
  notificationCount: 0,
  detailList: [
    {
      id: '0',
      title: 'Surgery',
      eventName: 'Appendectomy',
      date: '04/18',
      sensitive: false,
    },
    {
      id: '1',
      title: 'Major Injury',
      eventName: 'Leg fracture',
      date: '',
      sensitive: false,
    },
    {
      id: '2',
      title: 'Hospitalization',
      eventName: 'Heart attack',
      date: '05/21',
      sensitive: true,
    },
    {
      id: '3',
      title: 'Procedure/Scan',
      eventName: 'CT scan',
      date: '03/22',
      sensitive: false,
    },
  ],
};

type MedicalEventsProps = {
  details?: HealthDetail[];
  listOnly?: boolean;
};

export const MedicalEvents: React.FC<MedicalEventsProps> = ({ listOnly }) => {
  const classes = useMedicalEventsStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectMedicalEvents);
  const dismissed = !!detailList;
  const medicalEvents = detailList || [];
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const medicalEventsMenuOptions = detail => [
    {
      title: t('edit'),
      onClick: () =>
        history.push({
          pathname: `${path}/edit`,
          search: `?id=${detail.id}`,
        }),
    },
    {
      title: t('delete'),
      onClick: () =>
        dispatch(
          deleteItem({
            section: DashboardSections.MEDICAL_EVENTS,
            detailItem: detail,
          })
        ),
    },
  ];

  if (listOnly) {
    return (
      <div className={classes.detailCardsContainer}>
        {medicalEvents?.length ? (
          medicalEvents?.map((medicalEvent, index) => {
            const detail = flattenMedicalEvent(medicalEvent);
            return (
              <div className={classes.detailCard} key={index}>
                <HealthDetailCard detail={detail} icon={Bandaids} />
              </div>
            );
          })
        ) : (
          <FormBody>{t('nothing_to_share')}</FormBody>
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <FormHeader>{t('hospitalizations_title')}</FormHeader>
      <Switch>
        <Route
          path={`${path}/edit`}
          component={EditMedicalEvent}
          isPrivate={false}
        />
        <Route
          isPrivate={false}
          component={() => (
            <>
              <div className={classes.detailCardsContainer}>
                {medicalEvents?.map(medicalEvent => {
                  const detail = flattenMedicalEvent(medicalEvent);
                  return (
                    <div className={classes.detailCard}>
                      <HealthDetailCard
                        detail={detail}
                        icon={Bandaids}
                        menuOptions={medicalEventsMenuOptions(detail)}
                      />
                    </div>
                  );
                })}
              </div>
              {!isShareUser && !medicalEvents.length && (
                <div className={classes.actionButtons}>
                  {medicalEvents.length === 0 && !dismissed && (
                    <ThemedButton
                      title={t('no_thing_to_add', { thing: 'events' })}
                      isInverted
                      onClick={() => {
                        dispatch(
                          dismissFhirSection({
                            section: DashboardSections.MEDICAL_EVENTS,
                          })
                        );
                      }}
                    />
                  )}
                </div>
              )}
              {!isShareUser && (
                <div className={classes.actionButtons}>
                  <ThemedButton
                    title={t('add_event')}
                    isInverted
                    onClick={() => {
                      history.push(ROUTES.medicalEventsEdit);
                    }}
                  />
                </div>
              )}
            </>
          )}
        />
      </Switch>
      {!isShareUser && (
        <div className={classes.editAnytime}>
          <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
        </div>
      )}
    </div>
  );
};
