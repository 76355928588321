import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { AccountChecklist } from '../../../components/AccountChecklist/AccountChecklist';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translations';

const useConfirmInformationTextStyles = createUseStyles(styles);

const checklistItems = [
  {
    step: i18n.t('account_checklist_step1'),
    completed: true,
    highlighted: false,
  },
  {
    step: i18n.t('account_checklist_step2'),
    completed: true,
    highlighted: true,
  },
  {
    step: i18n.t('account_checklist_step3'),
    completed: false,
    highlighted: false,
  },
  {
    step: `${i18n.t('account_checklist_step4')}*`,
    completed: false,
    highlighted: false,
  },
  {
    step: `${i18n.t('account_checklist_step5')}*`,
    completed: false,
    highlighted: false,
  },
];

export const ConfirmInformationText = () => {
  const { t } = useTranslation();
  const classes = useConfirmInformationTextStyles();

  return (
    <div className={classes.message}>
      <div className={classes.title}>{t('getting_started')}</div>
      <ProgressBar progressWidth={11} />
      <div className={classes.textLeft}>
        <div className={classes.smallText}>{t('license_info_explanation')}</div>
        <div className={classes.checklist}>
          <AccountChecklist checklistItems={checklistItems} />
        </div>
        <div className={classes.asteriskNote}>
          *not required to create an account
        </div>
      </div>
    </div>
  );
};
