import { Switch } from 'react-router-dom';
import { ROUTES } from '../../../config/constants';
import Route from '../../../routes/route';
import { AddShare } from './AddShare';
import { ShareConfirmation } from './Confirmation';
import { NewShareConfirmation } from './NewShareConfirmation';
import { CreateEditShare } from './CreateEditShare';
import { ShareDetail } from './ShareDetail';
import { ShareExpired } from './ShareExpired';
import { ShareHome } from './ShareHome';
import { ShowCode } from './ShowCode';
import { indexStyles } from './styles';
import { ViewShareConfirmation } from './ViewSharedConfirmation';
import { QuickShareCode } from './QuickShareCode';

export const Share: React.FC = () => {
  const classes = indexStyles();
  return (
    <div className={classes.container}>
      <Switch>
        <Route path={ROUTES.newShare} isPrivate component={AddShare} />
        <Route
          path={ROUTES.createEditShare}
          isPrivate
          component={CreateEditShare}
        />
        <Route
          path={ROUTES.shareConfirmation}
          isPrivate
          component={ShareConfirmation}
        />
        <Route
          path={ROUTES.viewShareConfirmation}
          isPrivate
          component={ViewShareConfirmation}
        />
        <Route path={ROUTES.shareDetail} isPrivate component={ShareDetail} />
        <Route
          path={ROUTES.newShareConfirmation}
          isPrivate
          component={NewShareConfirmation}
        />
        <Route
          path={ROUTES.quickShareCode}
          isPrivate
          component={QuickShareCode}
        />
        <Route path={ROUTES.expired} isPrivate component={ShareExpired} />
        <Route path={ROUTES.showCode} isPrivate component={ShowCode} />
        <Route path={ROUTES.share} isPrivate component={ShareHome} />
      </Switch>
    </div>
  );
};
