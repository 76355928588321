import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ShareArtwork } from '../../../assets/images';
import { arrowLeft } from '../../../assets/images/icons';
import { ContentBox, ThemedButton } from '../../../components';
import {
  AboutMeTitle,
  FormHeader,
  FormSecondary,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import Route from '../../../routes/route';
import { selectCurrentUser, setSelectedUser } from '../../login/loginSlice';
import { DashboardSections } from '../DashboardSections';
import { selectSection } from '../dashboardSlice';
import { ConfirmDependentInfo } from './ConfirmDependentInfo';
import { CreateDependentHandle } from './CreateDependentHandle';
import { CreateDependentSuccess } from './CreateDependentSuccess';
import { useCreateDependentStyles } from './styles';
import { VerifyDependent } from './VerifyRelationship';

type props = {};

export const CreateDependent: React.FC<props> = () => {
  const classes = useCreateDependentStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isDependent } = useAppSelector(selectCurrentUser);
  const mainUser = useAppSelector(selectSection(DashboardSections.MAIN_USER));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isDependent) {
      dispatch(setSelectedUser({ index: 0, id: mainUser.id }));
    }
  }, [isDependent]);

  return (
    <div className={classes.container}>
      <Switch>
        <Route
          path={ROUTES.confirmDependent}
          isPrivate
          component={ConfirmDependentInfo}
        />
        <Route
          path={ROUTES.verifyDependent}
          isPrivate
          component={VerifyDependent}
        />
        <Route
          path={ROUTES.dependentHandle}
          isPrivate
          component={CreateDependentHandle}
        />
        <Route
          path={ROUTES.dependentSuccess}
          isPrivate
          component={CreateDependentSuccess}
        />
        <Route
          isPrivate
          component={() => (
            <>
              <div className={classes.titleSection}>
                <div
                  className={classes.backToAction}
                  onClick={() => history.push(ROUTES.dashboardHome)}
                >
                  <div className={classes.arrowContainer}>
                    <img src={arrowLeft} alt="arrowLeft" />
                  </div>
                  <AboutMeTitle>{t('back_to_data_constellation')}</AboutMeTitle>
                </div>
                <div className={classes.buttonsBox}>
                  <div
                    onClick={() => {
                      history.push(ROUTES.dashboardHome);
                    }}
                  >
                    <AboutMeTitle>{t('cancel')}</AboutMeTitle>
                  </div>
                </div>
              </div>
              <div className={classes.contentBox}>
                <ContentBox title={''} dropshadow>
                  <div className={classes.cardContent}>
                    <img
                      src={ShareArtwork}
                      alt=""
                      className={classes.starImage}
                    />
                    <div className={classes.header}>
                      <FormHeader>{t('create_a_star')}</FormHeader>
                    </div>
                    <div className={classes.bodyText}>
                      <FormSecondary>{t('create_dependent_1')}</FormSecondary>
                      <FormSecondary>{t('create_dependent_2')}</FormSecondary>
                      <FormSecondary>
                        <ul>
                          <li>
                            <b>{t('documentation')}</b>
                            <ul>
                              <li>{t('create_dependent_3')}</li>
                            </ul>
                          </li>
                          <li>
                            <b>{t('id_number')}</b>
                            <ul>
                              <li>{t('create_dependent_4')}</li>
                            </ul>
                          </li>
                        </ul>
                      </FormSecondary>
                    </div>
                    <div className={classes.continueButton}>
                      <ThemedButton
                        title={'Continue'}
                        onClick={() => history.push(ROUTES.confirmDependent)}
                      />
                    </div>
                  </div>
                </ContentBox>
              </div>
            </>
          )}
        />
      </Switch>
    </div>
  );
};
