import { Insurance } from '../../config/SharedTypes';
import { CardDetail1, CardHeader1, LinkText } from '../TextComponents';
import { useInsuranceCardStyles } from './styles';
import {
  greenCheck,
  yellowDots,
  orangeWarning,
  bcbsLogo,
  deltaDentalLogo,
  arrowRight,
  generalInsurance,
} from '../../assets/images/icons';
import { Colors } from '../../config/colors';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { insTypeToLabel } from '../../features/dashboard/health-insurance/utils';

interface InsuranceCardProps {
  detail: Insurance;
}

export const InsuranceCard: React.FC<InsuranceCardProps> = ({ detail }) => {
  const classes = useInsuranceCardStyles();
  const { provider, type, verificationPending, verified } = detail || {};
  const history = useHistory();
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  let providerLogo = generalInsurance;
  if (provider === 'Blue Cross Blue Shield') providerLogo = bcbsLogo;
  else if (provider === 'Delta Dental') providerLogo = deltaDentalLogo;
  // TODO: find better method to accommodate for more providers

  return (
    <>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logoImg}
            src={providerLogo}
            alt="providerLogo"
          />
        </div>
        <div className={classes.title}>
          <div className={classes.providerAndStatus}>
            <CardHeader1>{provider}</CardHeader1>
            {!verificationPending && !verified && (
              <div className={classes.verifiedStatus}>
                <img src={orangeWarning} alt="orangeWarning" />
                <div style={{ color: Colors.gradientLighterOrange }}>
                  <LinkText>{t('not_verified_all_caps')}</LinkText>
                </div>
              </div>
            )}
            {verificationPending && !verified && (
              <div className={classes.verifiedStatus}>
                <img src={yellowDots} alt="yellowDots" />
                <div style={{ color: Colors.gradientLighterYellow }}>
                  <LinkText>{t('pending_all_caps')}</LinkText>
                </div>
              </div>
            )}
            {!verificationPending && verified && (
              <div className={classes.verifiedStatus}>
                <img src={greenCheck} alt="greenCheck" />
                <div style={{ color: Colors.accent8 }}>
                  <LinkText>{t('verified_all_caps')}</LinkText>
                </div>
              </div>
            )}
          </div>
          <img
            src={arrowRight}
            alt="arrowRight"
            className={classes.arrowRight}
            onClick={() =>
              history.push({
                pathname: `${path}summary`,
                search: `?id=${detail.id}`,
              })
            }
          />
        </div>
        <CardDetail1>{insTypeToLabel(type)}</CardDetail1>
      </div>
    </>
  );
};
