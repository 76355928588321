import { useAccountChecklistStyles } from '../styles';
import uncheckedCircle from '../../assets/uncheckedCircle.svg';
import checked from '../../assets/checkedCircle.svg';
import darkerChecked from '../../assets/darkerCheckedCircle.svg';

interface AccountChecklistProps {
  checklistItems: Array<{
    step: string;
    completed: boolean;
    highlighted: boolean;
  }>;
}

export const AccountChecklist: React.FC<AccountChecklistProps> = ({
  checklistItems,
}) => {
  const classes = useAccountChecklistStyles();
  const getIcon = item => {
    if (item.completed) {
      if (item.highlighted) {
        return checked;
      } else {
        return darkerChecked;
      }
    } else {
      return uncheckedCircle;
    }
  };
  return (
    <div>
      {checklistItems.map(item => {
        return (
          <div className={classes.checklistLine}>
            <img width="18px" src={getIcon(item)} alt={item.step} />
            <div
              className={
                item.highlighted ? classes.highlighted : classes.notHighlighted
              }
            >
              {item.step}
            </div>
          </div>
        );
      })}
    </div>
  );
};
