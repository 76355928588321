import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  sectionHeader: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '-0.3px',
  },
});
export const SubHeader1: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.sectionHeader}>{children}</div>;
};
