import { Colors } from '../../config/colors';

export const styles = {
  container: {
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    width: '114px',
    height: '99px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '15px 0 15px 0',
    paddingTop: '15px',
    boxShadow: `0px 0px 6px 2px rgba(171, 171, 171, 0.25)`,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
    height: '100%',
    padingLeft: '20px',
  },
  notifContainer: {
    display: 'flex',
    paddingBottom: '25px',
    paddingLeft: '35px',
    position: 'absolute',
  },
  title: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    maxWidth: '243px',
  },
  header: {
    marginBottom: '6px',
  },
  notifications: {
    color: Colors.white,
    position: 'absolute',
    paddingLeft: '5.1px',
    paddingTop: '2px',
  },
};
