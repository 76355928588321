import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getNotifications } from './notificationsApi';

//Placeholder for now
export enum NotificationType {
  REMINDER = 'REMINDER',
  APPOINTMENT = 'APPOINTMENT',
  REQUEST = 'REQUEST',
}
export interface Notification {
  id: string; //Assuming every notification will have some uuid
  message: string;
  title?: string;
  type: NotificationType;
  dismissedUntil?: string; // Epoch string reflecting the time we want to wait untill show
  actionText?: string;
  path: string;
}

export interface NotificationState {
  notifications: Notification[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: NotificationState = {
  notifications: [
    {
      id: '0',
      message: 'You have one upcoming appointment',
      type: NotificationType.APPOINTMENT,
      path: '/',
    },
  ],
  status: 'idle',
};

export const refreshNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (user: string) => {
    const response = await getNotifications(user);
    return response.data;
  }
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotifications: state => {
      state.notifications = [];
    },
    dismissNotification: (state, action: PayloadAction<Notification>) => {
      const notifToDismiss = action.payload;
      const indexToupdate = state.notifications.findIndex(
        el => el.id === notifToDismiss.id
      );
      // Dismiss the notification for five seconds.
      state.notifications[indexToupdate].dismissedUntil = `${
        Date.now() + 5000
      }`;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(refreshNotifications.pending, state => {
        state.status = 'loading';
      })
      .addCase(refreshNotifications.fulfilled, (state, action) => {
        state.status = 'idle';
        state.notifications = action.payload;
      });
  },
});

export const { clearNotifications, dismissNotification } =
  notificationSlice.actions;

export const getAllNotifications = (state: RootState) =>
  state.notification.notifications;

export const getNotificationCount = (state: RootState) =>
  state.notification.notifications.length;

export const getCurrentNotifications = (state: RootState) =>
  state.notification.notifications.filter(notif => {
    const { dismissedUntil } = notif;
    if (!dismissedUntil) {
      // No dismissed date, include the notification
      return true;
    } else {
      const dismissedDate = Number(dismissedUntil);
      return dismissedDate < Date.now();
    }
  });

export default notificationSlice.reducer;
