import { createUseStyles } from 'react-jss';
import {
  ContentBox,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import { FormSecondary } from '../../../components/TextComponents';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ENDPOINTS, getConfiguredAxios } from '../../../api/axios';
import {
  LocalStorageKeys,
  ROUTES,
  setLocalStorageItem,
} from '../../../config/constants';
import { isValidCode } from '../../dashboard/share/utils';

const useVerifyCodeStyles = createUseStyles(styles);

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const VerifyCode = () => {
  const { t } = useTranslation();
  const classes = useVerifyCodeStyles();
  const history = useHistory();
  const queryCode = useQuery().get('code')?.toLowerCase();
  const codeLength = isValidCode(queryCode)
    ? queryCode.split('')
    : new Array(6).fill('');
  const [enteredCode, setEnteredCode] = useState([...codeLength]);
  const [hasApiError, setHasApiError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const axiosClient = getConfiguredAxios({ isPublic: true });

  const goToField = index => {
    if (index < codeLength.length && index >= 0) {
      document.getElementById(`code-field-${index}`).focus();
    }
    if (index === codeLength.length) {
      document.getElementById(`last-name-input`).focus();
    }
  };

  const onSubmit = async ({ lastName }) => {
    const codeString = enteredCode.join('');
    setHasApiError(false);

    await axiosClient
      .get(ENDPOINTS.sharing.viewShare(lastName, codeString))
      .then(response => {
        if (response?.data?.with) {
          setLocalStorageItem(LocalStorageKeys.SHARE_DATA, {
            ...response.data.with,
            contractDetails: { name: lastName, contractId: codeString },
          });
          history.push(ROUTES.shareViewPublic);
          return response.data.with;
        } else {
          //set error message
          setHasApiError(true);
        }
      })
      .catch(error => {
        //set error message
        console.error('Error with view share', error);
        setHasApiError(true);
      });
  };

  return (
    <div className={classes.cardContainer}>
      <ContentBox title="" slimTitle>
        <div className={classes.subTitle}>
          <FormSecondary>{t('verify_code_text')}</FormSecondary>
          {hasApiError && (
            <div className={classes.errorText}>
              <FormSecondary>{t('share_api_error')}</FormSecondary>
            </div>
          )}
        </div>
        <div className={classes.codeInputArea}>
          {codeLength.map((box, idx) => (
            <input
              type="text"
              maxLength={1}
              key={idx}
              id={`code-field-${idx}`}
              value={enteredCode[idx]}
              className={classes.digitInputBox}
              onChange={e => {
                let replaceAtIndex = [...enteredCode];
                replaceAtIndex[idx] = e.target.value;
                setEnteredCode([...replaceAtIndex]);
                if (e.target.value !== '') {
                  goToField(idx + 1);
                }
              }}
              onKeyDown={event => {
                if (!/[0-9a-z]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onKeyUp={event => {
                if (event.key === 'Backspace') {
                  goToField(idx - 1);
                }
              }}
              onFocus={event => {
                event.target.select();
              }}
            />
          ))}
        </div>
        <div className={classes.subTitle}>
          <FormSecondary>{t('enter_persons_name')}</FormSecondary>
        </div>
        <OneLineTextInput
          placeholder={t('persons_last_name')}
          id={'last-name-input'}
          formErrors={errors}
          {...register('lastName', { required: `${t('last_name_error')}` })}
        />
        <div className={classes.bottomButton}>
          <ThemedButton
            title={t('continue')}
            isDisabled={!isValidCode(enteredCode.join(''))}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </ContentBox>
    </div>
  );
};
