import { createUseStyles } from 'react-jss';

export const useImageUploadOnLinkStyles = createUseStyles({
  imageSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },
  imageUploadedOptions: {
    display: 'flex',
    gap: '30px',
  },
  imageView: {
    maxWidth: '200px',
  },
});
