import { AxiosResponse } from 'axios';

import { setCustomApiError } from '../config/utilities';
import i18n from '../translations';
import { Address, HumanName, Attachment, VecnaPatient } from '../typings';
import api, { ENDPOINTS, getConfiguredAxios } from './axios';

export type ShareFieldNames =
  | 'fhirData'
  | 'additionalData'
  | 'healthData.medications'
  | 'healthData.immunizations'
  | 'healthData.allergies'
  | 'healthData.conditions'
  | 'healthData.medicalEncounters'
  | 'insuranceData'
  | 'familyHistory'
  | 'vaultData';

export interface ShareFields {
  name: ShareFieldNames;
  ids?: string[];
  includeSensitive?: boolean;
}

export interface ShareParams {
  with: string;
  expiration: string;
  fields: ShareFields[];
}

interface ShareApiResponse {
  with: string; // Share code
}

export const share = (params: ShareParams) =>
  api
    .post('/sharing/share', params)
    .then((res: AxiosResponse<ShareApiResponse, any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export interface SharerData {
  email: string;
  enabled: boolean;
  id: string;
  username: string;
  info: {
    fhirData: {
      address?: { '0'?: Address };
      name?: { '0'?: HumanName };
      photo?: { '0'?: Attachment };
    };
  };
}

export interface SharedObject {
  contract: string;
  created: string;
  expires: string;
  fields: Array<ShareFields>;
  receiver: SharerData | string | null;
  sharer: SharerData | string | null;
  accepted: boolean | null;
  canceled: boolean | null;
  lastView: string;
  name: string;
}

interface SharedResponse {
  received: Array<SharedObject>;
  shared: Array<SharedObject>;
}

interface GetSharedApiResponse {
  with: SharedResponse;
}

// MY SHARED
export const getMyShared = (dependentId?: string) =>
  api
    .get(`/sharing/me${dependentId ? `/${dependentId}` : ''}`)
    .then((res: AxiosResponse<GetSharedApiResponse, any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export interface ViewSharedParams {
  lastName: string;
  contract: string;
}

export interface ViewSharedResponse {
  basicInfo: SharerData;
  created: string;
  expiration: string;
  fields: ShareFields[];
  info: VecnaPatient;
}

interface ViewSharedApiResponse {
  with: ViewSharedResponse;
}

// VIEW SHARED
export const viewShared = ({ lastName, contract }: ViewSharedParams) =>
  api
    .get(`/sharing/view/${lastName}/${contract}`)
    .then((res: AxiosResponse<ViewSharedApiResponse, any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

interface AcceptShareParams {
  lastName: string;
  contract: string;
  accept: boolean;
}

// ACCEPT/DENY SHARE
export const acceptShare = ({
  lastName,
  contract,
  accept,
}: AcceptShareParams) =>
  api
    .put(`/sharing/evaluate/${lastName}/${contract}?accept=${accept}`)
    .then(res => res)
    .catch(e => {
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

interface EditShareParams {
  lastName: string;
  contract: string;
  expiration: string;
  fields: ShareFields[];
  dependent: { isDependent: boolean; dependentId: string } | undefined;
}

// EDIT SHARE
export const editShare = ({
  lastName,
  contract,
  expiration,
  fields,
  dependent,
}: EditShareParams) =>
  api
    .put(
      `/sharing/edit/${lastName}/${contract}`,
      { expiration, fields },
      {
        params: dependent?.isDependent
          ? { dependentId: dependent?.dependentId }
          : {},
      }
    )
    .then(res => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

// SHARE HISTORY
export const shareHistory = ({ contract }: { contract: string }) =>
  api
    .get(`/sharing/history/${contract}`)
    .then((res: AxiosResponse<ViewSharedApiResponse, any>) => res)
    .catch(e => {
      const { with: status } = e.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

interface CancelShareWith {
  basicInfo: SharerData;
  created: string;
  expiration: string;
  fields: ShareFields[];
  info: VecnaPatient;
}

interface CancelShareResponse {
  with: CancelShareWith;
}

// CANCEL SHARE
export const cancelShare = ({
  contract,
  name,
}: {
  contract: string;
  name: string;
}) =>
  api
    .put(`/sharing/cancel/${name}/${contract}`)
    .then((res: AxiosResponse<CancelShareResponse, any>) => res)
    .catch(e => {
      const { with: status } = e.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export const searchForUser: (query: string) => Promise<any> = async query => {
  const axiosClient = getConfiguredAxios({ isPublic: false });
  const result = await axiosClient
    .get(ENDPOINTS.lookupUsername(query))
    .then(response => {
      if (response?.status === 200) {
        return response?.data?.with;
      } else return false;
    })
    .catch(error => {
      //set error message
      console.error('Error with lookup user', error);
      return false;
    });
  return result;
};
