import { useState } from 'react';
import { CopySimple, Eye } from '../../../assets/images/icons';
import { LinkComponent } from '../../../components';
import {
  HorizontalBreak,
  LinkText,
  TableHeader,
} from '../../../components/TextComponents';
import { useCredRowStyles } from './styles';

export type CredentialProps = {
  credential: { name: string; username: string; password: string };
};

export const CredentialRow: React.FC<CredentialProps> = ({ credential }) => {
  const classes = useCredRowStyles();
  const name =
    credential?.name?.length > 20
      ? `${credential.name.substring(0, 20)}...`
      : credential.name;
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <HorizontalBreak />
      <div className={classes.rowContainer}>
        <div className={classes.credColumn}>
          <div className={classes.rowTitle}>
            <TableHeader>NAME</TableHeader>
          </div>
          <div className={classes.credValue}>
            <LinkComponent
              label={name}
              onClick={() =>
                (window.location.href =
                  'https://members.hcsc.net/wps/portal/bam')
              }
            />
          </div>
        </div>
        <div className={classes.credColumn}>
          <div className={classes.rowTitle}>
            <TableHeader>USERNAME/EMAIL</TableHeader>
          </div>
          <div className={classes.credValue}>
            <LinkText>{credential.username}</LinkText>
            <img
              src={CopySimple}
              alt="Copy Username"
              height="15px"
              onClick={() => navigator.clipboard.writeText(credential.username)}
            />
          </div>
        </div>
        <div className={classes.credColumn}>
          <div className={classes.rowTitle}>
            <TableHeader>PASSWORD</TableHeader>
          </div>
          <div className={classes.credValue}>
            <LinkText>
              {showPass
                ? credential.password
                : '•'.repeat(credential.password.length + 2)}
            </LinkText>
            <img
              src={Eye}
              alt="Copy Username"
              height="15px"
              onClick={() => {
                setShowPass(!showPass);
              }}
            />
            <img
              src={CopySimple}
              alt="Copy Username"
              height="15px"
              onClick={() => navigator.clipboard.writeText(credential.password)}
            />
          </div>
        </div>
        <LinkComponent label="Edit" />
      </div>
      <HorizontalBreak />
    </>
  );
};
