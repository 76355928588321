import i18n from '../translations';

interface AppConstants {
  apiBaseUrl: string;
  tierName: string;
  s3PublicBucketUrl: string;
}

export const APP_CONSTANTS: AppConstants = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'someUrl',
  tierName: process.env.REACT_APP_ENVIRONMENT_NAME || 'undefined',
  s3PublicBucketUrl: process.env.REACT_APP_S3_PUBLIC_BUCKET || 'someUrl',
};

export const DESKTOP_VIEW_BREAKPOINT = '@media (min-width: 55em)';
export const TABLET_VIEW_BREAKPOINT = '@media (min-width: 35em)';

export enum ProfileDataFields {
  LEGAL_FIRST_NAME = 'LEGAL_FIRST_NAME',
  LEGAL_LAST_NAME = 'LEGAL_LAST_NAME',
  PREFERRED_NAME = 'PREFERRED_NAME',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  SSN = 'SSN',
  ADDRESS = 'ADDRESS',
  RACE = 'RACE',
  VETERAN_STATUS = 'VETERAN_STATUS',
  HOUSEHOLD_INCOME = 'HOUSEHOLD_INCOME',
  GENDER_IDENTITY = 'GENDER_IDENTITY',
  EMERGENCY_CONTACT = 'EMERGENCY_CONTACT',
}

export const ROUTES = {
  dashboardHome: '/dashboard',
  dashboard: '/dashboard',
  verifyIdentity: '/createAccount/verifyIdentity',
  accountSettings: '/dashboard/personalInfo/accountSettings',
  changePassword: '/dashboard/personalInfo/changePassword',
  conditionsDashboard: '/dashboard/personalInfo/healthConditions',
  conditionsEdit: '/dashboard/personalInfo/healthConditions/edit',
  conditionsAdd: '/dashboard/personalInfo/healthConditions/add',
  allergyDashboard: '/dashboard/personalInfo/allergies',
  allergyEdit: '/dashboard/personalInfo/allergies/edit',
  medicalEventsDashboard: '/dashboard/personalInfo/medicalEvents',
  medicalEventsEdit: '/dashboard/personalInfo/medicalEvents/edit',
  medicationsDashboard: '/dashboard/personalInfo/medications',
  medicationsEdit: '/dashboard/personalInfo/medications/edit',
  immunizationsDashboard: '/dashboard/personalInfo/immunizations',
  immunizationEdit: '/dashboard/personalInfo/immunizations/edit',
  immunizationAdd: '/dashboard/personalInfo/immunizations/add',
  familyHistoryDahboard: '/dashboard/personalInfo/familyHistory',
  familyHistoryEdit: '/dashboard/personalInfo/familyHistory/edit',
  insuranceDashboard: '/dashboard/personalInfo/healthInsurance',
  insuranceEdit: '/dashboard/personalInfo/healthInsurance/edit',
  insuranceAdd: '/dashboard/personalInfo/healthInsurance/add',
  insuranceScanCard: '/dashboard/personalInfo/healthInsurance/scanCard',
  vaultDocs: '/dashboard/vault/docs',
  addVaultDocs: '/dashboard/vault/uploadDoc',
  share: '/dashboard/share',
  newShare: '/dashboard/share/add',
  createEditShare: '/dashboard/share/createEditShare',
  showCode: '/dashboard/share/showCode',
  viewShareConfirmation: '/dashboard/share/viewShareConfirmation',
  newShareConfirmation: '/dashboard/share/newShareConfirmation',
  shareDetail: '/dashboard/share/detail',
  expired: '/dashboard/share/expired',
  shareConfirmation: '/dashboard/share/confirmation',
  shareEnterCode: '/guestAccess/verifyCode',
  shareViewPublic: '/guestAccess/viewShare',
  createDependent: '/dashboard/createDependent',
  confirmDependent: '/dashboard/createDependent/confirmInfo',
  verifyDependent: '/dashboard/createDependent/verify',
  dependentHandle: '/dashboard/createDependent/createHandle',
  dependentSuccess: '/dashboard/createDependent/success',
  quickShareCode: '/dashboard/share/quickShareCode',
  verifyEmail: '/createAccount/verifyEmail',
};

export enum LocalStorageKeys {
  DASHBOARD_DATA = 'DASHBOARD_DATA',
  SESSION_DATA = 'SESSION_DATA',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  SHARE_DATA = 'SHARE_DATA',
  VERIFY_DEPENDENT_DOC = 'VERIFY_DEPENDENT_DOC',
}

export const getLocalStorageItem = (key: LocalStorageKeys) => {
  const val = window.sessionStorage.getItem(key);
  if (val) {
    return JSON.parse(val);
  } else {
    return undefined;
  }
};
export const setLocalStorageItem = (key: LocalStorageKeys, val: any) => {
  const string = JSON.stringify(val);
  window.sessionStorage.setItem(key, string);
};

export const cleanup = () => {
  sessionStorage.clear();
  window.location.reload();
};

export const FORM_CONSTANTS = {
  allergies: {
    severity: [
      { value: 'low', label: 'Low' },
      { value: 'high', label: 'High' },
    ],
  },
};

export const relationshipList = [
  'Sibling',
  'Child',
  'Cousin',
  'Aunt',
  'Uncle',
  'Half-Sibling (Maternal)',
  'Half-Sibling (Paternal)',
  'Other',
];

export const sharedValidators = {
  zipCode: {
    pattern: {
      value: /^[0-9]{5}$/,
      message: i18n.t('enter_valid_zip_code'),
    },
  },
  phoneNumber: {
    pattern: {
      value: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      message: i18n.t('phone_error'),
    },
  },
  integer: {
    pattern: {
      value: /^[0-9]+$/,
      message: 'Value must be a whole number',
    },
  },
  longDate: {
    pattern: {
      value: /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/,
      message: 'Please enter a valid date mm/dd/yyyy',
    },
  },
  shortDate: {
    pattern: {
      value: /^(0[1-9]|1[012])[/](19|20)\d\d$/,
      message: 'Please enter a valid date mm/yyyy',
    },
  },
  last4SSN: {
    pattern: {
      value: /^[0-9]{4}$/,
      message: i18n.t('enter_valid_ssn'),
    },
  },
};

export const noSpaceInput = /[^\s]/g;

export const YESNO_RADIO_OPTS = [
  { value: 'Y', label: i18n.t('yes') },
  { value: 'N', label: i18n.t('no') },
];

export const sharedNameObject = {
  additionalData: { key: 'aboutMe', title: i18n.t('about_me') },
  insuranceData: { key: 'healthInsurance', title: i18n.t('health_insurance') },
  'healthData.immunizations': {
    key: 'immunizations',
    title: i18n.t('immunizations'),
  },
  'healthData.medications': {
    key: 'medications',
    title: i18n.t('medications'),
  },
  'healthData.allergies': { key: 'allergies', title: i18n.t('allergies') },
  'healthData.conditions': {
    key: 'conditions',
    title: i18n.t('health_conditions'),
  },
  'healthData.medicalEncounters': {
    key: 'hospitalizations',
    title: i18n.t('hospitalizations_title'),
  },
  familyHistory: { key: 'familyHistory', title: i18n.t('family_history') },
  vaultData: { key: 'vault', title: i18n.t('vault') },
};
