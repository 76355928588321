import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { CardBody, FormHeader } from '../../../components/TextComponents';
import { AboutMeTitle } from '../../../components/TextComponents/AboutMeTitle';
import { OneLineTextInput, ThemedButton } from '../../../components';
import arrowLeft from '../../../assets/arrowLeft.svg';
import { ROUTES } from '../../../config/constants';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

const useChangePasswordStyles = createUseStyles(styles);

export const ChangePassword: React.FC = () => {
  const classes = useChangePasswordStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = () => {
    // TODO: save new password in onClick
  };

  return (
    <div className={classes.contentBox}>
      <div
        className={classes.backToAction}
        onClick={() => history.push(ROUTES.accountSettings)}
      >
        <div className={classes.arrowContainer}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <AboutMeTitle>{t('back_to_details')}</AboutMeTitle>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.title}>
          <FormHeader>{t('change_password')}</FormHeader>
        </div>
        <OneLineTextInput
          placeholder={t('new_password')}
          type="password"
          {...register('password', {
            required: 'Please enter a password',
            validate: value =>
              (value.length > 8 &&
                /\d/.test(value) &&
                /[A-Z]/.test(value) &&
                /[a-z]/.test(value) &&
                /\W/.test(value)) ||
              'Please match password with password requirements',
          })}
          formErrors={errors}
        />
        <div className={classes.passwordRequirements}>
          <CardBody>{t('password_explanation')}</CardBody>
        </div>
        <OneLineTextInput
          placeholder={t('confirm_password')}
          type="password"
          {...register('confirmPassword', {
            required: 'Please confirm your password',
            validate: value =>
              value === password.current ||
              'Please confirm with matching password',
          })}
          formErrors={errors}
        />
        <div className={classes.buttonContainer}>
          <ThemedButton title={t('save')} onClick={() => {}} type="submit" />
        </div>
      </form>
    </div>
  );
};
