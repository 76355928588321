import { Classes } from 'jss';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { XGrey } from '../../assets/images/icons';
import { Colors } from '../../config/colors';
import { CardHeader } from '../TextComponents';

type TagProps = {
  title: string;
  color?: string;
  onChange?: any;
  onDelete?: any;
  additional?: boolean;
  isSelected?: boolean;
};

const useTagStyles: (
  data?: {
    clicked: boolean;
    color: string;
    theme: Jss.Theme;
  } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'tag' | 'deleteIcon'> = createUseStyles({
  tag: ({ clicked, color }) => ({
    padding: '8px 35px 9px',
    minWidth: '157px',
    position: 'relative',
    boxSizing: 'border-box',
    color: clicked ? Colors.white : color,
    background: clicked ? color : Colors.white,
    border: `2px solid ${color}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  deleteIcon: {
    position: 'absolute',
    right: '10px',
  },
});
export const Tag: React.FC<TagProps> = ({
  title,
  color = Colors.checkboxGray,
  additional,
  onChange = () => {},
  onDelete = () => {},
  isSelected,
}) => {
  const [clicked, setClicked] = useState(isSelected);
  const classes = useTagStyles({ clicked, color, theme: {} });
  return (
    <>
      <div
        className={classes.tag}
        onClick={() => {
          onChange(!clicked);
          setClicked(!clicked);
        }}
      >
        <CardHeader>{title}</CardHeader>
        {additional && (
          <img
            src={XGrey}
            alt=""
            className={classes.deleteIcon}
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          />
        )}
      </div>
    </>
  );
};
