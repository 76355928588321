import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useThreeDotMenuStyles = createUseStyles({
  button: {
    cursor: 'pointer',
    border: `none`,
    backgroundColor: Colors.clear,
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '3px',
    color: Colors.primaryText,
    paddingBottom: '8px',
  },
  popperOption: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px 14px',
    backgroundColor: Colors.white,
    color: Colors.primaryText,
    minHeight: '36px',
    minWidth: '100px',
    fontSize: '1rem',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: Colors.disabledGrey,
      color: Colors.white,
    },
  },
  icon: {
    height: '20px',
    width: '20px',
    marginRight: '10px',
  },
});
