export const vaccinationCodes = [
  {
    text: 'Adenovirus types 4 and 7',
    coding: [
      {
        code: '143',
        display: 'Adenovirus types 4 and 7',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'adenovirus, type 4',
    coding: [
      {
        code: '54',
        display: 'adenovirus, type 4',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'adenovirus, type 7',
    coding: [
      {
        code: '55',
        display: 'adenovirus, type 7',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'adenovirus, unspecified formulation',
    coding: [
      {
        code: '82',
        display: 'adenovirus, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'anthrax',
    coding: [
      {
        code: '24',
        display: 'anthrax',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'anthrax immune globulin',
    coding: [
      {
        code: '181',
        display: 'anthrax immune globulin',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'AS03 Adjuvant',
    coding: [
      {
        code: '801',
        display: 'AS03 Adjuvant',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'BCG',
    coding: [
      {
        code: '19',
        display: 'BCG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'botulinum antitoxin',
    coding: [
      {
        code: '27',
        display: 'botulinum antitoxin',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'cholera, BivWC',
    coding: [
      {
        code: '173',
        display: 'cholera, BivWC',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'cholera, live attenuated',
    coding: [
      {
        code: '174',
        display: 'cholera, live attenuated',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'cholera, unspecified formulation',
    coding: [
      {
        code: '26',
        display: 'cholera, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'cholera, WC-rBS',
    coding: [
      {
        code: '172',
        display: 'cholera, WC-rBS',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'CMVIG',
    coding: [
      {
        code: '29',
        display: 'CMVIG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'dengue fever',
    coding: [
      {
        code: '56',
        display: 'dengue fever',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'diphtheria antitoxin',
    coding: [
      {
        code: '12',
        display: 'diphtheria antitoxin',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DT (pediatric)',
    coding: [
      {
        code: '28',
        display: 'DT (pediatric)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP',
    coding: [
      {
        code: '20',
        display: 'DTaP',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP, 5 pertussis antigens',
    coding: [
      {
        code: '106',
        display: 'DTaP, 5 pertussis antigens',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP, unspecified formulation',
    coding: [
      {
        code: '107',
        display: 'DTaP, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP,IPV,Hib,HepB',
    coding: [
      {
        code: '146',
        display: 'DTaP,IPV,Hib,HepB',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP-Hep B-IPV',
    coding: [
      {
        code: '110',
        display: 'DTaP-Hep B-IPV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP-Hib',
    coding: [
      {
        code: '50',
        display: 'DTaP-Hib',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP-Hib-IPV',
    coding: [
      {
        code: '120',
        display: 'DTaP-Hib-IPV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP-IPV',
    coding: [
      {
        code: '130',
        display: 'DTaP-IPV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTaP-IPV-HIB-HEP B, historical',
    coding: [
      {
        code: '132',
        display: 'DTaP-IPV-HIB-HEP B, historical',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTAP/IPV/HIB - non-US',
    coding: [
      {
        code: '170',
        display: 'DTAP/IPV/HIB - non-US',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTP',
    coding: [
      {
        code: '01',
        display: 'DTP',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTP-Hib',
    coding: [
      {
        code: '22',
        display: 'DTP-Hib',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'DTP-Hib-Hep B',
    coding: [
      {
        code: '102',
        display: 'DTP-Hib-Hep B',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'hantavirus',
    coding: [
      {
        code: '57',
        display: 'hantavirus',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HBIG',
    coding: [
      {
        code: '30',
        display: 'HBIG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, adult',
    coding: [
      {
        code: '52',
        display: 'Hep A, adult',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, IG',
    coding: [
      {
        code: '154',
        display: 'Hep A, IG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, live attenuated',
    coding: [
      {
        code: '169',
        display: 'Hep A, live attenuated',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, ped/adol, 2 dose',
    coding: [
      {
        code: '83',
        display: 'Hep A, ped/adol, 2 dose',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, ped/adol, 3 dose',
    coding: [
      {
        code: '84',
        display: 'Hep A, ped/adol, 3 dose',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, pediatric, unspecified formulation',
    coding: [
      {
        code: '31',
        display: 'Hep A, pediatric, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A, unspecified formulation',
    coding: [
      {
        code: '85',
        display: 'Hep A, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A-Hep B',
    coding: [
      {
        code: '104',
        display: 'Hep A-Hep B',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep A-Hep B, pediatric/adolescent',
    coding: [
      {
        code: '193',
        display: 'Hep A-Hep B, pediatric/adolescent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep B, adolescent or pediatric',
    coding: [
      {
        code: '08',
        display: 'Hep B, adolescent or pediatric',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep B, adolescent/high risk infant',
    coding: [
      {
        code: '42',
        display: 'Hep B, adolescent/high risk infant',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep B, adult',
    coding: [
      {
        code: '43',
        display: 'Hep B, adult',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep B, dialysis',
    coding: [
      {
        code: '44',
        display: 'Hep B, dialysis',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep B, unspecified formulation',
    coding: [
      {
        code: '45',
        display: 'Hep B, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep C',
    coding: [
      {
        code: '58',
        display: 'Hep C',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hep E',
    coding: [
      {
        code: '59',
        display: 'Hep E',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HepB-CpG',
    coding: [
      {
        code: '189',
        display: 'HepB-CpG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'herpes simplex 2',
    coding: [
      {
        code: '60',
        display: 'herpes simplex 2',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib (HbOC)',
    coding: [
      {
        code: '47',
        display: 'Hib (HbOC)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib (PRP-D)',
    coding: [
      {
        code: '46',
        display: 'Hib (PRP-D)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib (PRP-OMP)',
    coding: [
      {
        code: '49',
        display: 'Hib (PRP-OMP)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib (PRP-T)',
    coding: [
      {
        code: '48',
        display: 'Hib (PRP-T)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib, unspecified formulation',
    coding: [
      {
        code: '17',
        display: 'Hib, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Hib-Hep B',
    coding: [
      {
        code: '51',
        display: 'Hib-Hep B',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HIV',
    coding: [
      {
        code: '61',
        display: 'HIV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HPV, bivalent',
    coding: [
      {
        code: '118',
        display: 'HPV, bivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HPV, quadrivalent',
    coding: [
      {
        code: '62',
        display: 'HPV, quadrivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HPV, unspecified formulation',
    coding: [
      {
        code: '137',
        display: 'HPV, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'HPV9',
    coding: [
      {
        code: '165',
        display: 'HPV9',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'IG',
    coding: [
      {
        code: '86',
        display: 'IG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'IG, unspecified formulation',
    coding: [
      {
        code: '14',
        display: 'IG, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'IGIV',
    coding: [
      {
        code: '87',
        display: 'IGIV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza A monovalent (H5N1), ADJUVANTED-2013',
    coding: [
      {
        code: '160',
        display: 'Influenza A monovalent (H5N1), ADJUVANTED-2013',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza nasal, unspecified formulation',
    coding: [
      {
        code: '151',
        display: 'influenza nasal, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, H5N1-1203',
    coding: [
      {
        code: '123',
        display: 'influenza, H5N1-1203',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, high dose seasonal',
    coding: [
      {
        code: '135',
        display: 'Influenza, high dose seasonal',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, injectable, MDCK, preservative free',
    coding: [
      {
        code: '153',
        display: 'Influenza, injectable, MDCK, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, injectable, MDCK, preservative free, quadrivalent',
    coding: [
      {
        code: '171',
        display: 'Influenza, injectable, MDCK, preservative free, quadrivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, injectable, MDCK, quadrivalent, preservative',
    coding: [
      {
        code: '186',
        display: 'Influenza, injectable, MDCK, quadrivalent, preservative',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, injectable, quadrivalent',
    coding: [
      {
        code: '158',
        display: 'influenza, injectable, quadrivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, injectable, quadrivalent, preservative free',
    coding: [
      {
        code: '150',
        display: 'influenza, injectable, quadrivalent, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, injectable,quadrivalent, preservative free, pediatric',
    coding: [
      {
        code: '161',
        display:
          'Influenza, injectable,quadrivalent, preservative free, pediatric',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, intradermal, quadrivalent, preservative free',
    coding: [
      {
        code: '166',
        display: 'influenza, intradermal, quadrivalent, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, live, intranasal',
    coding: [
      {
        code: '111',
        display: 'influenza, live, intranasal',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, live, intranasal, quadrivalent',
    coding: [
      {
        code: '149',
        display: 'influenza, live, intranasal, quadrivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, recombinant, injectable, preservative free',
    coding: [
      {
        code: '155',
        display: 'influenza, recombinant, injectable, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, recombinant, quadrivalent,injectable, preservative free',
    coding: [
      {
        code: '185',
        display:
          'influenza, recombinant, quadrivalent,injectable, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, seasonal, injectable',
    coding: [
      {
        code: '141',
        display: 'Influenza, seasonal, injectable',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Influenza, seasonal, injectable, preservative free',
    coding: [
      {
        code: '140',
        display: 'Influenza, seasonal, injectable, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, seasonal, intradermal, preservative free',
    coding: [
      {
        code: '144',
        display: 'influenza, seasonal, intradermal, preservative free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, split (incl. purified surface antigen)',
    coding: [
      {
        code: '15',
        display: 'influenza, split (incl. purified surface antigen)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, trivalent, adjuvanted',
    coding: [
      {
        code: '168',
        display: 'influenza, trivalent, adjuvanted',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, unspecified formulation',
    coding: [
      {
        code: '88',
        display: 'influenza, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'influenza, whole',
    coding: [
      {
        code: '16',
        display: 'influenza, whole',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'IPV',
    coding: [
      {
        code: '10',
        display: 'IPV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Japanese Encephalitis IM',
    coding: [
      {
        code: '134',
        display: 'Japanese Encephalitis IM',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Japanese encephalitis SC',
    coding: [
      {
        code: '39',
        display: 'Japanese encephalitis SC',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Japanese Encephalitis, unspecified formulation',
    coding: [
      {
        code: '129',
        display: 'Japanese Encephalitis, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Junin virus',
    coding: [
      {
        code: '63',
        display: 'Junin virus',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'leishmaniasis',
    coding: [
      {
        code: '64',
        display: 'leishmaniasis',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'leprosy',
    coding: [
      {
        code: '65',
        display: 'leprosy',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Lyme disease',
    coding: [
      {
        code: '66',
        display: 'Lyme disease',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'M/R',
    coding: [
      {
        code: '04',
        display: 'M/R',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'malaria',
    coding: [
      {
        code: '67',
        display: 'malaria',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'measles',
    coding: [
      {
        code: '05',
        display: 'measles',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'melanoma',
    coding: [
      {
        code: '68',
        display: 'melanoma',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal A polysaccharide (non-US)',
    coding: [
      {
        code: '191',
        display: 'meningococcal A polysaccharide (non-US)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal AC polysaccharide (non-US)',
    coding: [
      {
        code: '192',
        display: 'meningococcal AC polysaccharide (non-US)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal ACWY, unspecified formulation',
    coding: [
      {
        code: '108',
        display: 'meningococcal ACWY, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal B, OMV',
    coding: [
      {
        code: '163',
        display: 'meningococcal B, OMV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal B, recombinant',
    coding: [
      {
        code: '162',
        display: 'meningococcal B, recombinant',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal B, unspecified',
    coding: [
      {
        code: '164',
        display: 'meningococcal B, unspecified',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal C conjugate',
    coding: [
      {
        code: '103',
        display: 'meningococcal C conjugate',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Meningococcal C/Y-HIB PRP',
    coding: [
      {
        code: '148',
        display: 'Meningococcal C/Y-HIB PRP',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal MCV4, unspecified formulation',
    coding: [
      {
        code: '147',
        display: 'meningococcal MCV4, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Meningococcal MCV4O',
    coding: [
      {
        code: '136',
        display: 'Meningococcal MCV4O',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal MCV4P',
    coding: [
      {
        code: '114',
        display: 'meningococcal MCV4P',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal MPSV4',
    coding: [
      {
        code: '32',
        display: 'meningococcal MPSV4',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'meningococcal, unknown serogroups',
    coding: [
      {
        code: '167',
        display: 'meningococcal, unknown serogroups',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'MMR',
    coding: [
      {
        code: '03',
        display: 'MMR',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'MMRV',
    coding: [
      {
        code: '94',
        display: 'MMRV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'mumps',
    coding: [
      {
        code: '07',
        display: 'mumps',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Novel influenza-H1N1-09',
    coding: [
      {
        code: '127',
        display: 'Novel influenza-H1N1-09',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Novel Influenza-H1N1-09, all formulations',
    coding: [
      {
        code: '128',
        display: 'Novel Influenza-H1N1-09, all formulations',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Novel Influenza-H1N1-09, nasal',
    coding: [
      {
        code: '125',
        display: 'Novel Influenza-H1N1-09, nasal',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Novel influenza-H1N1-09, preservative-free',
    coding: [
      {
        code: '126',
        display: 'Novel influenza-H1N1-09, preservative-free',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'OPV',
    coding: [
      {
        code: '02',
        display: 'OPV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'OPV ,monovalent, unspecified',
    coding: [
      {
        code: '179',
        display: 'OPV ,monovalent, unspecified',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'OPV bivalent',
    coding: [
      {
        code: '178',
        display: 'OPV bivalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'OPV, Unspecified',
    coding: [
      {
        code: '182',
        display: 'OPV, Unspecified',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'parainfluenza-3',
    coding: [
      {
        code: '69',
        display: 'parainfluenza-3',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'PCV10',
    coding: [
      {
        code: '177',
        display: 'PCV10',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'pertussis',
    coding: [
      {
        code: '11',
        display: 'pertussis',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'plague',
    coding: [
      {
        code: '23',
        display: 'plague',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Pneumococcal conjugate PCV 13',
    coding: [
      {
        code: '133',
        display: 'Pneumococcal conjugate PCV 13',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'pneumococcal conjugate PCV 7',
    coding: [
      {
        code: '100',
        display: 'pneumococcal conjugate PCV 7',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Pneumococcal Conjugate, unspecified formulation',
    coding: [
      {
        code: '152',
        display: 'Pneumococcal Conjugate, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'pneumococcal polysaccharide PPV23',
    coding: [
      {
        code: '33',
        display: 'pneumococcal polysaccharide PPV23',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'pneumococcal, unspecified formulation',
    coding: [
      {
        code: '109',
        display: 'pneumococcal, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'polio, unspecified formulation',
    coding: [
      {
        code: '89',
        display: 'polio, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Q fever',
    coding: [
      {
        code: '70',
        display: 'Q fever',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rabies - IM Diploid cell culture',
    coding: [
      {
        code: '175',
        display: 'Rabies - IM Diploid cell culture',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rabies - IM fibroblast culture',
    coding: [
      {
        code: '176',
        display: 'Rabies - IM fibroblast culture',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rabies, intradermal injection',
    coding: [
      {
        code: '40',
        display: 'rabies, intradermal injection',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rabies, intramuscular injection',
    coding: [
      {
        code: '18',
        display: 'rabies, intramuscular injection',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rabies, unspecified formulation',
    coding: [
      {
        code: '90',
        display: 'rabies, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rheumatic fever',
    coding: [
      {
        code: '72',
        display: 'rheumatic fever',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rho(D) - Unspecified formulation',
    coding: [
      {
        code: '159',
        display: 'Rho(D) - Unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rho(D) -IG IM',
    coding: [
      {
        code: '157',
        display: 'Rho(D) -IG IM',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rho(D)-IG',
    coding: [
      {
        code: '156',
        display: 'Rho(D)-IG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Rift Valley fever',
    coding: [
      {
        code: '73',
        display: 'Rift Valley fever',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'RIG',
    coding: [
      {
        code: '34',
        display: 'RIG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rotavirus, monovalent',
    coding: [
      {
        code: '119',
        display: 'rotavirus, monovalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rotavirus, pentavalent',
    coding: [
      {
        code: '116',
        display: 'rotavirus, pentavalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rotavirus, tetravalent',
    coding: [
      {
        code: '74',
        display: 'rotavirus, tetravalent',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rotavirus, unspecified formulation',
    coding: [
      {
        code: '122',
        display: 'rotavirus, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'RSV-IGIV',
    coding: [
      {
        code: '71',
        display: 'RSV-IGIV',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'RSV-MAb',
    coding: [
      {
        code: '93',
        display: 'RSV-MAb',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'RSV-MAb (new)',
    coding: [
      {
        code: '145',
        display: 'RSV-MAb (new)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rubella',
    coding: [
      {
        code: '06',
        display: 'rubella',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'rubella/mumps',
    coding: [
      {
        code: '38',
        display: 'rubella/mumps',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Staphylococcus bacterio lysate',
    coding: [
      {
        code: '76',
        display: 'Staphylococcus bacterio lysate',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Td (adult)',
    coding: [
      {
        code: '138',
        display: 'Td (adult)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Td (adult), 2 Lf tetanus toxoid, preservative free, adsorbed',
    coding: [
      {
        code: '09',
        display: 'Td (adult), 2 Lf tetanus toxoid, preservative free, adsorbed',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Td (adult), 5 Lf tetanus toxoid, preservative free, adsorbed',
    coding: [
      {
        code: '113',
        display: 'Td (adult), 5 Lf tetanus toxoid, preservative free, adsorbed',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Td(adult) unspecified formulation',
    coding: [
      {
        code: '139',
        display: 'Td(adult) unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Tdap',
    coding: [
      {
        code: '115',
        display: 'Tdap',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tetanus immune globulin',
    coding: [
      {
        code: '180',
        display: 'tetanus immune globulin',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tetanus toxoid, adsorbed',
    coding: [
      {
        code: '35',
        display: 'tetanus toxoid, adsorbed',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tetanus toxoid, not adsorbed',
    coding: [
      {
        code: '142',
        display: 'tetanus toxoid, not adsorbed',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tetanus toxoid, unspecified formulation',
    coding: [
      {
        code: '112',
        display: 'tetanus toxoid, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tick-borne encephalitis',
    coding: [
      {
        code: '77',
        display: 'tick-borne encephalitis',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'TIG',
    coding: [
      {
        code: '13',
        display: 'TIG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'TST, unspecified formulation',
    coding: [
      {
        code: '98',
        display: 'TST, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'TST-OT tine test',
    coding: [
      {
        code: '95',
        display: 'TST-OT tine test',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'TST-PPD intradermal',
    coding: [
      {
        code: '96',
        display: 'TST-PPD intradermal',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'TST-PPD tine test',
    coding: [
      {
        code: '97',
        display: 'TST-PPD tine test',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'tularemia vaccine',
    coding: [
      {
        code: '78',
        display: 'tularemia vaccine',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Typhoid conjugate vaccine (TCV)',
    coding: [
      {
        code: '190',
        display: 'Typhoid conjugate vaccine (TCV)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhoid, oral',
    coding: [
      {
        code: '25',
        display: 'typhoid, oral',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhoid, parenteral',
    coding: [
      {
        code: '41',
        display: 'typhoid, parenteral',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhoid, parenteral, AKD (U.S. military)',
    coding: [
      {
        code: '53',
        display: 'typhoid, parenteral, AKD (U.S. military)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhoid, unspecified formulation',
    coding: [
      {
        code: '91',
        display: 'typhoid, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhoid, ViCPs',
    coding: [
      {
        code: '101',
        display: 'typhoid, ViCPs',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'typhus, historical',
    coding: [
      {
        code: '131',
        display: 'typhus, historical',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'vaccinia (smallpox)',
    coding: [
      {
        code: '75',
        display: 'vaccinia (smallpox)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'vaccinia (smallpox) diluted',
    coding: [
      {
        code: '105',
        display: 'vaccinia (smallpox) diluted',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'vaccinia immune globulin',
    coding: [
      {
        code: '79',
        display: 'vaccinia immune globulin',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'varicella',
    coding: [
      {
        code: '21',
        display: 'varicella',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'VEE, inactivated',
    coding: [
      {
        code: '81',
        display: 'VEE, inactivated',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'VEE, live',
    coding: [
      {
        code: '80',
        display: 'VEE, live',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'VEE, unspecified formulation',
    coding: [
      {
        code: '92',
        display: 'VEE, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'VZIG',
    coding: [
      {
        code: '36',
        display: 'VZIG',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'VZIG (IND)',
    coding: [
      {
        code: '117',
        display: 'VZIG (IND)',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'yellow fever',
    coding: [
      {
        code: '37',
        display: 'yellow fever',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Yellow fever vaccine - alt',
    coding: [
      {
        code: '183',
        display: 'Yellow fever vaccine - alt',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Yellow fever, unspecified formulation',
    coding: [
      {
        code: '184',
        display: 'Yellow fever, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'zoster live',
    coding: [
      {
        code: '121',
        display: 'zoster live',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'zoster recombinant',
    coding: [
      {
        code: '187',
        display: 'zoster recombinant',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'zoster, unspecified formulation',
    coding: [
      {
        code: '188',
        display: 'zoster, unspecified formulation',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'no vaccine administered',
    coding: [
      {
        code: '998',
        display: 'no vaccine administered',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'RESERVED - do not use',
    coding: [
      {
        code: '99',
        display: 'RESERVED - do not use',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'unknown',
    coding: [
      {
        code: '999',
        display: 'unknown',
        version: '4.0.1',
        system: 'http://hl7.org/fhir/sid/cvx',
      },
    ],
  },
  {
    text: 'Agrippal',
    coding: [
      {
        code: 'AGRPAL',
        display: 'Agrippal',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Avaxim',
    coding: [
      {
        code: 'AVAXM',
        display: 'Avaxim',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'BCG',
    coding: [
      {
        code: 'BCG',
        display: 'BCG',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'CDT',
    coding: [
      {
        code: 'CDT',
        display: 'CDT',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'COMVAX',
    coding: [
      {
        code: 'CMX',
        display: 'COMVAX',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Triple Antigen',
    coding: [
      {
        code: 'DTP',
        display: 'Triple Antigen',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'DTPa',
    coding: [
      {
        code: 'DTPA',
        display: 'DTPa',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Engerix B',
    coding: [
      {
        code: 'ENGP',
        display: 'Engerix B',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Fluarix',
    coding: [
      {
        code: 'FLRIX',
        display: 'Fluarix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Fluvax',
    coding: [
      {
        code: 'FLUVAX',
        display: 'Fluvax',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Fluvirin',
    coding: [
      {
        code: 'FLVRN',
        display: 'Fluvirin',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Fluvax Junior',
    coding: [
      {
        code: 'FVXJNR',
        display: 'Fluvax Junior',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Diphtheria',
    coding: [
      {
        code: 'GNDIP',
        display: 'Diphtheria',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Influenza',
    coding: [
      {
        code: 'GNFLU',
        display: 'Influenza',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Hepatitis B',
    coding: [
      {
        code: 'GNHEP',
        display: 'Hepatitis B',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'HIB',
    coding: [
      {
        code: 'GNHIB',
        display: 'HIB',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Hepatitis A',
    coding: [
      {
        code: 'GNHPA',
        display: 'Hepatitis A',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Japanese Encephalitis',
    coding: [
      {
        code: 'GNJEN',
        display: 'Japanese Encephalitis',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Measles',
    coding: [
      {
        code: 'GNMEA',
        display: 'Measles',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Meningococcal C',
    coding: [
      {
        code: 'GNMEN',
        display: 'Meningococcal C',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Mumps',
    coding: [
      {
        code: 'GNMUM',
        display: 'Mumps',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Pneumococcal',
    coding: [
      {
        code: 'GNPNE',
        display: 'Pneumococcal',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Polio',
    coding: [
      {
        code: 'GNPOL',
        display: 'Polio',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Rotavirus',
    coding: [
      {
        code: 'GNROX',
        display: 'Rotavirus',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Rubella',
    coding: [
      {
        code: 'GNRUB',
        display: 'Rubella',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Tetanus',
    coding: [
      {
        code: 'GNTET',
        display: 'Tetanus',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Varicella',
    coding: [
      {
        code: 'GNVAR',
        display: 'Varicella',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Twinrix Junior',
    coding: [
      {
        code: 'HATWNJ',
        display: 'Twinrix Junior',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Vaqta Paed/Adol',
    coding: [
      {
        code: 'HAVAQ',
        display: 'Vaqta Paed/Adol',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Havrix Junior',
    coding: [
      {
        code: 'HAVJ',
        display: 'Havrix Junior',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'HibTITER',
    coding: [
      {
        code: 'HBOC',
        display: 'HibTITER',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'HBV',
    coding: [
      {
        code: 'HBV',
        display: 'HBV',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'HBVAX II',
    coding: [
      {
        code: 'HBVP',
        display: 'HBVAX II',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Hiberix',
    coding: [
      {
        code: 'HBX',
        display: 'Hiberix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Infanrix Hexa',
    coding: [
      {
        code: 'IFHX',
        display: 'Infanrix Hexa',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Infanrix-IPV',
    coding: [
      {
        code: 'IFIP',
        display: 'Infanrix-IPV',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Infanrix Penta',
    coding: [
      {
        code: 'IFPA',
        display: 'Infanrix Penta',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Infanrix',
    coding: [
      {
        code: 'IFX',
        display: 'Infanrix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'InfanrixHepB',
    coding: [
      {
        code: 'IFXB',
        display: 'InfanrixHepB',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Influvac',
    coding: [
      {
        code: 'INFLUV',
        display: 'Influvac',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'IPOL',
    coding: [
      {
        code: 'IPV',
        display: 'IPOL',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'JE-VAX',
    coding: [
      {
        code: 'JEVAX',
        display: 'JE-VAX',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Menjugate',
    coding: [
      {
        code: 'MENJUG',
        display: 'Menjugate',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Meningitec',
    coding: [
      {
        code: 'MENTEC',
        display: 'Meningitec',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Menomune',
    coding: [
      {
        code: 'MENUME',
        display: 'Menomune',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Mencevax ACWY',
    coding: [
      {
        code: 'MENVAX',
        display: 'Mencevax ACWY',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'MMR',
    coding: [
      {
        code: 'MMR',
        display: 'MMR',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'MMR II',
    coding: [
      {
        code: 'MMRCSL',
        display: 'MMR II',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Priorix',
    coding: [
      {
        code: 'MMRSKB',
        display: 'Priorix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Menitorix',
    coding: [
      {
        code: 'MNTRX',
        display: 'Menitorix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'NeisVac-C',
    coding: [
      {
        code: 'NEISVC',
        display: 'NeisVac-C',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Polio',
    coding: [
      {
        code: 'OPV',
        display: 'Polio',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Pertussis',
    coding: [
      {
        code: 'P',
        display: 'Pertussis',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Panvax',
    coding: [
      {
        code: 'PANVAX',
        display: 'Panvax',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Pediacel',
    coding: [
      {
        code: 'PDCL',
        display: 'Pediacel',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Poliacel',
    coding: [
      {
        code: 'PLCL',
        display: 'Poliacel',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Pneumovax',
    coding: [
      {
        code: 'PNEUMO',
        display: 'Pneumovax',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'ProHIBit',
    coding: [
      {
        code: 'PRPD',
        display: 'ProHIBit',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'ProQuad',
    coding: [
      {
        code: 'PROQAD',
        display: 'ProQuad',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'PedvaxHIB',
    coding: [
      {
        code: 'PRPOMP',
        display: 'PedvaxHIB',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'ActHIB',
    coding: [
      {
        code: 'PRPT',
        display: 'ActHIB',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Prevenar 7',
    coding: [
      {
        code: 'PRVNR',
        display: 'Prevenar 7',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Prevenar 13',
    coding: [
      {
        code: 'PRVTH',
        display: 'Prevenar 13',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Priorix-Tetra',
    coding: [
      {
        code: 'PRXTEX',
        display: 'Priorix-Tetra',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Quadracel',
    coding: [
      {
        code: 'QDCL',
        display: 'Quadracel',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Rotarix',
    coding: [
      {
        code: 'ROTRIX',
        display: 'Rotarix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Rotateq',
    coding: [
      {
        code: 'ROTTEQ',
        display: 'Rotateq',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Synflorix',
    coding: [
      {
        code: 'SYNFLX',
        display: 'Synflorix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Tripacel',
    coding: [
      {
        code: 'TCL',
        display: 'Tripacel',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Vaxigrip',
    coding: [
      {
        code: 'VAXGRP',
        display: 'Vaxigrip',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Vaxigrip Junior',
    coding: [
      {
        code: 'VGRJNR',
        display: 'Vaxigrip Junior',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Varilrix',
    coding: [
      {
        code: 'VLRIX',
        display: 'Varilrix',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Varivax',
    coding: [
      {
        code: 'VRVAX',
        display: 'Varivax',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
  {
    text: 'Other...',
    coding: [
      {
        code: 'OTHER',
        display: 'Other...',
        version: '4.0.1',
        system: 'urn:oid:1.2.36.1.2001.1005.17',
      },
    ],
  },
];
