import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useTextAreaStyles: (
  data?: { showPlaceholder: boolean; isError: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'container'
  | 'textInputField'
  | 'label'
  | 'translate'
  | 'errorMessage'
  | 'root'
> = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: ({ isError }) => ({
    position: 'relative',
    color: Colors.primaryText,
    height: '138px',
    borderRadius: '5px',
    border: `1px solid ${isError ? Colors.errorRed : Colors.textInputBorder}`,
    '&:hover': {
      borderColor: isError ? Colors.errorRed : Colors.buttonPurple,
    },
    width: '100%',
    margin: '7px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '&:focus-within': {
      border: `2px solid ${isError ? Colors.errorRed : Colors.linkPurple}`,
      height: '137px',
    },
  }),
  textInputField: ({ showPlaceholder, isError }) => ({
    border: 'none',
    outline: 'none',
    width: '100%',
    fontSize: '16px',
    resize: 'none',
    height: '112px',
    fontFamily: 'Inter',
    color: isError ? Colors.errorRed : Colors.primaryText,
    backgroundColor: Colors.clear,
    lineHeight: '18px',
    paddingLeft: '19px',
    marginBottom: showPlaceholder ? '-12px' : 0,
    marginTop: showPlaceholder ? '20px' : '4px',
    '&:focus': {
      paddingLeft: '18px',
      marginTop: '20px',
    },
    '&::placeholder': {
      color: isError ? Colors.errorRed : Colors.primaryText,
      fontSize: '16px',
      fontWeight: 400,
      opacity: showPlaceholder ? '0' : '1',
    },
  }),
  label: ({ isError }) => ({
    position: 'absolute',
    color: isError ? Colors.errorRed : Colors.primaryText,
    top: '19px',
    fontSize: '12px',
    left: '19px',
    opacity: '0',
    pointerEvents: 'none',
  }),
  translate: ({ isError }) => ({
    transition: 'all 150ms ease-out',
    transform: 'translateY(-16px)',
    opacity: '1',
    fontSize: '12px',
    left: '18',
    top: '16',
    color: isError ? Colors.errorRed : Colors.primaryText,
  }),
  errorMessage: {
    fontSize: '12px',
    color: Colors.errorRed,
  },
});
