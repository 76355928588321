import React, { useEffect, useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import './styles.css';

type SwitchProps = {
  name?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  label?: any;
  defaultValue?: boolean;
  disabled?: boolean;
};

export const Switch: React.FC<SwitchProps> = React.forwardRef<
  HTMLInputElement,
  SwitchProps
>(({ defaultValue = false, onChange, onBlur, name, disabled = false }, ref) => {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setChecked(!disabled && !checked);
  };

  return (
    <label className="switch">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="slider round" onClick={handleClick} />
    </label>
  );
});
