import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

const useStyles = createUseStyles({
  sectionTitle: {
    color: Colors.contentTitleBlue,
    fontWeight: '800',
    fontSize: '1.3rem',
    textAlign: 'left',
    marginBottom: '16px',
  },
});
export const SectionTitle: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.sectionTitle}>{children}</div>;
};
