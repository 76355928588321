import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Info, LockDarkPurple } from '../../assets/images/icons';
import { Switch } from '../Switch/Switch';
import { CardDetail1 } from '../TextComponents';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Colors } from '../../config/colors';

export type MarkSensitiveProps = {
  register?: any;
  defaultValue?: boolean;
};

const useSensitiveStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '17px',
  },
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const MarkSensitive: React.FC<MarkSensitiveProps> = ({
  register = () => {},
  defaultValue,
}) => {
  const classes = useSensitiveStyles();
  const { t } = useTranslation();

  return (
    <div
      className={classes.container}
      style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
    >
      <CardDetail1>
        <img
          src={LockDarkPurple}
          alt="sensitive"
          width="19"
          style={{ paddingRight: '10px' }}
        />
        {t('mark_as_sensitive')}
      </CardDetail1>
      <div className="infoIcon">
        <HtmlTooltip title={<>{t('sensitive_info_icon')}</>}>
          <img src={Info} alt="info" />
        </HtmlTooltip>
      </div>
      <Switch {...register} defaultValue={defaultValue} />
    </div>
  );
};
