import { useState } from 'react';
import { RadioList, OneLineTextInput, ThemedButton } from '../../../components';
import { FormPrompt } from '../../../components/TextComponents/FormPrompt';
import { VeteranStatusEnum } from '../../../config/SharedTypes';
import i18n from '../../../translations';

type VetStatusProps = {
  classes: any;
  defaultValue: { value?: any; identifier?: any };
  register: any;
  onSave: any;
  setValue: any;
};
export const VeteranStatus: React.FC<VetStatusProps> = ({
  classes,
  defaultValue,
  register,
  onSave,
  setValue,
}) => {
  const { value, identifier } = defaultValue;
  const [selected, setSelected] = useState(VeteranStatusEnum[value]);
  return (
    <div className={classes.formContainer}>
      <div className={classes.prompt}>
        <FormPrompt>{i18n.t('veteran_prompt')}</FormPrompt>
      </div>
      <div className={classes.inputContainer}>
        <RadioList
          title=""
          defaultValue={value}
          optionsArr={[
            { value: 'N', label: i18n.t('none') },
            { value: 'A', label: i18n.t('active_duty') },
            { value: 'V', label: i18n.t('veteran') },
          ]}
          onChange={e => {
            setSelected(e.target.value || '');
            setValue('veteranStatus', e.target.value);
          }}
          // TODO: add function call to record option chosen
          isHorizontal
        />
        {!selected?.includes('N') && (
          <OneLineTextInput
            defaultValue={identifier}
            placeholder={i18n.t('military_id')}
            type="text"
            {...register('veteranId', {})}
          />
        )}
      </div>
      <div className={classes.buttonContainer}>
        <ThemedButton
          title={i18n.t('save')}
          onClick={() => {
            onSave();
          }}
          // TODO: add info saving func in onClick after info is set to local
        />
      </div>
    </div>
  );
};
