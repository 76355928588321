import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const HealthInsuranceShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.INSURANCES)
  );
  const optionsList: ShareDetailLine[] = detailList?.map(item => {
    return {
      label: item?.fhirData?.payor[0]?.display || '',
      sensitive: false,
      id: '',
    };
  });
  return (
    <ShareSectionSelector
      title={t('health_insurance')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
