import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { Colors } from '../../config/colors';

export const useAboutMeInfoStyles: (
  data?: { isInEditMode: boolean; isEditing: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'container'
  | 'firstRow'
  | 'redDot'
  | 'title'
  | 'info'
  | 'canEdit'
  | 'titleAndEdit'
  | 'line'
  | 'formContainer'
  | 'prompt'
  | 'inputContainer'
  | 'buttonContainer'
> = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
  },
  redDot: {
    width: '11px',
    height: '11px',
    marginRight: '14px',
  },
  title: ({ isInEditMode, isEditing }) => ({
    fontSize: '18px',
    color:
      isInEditMode && !isEditing ? Colors.checkboxGray : Colors.primaryText,
  }),
  info: ({ isInEditMode, isEditing }) => ({
    fontSize: '16px',
    color:
      isInEditMode && !isEditing ? Colors.checkboxGray : Colors.secondaryText,
    textAlign: 'left',
    paddingLeft: '25px',
    marginTop: '10px',
  }),
  canEdit: {
    marginRight: '20px',
  },
  titleAndEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  line: {
    height: '0.5px',
    width: '97%',
    margin: '25px 0 0 24px',
    background: Colors.checkboxGray,
  },
  formContainer: {
    margin: '20px 20px 0 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  prompt: {
    color: Colors.secondaryText,
  },
  inputContainer: {
    margin: '5px 0',
    width: '100%',
  },
  buttonContainer: {
    marginTop: '27px',
    width: '26%',
  },
});
