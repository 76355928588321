import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { flattenFamilyHistory } from '../../../../components/FamilyDetailCard/utils';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const FamHistShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.FAMILY_HISTORY)
  );
  const flatList = detailList
    ?.map(event => flattenFamilyHistory(event))
    .filter(item => !!item?.condition);

  const optionsList: ShareDetailLine[] = flatList?.map(
    ({ relationship, name, sensitive, id }) => {
      return {
        label: name ? `${relationship} - ${name}` : '',
        sensitive: sensitive,
        id: id,
      };
    }
  );
  return (
    <ShareSectionSelector
      title={t('family_history')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
