import { createUseStyles } from 'react-jss';
import { Colors } from '../../config/colors';

export const useVaultDocTableCardStyles = createUseStyles({
  container: {
    textAlign: 'left',
  },
  tableRow: {
    display: 'flex',
    margin: '30px 0 24px 0',
  },
  nameColumn: {
    height: '19px',
    marginLeft: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '43px',
    width: '20%',
  },
  nameBox: {
    width: '190px',
    marginRight: '3px',
  },
  uploadDateColumn: {
    color: Colors.secondaryText,
    width: '23.4%',
  },
  tagsColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginRight: '25px',
    width: '35%',
  },
  deathNoteColumn: {
    marginRight: '77px',
    display: 'flex',
    justifyContent: 'center',
  },
  viewAndMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    height: '17px',
    width: '8%',
  },
  receiptTag: {
    color: Colors.accent8,
    background: Colors.accent8Transparent,
    padding: '3px 11px 4px 11px',
    borderRadius: '4px',
  },
  careSummaryTag: {
    color: Colors.accent5Purple,
    background: Colors.accent5Transparent,
    padding: '3px 5px 4px 5px',
    borderRadius: '4px',
  },
  policyTag: {
    color: Colors.gradientLighterOrange,
    background: Colors.accent1Transparent,
    padding: '3px 15px 4px 17px',
    borderRadius: '4px',
  },
  legalTag: {
    color: Colors.yellowTagText,
    background: Colors.yellowTagTextTransparent,
    padding: '3px 17px 4px 18px',
    borderRadius: '4px',
  },
  accountInfoTag: {
    color: Colors.accent2,
    background: Colors.accent2Transparent,
    padding: '3px 12px 4px 10px',
    borderRadius: '4px',
  },
  otherTag: {
    color: Colors.accent4,
    background: Colors.accent4Transparent,
    padding: '3px 8px 4px 10px',
    borderRadius: '4px',
  },
  displayCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 10px 15px 23px',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '77px',
    border: `1px solid ${Colors.lightPurple}`,
    borderRadius: '6px',
  },
  displayCardTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: Colors.accent5Purple,
    marginBottom: '8px',
  },
  displayCardUploaded: {
    display: 'flex',
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '-0.3px',
    color: Colors.notificationTitles,
    marginBottom: '15px',
  },
  displayCardTags: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  },
});
