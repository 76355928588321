import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const VaultDocsShareCard = ({
  onClick,
  onChange,
  initialSelected,
  initialChecked,
}: {
  onClick?: () => void;
  onChange?: (array: string[]) => void;
  initialSelected?: string[];
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.VAULT_DOCS)
  );

  const optionsList: ShareDetailLine[] = detailList?.map(
    ({ fhirData, sensitive }) => {
      return {
        label: fhirData?.title || '',
        sensitive: sensitive,
        id: fhirData?.id,
      };
    }
  );

  return (
    <ShareSectionSelector
      title={t('vault')}
      options={optionsList}
      expanded={true}
      onClick={onClick}
      onChange={onChange}
      selectable
      initialSelected={initialSelected}
      initialChecked={initialChecked}
    />
  );
};
