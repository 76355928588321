import { Colors } from '../../config/colors';
import Plus from '../../assets/images/Plus.svg';
import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';

type PlusButtonProps = {
  size: number;
};

const usePlusButtonStyles: (
  data?: { size: number; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'button'> = createUseStyles({
  button: ({ size }) => ({
    background: Colors.sidebarBackground,
    padding: '5px',
    borderRadius: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: `${size}px`,
    width: `${size}px`,
  }),
});
export const PlusButton: React.FC<PlusButtonProps> = ({ size }) => {
  const classes = usePlusButtonStyles({ size, theme: {} });
  return (
    <div className={classes.button}>
      <img src={Plus} alt="" className="plus" height={size / 2} />
    </div>
  );
};
