import { useState } from 'react';
import { CarretDown, CarretUp } from '../../assets/images/icons';
import { useContentBoxStyles } from '../styles';

interface ContentBoxProps {
  title: string;
  slimTitle?: boolean;
  largeTitle?: boolean;
  dropshadow?: boolean;
  showCollapse?: boolean;
  collapsed?: boolean;
}

export const ContentBox: React.FC<ContentBoxProps> = ({
  title,
  slimTitle,
  children,
  dropshadow,
  collapsed,
  showCollapse,
  largeTitle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const classes = useContentBoxStyles({
    dropshadow,
    collapsed: isCollapsed,
    largeTitle,
    theme: {},
  });
  return (
    <div
      className={`
        ${classes.container}  ${slimTitle && classes.slimContainer}`}
    >
      <div className={slimTitle ? classes.slimTitle : classes.title}>
        {title}
        {showCollapse && (
          <img
            src={isCollapsed ? CarretDown : CarretUp}
            alt=""
            style={{ margin: '4px 12px 4px' }}
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          />
        )}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
