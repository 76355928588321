import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { actionButtons } from '../../../config/sharedStyles';

export const useAllergiesStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '25px 0 0  70px',
      maxWidth: '70%',
    },
  },
  currentAllergies: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  pastAllergies: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  actionButtons,
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19px',
    color: Colors.accent10,
  },
  detailCard: {
    display: 'flex',
  },
});

export const useEditAllergiesStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.white,
    borderRadius: '6px',
    padding: '25px',
  },
  subheader: {
    marginTop: '12px',
    textAlign: 'left',
  },
  line: {
    height: '0.03rem',
    width: '100%',
    margin: '21px 0 21px',
    background: Colors.checkboxGray,
  },
  pastAllergies: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    color: Colors.secondaryText,
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '34px',
    color: Colors.linkPurple,
    [DESKTOP_VIEW_BREAKPOINT]: {
      width: '21.2rem',
      height: '3.2rem',
    },
  },
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19px',
    color: Colors.accent10,
  },
});
