import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  customSelectStyles,
  customSelectTheme,
  useDropdownStyles,
} from './styles';

interface DropdownProps {
  placeholder: string;
  field?: any;
  formErrors?: any;
  searchFunction: any;
}

export const FilterTypeAhead: React.FC<DropdownProps> = ({
  placeholder,
  field = { onChange: () => {}, name: 'input', value: undefined },
  searchFunction,
  formErrors,
}) => {
  const { onChange, name, value: formValue } = field;

  const [value, setValue] = useState<any>('');
  const [defaultInputValue] = useState<any>(formValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const showPlaceholder: boolean = !!value; //Move the placeholder up when we select a value
  //This is the logic to style the input if we have an error

  useEffect(() => {
    const cond = formErrors?.hasOwnProperty(name);
    setErrorMessage(cond ? formErrors[name].message : '');
    setHasError(cond);
  }, [formErrors, formValue]);

  const loadOptions = async (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    const options = await searchFunction(inputValue);
    callback(options);
  };

  useEffect(() => {
    // Sets default value
    if (value === '') {
      if (formValue?.length > 0) {
        setValue({ label: formValue, value: formValue });
      }
    }
  }, [formValue]);

  const classes = useDropdownStyles({
    showPlaceholder: !value,
    isError: hasError,
    theme: {},
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <AsyncSelect
          defaultValue={value}
          loadOptions={loadOptions}
          defaultInputValue={defaultInputValue}
          styles={customSelectStyles(hasError, showPlaceholder)}
          placeholder={placeholder}
          theme={customSelectTheme(hasError)}
          {...field}
          onChange={(e: any) => {
            onChange(e);
            setValue(e as any);
          }}
        />
        <label
          className={`${classes.label} ${showPlaceholder && classes.translate}`}
        >
          {placeholder}
        </label>
      </div>
      <span className={classes.errorMessage}>{errorMessage}</span>
    </div>
  );
};
