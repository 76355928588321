import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { MagnifyingGlass } from '../../assets/images/icons';
import { Checkbox } from '../Checkbox/Checkbox';
import { OneLineTextInput } from '../OneLineTextInput/OneLineTextInput';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import { useCheckboxListStyles } from './styles';

export type CheckboxListProps = {
  optionsList: string[];
  searchPlaceholder?: string;
  searchOptionsList?: (a: string) => void;
  onChange?: (a: string[]) => void;
  onSubmit: (a: string[]) => void;
  showOther?: boolean;
  disableContinue?: boolean;
};

const OTHER = 'Other...';

export const CheckboxList: React.FC<CheckboxListProps> = ({
  optionsList,
  searchPlaceholder = 'Search',
  onChange = () => {},
  searchOptionsList,
  onSubmit,
  showOther,
  disableContinue,
}) => {
  const [options, setOptions] = useState(
    showOther ? [...optionsList, OTHER] : optionsList
  );
  const [checked, setChecked] = useState(options.map(() => false));
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchString, setSearchString] = useState('');
  const classes = useCheckboxListStyles();

  const handleSubmit = () => {
    //User Clicks Continue
    onSubmit(checkedItems);
  };

  useEffect(() => {
    setOptions(showOther ? [...optionsList, OTHER] : optionsList);
    setChecked(optionsList.map(() => false));
  }, [optionsList]);

  const handleToggle = (index: number) => () => {
    const currentValue = checked[index];
    const newValue = !currentValue;
    const placeholderArray = [...checked];

    placeholderArray.splice(index, 1, newValue);

    setChecked(placeholderArray);
    const checkedItemsList = options.filter(
      (i, index) => placeholderArray[index]
    );

    setCheckedItems(checkedItemsList);
    onChange(checkedItemsList);
  };

  const handleSearch = (searchString: string) => {
    if (searchOptionsList) {
      searchOptionsList(searchString);
    } else {
      setSearchString(searchString.toUpperCase());
    }
  };

  const filterValues = (value: string, filterString: string) => {
    if (value?.includes(OTHER)) return false;
    else {
      return (
        !!filterString.length && !value.toUpperCase().includes(filterString)
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchBar}>
        <OneLineTextInput
          type="text"
          placeholder={searchPlaceholder}
          icon={MagnifyingGlass}
          onChange={async e => handleSearch(e.target.value)}
        />
      </div>
      <div className={classes.optionsList}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {options.map((value, index) => {
            const labelId = `checkbox-list-label-${value}`;
            if (filterValues(value, searchString)) {
              return null;
            }

            return (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(index)}
                  dense
                >
                  <div style={{ paddingRight: '20px' }}>
                    <Checkbox checkedState={checked[index]} managed />
                  </div>
                  <ListItemText id={labelId} primary={value} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className={classes.actionButton}>
        <div className={classes.buttonContainer}>
          <ThemedButton
            title="Continue"
            onClick={handleSubmit}
            isDisabled={
              disableContinue !== undefined
                ? disableContinue
                : checkedItems.length === 0
            }
          />
        </div>
      </div>
    </div>
  );
};
