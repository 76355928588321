import { useHistory } from 'react-router-dom';
import { useDashboardDrawerStyles } from './styles';
import { notificationCircle } from '../../assets/images/icons';
import { DrawerTitle } from '../TextComponents/DrawerTitle';

interface DashboardDrawerProps {
  title: string;
  notificationCount: boolean;
  path: string;
  isInDrawer?: boolean;
}

export const DashboardDrawer: React.FC<DashboardDrawerProps> = ({
  title,
  notificationCount,
  path,
  isInDrawer = false,
}) => {
  const classes = useDashboardDrawerStyles({ isInDrawer, theme: {} });
  const history = useHistory();
  return (
    <div className={classes.container} onClick={() => history.push(path)}>
      <div className={classes.title}>
        <DrawerTitle>{title}</DrawerTitle>
      </div>
      {notificationCount && (
        <div className={classes.imageContainer}>
          <img width="11px" src={notificationCircle} alt={title} />
        </div>
      )}
    </div>
  );
};
