import { useState, useEffect, useMemo } from 'react';
import { useRequest } from '../../../app/hooks';
import { getDefaultConditions } from '../../../api/info';
import { useTranslation } from 'react-i18next';
import { MarkSensitive, OneLineTextInput, Switch } from '../../../components';
import { CardDetail1, CardHeader1 } from '../../../components/TextComponents';
import { sharedValidators } from '../../../config/constants';
import { useHealthConditionCardStyles } from './styles';

type HealthConditionCardProps = {
  condition: any;
  register: any;
  errors: any;
};

export const HealthConditionCard: React.FC<HealthConditionCardProps> = ({
  condition,
  register,
  errors,
}) => {
  const [isCurrent, setIsCurrent] = useState(condition.current);
  const { code, onsetPeriod, sensitive } = condition;
  const title = code.text;
  const { start, end } = onsetPeriod;
  const { t } = useTranslation();
  const classes = useHealthConditionCardStyles();
  const [isOther, setIsOther] = useState(false);

  const {
    request: getDefaultConditionsRequest,
    data: getDefaultConditionsData,
  } = useRequest(getDefaultConditions);

  useEffect(() => {
    getDefaultConditionsRequest();
  }, []);

  const ConditionsList = useMemo(
    () => getDefaultConditionsData?.with || [],
    [getDefaultConditionsData]
  );

  useEffect(() => {
    if (ConditionsList?.length) {
      setIsOther(!ConditionsList.includes(title));
    }
  }, [ConditionsList]);

  return (
    <div className={classes.conditionCard}>
      <div className={classes.topSection}>
        {isOther ? (
          <OneLineTextInput
            placeholder={t('health_condition_name')}
            type="text"
            defaultValue={title}
            {...register(`title`, {
              required: `${t('condition_name_required')}`,
            })}
            formErrors={errors}
          />
        ) : (
          <div className={classes.cardTitle}>
            <CardHeader1>{title}</CardHeader1>
          </div>
        )}
      </div>
      <div className={classes.middleSection}>
        <div className={classes.currentToggle}>
          <CardDetail1>{t('currently_experiencing')}</CardDetail1>
          <Switch
            {...register(`current`)}
            defaultValue={isCurrent}
            onChange={() => {
              setIsCurrent(!isCurrent);
            }}
          />
        </div>
        <div className={classes.dateFields}>
          <div className={classes.inputFieldContainer}>
            <OneLineTextInput
              placeholder={t('onset_date')}
              defaultValue={start}
              type="text"
              {...register(`onsetDate`, {
                required: `${t('onset_required')}`,
                ...sharedValidators.shortDate,
              })}
              formErrors={errors}
            />
          </div>
          <div className={classes.inputFieldContainer}>
            {!isCurrent && (
              <OneLineTextInput
                placeholder={t('resolution_date')}
                defaultValue={end}
                type="text"
                {...register(`resolutionDate`, {
                  required: `${t('resolution_required')}`,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            )}
          </div>
        </div>
      </div>
      <MarkSensitive
        register={{ ...register(`sensitive`) }}
        defaultValue={sensitive}
      />
      {ConditionsList.includes(title) && (
        <div style={{ height: '0px', width: '0px', opacity: '0' }}>
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`title`, {})}
            formErrors={errors}
          />
        </div>
      )}
    </div>
  );
};

export const NewHealthConditionCard = ({ title, register, errors, index }) => {
  const [isCurrent, setIsCurrent] = useState(false);
  const { t } = useTranslation();
  const classes = useHealthConditionCardStyles();

  return (
    <div className={classes.conditionCard}>
      <div className={classes.topSection}>
        {title === 'Other...' ? (
          <OneLineTextInput
            placeholder={t('health_condition_name')}
            type="text"
            {...register(`${index}-title`, {
              required: `${t('condition_name_required')}`,
            })}
            formErrors={errors}
          />
        ) : (
          <div className={classes.cardTitle}>
            <CardHeader1>{title}</CardHeader1>
          </div>
        )}
      </div>
      <div className={classes.middleSection}>
        <div className={classes.currentToggle}>
          <CardDetail1>{'I am currently experiencing this'}</CardDetail1>
          <Switch
            {...register(`${index}-current`)}
            onChange={() => {
              setIsCurrent(!isCurrent);
            }}
          />
        </div>
        <div className={classes.dateFields}>
          <div className={classes.inputFieldContainer}>
            <OneLineTextInput
              placeholder={t('onset_date')}
              type="text"
              {...register(`${index}-onsetDate`, {
                required: `${t('onset_required')}`,
                ...sharedValidators.shortDate,
              })}
              formErrors={errors}
            />
          </div>

          <div className={classes.inputFieldContainer}>
            {!isCurrent && (
              <OneLineTextInput
                placeholder={t('resolution_date')}
                type="text"
                {...register(`${index}-resolutionDate`, {
                  required: `${t('resolution_required')}`,
                  ...sharedValidators.shortDate,
                })}
                formErrors={errors}
              />
            )}
          </div>
        </div>
      </div>
      <MarkSensitive register={{ ...register(`${index}-sensitive`) }} />
      {title !== 'Other...' && (
        <div style={{ height: '0px', width: '0px', opacity: '0' }}>
          <OneLineTextInput
            type="text"
            defaultValue={title}
            {...register(`${index}-title`, {})}
            formErrors={errors}
          />
        </div>
      )}
    </div>
  );
};
