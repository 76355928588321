import Popover from '@mui/material/Popover';
import { useState } from 'react';

import { MenuOption } from '../../config/SharedTypes';
import { CardDetail1 } from '../TextComponents';
import { useThreeDotIconsMenuStyles } from './styles';

interface ThreeDotIconsMenuProps {
  options: MenuOption[];
}
const MenuOptionButton: React.FC<any> = ({ option, key, className }) => {
  return (
    <div key={key} onClick={option.onClick} className={className}>
      <img src={option.icon} alt={`${option.title}`} width="20px" />
      <CardDetail1>{option.title}</CardDetail1>
    </div>
  );
};

export const ThreeDotIconsMenu: React.FC<ThreeDotIconsMenuProps> = ({
  options,
}) => {
  const classes = useThreeDotIconsMenuStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    event.preventDefault();
  };

  const handleClose = e => {
    setIsOpen(false);
    setAnchorEl(null);
    e.preventDefault();
  };

  return (
    <>
      {options.length && (
        <>
          <button className={classes.button} onClick={handleClick}>
            ...
          </button>
          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {options.map((option, index) => (
              <MenuOptionButton
                option={option}
                key={index}
                className={classes.popperOption}
              />
            ))}
          </Popover>
        </>
      )}
    </>
  );
};
