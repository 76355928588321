import { useButtonStyles } from './styles';

interface GradientButtonProps {
  title: string;
  onClick: () => void;
  type?: 'reset' | 'button' | 'submit';
  gradientStart: string;
  gradientEnd: string;
  isDisabled?: boolean;
  icon?: string;
  isSmall?: boolean;
}

export const GradientButton: React.FC<GradientButtonProps> = ({
  onClick = () => {},
  isDisabled,
  type = 'button',
  gradientStart,
  gradientEnd,
  title,
  icon,
  isSmall,
}) => {
  const classes = useButtonStyles({
    isDisabled,
    gradientStart,
    gradientEnd,
    isSmall,
    theme: {},
  });
  return (
    <button
      className={classes.button}
      onClick={onClick}
      type={type}
      disabled={isDisabled}
    >
      <div className={classes.iconContainer}>
        {icon && <img src={icon} alt={title} className={classes.icon} />}
      </div>
      <div className={classes.titleContainer}>{title}</div>
    </button>
  );
};
