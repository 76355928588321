import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trash } from '../../assets/images/icons';
import debounce from 'lodash/debounce';
import { CheckboxList } from '../CheckboxList/CheckboxList';
import { OneLineTextInput } from '../OneLineTextInput/OneLineTextInput';
import { CardDetail1 } from '../TextComponents';
import { useRequest } from '../../app/hooks';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import { NewFamilyConditionCard } from './FamilyConditionCard';
import { useAddFamConditionStyles, useCheckboxStyles } from './styles';
import { getDefaultConditions, searchConditions } from '../../api/info';
import { sharedValidators, noSpaceInput } from '../../config/constants';
import { Dropdown } from '../Dropdown/Dropdown';
import { Controller } from 'react-hook-form';
import { relationshipList } from '../../config/constants';

export const AddFamilyCondition = ({
  register,
  unregister,
  control,
  errors,
  familyMember,
  additional,
  cancelAction = () => {},
  isDeceased,
}) => {
  const [deceased, setDeceased] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [conditionsList, setConditionsList] = useState([]);

  const { t } = useTranslation();

  const {
    request: getDefaultConditionsRequest,
    data: getDefaultConditionsData,
  } = useRequest(getDefaultConditions);

  const { request: searchConditionsRequest, data: searchConditionsData } =
    useRequest(searchConditions);

  useEffect(() => {
    getDefaultConditionsRequest();
  }, []);

  useEffect(() => {
    if (!!searchConditionsData?.with?.length) {
      setConditionsList(searchConditionsData?.with);
    } else {
      setConditionsList(getDefaultConditionsData?.with || []);
    }
  }, [searchConditionsData?.with, getDefaultConditionsData?.with]);

  const resetConditionsList = () =>
    setConditionsList(getDefaultConditionsData?.with);

  const handleListSubmit = list => {
    setSubmitted(true);
    setSelectedConditions(list);
  };

  const removeCondition = conditionName => {
    const filtered = selectedConditions.filter(str => str !== conditionName);
    setSelectedConditions(filtered);
    if (!filtered.length) {
      cancelAction();
    }
  };

  const searchOptionsList = debounce((term: string) => {
    if (term.length < 4) {
      resetConditionsList();
    } else {
      searchConditionsRequest(term);
    }
  }, 400);

  const classes = useAddFamConditionStyles();
  const checkClasses = useCheckboxStyles();

  useEffect(() => {
    return () => {
      unregister();
    };
  }, []);

  return (
    <div className={classes.addSection}>
      <div className="inputs">
        {!additional && (
          <>
            {familyMember === 'Other' && (
              <Controller
                name="relation"
                control={control}
                rules={{ required: 'Please select the relationship type' }}
                render={({ field }) => (
                  <Dropdown
                    field={field}
                    placeholder={t('select_relationship')}
                    optionsList={relationshipList}
                    formErrors={errors}
                  />
                )}
              />
            )}
            <OneLineTextInput
              placeholder={t('name')}
              type="text"
              {...register(`member.name`, {
                required: `${t('their_name_required')}`,
                pattern: {
                  value: noSpaceInput,
                  message: `${t('their_name_required')}`,
                },
              })}
              formErrors={errors}
            />
            <OneLineTextInput
              placeholder={t('dob_format')}
              type="text"
              {...register(`member.dob`, {
                ...sharedValidators.longDate,
              })}
              formErrors={errors}
            />
            <label
              className={checkClasses.container}
              onClick={e => {
                setDeceased(!deceased);
                e.preventDefault();
              }}
            >
              <CardDetail1>{t('deceased')}</CardDetail1>
              <input type="checkbox" checked={deceased} />
              <span className={checkClasses.checkmark} />
            </label>
            {deceased && (
              <>
                <OneLineTextInput
                  placeholder={t('dod_format')}
                  type="text"
                  {...register(`member.dod`, {
                    ...sharedValidators.shortDate,
                  })}
                  formErrors={errors}
                />
              </>
            )}
          </>
        )}
        {submitted ? (
          <>
            {selectedConditions.map((condition, index) => {
              return (
                <div className={classes.newConditonCardContainer}>
                  <div className={classes.trashButtonContainer}>
                    <img
                      className={classes.trashButton}
                      src={Trash}
                      onClick={() => {
                        removeCondition(condition);
                      }}
                      alt={`Remove ${condition}`}
                    />
                  </div>
                  <div className={classes.newConditionCard}>
                    <NewFamilyConditionCard
                      title={condition}
                      index={index}
                      register={register}
                      unregister={unregister}
                      errors={errors}
                      isDeceased={isDeceased}
                    />
                  </div>
                </div>
              );
            })}
            <div style={{ height: '0px', width: '0px', opacity: '0' }}>
              {familyMember !== 'Other' && (
                <OneLineTextInput
                  type="text"
                  defaultValue={familyMember}
                  {...register(`title`, {
                    required: `${t('their_name_required')}`,
                    pattern: {
                      value: noSpaceInput,
                      message: `${t('their_name_required')}`,
                    },
                  })}
                  formErrors={errors}
                />
              )}
            </div>
            <div className={classes.actionButtons}>
              <ThemedButton title={t('save')} type="submit" />
            </div>
          </>
        ) : (
          <div className={classes.searchBox}>
            <CheckboxList
              optionsList={conditionsList}
              searchPlaceholder={t('search_for_thing', {
                thing: 'Condition',
              })}
              searchOptionsList={searchOptionsList}
              onSubmit={handleListSubmit}
              disableContinue={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
