import { AxiosResponse } from 'axios';

import { setCustomApiError } from '../config/utilities';
import i18n from '../translations';
import api from './axios';

export const getDefaultConditions = () =>
  api
    .get('/info/defaultConditions')
    // TODO: Fix <any> type here
    .then((res: AxiosResponse<any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export const searchConditions = (terms: string) =>
  api
    .get(`/info/conditions?terms=${terms}`)
    // TODO: Fix <any> type here
    .then((res: AxiosResponse<any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export const searchMedications = (terms: string) =>
  api
    .get(`/info/medications?terms=${terms}`)
    // TODO: Fix <any> type here
    .then((res: AxiosResponse<any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });

export const searchProcedures = (terms: string) =>
  api
    .get(`/info/procedures?terms=${terms}`)
    // TODO: Fix <any> type here
    .then((res: AxiosResponse<any>) => res)
    .catch(e => {
      const { with: status } = e?.response?.data || {};

      if (status === 401) throw e;
      throw setCustomApiError(e, i18n.t('something_wrong'));
    });
