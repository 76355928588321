import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  Bullet,
  CarretDown,
  CarretUp,
  OrangeLock,
} from '../../../../assets/images/icons';
import { CardSecondary } from '../../../../components/TextComponents';
import { useShareSelectorStyles } from '../styles';
import { ShareDetailLine } from '../utils';

type props = {
  title: string;
  options?: Array<ShareDetailLine>;
  expandable?: boolean;
  expanded?: boolean;
  onClick?: () => void;
  onChange?: (value: string[]) => void;
  selectable?: boolean;
  initialSelected?: string[];
  initialChecked?: boolean;
};

export const ShareSectionSelector: React.FC<props> = ({
  title,
  options = [],
  expandable = true,
  expanded,
  onClick,
  onChange,
  selectable,
  initialSelected = [],
  initialChecked = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isTopChecked, setIsTopChecked] = useState(initialChecked);
  const [selectedItems, setSelectedItems] = useState<string[]>(initialSelected);
  const classes = useShareSelectorStyles({ isExpanded, theme: {} });

  useEffect(() => {
    setIsTopChecked(initialChecked);
  }, [initialChecked]);

  useEffect(() => {
    setSelectedItems(initialSelected);
  }, [initialSelected]);

  const handleTopCheck = () => {
    setIsTopChecked(!isTopChecked);
    onClick();
  };

  const handleCheckItem = id => {
    const arr = selectedItems;
    if (arr.indexOf(id) === -1) {
      // Add Item
      arr.push(id);
      setSelectedItems([...arr]);
      onChange([...arr]);
    } else {
      //Remove Item
      const newItems = arr.filter(item => item !== id);
      setSelectedItems([...newItems]);
      onChange([...newItems]);
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <div className={classes.checkName}>
          {!!onClick ? (
            <Checkbox onClick={handleTopCheck} checked={isTopChecked} />
          ) : (
            <div style={{ marginRight: 20 }} />
          )}

          <CardSecondary>{title}</CardSecondary>
        </div>
        {expandable && (
          <img
            src={isExpanded ? CarretUp : CarretDown}
            alt=""
            style={{ margin: '4px 12px 4px' }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        )}
      </div>
      <div className={classes.detailList}>
        {!options?.length && (
          <div className={classes.detailLine}>
            <img src={Bullet} alt="" style={{ padding: '0 10px' }} />
            <CardSecondary>{t('nothing_to_share')}</CardSecondary>
          </div>
        )}
        {options.map(({ sensitive, id, label }) => {
          if (
            selectable &&
            !isTopChecked &&
            !onChange &&
            !selectedItems.includes(id)
          ) {
            return null;
          }
          return (
            <div
              className={
                sensitive ? classes.secureDetailLine : classes.detailLine
              }
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              {selectable && !isTopChecked && onChange ? (
                <Checkbox sx={{ p: 0.5 }} onClick={() => handleCheckItem(id)} />
              ) : (
                <img src={Bullet} alt="" style={{ padding: '0 10px' }} />
              )}
              {sensitive && (
                <img
                  src={OrangeLock}
                  alt=""
                  height={15}
                  style={{ margin: '0 8px 0 0' }}
                />
              )}
              <CardSecondary>{label}</CardSecondary>
            </div>
          );
        })}
      </div>
    </div>
  );
};
