import { useIconStyles } from '../styles';

interface IconProps {
  icon: string;
  onClick: () => void;
  alt: string;
}

export const IconButton: React.FC<IconProps> = ({ icon, onClick, alt }) => {
  const classes = useIconStyles();
  return (
    <button className={classes.iconButton} onClick={onClick}>
      <img className={classes.img} src={icon} alt={alt} />
    </button>
  );
};
