import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { arrowLeft, VaultIcon } from '../../../assets/images/icons';
import { GradientButton, LinkComponent } from '../../../components';
import {
  AboutMeTitle,
  ActionPageTitle,
  FormPrompt,
} from '../../../components/TextComponents';
import { Colors } from '../../../config/colors';
import { ROUTES } from '../../../config/constants';
import Route from '../../../routes/route';
import { getProfileThunk } from '../dashboardSlice';
import { AddVaultDoc } from './AddVaultDocument';
import { CredentialRow } from './CredentialRow';
import { useVaultStyles } from './styles';
import { VaultDocsDash } from './VaultDocsDash';

type VaultProps = {
  user: string;
};

export const Vault: React.FC<VaultProps> = () => {
  const { t } = useTranslation();
  let { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const vaultCreds = [
    {
      name: 'https://members.hcsc.net/wps/PA_BAMLogin/bamGateway?corpEntCode=IL1',
      username: 'jane@gmail.com',
      password: 'password',
    },
  ];
  // TODO: ^^ switch these out for window storage data
  const [isOnDocsPath, setIsOnDocsPath] = useState(true);
  const classes = useVaultStyles({ isOnDocsPath, theme: {} });
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  return (
    <div className={classes.container}>
      <Switch>
        <Route
          path={`${path}/uploadDoc`}
          isPrivate={false}
          component={AddVaultDoc}
        />
        <Route
          path={`/`}
          isPrivate={false}
          component={() => (
            <>
              <div
                className={classes.backToAction}
                onClick={() => history.push(ROUTES.dashboardHome)}
              >
                <div className={classes.arrowContainer}>
                  <img src={arrowLeft} alt="arrowLeft" />
                </div>
                <AboutMeTitle>{t('back_to_action_center')}</AboutMeTitle>
              </div>
              {isShareUser && <div style={{ margin: 15 }} />}
              <div className={classes.titleSection}>
                <div className={classes.titleAndIcon}>
                  <div className={classes.iconBackground}>
                    <img src={VaultIcon} alt="vaultIcon" />
                  </div>
                  <ActionPageTitle>{t('vault')}</ActionPageTitle>
                </div>
                {!isShareUser && (
                  <div className={classes.buttonsBox}>
                    <div className={classes.buttonContainer}>
                      <GradientButton
                        title={t('upload_a_document')}
                        onClick={() => history.push('uploadDoc')}
                        gradientStart={Colors.linkPurple}
                        gradientEnd={Colors.gradientDarkPurple}
                        isSmall={true}
                      />
                    </div>
                    <div className={classes.buttonContainer}>
                      <GradientButton
                        title={t('add_creds')}
                        onClick={() => console.log('add creds')}
                        // TODO: add path to add creds page
                        gradientStart={Colors.linkPurple}
                        gradientEnd={Colors.gradientDarkPurple}
                        isDisabled
                        isSmall={true}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.dataListView}>
                {!isShareUser && (
                  <div className={classes.dataViewSwitchers}>
                    <div
                      className={classes.docsSwitch}
                      onClick={() => {
                        setIsOnDocsPath(true);
                        history.push(`${path}/docs`);
                      }}
                    >
                      <AboutMeTitle>{t('documents')}</AboutMeTitle>
                    </div>
                    <div
                      className={classes.credsSwitch}
                      onClick={() => {
                        setIsOnDocsPath(false);
                        history.push(`${path}/creds`);
                      }}
                    >
                      <AboutMeTitle>{t('credentials')}</AboutMeTitle>
                    </div>
                  </div>
                )}
                <Switch>
                  <Route
                    path={`${path}/docs`}
                    isPrivate={false}
                    component={VaultDocsDash}
                  />
                  <Route
                    path={`${path}/creds`}
                    isPrivate={false}
                    component={() => (
                      <>
                        {vaultCreds.length ? (
                          <div
                            className="credentialRows"
                            style={{ maxWidth: '100%', padding: '0 22px' }}
                          >
                            {vaultCreds?.map(cred => (
                              <CredentialRow credential={cred} />
                            ))}
                          </div>
                        ) : (
                          <div className={classes.emptyDocsText}>
                            <AboutMeTitle>
                              {t('alive_stores_your_creds')}
                            </AboutMeTitle>
                            <div className={classes.middleText}>
                              <FormPrompt>
                                {t('vault_storage_creds')}
                              </FormPrompt>
                            </div>
                            <LinkComponent
                              label={t(
                                'Enter your credentials here (Coming Soon)'
                              )}
                              onClick={() => {
                                console.log(
                                  'TODO: add path to upload doc page'
                                );
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  />
                </Switch>
              </div>
            </>
          )}
        />
      </Switch>
    </div>
  );
};
