import { createUseStyles } from 'react-jss';
import { StyledDisplayTag } from '../StyledDisplayTag/StyledDisplayTag';
import { SubHeader1, SubHeader3 } from '../TextComponents';
import { ThemedButton } from '../ThemedButton/ThemedButton';

const useModalActionsStyles = createUseStyles({
  container: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
    gap: '8px',
  },
  title: {
    paddingBottom: '4px',
  },
  buttons: {
    display: 'flex',
    gap: '12px',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  section: {},
});

type props = {
  actions: {
    onDelete?: () => void;
    onDownload?: () => void;
  };
  createDate: string;
  tags: string[];
};
export const ViewModalActions: React.FC<props> = ({
  actions,
  createDate,
  tags,
}) => {
  const classes = useModalActionsStyles();
  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <div className={classes.title}>
          <SubHeader3>Date Uploaded</SubHeader3>
        </div>
        <div className={classes.buttons}>
          <SubHeader1>{new Date(createDate).toLocaleDateString()}</SubHeader1>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.title}>
          <SubHeader3>Tags</SubHeader3>
        </div>
        <div className={classes.tags}>
          {tags?.map(tag => (
            <StyledDisplayTag tagName={tag} />
          ))}
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.title}>
          <SubHeader3>Actions</SubHeader3>
        </div>
        <div className={classes.buttons}>
          <ThemedButton title="Share" isInverted small isDisabled />
          <ThemedButton
            title="Download"
            isInverted
            small
            onClick={() => {
              actions.onDownload();
            }}
          />
          <ThemedButton title="Print" isInverted small isDisabled />
          <ThemedButton title="Edit" isInverted small isDisabled />
          <ThemedButton
            title="Remove"
            isInverted
            small
            isDisabled={!actions?.onDelete}
            onClick={() => actions.onDelete()}
          />
        </div>
      </div>
    </div>
  );
};
