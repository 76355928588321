import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getLocalStorageItem,
  LocalStorageKeys,
  setLocalStorageItem,
  cleanup,
} from '../../config/constants';
import axios from 'axios';
import { VecnaPatient } from '../../typings';

let localStorageState = getLocalStorageItem(LocalStorageKeys.SESSION_DATA);

export type FlatUser = {
  id: string;
  fullName: string;
  firstName: string;
  photo: string;
  preferredName: string;
};

const loginSlice = createSlice({
  name: 'login',
  initialState: localStorageState || {
    loggedIn: false,
    user: null,
    shareUser: null,
    selectedUser: { index: 0, isDependent: false },
    mainUser: { id: '', fullName: '', firstName: '', photo: '' },
    createDependent: {},
    contractDetails: { name: '', contractId: '' },
  },
  reducers: {
    login: (state, { payload }) => {
      state.loggedIn = true;
      state.user = payload;
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${payload.accessToken}`;
      setLocalStorageItem(LocalStorageKeys.ACCESS_TOKEN, payload.accessToken);
      setLocalStorageItem(LocalStorageKeys.REFRESH_TOKEN, payload.refreshToken);
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    setShareUser: (state, { payload }: PayloadAction<VecnaPatient | null>) => {
      state.shareUser = payload;
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    setSelectedUser: (
      state,
      { payload }: PayloadAction<{ index: number; id: string }>
    ) => {
      const { index, id } = payload;
      state.selectedUser = { index, id, isDependent: index !== 0 };
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    setMainUser: (state, { payload }: PayloadAction<FlatUser>) => {
      state.mainUser = payload;
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    setContract: (state, { payload }: PayloadAction<FlatUser>) => {
      state.contract = payload;
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    setDependentData: (state, { payload }) => {
      const currentState = state.createDependent;
      const {
        dependentInfo = currentState?.dependentInfo,
        verifyInfo = currentState?.verifyInfo,
        username = currentState?.username,
        createResult = currentState?.createResult,
      } = payload;
      state.createDependent = {
        dependentInfo: dependentInfo,
        verifyInfo: verifyInfo,
        username: username,
        createResult: createResult,
      };
      setLocalStorageItem(LocalStorageKeys.SESSION_DATA, state);
    },
    logout: state => {
      state.loggedIn = false;
      state.user = null;
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('loginPassword');
      delete axios.defaults.headers.common['Authorization'];
      cleanup();
    },
  },
});
export default loginSlice.reducer;

export const selectCurrentUser = state => {
  return (
    state?.session?.selectedUser || { index: 0, isDependent: false, id: '' }
  );
};

export const selectCreateDependent = state => {
  return state?.session?.createDependent || {};
};

export const selectShareUser = state => {
  return state?.session?.shareUser;
};

export const selectIsPublic = state => {
  return !state?.session?.loggedIn || state?.session?.user !== null;
};

export const selectContractDetails = state => {
  return state?.session?.contract;
};

export const {
  login: loginActionCreator,
  logout,
  setShareUser,
  setSelectedUser,
  setDependentData,
  setMainUser,
  setContract,
} = loginSlice.actions;
