import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { viewShared } from '../../../api/share';
import { useRequest } from '../../../app/hooks';

import { arrowLeft } from '../../../assets/images/icons';
import { OneLineTextInput, ThemedButton } from '../../../components';
import {
  AboutMeTitle,
  InfoHeader,
  SectionSecondary,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { setShareUser } from '../../login/loginSlice';
import { quickShareCodeStyle } from './styles';
import { isValidCode } from './utils';

export const QuickShareCode: React.FC = () => {
  const classes = quickShareCodeStyle();
  const history = useHistory();
  const [enteredCode, setEnteredCode] = useState(new Array(6).fill(''));
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: userData, error, loading, request } = useRequest(viewShared);

  const goToField = index => {
    if (index < enteredCode.length && index >= 0) {
      document.getElementById(`code-field-${index}`).focus();
    }
    if (index === enteredCode.length) {
      document.getElementById(`last-name-input`).focus();
    }
  };

  const onSubmit = ({ lastName }) => {
    const codeString = enteredCode.join('');
    request({ contract: codeString, lastName });
  };

  useEffect(() => {
    const infoApi = userData?.with?.info;
    if (userData?.with?.info) {
      dispatch(setShareUser(infoApi));
      history.push(ROUTES.dashboard);
    }
  }, [userData]);

  return (
    <div className={classes.container}>
      <div
        className={classes.backToAction}
        onClick={() => history.push(ROUTES.share)}
      >
        <div className={classes.arrowContainer}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
      </div>

      <div className={classes.shareHeader}>
        <div className={classes.sectionName}>
          <InfoHeader>{t('have_a_quick_share_code')}</InfoHeader>
        </div>
      </div>
      <div className={classes.shadowContainer}>
        <div className={classes.topContainer}>
          <div className={classes.subTitle}>
            <SectionSecondary>{t('verify_code_text')}</SectionSecondary>
          </div>
          <div className={classes.codeInputArea}>
            {enteredCode.map((box, idx) => (
              <input
                type="text"
                maxLength={1}
                key={idx}
                id={`code-field-${idx}`}
                value={box}
                className={classes.digitInputBox}
                onChange={e => {
                  let replaceAtIndex = [...enteredCode];
                  replaceAtIndex[idx] = e.target.value;
                  setEnteredCode([...replaceAtIndex]);
                  if (e.target.value !== '') {
                    goToField(idx + 1);
                  }
                }}
                onKeyDown={event => {
                  if (!/[0-9a-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onKeyUp={event => {
                  if (event.key === 'Backspace') {
                    goToField(idx - 1);
                  }
                }}
                onFocus={event => {
                  event.target.select();
                }}
              />
            ))}
          </div>
          <div className={classes.subTitle}>
            <SectionSecondary>{t('enter_persons_name')}</SectionSecondary>
          </div>
          <OneLineTextInput
            placeholder={t('persons_last_name')}
            id={'last-name-input'}
            formErrors={errors}
            {...register('lastName', { required: `${t('last_name_error')}` })}
          />

          <div className={classes.bottomButton}>
            <ThemedButton
              title={t('continue')}
              isDisabled={!isValidCode(enteredCode.join(''))}
              onClick={handleSubmit(onSubmit)}
              isLoading={loading}
            />
          </div>
          {!!error && (
            <div className={classes.errorText}>{t('share_api_error')}</div>
          )}
        </div>
      </div>
    </div>
  );
};
