import { Classes } from 'jss';
import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';

export const useNavigationTileStyles: (
  data?: { iconBg: string; disabled: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'container'
  | 'imageContainer'
  | 'notifContainer'
  | 'title'
  | 'header'
  | 'iconBackground'
> = createUseStyles({
  container: {
    background: Colors.white,
    color: Colors.primaryText,
    borderRadius: '10px',
    width: '390px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '15px 0 15px 0',
    padding: '17px 0',
    boxShadow: `0px 2px 7px 2px rgba(116, 116, 116, 0.25)`,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '0 20px',
  },
  notifContainer: {
    display: 'flex',
    position: 'absolute',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.white,
    backgroundColor: Colors.errorRed,
    margin: '-3px -3px',
  },
  title: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    textAlign: 'left',
    maxWidth: '243px',
  },
  header: {
    marginBottom: '6px',
  },
  iconBackground: ({ iconBg, disabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: disabled ? `${Colors.disabledGrey}` : `${iconBg}`,
    height: '48px',
    width: '48px',
    borderRadius: '10px',
  }),
});
