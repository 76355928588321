import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  customSelectStyles,
  customSelectTheme,
  useDropdownStyles,
} from './styles';

interface DropdownProps {
  placeholder: string;
  optionsList?: string[];
  optionsArr?: Array<{ value: string; label: string }>;
  field?: any;
  formErrors?: any;
}

const getReactSelectOptions = optionsList => {
  return optionsList.map((val: any) => {
    return { value: val, label: val };
  });
};

export const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  optionsList,
  optionsArr,
  field = { onChange: () => {}, name: 'input', value: undefined },
  formErrors,
}) => {
  const { onChange, name, value: formValue } = field;

  const reactSelectOptions = optionsArr
    ? optionsArr
    : getReactSelectOptions(optionsList);

  const [value, setValue] = useState();
  const showPlaceholder: boolean = !!value; //Move the placeholder up when we select a value
  //This is the logic to style the input if we have an error
  let errorMessage = false,
    isError = false;
  if (formErrors?.hasOwnProperty(name)) {
    errorMessage = formErrors[name].message;
    isError = true;
  }

  useEffect(() => {
    if (!value) {
      const defaultOption = optionsArr
        ? reactSelectOptions.find(opt => opt.label === formValue)
        : reactSelectOptions.find(opt => opt.value === formValue);
      setValue(defaultOption);
    }
  }, [formValue, optionsArr]);

  const classes = useDropdownStyles({
    showPlaceholder: !value,
    isError,
    theme: {},
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Select
          options={reactSelectOptions}
          defaultValue={value}
          defaultInputValue={
            reactSelectOptions.find(item => item.value === formValue)?.label ||
            ''
          }
          styles={customSelectStyles(isError, showPlaceholder)}
          placeholder={placeholder}
          theme={customSelectTheme(isError)}
          {...field}
          onChange={(e: any) => {
            onChange(e);
            setValue(e as any);
          }}
        />
        <label
          className={`${classes.label} ${showPlaceholder && classes.translate}`}
        >
          {placeholder}
        </label>
      </div>
      <span className={classes.errorMessage}>{errorMessage}</span>
    </div>
  );
};
