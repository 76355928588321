import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { actionButtons } from '../../../config/sharedStyles';

export const useFamilyHistoryStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '25px 0 0 70px',
    },
  },
  titleAndButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topPageButtons: {
    display: 'flex',
    gap: '15px',
  },
  subtitle: {
    margin: '17px 0 18px 0',
    width: '80%',
  },
  actionButtons,
  familyCards: {
    marginTop: '24px',
  },
  addMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '19px',
    color: Colors.accent10,
  },
  detailCard: {
    display: 'flex',
  },
  editAnytime: {
    color: Colors.disabledGrey,
    margin: '27px 0 30px 0',
  },
});

export const useEditFamilyMemberStyles = createUseStyles({
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '6px',
    padding: '25px',
  },
  deceasedQuestion: {
    display: 'flex',
  },
  checkboxContainer: {
    width: '20px',
    height: '20px',
  },
});
