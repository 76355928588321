import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ThemedButton } from '../../../components';
import { SubHeader3 } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import { selectConditions, updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { HealthConditionCard } from './HealthConditionCard';
import { useEditAllergiesStyles } from './styles';
import { flattenHealthConditions } from './utils';
import { getUUID } from '../../../config/utilities';

type HealthAllergiesProps = {
  detail?: HealthDetail;
};

type EditHealthConditionForm = {
  current: boolean;
  onsetDate: string;
  resolutionDate: string;
  sensitive: boolean;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditHealthCondition: React.FC<HealthAllergiesProps> = () => {
  const classes = useEditAllergiesStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList } = useAppSelector(selectConditions);
  const conditions = flattenHealthConditions(detailList);

  const queryId = useQuery().get('id');

  const [conditionId] = useState(queryId || getUUID());
  const history = useHistory();
  const conditionData = conditions.find(el => el.id === conditionId);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditHealthConditionForm>();

  const onSubmit = data => {
    const resolutionDate = data.current ? false : data.resolutionDate;
    const update = {
      section: DashboardSections.HEALTH_CONDITIONS,
      detailItem: {
        fhirData: {
          ...conditionData.fhirData,
          title: data.title,
          onsetPeriod: {
            start: data.onsetDate,
            ...(resolutionDate && { end: resolutionDate }),
          },
          code: { text: data.title },
        },
        sensitive: data.sensitive,
      },
    };
    dispatch(updateFhirItem(update));
    history.push(ROUTES.conditionsDashboard);
  };

  const onError = errors => {
    console.error(errors);
  };

  return (
    <>
      <SubHeader3>{t('please_add_health_conditions')}</SubHeader3>
      <div className={classes.container}>
        <div className={classes.editForm}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <HealthConditionCard
              condition={conditionData}
              register={register}
              errors={errors}
            />
            <div
              style={{
                paddingTop: '12px',
                maxWidth: '222px',
                display: 'flex',
                gap: '20px',
              }}
            >
              <ThemedButton title={t('save')} type="submit" />
              <ThemedButton
                title={t('cancel', { thing: 'allergies' })}
                isInverted
                onClick={() => {
                  history.push(ROUTES.conditionsDashboard);
                }}
              />
            </div>
          </form>
        </div>
        <div className={classes.addMessage}>
          <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
        </div>
      </div>
    </>
  );
};
