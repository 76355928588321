import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { flattenMedication } from '../../medications/utils';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const MedicationsShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.MEDICATIONS)
  );
  const flatList = detailList?.map(med => flattenMedication(med));

  const medicationNames: ShareDetailLine[] = flatList?.map(
    ({ title, sensitive, id }) => {
      return {
        label: title || '',
        sensitive: sensitive,
        id: id,
      };
    }
  );
  return (
    <ShareSectionSelector
      title={t('medications')}
      options={medicationNames}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
