import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';
import Stars from './assets/images/stars-cropped.jpeg';

function App() {
  return (
    <div
      className="App"
      style={{ backgroundImage: `url(${Stars})`, backgroundSize: 'cover' }}
    >
      <Router>
        <div id="main">
          <Routes />
        </div>
      </Router>
    </div>
  );
}

export default App;
