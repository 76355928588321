import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts, createPost, updatePost } from './postsSlice';

const Posts = () => {
  const dispatch = useDispatch();
  const posts = useSelector(state => state.posts);

  useEffect(() => {
    console.log('I just loaded the posts page');
    dispatch(createPost({ title: 'all new for the win' })).then(() =>
      dispatch(getPosts())
    );
  }, [dispatch]);

  return (
    <div>
      {posts.list &&
        posts.list.map(post => (
          <p key={post._id}>
            <button
              onClick={() => {
                dispatch(
                  updatePost({
                    id: post._id,
                    title: 'wamba lamba',
                    body: 'yayay',
                  })
                ).then(() => {
                  dispatch(getPosts());
                });
              }}
            >
              {post.title}
            </button>
          </p>
        ))}
    </div>
  );
};

export default Posts;
