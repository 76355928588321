import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { UserCircle } from '../../assets/images/icons';
import { relationshipList } from '../../config/constants';
import { DashboardSections } from '../../features/dashboard/DashboardSections';
import { updateFhirItem } from '../../features/dashboard/dashboardSlice';
import { Dropdown } from '../Dropdown/Dropdown';
import { LinkComponent } from '../LinkComponent/LinkComponent';
import { OneLineTextInput } from '../OneLineTextInput/OneLineTextInput';
import { CardDetail1, CardHeader1 } from '../TextComponents';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import { useCheckboxStyles, useFamilyDetailCardStyles } from './styles';
import { newRelation } from './utils';
import { sharedValidators, noSpaceInput } from '../../config/constants';

type AddFamilyProps = {
  onClose: any;
  sibling?: boolean;
};

export const AddAdditionalFamily: React.FC<AddFamilyProps> = ({
  onClose,
  sibling,
}) => {
  const classes = useFamilyDetailCardStyles({ displayOnly: false });
  const checkClasses = useCheckboxStyles();
  const [deceased, setDeceased] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    register,
    unregister,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    return () => {
      unregister();
    };
  }, []);

  useEffect(() => {
    if (!!sibling) {
      setValue('relation', { value: t('sibling'), label: t('sibling') });
    }
    return () => {
      unregister('relation');
    };
  }, [sibling]);

  const onSubmit = data => {
    const update = {
      detailItem: newRelation(data, true),
      section: DashboardSections.FAMILY_HISTORY,
    };
    dispatch(updateFhirItem(update));
    onClose();
  };

  return (
    <div className={classes.container}>
      <div className={classes.additionalContainer}>
        <div className={classes.title}>
          <div className={classes.iconAndTitle}>
            <div className={classes.imageContainer}>
              <img width="29px" src={UserCircle} alt="userCircle" />
            </div>
            <CardHeader1>
              {sibling ? t('new_sibling') : t('new_member')}
            </CardHeader1>
          </div>
          <div className={classes.controls}>
            <LinkComponent label={t('cancel')} onClick={() => onClose()} />
          </div>
        </div>
        <div className={classes.editContainer}>
          <form>
            {sibling ? (
              <div
                style={{
                  height: '0px',
                  width: '0px',
                  display: 'flex',
                  opacity: '0',
                }}
              >
                <Controller
                  name="relation"
                  control={control}
                  rules={{ required: 'Please select the relationship type' }}
                  render={({ field }) => (
                    <Dropdown
                      field={field}
                      placeholder={t('select_relationship')}
                      optionsList={relationshipList}
                      formErrors={errors}
                    />
                  )}
                />
              </div>
            ) : (
              <Controller
                name="relation"
                control={control}
                rules={{ required: 'Please select the relationship type' }}
                render={({ field }) => (
                  <Dropdown
                    field={field}
                    placeholder={t('select_relationship')}
                    optionsList={relationshipList}
                    formErrors={errors}
                  />
                )}
              />
            )}
            <OneLineTextInput
              placeholder={t('name')}
              type="text"
              {...register(`name`, {
                required: `${t('their_name_required')}`,
                pattern: {
                  value: noSpaceInput,
                  message: `${t('their_name_required')}`,
                },
              })}
              formErrors={errors}
            />
            <OneLineTextInput
              placeholder={t('dob')}
              type="text"
              {...register(`dob`, {
                ...sharedValidators.longDate,
              })}
              formErrors={errors}
            />
            <label
              className={checkClasses.container}
              onClick={e => {
                setDeceased(!deceased);
                e.preventDefault();
              }}
            >
              <CardDetail1>{t('deceased')}</CardDetail1>
              <input
                type="checkbox"
                checked={deceased}
                {...register('deceased')}
              />
              <span className={checkClasses.checkmark} />
            </label>
            {deceased && (
              <>
                <OneLineTextInput
                  placeholder={t('dod_format')}
                  type="text"
                  {...register(`dod`, {
                    ...sharedValidators.shortDate,
                  })}
                  formErrors={errors}
                />
              </>
            )}
            <div className={classes.actionButtons}>
              <ThemedButton
                title={t('save')}
                type="button"
                onClick={() => handleSubmit(onSubmit)()}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
