import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { t } from 'i18next';

type props = {
  open: boolean;
  handleNo: () => void;
  handleYes: () => void;
  title: string;
  message: string;
};
export const ConfirmDeleteModal: React.FC<props> = ({
  open,
  handleNo,
  handleYes,
  title,
  message,
}) => (
  <Dialog
    open={open}
    keepMounted
    onClose={handleNo}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleYes}>{t('yes')}</Button>
      <Button onClick={handleNo}>{t('No')}</Button>
    </DialogActions>
  </Dialog>
);
