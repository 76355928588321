import { useTranslation } from 'react-i18next';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Warning } from '../../../assets/images/icons';
import { HealthDetailCard, ThemedButton } from '../../../components';
import {
  FormBody,
  InfoHeader,
  SubHeader3,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import Route from '../../../routes/route';
import {
  deleteFhirItem,
  dismissFhirSection,
  selectAllergies,
} from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { EditAllergy } from './EditAllergy';
import { useAllergiesStyles } from './styles';
import { allergyToHealthDetail } from './allergyUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

type HealthAllergiesProps = {
  details?: HealthDetail[];
  listOnly?: boolean;
};

export const Allergies: React.FC<HealthAllergiesProps> = ({ listOnly }) => {
  const classes = useAllergiesStyles();
  const { t } = useTranslation();
  const { detailList } = useAppSelector(selectAllergies);
  const showDismiss = !!detailList;
  const allergies = detailList
    ? detailList.map(item => allergyToHealthDetail(item))
    : [];
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  if (listOnly) {
    return (
      <div className={classes.currentAllergies}>
        {!!allergies?.length ? (
          allergies?.map((detail, index) => (
            <div key={index} className={classes.detailCard}>
              <HealthDetailCard detail={detail} icon={Warning} />
            </div>
          ))
        ) : (
          <FormBody>{t('nothing_to_share')}</FormBody>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <InfoHeader>{t('allergies')}</InfoHeader>
        <Switch>
          <Route
            path={`${path}/edit`}
            component={EditAllergy}
            isPrivate={false}
          />
          <Route
            isPrivate={false}
            component={() => (
              <>
                <div className={classes.currentAllergies}>
                  {allergies?.map(detail => (
                    <div className={classes.detailCard}>
                      <HealthDetailCard
                        detail={detail}
                        icon={Warning}
                        menuOptions={[
                          {
                            title: t('edit'),
                            onClick: () =>
                              history.push({
                                pathname: `${path}/edit`,
                                search: `?id=${detail.id}`,
                              }),
                          },
                          {
                            title: t('delete'),
                            onClick: () =>
                              dispatch(
                                deleteFhirItem({
                                  section: DashboardSections.ALLERGY,
                                  id: detail.id,
                                })
                              ),
                          },
                        ]}
                      />
                    </div>
                  ))}
                </div>
                {!isShareUser && (
                  <div className={classes.actionButtons}>
                    <ThemedButton
                      title={t('add_allrgy')}
                      isInverted
                      onClick={() => {
                        history.push(ROUTES.allergyEdit);
                      }}
                    />
                    {!allergies.length && (
                      <>
                        {allergies.length === 0 && !showDismiss && (
                          <ThemedButton
                            title={t('no_thing_to_add', { thing: 'allergies' })}
                            isInverted
                            onClick={() => {
                              dispatch(
                                dismissFhirSection({
                                  section: DashboardSections.ALLERGY,
                                })
                              );
                            }}
                          />
                        )}
                      </>
                    )}
                    <div className={classes.addMessage}>
                      <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
                    </div>
                  </div>
                )}
                <div className={classes.actionButtons}>
                  <ThemedButton
                    title={t('add_allrgy')}
                    isInverted
                    onClick={() => {
                      history.push(ROUTES.allergyEdit);
                    }}
                  />
                </div>
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
};
