import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export const getUUID = () => {
  return uuidv4();
};

export enum DateFormats {
  MMDDYYYY = 'mm/dd/yyyy',
  MMYYYY = 'mm/yyyy',
  YYYYMMDD = 'YYYY-MM-DD',
}

export const toIsoString = (dateString: string, format: DateFormats) => {
  if (!dateString || !dateString?.length) {
    return '';
  }
  if (format === DateFormats.MMDDYYYY) {
    return new Date(dateString).toISOString();
  } else if (format === DateFormats.MMYYYY) {
    const date = new Date('01/01/1900');
    date.setUTCFullYear(Number(dateString.substring(dateString.length - 4)));
    date.setUTCMonth(Number(dateString.substring(0, 2)));
    date.setUTCDate(0);
    date.setUTCMilliseconds(0);
    date.setUTCSeconds(0);
    date.setUTCMinutes(0);
    date.setUTCHours(0);
    return date.toISOString();
  } else return '';
};

export const toDateString = (dateString: string, format: DateFormats) => {
  if (!dateString || !dateString?.length) {
    return '';
  }
  if (format === DateFormats.MMDDYYYY) {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }
  if (format === DateFormats.MMYYYY) {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: '2-digit',
      year: 'numeric',
    });
  } else if (format === DateFormats.YYYYMMDD) {
    return dayjs(dateString).format('YYYY-MM-DD');
  }
};

/*
  Returns new AxiosError object with custom message `m` set
  in the `e.response.data.because` property
*/
export const setCustomApiError = (e: AxiosError, m: string) => {
  const newError: AxiosError = { ...e };
  if (e?.response?.data) {
    newError.response = {
      ...e.response,
      data: { ...e.response.data, because: m },
    };
  }
  return newError;
};
