import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { LinkComponent } from '../../../components';
import { ContentBox } from '../../../components/ContentBox/ContentBox';
import { styles } from './styles';

const useVerifyEmailStyles = createUseStyles({
  ...styles,
});

export const VerifyEmail = () => {
  const classes = useVerifyEmailStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('verify_email')} slimTitle>
        <div className={classes.resendContent}>
          <div className={classes.detailText}>{t('we_emailed_you_link')}</div>
          <div className="resend">
            <LinkComponent label={t('resend_email')} />
          </div>
        </div>
        <div className={classes.skipLink}>
          <LinkComponent
            label={t('go_to_login')}
            onClick={() => history.push('/')}
          />
        </div>
      </ContentBox>
    </div>
  );
};
