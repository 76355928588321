import { useAppSelector } from '../../app/hooks';
import { selectOnboardingProgress } from '../../features/dashboard/dashboardSlice';
import { useProgressBarStyles } from './styles';

interface ProgressBarProps {
  progressWidth: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = () => {
  const progressPercent = useAppSelector(selectOnboardingProgress);
  const classes = useProgressBarStyles({
    progressWidth: progressPercent,
    theme: {},
  });
  return (
    <div className={classes.container}>
      <div className={classes.barBackground} />
      <div className={classes.progressBar} />
    </div>
  );
};
