import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { LinkComponent, ThemedButton } from '../../../components';
import { AddAdditionalFamily } from '../../../components/FamilyDetailCard/AddAdditionalFamily';
import { FamilyDetailCard } from '../../../components/FamilyDetailCard/FamilyDetailCard';
import {
  FormBody,
  FormHeader,
  FormPrompt,
  SubHeader3,
} from '../../../components/TextComponents';
import { HealthDetail } from '../../../config/SharedTypes';
import { DashboardSections } from '../DashboardSections';
import { dismissFhirSection, selectFamilyHistory } from '../dashboardSlice';
import { familyHistoryNotifications } from '../utils';
import { useFamilyHistoryStyles } from './styles';

type FamilyHistoryProps = {
  details?: HealthDetail[];
  listOnly?: boolean;
};

export const FamilyHistory: React.FC<FamilyHistoryProps> = ({ listOnly }) => {
  const classes = useFamilyHistoryStyles();
  const [showAddOther, setShowAddOther] = useState(false);
  const [showAddSibling, setShowAddSibling] = useState(false);
  const { t } = useTranslation();
  const { detailList, added } = useAppSelector(selectFamilyHistory);
  const hasNoDetails = familyHistoryNotifications({ detailList });
  const dismissed = !added;
  const familyHistory = detailList || [];
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  const closeFamily = familyHistory.filter(obj => {
    const closeFamilyList = [
      t('mother'),
      t('father'),
      t('grandmother_maternal'),
      t('grandfather_maternal'),
      t('grandmother_paternal'),
      t('grandfather_paternal'),
      t('child'),
    ];
    return closeFamilyList.includes(obj.fhirData.relationship.text);
  });

  const siblings = familyHistory.filter(obj => {
    return obj.fhirData.relationship.text === t('sibling');
  });
  const additionalFamilyList = familyHistory.filter(obj => {
    const closeFamilyList = [
      t('mother'),
      t('father'),
      t('grandmother_maternal'),
      t('grandfather_maternal'),
      t('grandmother_paternal'),
      t('grandfather_paternal'),
      t('child'),
      t('sibling'),
    ];
    return !closeFamilyList.includes(obj.fhirData.relationship.text);
  });
  const dispatch = useAppDispatch();

  const renderFamilyHistory = ({ renderArray }: { renderArray: any }) =>
    renderArray
      ?.filter(member => member?.fhirData?.condition?.length > -1)
      .map((member, index) => (
        <div key={index} className={classes.detailCard}>
          <FamilyDetailCard memberId={member.fhirData.id} displayOnly />
        </div>
      ));

  if (listOnly) {
    const noFamilyData = !familyHistory?.filter(
      member => member?.fhirData?.condition?.length > -1
    )?.length;
    return (
      <div>
        {renderFamilyHistory({ renderArray: closeFamily })}
        {renderFamilyHistory({ renderArray: siblings })}
        {renderFamilyHistory({ renderArray: additionalFamilyList })}
        {noFamilyData && <FormBody>{t('nothing_to_share')}</FormBody>}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleAndButtons}>
        <FormHeader>{t('family_history_title_case')}</FormHeader>
        {!isShareUser && (
          <div className={classes.topPageButtons}>
            <ThemedButton title={t('download')} isDisabled />
            <ThemedButton title={t('print')} isDisabled />
          </div>
        )}
      </div>
      {!isShareUser && (
        <div className={classes.subtitle}>
          <FormPrompt>{t('add_any_fam_history')}</FormPrompt>
        </div>
      )}
      {dismissed && hasNoDetails && !isShareUser && (
        <div className={classes.actionButtons}>
          <ThemedButton
            title={t('no_thing_to_add', { thing: 'family history' })}
            isInverted
            onClick={() => {
              dispatch(
                dismissFhirSection({
                  section: DashboardSections.FAMILY_HISTORY,
                })
              );
            }}
          />
        </div>
      )}
      <div className={classes.familyCards}>
        {closeFamily?.map(member => {
          return (
            <div className={classes.detailCard}>
              <FamilyDetailCard
                memberId={member.fhirData.id}
                displayOnly={isShareUser}
              />
            </div>
          );
        })}
        {siblings?.map(member => {
          return (
            <div className={classes.detailCard}>
              <FamilyDetailCard
                memberId={member.fhirData.id}
                displayOnly={isShareUser}
              />
            </div>
          );
        })}
        {showAddSibling && !isShareUser && (
          <AddAdditionalFamily
            onClose={() => {
              setShowAddSibling(false);
            }}
            sibling
          />
        )}
        {!isShareUser && (
          <LinkComponent
            label={t('add_another_sibling')}
            onClick={() => {
              setShowAddSibling(true);
            }}
          />
        )}
        {additionalFamilyList?.map(member => {
          return (
            <div className={classes.detailCard}>
              <FamilyDetailCard
                memberId={member.fhirData.id}
                displayOnly={isShareUser}
              />
            </div>
          );
        })}
        {showAddOther && !isShareUser && (
          <AddAdditionalFamily
            onClose={() => {
              setShowAddOther(false);
            }}
          />
        )}
        {!isShareUser && (
          <LinkComponent
            label={t('add_another_member')}
            onClick={() => {
              setShowAddOther(true);
            }}
          />
        )}
      </div>
      {!isShareUser && (
        <div className={classes.editAnytime}>
          <SubHeader3>{t('can_add_edit_all_caps')}</SubHeader3>
        </div>
      )}
    </div>
  );
};
