import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const styles = {
  cardContainer: {
    display: 'flex',
    margin: '30px 10px',
    width: '80%',
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '15px 26px',
      width: 'unset',
    },
  },
  message: {
    textAlign: 'left',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: '500',
    marginBottom: '10px',
  },
  progressBar: {
    backgroundColor: Colors.white,
    height: '.1em',
    marginBottom: '32px',
    borderRadius: '9px',
  },
  textLeft: {
    fontSize: '1.5rem',
  },
  subheader: {
    fontWeight: '700',
  },
  smallText: {
    fontWeight: '500',
  },
  radioList: {
    marginBottom: '33px',
  },
};
