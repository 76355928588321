/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { logout } from '../../login/loginSlice';

export interface ShareSliceState {
  notificationNumber: number;
}

const shareNotificationInitialState: ShareSliceState = {
  notificationNumber: 0,
};

const shareNotification = createSlice({
  name: 'shareNotification',
  initialState: shareNotificationInitialState,
  reducers: {
    setNotificationNumber(state, { payload }: PayloadAction<number>) {
      state.notificationNumber = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logout, state => {
      state.notificationNumber =
        shareNotificationInitialState.notificationNumber;
    });
  },
});

export const { setNotificationNumber } = shareNotification.actions;

export default shareNotification.reducer;
