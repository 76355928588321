import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { actionButtons } from '../../../config/sharedStyles';

export const useMedicalEventsStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '25px 0 0 70px',
    },
    textAlign: 'left',
  },
  actionButtons,
  editAnytime: {
    color: Colors.disabledGrey,
    marginTop: '39px',
  },
  detailCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: Colors.secondaryText,
    width: '100%',
  },
  detailCard: {
    display: 'flex',
  },
});

export const useEditMedicalEventStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.backgroundGrey,
    textAlign: 'left',
    width: '100%',
  },
  editFormPrompt: {
    margin: '32px 0 20px 0',
  },
  editForm: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.white,
    borderRadius: '6px',
    padding: '23px 26px 23px 26px',
  },
  lineContainer1: {
    margin: '21px 0 21px 0',
  },
  lineContainer2: {
    margin: '28px 0 16px 0',
  },
  saveButton: {
    marginTop: '26.5px',
    width: '13.9rem',
    height: '52px',
  },
});
