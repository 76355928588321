import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { editShare, share, ShareParams } from '../../../api/share';
import { useAppSelector, useRequest } from '../../../app/hooks';
import {
  arrowLeft,
  Info,
  LockGreen,
  OrangeLock,
  ShareHourglassGreen,
  Star,
} from '../../../assets/images/icons';
import {
  Dropdown,
  LinkComponent,
  Switch,
  ThemedButton,
} from '../../../components';
import {
  AboutMeTitle,
  InfoHeader,
  NavSubheader2,
} from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { selectCurrentUser } from '../../login/loginSlice';
import { DashboardSections } from '../DashboardSections';
import { AllergyShareCard } from './components/AllergiesShareCard';
import { FamHistShareCard } from './components/FamHistShareCard';
import { HealthConditionsShareCard } from './components/HealthConditionsShareCard';
import { HealthInsuranceShareCard } from './components/HealthInsuranceShareCard';
import { HospitalizationsShareCard } from './components/HospitalizationsShareCard';
import { ImmunizationsShareCard } from './components/ImmunizationsShareCard';
import { MedicationsShareCard } from './components/MedicationsShareCard';
import { ShareSectionSelector } from './components/ShareSectionSelector';
import { VaultDocsShareCard } from './components/VaultShareCard';
import { ShareItem } from './ShareHome';
import { quickShareStyles } from './styles';
import { dateOptions } from './utils';

export enum ShareOptions {
  SHARE_SENSITIVE = 'SHARE_SENSITIVE',
  COVID_19 = 'COVID_19',
  CHECK_UP = 'CHECK_UP',
  ALLOW_SUBSCRIBE = 'ALLOW_SUBSCRIBE',
  NEVER_EXPIRE = 'NEVER_EXPIRE',
}
export enum TimeOptions {
  HOURS = 'Hours',
  DAYS = 'Days',
  MONTHS = 'Months',
  YEARS = 'Years',
}

const defaultSections = {
  [DashboardSections.ABOUT_ME]: false,
  [DashboardSections.ALLERGY]: false,
  [DashboardSections.FAMILY_HISTORY]: false,
  [DashboardSections.HEALTH_CONDITIONS]: false,
  [DashboardSections.IMMUNIZATIONS]: false,
  [DashboardSections.INSURANCES]: false,
  [DashboardSections.MEDICAL_EVENTS]: false,
  [DashboardSections.MEDICATIONS]: false,
  [DashboardSections.VAULT_DOCS]: false,
  [ShareOptions.SHARE_SENSITIVE]: false,
  [ShareOptions.COVID_19]: false,
  [ShareOptions.CHECK_UP]: false,
  [ShareOptions.ALLOW_SUBSCRIBE]: false,
  [ShareOptions.NEVER_EXPIRE]: false,
};

type props = {
  open: boolean;
  onClose: () => void;
  children?: JSX.Element;
};
const ShareModal: React.FC<props> = ({ open, onClose, children }) => (
  <Dialog
    open={open}
    keepMounted
    onClose={onClose}
    aria-describedby="alert-dialog-slide-description"
    PaperComponent={() => children}
  />
);

export const CreateEditShare: React.FC = () => {
  const classes = quickShareStyles();
  const history = useHistory();
  const { isDependent = false, id: dependentId = '' } =
    useAppSelector(selectCurrentUser);

  const [timeAmount, setTimeAmount] = useState<any>();
  const [timeUnits, setTimeUnits] = useState<any>();
  const [expireTime, setExpireTime] = useState<string>(t('expiration_default'));
  const [hasTime, setHasTime] = useState(false);
  const [selectedSections, setSelectedSections] = useState(defaultSections);
  const {
    isQuickShare = false,
    email = '',
    itemSelected,
    isEdit = false,
  } = (useLocation().state || {}) as {
    isQuickShare?: boolean;
    email?: string;
    itemSelected?: ShareItem;
    isEdit?: boolean;
  };
  const [vaultItems, setVaultItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fromFields = () => {
    if (!itemSelected) return;
    const newSelectedSections = { ...selectedSections };
    const dayExpires = dayjs(itemSelected.expires);
    newSelectedSections.NEVER_EXPIRE = dayExpires.year() > 9000;
    if (!newSelectedSections.NEVER_EXPIRE) {
      setHasTime(true);
      setExpireTime(dayExpires.toISOString());
      // TODO use this format to set the dropdowns, the left dropdown doesn't always is showing 31 values
      // const diff = dayExpires.diff(dayjs(), 'hour');
      // if (diff <24) {
      //   setTimeUnits(TimeOptions.HOURS);
      //   setTimeAmount(diff)
      //   now.setHours(now.getHours() + Number(timeAmount));
      // } else if (timeUnits === TimeOptions.DAYS) {
      //   now.setDate(now.getDate() + Number(timeAmount));
      // } else if (timeUnits === TimeOptions.MONTHS) {
      //   now.setMonth(now.getMonth() + Number(timeAmount));
      // } else if (timeUnits === TimeOptions.YEARS) {
      //   now.setFullYear(now.getFullYear() + Number(timeAmount));
      // }
    }

    newSelectedSections[ShareOptions.SHARE_SENSITIVE] =
      !!itemSelected.fields.find(({ includeSensitive }) => !!includeSensitive);
    itemSelected.fields.forEach(({ name, ids }) => {
      if (name === 'additionalData') {
        newSelectedSections[DashboardSections.ABOUT_ME] = true;
      }
      if (name === 'insuranceData') {
        newSelectedSections[DashboardSections.INSURANCES] = true;
      }
      if (name === 'healthData.immunizations') {
        newSelectedSections[DashboardSections.IMMUNIZATIONS] = true;
      }
      if (name === 'healthData.medications') {
        newSelectedSections[DashboardSections.MEDICATIONS] = true;
      }
      if (name === 'healthData.allergies') {
        newSelectedSections[DashboardSections.ALLERGY] = true;
      }
      if (name === 'healthData.conditions') {
        newSelectedSections[DashboardSections.HEALTH_CONDITIONS] = true;
      }
      if (name === 'healthData.medicalEncounters') {
        newSelectedSections[DashboardSections.MEDICAL_EVENTS] = true;
      }
      if (name === 'familyHistory') {
        newSelectedSections[DashboardSections.FAMILY_HISTORY] = true;
      }
      if (name === 'vaultData') {
        if (ids) {
          setVaultItems(ids);
        } else {
          newSelectedSections[DashboardSections.VAULT_DOCS] = true;
        }
      }
    });
    setSelectedSections(newSelectedSections);
  };

  const shareObject = (isDep: boolean, depId: string) => {
    const obj: ShareParams = {
      with: email,
      ...(isDep && { dependent: depId }),
      expiration:
        selectedSections.NEVER_EXPIRE || !hasTime
          ? '9999-01-01T00:00:00.000Z'
          : dayjs(expireTime).toISOString(),
      fields: [{ name: 'fhirData' as const }],
    };
    const shareSensitive = selectedSections[ShareOptions.SHARE_SENSITIVE];
    if (selectedSections.ABOUT_ME) {
      obj.fields.push({ name: 'additionalData' as const });
    }
    if (selectedSections.INSURANCES) {
      obj.fields.push({
        name: 'insuranceData' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.IMMUNIZATIONS) {
      obj.fields.push({
        name: 'healthData.immunizations' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.MEDICATIONS) {
      obj.fields.push({
        name: 'healthData.medications' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.ALLERGY) {
      obj.fields.push({
        name: 'healthData.allergies' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.HEALTH_CONDITIONS) {
      obj.fields.push({
        name: 'healthData.conditions' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.MEDICAL_EVENTS) {
      obj.fields.push({
        name: 'healthData.medicalEncounters' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.FAMILY_HISTORY) {
      obj.fields.push({
        name: 'familyHistory' as const,
        includeSensitive: shareSensitive,
      });
    }
    if (selectedSections.VAULT_DOCS) {
      obj.fields.push({
        name: 'vaultData' as const,
        includeSensitive: shareSensitive,
      });
    } else if (vaultItems.length) {
      obj.fields.push({
        name: 'vaultData' as const,
        ids: vaultItems,
      });
    }

    return obj;
  };

  useEffect(() => {
    if (isEdit) {
      fromFields();
    }
  }, [isEdit]);

  const sharedCards = useMemo(
    () =>
      Object.entries(selectedSections).filter(value =>
        value[0] === ShareOptions.NEVER_EXPIRE ? false : !!value[1]
      ) || [],
    [selectedSections]
  );

  const {
    request: shareRequest,
    loading: shareLoading,
    error: shareError,
    data: shareResponse,
    status: shareStatus,
  } = useRequest(share);

  const {
    request: editShareRequest,
    loading: editShareLoading,
    error: editShareError,
    status: editShareStatus,
  } = useRequest(editShare);

  useEffect(() => {
    if (shareStatus === 200 && shareResponse?.with) {
      const code = shareResponse?.with || '';
      if (isQuickShare)
        history.push(ROUTES.showCode, {
          email,
          isQuickShare,
          code: code.toUpperCase(),
        });
      else {
        history.push(ROUTES.shareConfirmation, {
          email,
        });
      }
    }
  }, [shareStatus, shareResponse]);

  useEffect(() => {
    if (editShareStatus === 200) {
      history.push(ROUTES.share);
    }
  }, [editShareStatus]);

  const onContinuePress = () => {
    const shareObjectResult = shareObject(isDependent, dependentId);
    if (isEdit) {
      editShareRequest({
        expiration: shareObjectResult?.expiration,
        contract: itemSelected?.contract,
        lastName: itemSelected?.name,
        fields: shareObjectResult?.fields,
        dependent: { isDependent, dependentId },
      });
    } else {
      shareRequest(shareObjectResult);
    }
  };

  const setSelection = (
    section: DashboardSections | ShareOptions,
    value?: boolean
  ) => {
    const update = value
      ? {
          //explicity set

          ...selectedSections,
          [section]: value,
        }
      : {
          //toggle

          ...selectedSections,
          [section]: !selectedSections[section],
        };
    setSelectedSections(update);
  };

  const getSelection = (section: DashboardSections | ShareOptions) => {
    return selectedSections[section];
  };

  useEffect(() => {
    if (!!timeAmount?.value && !!timeUnits?.value) {
      const now = new Date();
      const timeVal = timeAmount?.value;
      const unitVal = timeUnits?.value;
      if (unitVal === TimeOptions.HOURS) {
        now.setHours(now.getHours() + Number(timeVal));
      } else if (unitVal === TimeOptions.DAYS) {
        now.setDate(now.getDate() + Number(timeVal));
      } else if (unitVal === TimeOptions.MONTHS) {
        now.setMonth(now.getMonth() + Number(timeVal));
      } else if (unitVal === TimeOptions.YEARS) {
        now.setFullYear(now.getFullYear() + Number(timeVal));
      }
      setExpireTime(now.toISOString());

      setHasTime(true);
    }
  }, [timeAmount, timeUnits, selectedSections]);

  const RenderContent = () => (
    <div className={classes.modalContainer}>
      <div className={classes.modalTopContainer}>
        {t('are_you_sure_share')}
        <div style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)}>
          {t('cancel')}
        </div>
      </div>
      <div className={classes.modalSubContainer}>
        {!isQuickShare && (
          <div className={classes.userContainer}>
            <img src={Star} alt="star" className={classes.starIcon} />
            {t('new_data_shared', { user: email })}
          </div>
        )}
        <div className={classes.extraDataContainer}>
          <div className={classes.extraDataSubContainer}>
            <img
              src={ShareHourglassGreen}
              alt="shareHourglassGreen"
              className={classes.icon}
            />
            {t(
              selectedSections.NEVER_EXPIRE
                ? 'share_never_expires'
                : 'share_expires',
              { date: dayjs(expireTime).fromNow() }
            )}
          </div>
          <div className={classes.extraDataSubContainer}>
            <img src={LockGreen} alt="lock" className={classes.icon} />
            {t('sensitive_data')}
          </div>
        </div>
        <div className={classes.modalDataContainer}>
          {t('data_being_shared')}
          {selectedSections.ABOUT_ME && (
            <ShareSectionSelector
              title={t('about_me')}
              expandable={false}
              initialChecked={selectedSections.ABOUT_ME}
            />
          )}
          {selectedSections.INSURANCES && (
            <HealthInsuranceShareCard
              initialChecked={selectedSections.INSURANCES}
            />
          )}
          {selectedSections.IMMUNIZATIONS && (
            <ImmunizationsShareCard
              initialChecked={selectedSections.IMMUNIZATIONS}
            />
          )}
          {selectedSections.MEDICATIONS && (
            <MedicationsShareCard
              initialChecked={selectedSections.MEDICATIONS}
            />
          )}
          {selectedSections.ALLERGY && (
            <AllergyShareCard initialChecked={selectedSections.ALLERGY} />
          )}
          {selectedSections.HEALTH_CONDITIONS && (
            <HealthConditionsShareCard
              initialChecked={selectedSections.HEALTH_CONDITIONS}
            />
          )}
          {selectedSections.MEDICAL_EVENTS && (
            <HospitalizationsShareCard
              initialChecked={selectedSections.MEDICAL_EVENTS}
            />
          )}
          {selectedSections.FAMILY_HISTORY && (
            <FamHistShareCard
              initialChecked={selectedSections.FAMILY_HISTORY}
            />
          )}
          {(selectedSections.VAULT_DOCS || !!vaultItems.length) && (
            <VaultDocsShareCard
              initialSelected={vaultItems}
              initialChecked={selectedSections.VAULT_DOCS}
            />
          )}
        </div>
      </div>
      <div className={classes.modalBottomContainer}>
        <div className={classes.shareButton}>
          <ThemedButton
            title={t('share_my_data')}
            isLoading={shareLoading || editShareLoading}
            onClick={onContinuePress}
          />
        </div>
        {(!!shareError || !!editShareError) && (
          <div className={classes.errorText}>
            {shareError || editShareError}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <ShareModal open={showModal} onClose={() => setShowModal(false)}>
        <RenderContent />
      </ShareModal>
      <div className={classes.navContent}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.share)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
        </div>
        <div className={classes.shareHeader}>
          <div className={classes.sectionName}>
            <InfoHeader>
              {t(isQuickShare ? 'quick_share' : 'data_shared_for', {
                shareIdentifier: email,
              })}
            </InfoHeader>
          </div>
        </div>
        <div className={classes.card}>
          {!isQuickShare && (
            <div className={classes.cardControls}>
              <NavSubheader2>{email}</NavSubheader2>
              <LinkComponent
                label={t('cancel')}
                onClick={() => history.push(ROUTES.share)}
              />
            </div>
          )}
          <div className={classes.shareSensitive}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <img
                  src={OrangeLock}
                  alt=""
                  height={15}
                  style={{ margin: '0' }}
                />
                <NavSubheader2>{t('share_sensitive_question')}</NavSubheader2>
                <img src={Info} alt="" height={17} style={{ margin: '0' }} />
              </div>

              <Switch
                // {...register}
                defaultValue={getSelection(ShareOptions.SHARE_SENSITIVE)}
                onChange={async () =>
                  setSelection(ShareOptions.SHARE_SENSITIVE)
                }
              />
            </div>
            <div>
              {getSelection(ShareOptions.SHARE_SENSITIVE) && (
                <NavSubheader2>{t('sensitive_data_warning')}</NavSubheader2>
              )}
            </div>
          </div>
          <div className={classes.shareCards}>
            <NavSubheader2>{t('select_categories')}</NavSubheader2>
            <div className={classes.categoryButtons}>
              <ThemedButton
                title={t('cov_vax')}
                isInverted={!getSelection(ShareOptions.COVID_19)}
                onClick={() => setSelection(ShareOptions.COVID_19)}
              />
              <ThemedButton
                title={t('check_up')}
                isInverted={!getSelection(ShareOptions.CHECK_UP)}
                onClick={() => setSelection(ShareOptions.CHECK_UP)}
              />
            </div>
            <ShareSectionSelector
              title={t('about_me')}
              expandable={false}
              onClick={() => {
                setSelection(DashboardSections.ABOUT_ME);
              }}
              initialChecked={selectedSections.ABOUT_ME}
            />
            <HealthInsuranceShareCard
              onClick={() => {
                setSelection(DashboardSections.INSURANCES);
              }}
              initialChecked={selectedSections.INSURANCES}
            />
            <ImmunizationsShareCard
              onClick={() => {
                setSelection(DashboardSections.IMMUNIZATIONS);
              }}
              initialChecked={selectedSections.IMMUNIZATIONS}
            />
            <MedicationsShareCard
              onClick={() => {
                setSelection(DashboardSections.MEDICATIONS);
              }}
              initialChecked={selectedSections.MEDICATIONS}
            />
            <AllergyShareCard
              onClick={() => {
                setSelection(DashboardSections.ALLERGY);
              }}
              initialChecked={selectedSections.ALLERGY}
            />
            <HealthConditionsShareCard
              onClick={() => {
                setSelection(DashboardSections.HEALTH_CONDITIONS);
              }}
              initialChecked={selectedSections.HEALTH_CONDITIONS}
            />
            <HospitalizationsShareCard
              onClick={() => {
                setSelection(DashboardSections.MEDICAL_EVENTS);
              }}
              initialChecked={selectedSections.MEDICAL_EVENTS}
            />
            <FamHistShareCard
              onClick={() => {
                setSelection(DashboardSections.FAMILY_HISTORY);
              }}
              initialChecked={selectedSections.FAMILY_HISTORY}
            />
          </div>
          <VaultDocsShareCard
            onClick={() => {
              setSelection(DashboardSections.VAULT_DOCS);
            }}
            onChange={setVaultItems}
            initialSelected={vaultItems}
            initialChecked={selectedSections.VAULT_DOCS}
          />
        </div>
        <div className={classes.card}>
          <div className={classes.cardControls}>
            <NavSubheader2>{t('add_share_settings')}</NavSubheader2>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Dropdown
              placeholder={t('select_amount')}
              optionsList={dateOptions}
              formErrors={{}}
              field={{
                onChange: val => setTimeAmount(val),
                value: timeAmount,
                name: 'selectAmmount',
              }}
            />
            <Dropdown
              placeholder={t('select_timeframe')}
              optionsList={Object.values(TimeOptions)}
              formErrors={{}}
              field={{
                onChange: val => setTimeUnits(val),
                value: timeUnits,
                name: 'selectUnits',
              }}
            />
            <div className={classes.expire}>
              {selectedSections[ShareOptions.NEVER_EXPIRE]
                ? t('never_expires')
                : hasTime
                ? new Date(expireTime).toLocaleString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })
                : expireTime}
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <NavSubheader2>{t('never_expire')}</NavSubheader2>
            <Switch
              defaultValue={getSelection(ShareOptions.NEVER_EXPIRE)}
              onChange={async () => setSelection(ShareOptions.NEVER_EXPIRE)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <NavSubheader2>{t('allow_sub_updates')}</NavSubheader2>
            <Switch
              defaultValue={getSelection(ShareOptions.ALLOW_SUBSCRIBE)}
              onChange={async () => setSelection(ShareOptions.ALLOW_SUBSCRIBE)}
            />
          </div>
        </div>
      </div>
      <div className={classes.shareButton}>
        <ThemedButton
          title={t('share_my_data')}
          isDisabled={
            (!vaultItems?.length && !sharedCards?.length) ||
            (!hasTime && !getSelection(ShareOptions.NEVER_EXPIRE))
          }
          onClick={() => setShowModal(true)}
        />
      </div>
    </div>
  );
};
