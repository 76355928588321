import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AboutMeTitle,
  CancelOptionText,
  CardDetail2,
  FormHeader,
} from '../../../components/TextComponents';
import {
  LinkComponent,
  MarkSensitive,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import { useAddVaultDocStyles } from './styles';
import { MarkDeathNote } from '../../../components/MarkDeathNote/MarkDeathNote';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useForm } from 'react-hook-form';
import { Tag } from '../../../components/Tag/Tag';
import { Colors } from '../../../config/colors';
import { createVaultDoc } from './utils';
import { useAppDispatch } from '../../../app/hooks';
import { updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { ROUTES } from '../../../config/constants';
import { uploadVaultDoc } from '../../../api/vaultUtils';
import { noSpaceInput } from '../../../config/constants';

type AddVaultDocProps = {
  user: string;
};

const defaultTags = [
  'Receipt',
  'Legal',
  'Policy',
  'Care summary',
  'Account info',
];

export const AddVaultDoc: React.FC<AddVaultDocProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation<any>();
  const queryTags = location?.state?.tags || [];
  const [isUploaded, setIsUploaded] = useState(false);
  const [pdfLink, setPdfLink] = useState('');
  const [fileType, setFileType] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [receiptClicked] = useState(false);
  const [legalClicked] = useState(false);
  const [policyClicked] = useState(false);
  const [careSummaryClicked] = useState(false);
  const [accountInfoClicked] = useState(false);
  const [customTagList, setCustomTagList] = useState([...queryTags]);
  const [selectedTags, setSelectedTags] = useState([...queryTags]);
  const classes = useAddVaultDocStyles({
    receiptClicked,
    legalClicked,
    policyClicked,
    careSummaryClicked,
    accountInfoClicked,
    theme: {},
  });

  const onDocumentLoadSuccess = () => {
    setNumPages(1);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async data => {
    const tags = selectedTags;

    try {
      const awsId = await uploadVaultDoc(pdfLink);
      //save result to storage
      const update = {
        detailItem: createVaultDoc({
          id: awsId.id,
          tags,
          url: awsId.url,
          title: data.documentName,
          contentType: fileType,
          ...data,
        }),
        section: DashboardSections.VAULT_DOCS,
      };
      dispatch(updateFhirItem(update));
      history.push(ROUTES.vaultDocs);
    } catch (error) {
      //Do something if upload fails
      console.log(`Upload Failed`, error);
    }
  };

  const setTags = (key: string, val: boolean) => {
    let tags;
    if (val) {
      tags = [...selectedTags, key];
    } else {
      tags = selectedTags.filter(t => t !== key);
    }
    setSelectedTags(tags);
  };

  const removeTag = tagName => {
    setTags(tagName, false);
    const tags = customTagList.filter(item => item !== tagName);
    setCustomTagList(tags);
  };

  const isTagChecked: (tagName: string) => boolean = tagName => {
    return selectedTags.includes(tagName);
  };

  return (
    <>
      <div className={classes.titleSection}>
        <div className={classes.cancel} onClick={() => history.goBack()}>
          <CancelOptionText>{t('cancel')}</CancelOptionText>
        </div>
        <FormHeader>{t('upload_a_document')}</FormHeader>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.uploadPageContainer}>
          <div className={classes.leftSide}>
            <div className={classes.docNameField}>
              <OneLineTextInput
                placeholder={t('document_name')}
                type="text"
                {...register('documentName', {
                  required: `${t('enter_doc_name')}`,
                  pattern: {
                    value: noSpaceInput,
                    message: `${t('enter_doc_name')}`,
                  },
                })}
                formErrors={errors}
              />
            </div>
            <div className={classes.sensitiveContainer}>
              <MarkSensitive
                defaultValue={false}
                register={{ ...register(`sensitive`) }}
              />
            </div>
            <MarkDeathNote
              defaultValue={false}
              register={{ ...register(`deathNote`) }}
            />
            <div className={classes.tagTitleContainer}>
              <AboutMeTitle>{t('add_tags')}</AboutMeTitle>
            </div>
            <div className={classes.tagSection}>
              {defaultTags?.length &&
                defaultTags.map(tag => (
                  <>
                    {tag === t('receipt') && (
                      <Tag
                        title={t('receipt')}
                        color={Colors.accent8}
                        onChange={val => setTags(t('receipt'), val)}
                      />
                    )}
                    {tag === t('care_summary') && (
                      <Tag
                        title={t('care_summary')}
                        color={Colors.accent5Purple}
                        onChange={val => setTags(`${t('care_summary')}`, val)}
                      />
                    )}
                    {tag === t('policy') && (
                      <Tag
                        title={t('policy')}
                        color={Colors.gradientLighterOrange}
                        onChange={val => setTags(`${t('policy')}`, val)}
                      />
                    )}
                    {tag === t('legal') && (
                      <Tag
                        title={t('legal')}
                        color={Colors.accent3}
                        onChange={val => setTags(`${t('legal')}`, val)}
                      />
                    )}
                    {tag === t('account_info') && (
                      <Tag
                        title={t('account_info')}
                        color={Colors.accent2}
                        onChange={val => setTags(`${t('account_info')}`, val)}
                      />
                    )}
                  </>
                ))}
              {customTagList.map((tag, index) => (
                <Tag
                  title={tag}
                  key={tag + index}
                  onChange={val => setTags(tag, val)}
                  onDelete={() => removeTag(tag)}
                  additional
                  isSelected={isTagChecked(tag)}
                />
              ))}
            </div>
            <div className={classes.addAnotherTag}>
              {isAddingTag && (
                <div className={classes.newTagField}>
                  <OneLineTextInput
                    placeholder={t('add_new_tag')}
                    maxLength={15}
                    id="newTagInputField"
                  />
                  <LinkComponent
                    label={t('Save Tag')}
                    onClick={() => {
                      const inputBox: HTMLInputElement =
                        window.document.getElementById(
                          'newTagInputField'
                        ) as HTMLInputElement;
                      const inputValue = inputBox?.value || '';
                      if (!customTagList.includes(inputValue)) {
                        setTags(inputValue, true);
                        setCustomTagList([...customTagList, inputValue]);
                      }
                      setIsAddingTag(false);
                    }}
                  />
                </div>
              )}
              <LinkComponent
                label={t('add_another_tag')}
                onClick={() => setIsAddingTag(true)}
              />
            </div>
            <div className={classes.saveButton}>
              <ThemedButton
                title={t('save')}
                type="submit"
                isDisabled={!pdfLink.length}
              />
            </div>
          </div>
          <div className={classes.rightSide}>
            <AboutMeTitle>{t('upload_your_document')}</AboutMeTitle>
            <Dropzone
              accept={'application/pdf, image/jpeg, image/png'}
              onDrop={acceptedFiles => {
                acceptedFiles.forEach(file => {
                  const reader = new FileReader();
                  reader.onabort = () =>
                    console.log('file reading was aborted');
                  reader.onerror = () => console.log('file reading has failed');
                  reader.onload = async () => {
                    const dataURL = reader.result as string;
                    localStorage.setItem(`document`, dataURL);
                    setFileType(file.type);
                    setPdfLink(reader.result as string);
                    setIsUploaded(true);
                  };
                  reader.readAsDataURL(file);
                });
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isUploaded ? (
                      <>
                        {fileType === 'application/pdf' ? (
                          <div className={classes.documentContainer}>
                            <Document
                              file={pdfLink}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={numPages}
                                className={classes.documentContainer}
                                width={350}
                              />
                            </Document>
                          </div>
                        ) : (
                          <img
                            className={classes.uploadedImg}
                            src={pdfLink}
                            alt={'uploadedImg'}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <div className={classes.uploadDocButton}>
                          <ThemedButton
                            title={t('upload_a_document')}
                            isInverted
                          />
                        </div>
                        <div className={classes.dragAndDropBox}>
                          <CardDetail2>{t('drag_and_drop_doc')}</CardDetail2>
                        </div>
                      </>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <div className={classes.bottomText}>
              <CardDetail2>{t('all_files_type')}</CardDetail2>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
