import { t } from 'i18next';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSharerInformation } from '../../../app/hooks';

import { ShowCodeBackground } from '../../../assets/images';
import { arrowLeft } from '../../../assets/images/icons';
import { ThemedButton } from '../../../components';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { ShareItem } from './ShareHome';
import { newShareConfirmationStyle } from './styles';

export const NewShareConfirmation: React.FC = () => {
  const classes = newShareConfirmationStyle();
  const history = useHistory();
  const { itemSelected } = (useLocation().state || {}) as {
    itemSelected: ShareItem;
  };

  const { request: getSharerInformation, loading: getSharerLoading } =
    useSharerInformation({
      onError: console.log,
      onSucess: () => history.push(ROUTES.dashboard),
    });

  const { username, name } = useMemo(() => {
    const username = itemSelected?.sharerName || '';
    let name = '';
    if (
      typeof itemSelected?.sharer === 'object' &&
      itemSelected?.sharer?.info
    ) {
      name = itemSelected?.sharer?.info?.fhirData?.name?.[0]?.family || '';
    }
    return { username, name };
  }, [itemSelected]);

  return (
    <div className={classes.container}>
      <div
        className={classes.backToAction}
        onClick={() => history.push(ROUTES.share)}
      >
        <div className={classes.arrowContainer}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
      </div>

      <div className={classes.shareHeader}>
        <div className={classes.sectionName}>
          <InfoHeader>{t('new_data_share')}</InfoHeader>
        </div>
      </div>
      <div className={classes.shadowContainer}>
        <div className={classes.shareTopContainer}>{username}</div>
        <div className={classes.line} />
        <img
          src={ShowCodeBackground}
          alt="showBackgroundHeader"
          className={classes.showCodeHeaderImage}
        />
        <div className={classes.header}>
          {t('is_sharing_data', { username })}
          <div className={classes.codeInfoItem}>
            {t('you_can_see_data_user', { name })}
          </div>
        </div>
        <div className={classes.shareCodeButton}>
          <ThemedButton
            title={t('view_data', { user: name })}
            onClick={() =>
              getSharerInformation({
                contract: itemSelected.contract,
                lastName: itemSelected.name,
              })
            }
            isLoading={getSharerLoading}
          />
        </div>
        <div
          className={classes.linkContainer}
          onClick={() => history.push(ROUTES.share)}
        >
          {t('done')}
        </div>
      </div>
    </div>
  );
};
