import { t } from 'i18next';
import { useAppSelector } from '../../../../app/hooks';
import { DashboardSections } from '../../DashboardSections';
import { selectSection } from '../../dashboardSlice';
import { flattenImmunizationList } from '../../immunizations/utils';
import { ShareDetailLine } from '../utils';
import { ShareSectionSelector } from './ShareSectionSelector';

export const ImmunizationsShareCard = ({
  onClick,
  initialChecked,
}: {
  onClick?: () => void;
  initialChecked?: boolean;
}) => {
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.IMMUNIZATIONS)
  );
  const flatList = flattenImmunizationList(detailList);

  const immunoNames: ShareDetailLine[] = flatList?.map(item => {
    return {
      label: item?.fhirData?.vaccineCode?.text || '',
      sensitive: false,
      id: item?.id,
    };
  });
  return (
    <ShareSectionSelector
      title={t('immunizations')}
      options={immunoNames}
      expanded={true}
      onClick={onClick}
      initialChecked={initialChecked}
    />
  );
};
