import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { searchProcedures } from '../../../api/info';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  Dropdown,
  MarkSensitive,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import { FilterTypeAhead } from '../../../components/Dropdown/FilterTypeAhead';
import {
  CardDetail1,
  FormPrompt,
  HorizontalBreak,
} from '../../../components/TextComponents';
import { ROUTES, sharedValidators } from '../../../config/constants';
import { HealthDetail } from '../../../config/SharedTypes';
import {
  DateFormats,
  getUUID,
  toDateString,
  toIsoString,
} from '../../../config/utilities';
import { SingleEncounter } from '../../../typings/vecna_types/vecnaPatient';
import { DashboardSections } from '../DashboardSections';
import { selectMedicalEvents, updateFhirItem } from '../dashboardSlice';
import { DashboardUpdate } from '../utils';
import { useEditMedicalEventStyles } from './styles';
import { flattenMedicalEvent } from './utils';

type EditMedicalEventProps = {
  detail?: HealthDetail;
};

type EditMedicalEventForm = {
  eventTypeTitle: string;
  eventName: { value: string; label: string } | string;
  date: string;
  sensitive: boolean;
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditMedicalEvent: React.FC<EditMedicalEventProps> = () => {
  const classes = useEditMedicalEventStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList: medicalEvents } = useAppSelector(selectMedicalEvents);
  const queryId = useQuery().get('id');
  const [medicalEventId] = useState(queryId || getUUID());
  const history = useHistory();
  const medEventData = medicalEvents?.find(
    el => el.fhirData.id === medicalEventId
  );

  const flatMedEvent = flattenMedicalEvent(medEventData);

  const defaultForm = medEventData
    ? {
        defaultValues: {
          eventName: flatMedEvent?.eventName,
          date: toDateString(flatMedEvent?.date, DateFormats.MMYYYY),
          sensitive: flatMedEvent?.sensitive,
        },
      }
    : {};

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditMedicalEventForm>(defaultForm);

  const handleSearchProceduress = (term: string) => {
    let result = [
      { label: `${t('type_to_search')}`, value: `${t('type_to_search')}` },
    ];
    return new Promise(async resolve => {
      if (term.length > 3) {
        const results = await searchProcedures(term);
        result =
          results?.data?.with?.map(item => ({ label: item, value: item })) ||
          [];
      }
      resolve(result);
    });
  };

  const onSubmit = data => {
    const eventTypeTitleText =
      data?.eventTypeTitle?.value || data?.eventTypeTitle;
    const eventName = data?.eventName?.value || data?.eventName;
    const update: DashboardUpdate<SingleEncounter> = {
      section: DashboardSections.MEDICAL_EVENTS,
      detailItem: {
        fhirData: {
          id: medicalEventId,
          resourceType: 'Encounter',
          status: 'unknown',
          period: { start: toIsoString(data?.date, DateFormats.MMYYYY) },
          class: { code: eventTypeTitleText },
          diagnosis: [{ condition: { display: eventName } }],
        },
        sensitive: data.sensitive,
      },
    };

    dispatch(updateFhirItem(update));
    history.push(ROUTES.medicalEventsDashboard);
  };

  const onError = errors => {
    console.error(errors);
  };

  return (
    <>
      <div className={classes.editFormPrompt}>
        <FormPrompt>{t('med_events_edit_prompt')}</FormPrompt>
      </div>
      <div className={classes.container}>
        <div className={classes.editForm}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardDetail1>{t('select_event_type')}</CardDetail1>
            <Controller
              name="eventTypeTitle"
              control={control}
              rules={{ required: 'Please select the event type' }}
              defaultValue={medEventData?.fhirData?.class?.code}
              shouldUnregister={true}
              render={({ field }) => (
                <Dropdown
                  field={field}
                  placeholder={t('event_type')}
                  optionsArr={[
                    { value: 'I', label: t('major_injury') },
                    { value: 'S', label: t('surgery') },
                    { value: 'H', label: t('hospitalization') },
                    { value: 'P', label: t('procedure_scan') },
                  ]}
                  formErrors={errors}
                />
              )}
            />
            <div className={classes.lineContainer1}>
              <HorizontalBreak />
            </div>
            <Controller
              name="eventName"
              control={control}
              rules={{ required: `${t('please_select_procedure')}` }}
              render={({ field }) => (
                <FilterTypeAhead
                  field={field}
                  placeholder={`${t('search_for_procedures_here')}`}
                  searchFunction={handleSearchProceduress}
                  formErrors={errors}
                />
              )}
            />
            <div style={{ marginTop: '10px' }} />
            <OneLineTextInput
              placeholder={t('approx_date')}
              defaultValue={toDateString(
                flatMedEvent?.date,
                DateFormats.MMYYYY
              )}
              type="text"
              {...register('date', {
                required: `${t('event_date_required')}`,
                ...sharedValidators.shortDate,
              })}
              formErrors={errors}
            />
            <div className={classes.lineContainer2}>
              <HorizontalBreak />
            </div>
            <MarkSensitive register={{ ...register('sensitive') }} />
            <div className={classes.saveButton}>
              <ThemedButton title={t('save')} type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
