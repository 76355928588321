import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { Typography } from '../../../config/typography';
export const indexStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
});

export const sharedWithMeStyles = createUseStyles({
  container: { width: '100%', display: 'flex', flexDirection: 'column' },
  imageProfile: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    marginRight: '12px',
  },
  profileName: {
    ...Typography.sectionHeader,
    whiteSpace: 'nowrap',
  },
  topContainer: { width: '100%', display: 'flex', alignItems: 'center' },
  leftContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  ongoingText: (expired: boolean) => ({
    ...Typography.cardHeader,
    display: 'flex',
    backgroundColor: expired
      ? Colors.accent3Transparent
      : Colors.accent8Transparent,
    color: expired ? Colors.accent3 : Colors.accent8,
    padding: '4px 14px',
    borderRadius: '19px',
  }),
  bottomContainer: {
    display: 'flex',
    backgroundColor: Colors.notificationBackground,
    padding: '8px 16px',
    borderRadius: '10px',
    marginTop: '8px',
    border: `1px solid ${Colors.accent11}`,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  sharedText: {
    ...Typography.cardHeader,
    width: '100%',
    textAlign: 'start',
  },
  downArrow: {
    width: '18px',
    height: '18px',
  },
  linkContainer: {
    ...Typography.linkStyle1,
    cursor: 'pointer',
    display: 'flex',
    margin: '12px 0 4px 0',
    width: 'fit-content',
  },
});
export const useShareStyles = createUseStyles({
  container: {
    padding: '32px 0px',
    display: 'block !important',
  },
  backContainer: { display: 'flex' },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0px 14px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '20px',
    background: Colors.gradientOrange,
    padding: '6px 5px 8px 5px',
  },
  iconImage: {
    width: '34px',
    height: '30px',
  },
  newShareButtonContainer: {
    marginTop: '2% !important',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  infoContainer: {
    marginTop: '4% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
    padding: '18px 24px',
  },
  whoIShareWithSwitch: ({
    isOnWhoIShare,
    showNotifications,
  }: {
    isOnWhoIShare: boolean;
    showNotifications: boolean;
  }) => ({
    ...Typography.navSubHeader2,
    background: showNotifications
      ? Colors.notificationBackground
      : isOnWhoIShare
      ? Colors.lightPurple
      : Colors.notificationBackground,
    color: showNotifications
      ? Colors.disabledText
      : isOnWhoIShare
      ? Colors.primaryText
      : Colors.disabledText,
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px 0 0 10px',
    cursor: 'pointer',
  }),
  whoSharesWithMeSwitch: ({
    isOnWhoIShare,
    showNotifications,
  }: {
    isOnWhoIShare: boolean;
    showNotifications: boolean;
  }) => ({
    ...Typography.navSubHeader2,
    background: showNotifications
      ? Colors.notificationBackground
      : isOnWhoIShare
      ? Colors.notificationBackground
      : Colors.lightPurple,
    color: showNotifications
      ? Colors.disabledText
      : isOnWhoIShare
      ? Colors.disabledText
      : Colors.primaryText,
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 10px 10px 0',
    cursor: 'pointer',
  }),
  shareButton: ({
    showNotifications,
  }: {
    isOnWhoIShare: boolean;
    showNotifications: boolean;
  }) => ({
    ...Typography.navSubHeader2,
    background: showNotifications
      ? Colors.lightPurple
      : Colors.notificationBackground,
    color: showNotifications ? Colors.primaryText : Colors.disabledText,
    height: '49px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: '0 10px',
  }),
  bellIcon: {
    width: '23px',
    height: '23px',
    marginRight: '4px',
  },
  dataContainer: {
    ...Typography.navSubHeader2,
    backgroundColor: Colors.sidebarBackground,
    padding: '9px',
    borderRadius: '10px',
    maxHeight: '20vh',
    overflow: 'scroll',
    justifyContent: 'center',
  },
  dataSubContainer: {
    backgroundColor: Colors.white,
    padding: '9px',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  viewExpiredContainer: {
    marginTop: '2% !important',
    display: 'flex',
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
    padding: '18px 24px',
  },
  viewExpiredSubContainer: {
    ...Typography.sectionSecondary,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  clockIcon: {
    width: '28px',
    height: '28px',
  },
  clockIconContainer: {
    display: 'flex',
    alignItems: 'center',
    juustifyContent: 'center',
    padding: '4px',
    borderRadius: '6px',
    backgroundColor: Colors.accent3,
    marginRight: '10px',
  },
  notificationContainer: {
    ...Typography.cardHeader,
    display: 'flex',
    width: '22px',
    height: '18px',
    color: Colors.white,
    backgroundColor: Colors.errorRed,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7px',
  },
});

export const addShareStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '64%',
  },
  navContent: {
    padding: '20px 50px 0 50px',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '20px 33px 0 32px',
    },
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 14px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '14px',
    background: Colors.gradientOrange,
  },
  iconImage: {
    width: '44px',
    height: '44px',
    padding: '8px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginBottom: '20px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    background: Colors.white,
    borderRadius: '20px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    textAlign: 'left',
    padding: '19px 36px',
    alignItems: 'center',
  },
  title: { display: 'flex', flexDirection: 'column' },

  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '31px',
    marginBottom: '22px',
    gap: '38px',
    minWidth: '50%',
  },
});

export const useShareSelectorStyles: (
  data?: { isExpanded: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<any> = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '486px',
    left: '479px',
    top: '617px',
    background: Colors.notificationBackground,
    border: `2px solid ${Colors.lightPurple}`,
    borderRadius: '6px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  checkName: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px',
  },
  detailList: ({ isExpanded }) => ({
    height: isExpanded ? 'unset' : '0px',
    width: isExpanded ? '100%' : '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    borderTop: `2px solid ${Colors.lightPurple}`,
    paddingTop: isExpanded ? '10px' : '0px',
  }),
  detailLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    paddingLeft: '30px',
  },
  secureDetailLine: {
    display: 'flex',
    marginBottom: '10px',
    paddingLeft: '30px',
    alignItems: 'center',
    color: Colors.gradientLighterOrange,
  },
});

export const quickShareStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '64%',
  },
  navContent: {
    padding: '20px 50px 0 50px',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '20px 33px 0 32px',
    },
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 14px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '14px',
    background: Colors.gradientOrange,
  },
  iconImage: {
    width: '44px',
    height: '44px',
    padding: '8px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  bodyText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginBottom: '20px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    background: Colors.white,
    borderRadius: '20px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    textAlign: 'left',
    padding: '19px 36px',
    marginBottom: '16px',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '38px',
    gap: '60px',
    padding: '0 20%',
  },
  cardControls: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    padding: '0 36px',
    margin: '0 -36px',
    borderBottom: `1px solid ${Colors.lightPurple}`,
  },
  shareSensitive: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    padding: '0 36px',
    margin: '0 -36px',
    borderBottom: `1px solid ${Colors.lightPurple}`,
    color: Colors.gradientLighterOrange,
  },
  shareCards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    padding: '0 36px',
    margin: '0 -36px',
    borderBottom: `1px solid ${Colors.lightPurple}`,
    gap: '10px',
  },
  expire: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '57px',
    left: '795px',
    top: '1934px',
    background: Colors.notificationBackground,
    border: `2px solid ${Colors.notificationBorder}`,
    borderRadius: '6px',
    fontFamily: "'Inter'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.3px',
    color: Colors.idIconBackground,
  },
  categoryButtons: {
    display: 'flex',
    gap: '18px',
  },
  shareButton: {
    width: '50%',
    margin: '10px 0px',
  },
  errorText: {
    ...Typography.errorMessage,
    marginBottom: '20px',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.white,
    borderRadius: '10px',
    boxShadow: `0px 0px 4px ${Colors.boxShadow}`,
    textAlign: 'left',
    maxHeight: '80vh',
  },
  modalSubContainer: {
    boxShadow: `0px 0px 4px 0px ${Colors.boxShadow}`,
    margin: '0 36px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '6px',
  },
  modalTopContainer: {
    ...Typography.navSubHeader1,
    display: 'flex',
    borderBottom: `1px solid ${Colors.accent11}`,
    marginBottom: '17px',
    padding: '26px 36px',
    justifyContent: 'space-between',
  },
  modalDataContainer: {
    ...Typography.cardSecondary,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflow: 'scroll',
    padding: '16px 24px',
  },
  modalBottomContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  starIcon: {
    width: '34px',
    heigth: '32px',
  },
  userContainer: {
    ...Typography.sectionHeader,
    color: Colors.accent5Purple,
    display: 'flex',
    borderBottom: `1px solid ${Colors.sidebarBackground}`,
    padding: '10px 24px',
    alignItems: 'center',
    gap: '16px',
  },
  extraDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '10px 24px',
    borderBottom: `1px solid ${Colors.sidebarBackground}`,
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  extraDataSubContainer: {
    display: 'flex',
    ...Typography.formSecondary,
    gap: '10px',
    alignItems: 'center',
  },
});

export const showCode = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: '30px 0px',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 18px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  shadowContainer: {
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    backgroundColor: Colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    marginBottom: '50px',
  },
  showCodeHeaderImage: {
    width: '50%',
    marginTop: '-30px',
    minWidth: '342px',
  },
  header: {
    ...Typography.formHeader,
    margin: '32px 0 22px 0',
  },
  codeContainer: {
    ...Typography.formHeader,
    border: `2px solid ${Colors.lightPurple}`,
    padding: '14px 34px 14px 46px',
    borderRadius: '6px',
    marginBottom: '38px',
    letterSpacing: '0.75em',
  },
  codeInfoContainer: {
    width: '56%',
    textAlign: 'left',
  },
  codeInfo: { ...Typography.sectionSecondary },
  codeInfoItem: {
    ...Typography.formSecondary,
    gap: '20px',
    display: 'flex',
    marginTop: '24px',
  },
  shareCodeButton: {
    marginTop: '28px',
    width: '60%',
    fontSize: '30px',
  },
  doneButton: {
    ...Typography.linkStyle1,
    textDecorationLine: 'underline',
    margin: '43px 0 35px 0',
    cursor: 'pointer',
  },
});
export const confirmation = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: '30px 0px',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 18px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  shadowContainer: {
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    backgroundColor: Colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    marginBottom: '50px',
  },
  showCodeHeaderImage: {
    width: '50%',
    marginTop: '-30px',
    minWidth: '342px',
  },
  header: {
    ...Typography.formHeader,
    margin: '32px 0 0 0',
  },
  codeInfoItem: {
    ...Typography.formSecondary,
    display: 'flex',
    marginTop: '20px',
  },
  shareCodeButton: {
    margin: '28px 0 35px 0',
    fontSize: '30px',
    width: '40%',
  },
  shareTopContainer: {
    ...Typography.navSubHeader1,
    padding: '20px 0 20px 40px',
    alignSelf: 'flex-start',
  },
  line: {
    border: `1px solid ${Colors.accent11}`,
    width: '100%',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const notificationStyles = createUseStyles({
  container: { width: '100%', display: 'flex', flexDirection: 'column' },
  profileName: {
    ...Typography.sectionHeader,
    display: 'flex',
  },
  topContainer: { width: '100%', display: 'flex', alignItems: 'center' },
  linkContainer: {
    ...Typography.linkStyle1,
    cursor: 'pointer',
    display: 'flex',
    margin: '12px 0 4px 0',
    width: 'fit-content',
    textDecoration: 'underline',
  },
  notificationNumberContainer: {
    ...Typography.cardHeader,
    display: 'flex',
    width: '22px',
    height: '18px',
    color: Colors.white,
    backgroundColor: Colors.errorRed,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7px',
    margin: '0 12px',
  },
  buttonsContainer: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: '20px 0 0 0',
  },
});

export const expiredStyles = createUseStyles({
  container: {
    padding: '32px 0px',
    display: 'block !important',
  },
  backContainer: { display: 'flex' },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  infoContainer: {
    marginTop: '4% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
    padding: '18px 24px',
  },
  whoIShareWithSwitch: {
    ...Typography.navSubHeader2,
    background: Colors.accent2,
    color: Colors.white,
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px 0 0 10px',
    cursor: 'pointer',
  },
  whoSharesWithMeSwitch: {
    ...Typography.navSubHeader2,
    background: Colors.notificationBackground,
    color: Colors.disabledText,
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 10px 10px 0',
    cursor: 'pointer',
  },
  dataContainer: {
    ...Typography.navSubHeader2,
    backgroundColor: Colors.sidebarBackground,
    padding: '9px',
    borderRadius: '10px',
    maxHeight: '20vh',
    overflow: 'scroll',
    justifyContent: 'center',
  },
  dataSubContainer: {
    backgroundColor: Colors.white,
    padding: '9px',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  sectionSubtitle: {
    ...Typography.formSecondary,
    color: Colors.secondaryText,
    textAlign: 'left',
  },
  selectedInfo: {
    ...Typography.cardSecondary,
  },
});
export const detailStyles = createUseStyles({
  container: {
    padding: '26px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subContainer: {
    width: '64%',
    display: 'flex',
    flexDirection: 'column',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '16px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  infoContainer: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
    padding: '18px 0',
    flexDirection: 'column',
  },
  youAreViewingContainer: {
    ...Typography.navSubHeader1,
    width: '100%',
    backgroundColor: Colors.gradientLighterOrange,
    color: Colors.white,
    padding: '15px 0 10px 0',
    boxShadow: `0px 0px 6px 0px ${Colors.shadow}`,
  },
  username: {
    ...Typography.navSubHeader1,
    width: '90%',
    textAlign: 'left',
  },
  line: {
    marginTop: '26px',
    border: `1px solid ${Colors.accent11}`,
    width: '100%',
  },
  subInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.shadow2}`,
    borderRadius: '6px',
    padding: '20px 0px',
    flexDirection: 'column',
    width: '90%',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  iconLabel: {
    ...Typography.formSecondary,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  topSubContainer: {
    display: 'flex',
    gap: '25px',
    flexDirection: 'column',
    padding: '0 20px',
  },
  subContainerLine: {
    marginTop: '19px',
    border: `1px solid ${Colors.sidebarBackground}`,
  },
  bottomSubContainer: {
    ...Typography.cardSecondary,
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    padding: '18px 20px',
    textAlign: 'left',
  },
  sharedItemContainer: {
    ...Typography.formSecondary,
    backgroundColor: Colors.notificationBackground,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    border: `2px solid ${Colors.accent11}`,
  },
  sharedItemLabel: {
    display: 'flex',
    ...Typography.formSecondary,
    padding: '20px',
    justifyContent: 'space-between',
  },
  downArrow: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
  },
  downArrowInverted: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    transform: 'rotate(180deg)',
  },
  sharedSubItemsContainer: {
    ...Typography.bodyText2,
    display: 'flex',
    gap: '9px',
    padding: '10px 20px',
    flexDirection: 'column',
  },
  sharedSubItem: {
    display: 'flex',
  },
  sharedItemLine: {
    border: `1px solid ${Colors.accent11}`,
  },
  centerLoading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const confirmationShareStyles = createUseStyles({
  container: {
    padding: '26px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '16px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  infoContainer: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.white,
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    borderRadius: '10px',
    padding: '18px 0',
    flexDirection: 'column',
  },
  username: {
    ...Typography.navSubHeader1,
    width: '90%',
    textAlign: 'left',
  },
  line: {
    marginTop: '26px',
    border: `1px solid ${Colors.accent11}`,
    width: '100%',
  },
  subInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.shadow2}`,
    borderRadius: '6px',
    padding: '20px 0px',
    flexDirection: 'column',
    width: '90%',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  iconLabel: {
    ...Typography.formSecondary,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  topSubContainer: {
    display: 'flex',
    gap: '14px',
    flexDirection: 'column',
    padding: '16px 20px',
    borderBottom: `1px solid ${Colors.sidebarBackground}`,
  },
  topSubContainer2: {
    ...Typography.sectionHeader,
    color: Colors.accent5Purple,
    display: 'flex',
    gap: '18px',
    padding: '0 20px 10px 20px',
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.sidebarBackground}`,
  },
  star: {
    width: '34px',
    height: '32px',
  },
  bottomSubContainer: {
    ...Typography.cardSecondary,
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    padding: '18px 20px',
    textAlign: 'left',
  },
  sharedItemContainer: {
    ...Typography.formSecondary,
    backgroundColor: Colors.notificationBackground,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    border: `2px solid ${Colors.accent11}`,
  },
  sharedItemLabel: {
    display: 'flex',
    ...Typography.formSecondary,
    padding: '20px',
    justifyContent: 'space-between',
  },
  downArrow: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
  },
  downArrowInverted: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    transform: 'rotate(180deg)',
  },
  sharedSubItemsContainer: {
    ...Typography.bodyText2,
    display: 'flex',
    gap: '9px',
    padding: '10px 20px',
    flexDirection: 'column',
  },
  sharedSubItem: {
    display: 'flex',
  },
  sharedItemLine: {
    border: `1px solid ${Colors.accent11}`,
  },
  centerLoading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subscribeContainer: {
    ...Typography.cardSecondary,
    display: 'flex',
    gap: '20px',
    width: '90%',
    marginTop: '10px',
    alignItems: 'center',
  },
  linkContainer: {
    ...Typography.linkStyle1,
    cursor: 'pointer',
    display: 'flex',
    margin: '12px 0 4px 0',
    width: 'fit-content',
    textDecoration: 'underline',
  },
  buttonsContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: '20px 0 0 0',
  },
  errorText: {
    ...Typography.errorMessage,
    marginBottom: '20px',
  },
});

export const newShareConfirmationStyle = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: '30px 0px',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 18px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  shadowContainer: {
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    backgroundColor: Colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    marginBottom: '50px',
  },
  showCodeHeaderImage: {
    width: '50%',
    marginTop: '-30px',
    minWidth: '342px',
  },
  header: {
    ...Typography.formHeader,
    margin: '32px 0 0 0',
  },
  codeInfoItem: {
    ...Typography.formSecondary,
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'center',
  },
  shareCodeButton: {
    margin: '28px 0 0 0',
    fontSize: '30px',
    width: '40%',
  },
  shareTopContainer: {
    ...Typography.navSubHeader1,
    padding: '20px 0 20px 40px',
    alignSelf: 'flex-start',
  },
  line: {
    border: `1px solid ${Colors.accent11}`,
    width: '100%',
  },
  linkContainer: {
    ...Typography.linkStyle1,
    cursor: 'pointer',
    display: 'flex',
    margin: '20px 0 28px 0',
    width: 'fit-content',
  },
});

export const quickShareCodeStyle = createUseStyles({
  topContainer: {
    margin: '40px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    color: Colors.primaryText,
    marginBottom: '20px',
  },
  errorText: {
    ...Typography.errorMessage,
    textAlign: 'center',
    marginTop: '10px',
  },
  codeInputArea: {
    display: 'flex',
    justifyContent: 'center',
    gap: '14px',
    margin: '18px 0 58px',
  },
  digitInputBox: {
    border: `1px solid ${Colors.primaryText}`,
    borderRadius: '10px',
    width: '45px',
    height: '49px',
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    fontSize: '35px',
    fontWeight: '500',
    color: Colors.primaryText,
    textTransform: 'uppercase',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    margin: '30px 0px',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  shareHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.primaryText,
    margin: '27px 0 18px',
  },
  sectionName: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  shadowContainer: {
    boxShadow: `0px 4px 22px 5px ${Colors.shadow2}`,
    backgroundColor: Colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    marginBottom: '50px',
    textAlign: 'left',
  },
  bottomButton: {
    marginTop: '28px',
    fontSize: '30px',
    width: '40%',
    alignSelf: 'center',
  },
});
