import { cardContainer } from '../styles';
import {
  textPageStyles,
  centeredContent,
  bottomButton,
} from '../../../config/sharedStyles';

export const styles = {
  cardContainer,
  ...textPageStyles,
  centeredContent,
  bottomButton,
  imgUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 0 42px',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '34px',
  },
  asteriskNote: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    marginLeft: '35px',
  },
  reUploadContainer: {
    marginTop: '12px',
  },
};
