import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserInformation } from '../../../api/user';
import { useRequest } from '../../../app/hooks';
import { ShowCodeBackground } from '../../../assets/images';
import { arrowLeft } from '../../../assets/images/icons';
import { ThemedButton } from '../../../components';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { confirmation } from './styles';

export const ShareConfirmation: React.FC = () => {
  const classes = confirmation();
  const history = useHistory();
  const { email = '' } = useLocation().state || ({} as any);
  const {
    request: getUserInfoRequest,
    data: userInfo,
    loading: isLoading,
  } = useRequest(getUserInformation);

  const { userName, name } = useMemo(() => {
    let usernameResult = email;
    let nameResult = '';
    const result = userInfo?.with;
    if (result) {
      usernameResult = `@${result?.username}` || '';
      nameResult = result?.info?.fhirData?.name[0]?.given[0] || '';
    }
    return {
      userName: usernameResult,
      name: nameResult,
    };
  }, [userInfo]);
  useEffect(() => {
    getUserInfoRequest(email);
  }, []);

  return (
    <div className={classes.container}>
      <div
        className={classes.backToAction}
        onClick={() => history.push(ROUTES.share)}
      >
        <div className={classes.arrowContainer}>
          <img src={arrowLeft} alt="arrowLeft" />
        </div>
        <AboutMeTitle>{t('back_to_constellation')}</AboutMeTitle>
      </div>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress style={{ display: 'flex' }} />
        </div>
      ) : (
        <>
          <div className={classes.shareHeader}>
            <div className={classes.sectionName}>
              <InfoHeader>
                {t('data_shared_for', {
                  shareIdentifier: userName,
                })}
              </InfoHeader>
            </div>
          </div>
          <div className={classes.shadowContainer}>
            <div className={classes.shareTopContainer}>{userName}</div>
            <div className={classes.line} />
            <img
              src={ShowCodeBackground}
              alt="showBackgroundHeader"
              className={classes.showCodeHeaderImage}
            />
            <div className={classes.header}>
              {t('confirmation_share_title', { shareIdentifier: userName })}
              <div className={classes.codeInfoItem}>
                {t(
                  name
                    ? 'confirmation_share_subtitle_user'
                    : 'confirmation_share_subtitle',
                  { name }
                )}
              </div>
            </div>
            <div className={classes.shareCodeButton}>
              <ThemedButton
                title={t('done')}
                onClick={() => history.push(ROUTES.share)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
