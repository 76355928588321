import { SingleMedication } from '../../../typings/vecna_types/vecnaPatient';
import {
  DateFormats,
  getUUID,
  toDateString,
  toIsoString,
} from '../../../config/utilities';
import { EditMedicationForm } from './EditMedication';

export const formToMedication = (
  data: EditMedicationForm,
  id?: string
): SingleMedication => {
  const result: SingleMedication = {
    fhirData: {
      id: id || getUUID(),
      status: data.current ? 'active' : 'completed',
      subject: {},
      resourceType: 'MedicationStatement',
      effectivePeriod: {
        start: toIsoString(data?.startDate, DateFormats.MMYYYY),
        ...(!data.current && {
          end: toIsoString(data?.endDate, DateFormats.MMYYYY),
        }),
      },
      medicationCodeableConcept: {
        text:
          typeof data?.title === 'string' ? data?.title : data?.title?.value,
      },
      dosage: [
        {
          doseAndRate: [
            {
              doseQuantity: {
                value: Number(data?.dosageValue),
                unit: data?.dosageUnit?.value || data?.dosageUnit,
              },
            },
          ],
          timing: { code: { text: data?.regimen } },
          method: { text: data?.dosageForm?.value || data?.dosageForm },
        },
      ],
    },
    sensitive: data.sensitive,
  };

  return result;
};

export const flattenMedication = (med: SingleMedication): any => {
  if (!med) return {};

  const dosageValue =
    med?.fhirData?.dosage[0]?.doseAndRate[0]?.doseQuantity?.value;
  const dosageUnit =
    med?.fhirData?.dosage[0]?.doseAndRate[0]?.doseQuantity?.unit;
  const dosageForm = med?.fhirData?.dosage[0]?.method?.text;
  const regimen = med?.fhirData?.dosage[0]?.timing?.code?.text;
  const dosageString = [
    `${med?.fhirData?.dosage[0]?.doseAndRate[0]?.doseQuantity?.value}${med?.fhirData?.dosage[0]?.doseAndRate[0]?.doseQuantity?.unit}`,
    `${med?.fhirData?.dosage[0]?.timing?.code?.text}`,
  ];
  return {
    ...med,
    id: med.fhirData.id,
    title: med?.fhirData?.medicationCodeableConcept?.text,
    detailItems: [[...dosageString]],
    startDate: toDateString(
      med?.fhirData?.effectivePeriod?.start,
      DateFormats.MMYYYY
    ),
    endDate: toDateString(
      med?.fhirData?.effectivePeriod?.end,
      DateFormats.MMYYYY
    ),
    current: !med?.fhirData?.effectivePeriod?.end?.length,
    dosageUnit,
    dosageValue,
    dosageForm,
    regimen,
  };
};

export const dosageUnits = [
  { value: 'ml', label: 'ml' },
  { value: 'L', label: 'L' },
  { value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
];
