import { ENDPOINTS, getConfiguredAxios } from '../../../api/axios';
import { getUUID } from '../../../config/utilities';
import { VecnaPatient, Patient } from '../../../typings';
import { getGender } from '../../create-account/confirm-information/utils';
import { LoginFormType } from '../../create-account/create-login/CreateLogin';

export const isUsernameTaken: (
  username: string
) => Promise<boolean> = async username => {
  const axiosClient = getConfiguredAxios({ isPublic: false });
  const result = await axiosClient
    .get(ENDPOINTS.getUsernameExists(username))
    .then(response => !!response?.data?.with)
    .catch(error => {
      //set error message
      console.error('Error with view share', error);
      return true;
    });
  return result;
};

export const createNewDependentUser = (
  userData: any,
  accountData: LoginFormType,
  profilePhoto?: string,
  verifyDoc?: any
): VecnaPatient => {
  const vaultItems = verifyDoc ? [verifyDoc] : [];
  const fhir: Patient = {
    resourceType: 'Patient',
    address: [
      {
        id: getUUID(),
        state: userData.state,
        city: userData.city,
        line: [userData.address],
        postalCode: userData.postalCode,
      },
    ],
    birthDate: userData.dateOfBirth,
    gender: getGender(userData.gender),
    contact: [],
    name: [
      {
        family: userData.lastName,
        given: [userData.firstName],
        use: 'official',
      },
    ],
    photo: [{ url: profilePhoto }],
    telecom: [{ value: accountData?.phone || '' }],
    maritalStatus: { text: '' },
  };

  const newUser: VecnaPatient = {
    id: getUUID(),
    email: accountData.email,
    fhirData: fhir,
    healthData: {
      medicalEncounters: undefined,
      medications: undefined,
      immunizations: undefined,
      allergies: undefined,
      conditions: undefined,
    },
    additionalData: {
      ssn4: userData.ssn,
      idNumber: userData.idNumber,
      idExpiration: userData.idExpiration,
      idType: userData.idType,
      username: accountData.username,
      preferredName: '',
      identityGender: '', //ToDo Is this the right place for this?
      military: {
        status: { fhirData: { text: '' } }, //Possible Values? V
        retirementDate: '', //Should this be undefinded on create?
        identifier: '',
      },
      physical: {
        height: NaN, // TODO: no input yet
        heightUnit: 'in', // TODO: no input yet
        eyeColor: { fhirData: { text: '' } }, // TODO: no input yet
        ethnicity: { fhirData: { text: '' } }, //ToDo No intake input yet
      },
      householdIncome: { fhirData: { text: '' } },
      stateIdPhoto: [
        {
          fhirData: { url: '' }, // Front
        },
        {
          fhirData: { url: '' }, // Back
        },
      ],
    },
    familyHistory: undefined,
    insuranceData: undefined,
    vaultData: vaultItems,
  };

  return newUser;
};

type CreateDependent = {
  username: string;
  id: string;
  healthData: any;
};

export const createDependentCall: (
  userData: CreateDependent
) => Promise<void> = async userData => {
  const createDependentRequest = {
    secretIdentifier: userData.id,
    username: userData.username,
  };
  const axiosClient = getConfiguredAxios({ isPublic: false });

  //Call to Create Dependent
  const createDependentResultId = await axiosClient
    .post(ENDPOINTS.createDependent, createDependentRequest)
    .then(response => {
      if (response?.data?.with) {
        return response?.data?.with;
      } else {
        //username doesnt exist
        throw new Error('Create dependent did not return userId');
      }
    })
    .catch(error => {
      //set error message
      console.error('Error with create dependent request', error);
      throw error;
    });

  //Call to Populate dependent date
  const postDependentDataResult = await axiosClient
    .post(
      ENDPOINTS.updateDependentDataPost(createDependentResultId),
      userData.healthData
    )
    .then(response => {
      if (response?.status === 200) {
        //If we can set the data, return the dependent ID
        return createDependentResultId;
      } else {
        //username doesnt exist
        throw new Error(
          `Error Setting Dependent Data: response code ${response?.status} body ${response?.data}`
        );
      }
    })
    .catch(error => {
      //set error message
      console.error('Error with loading dependent data', error);
      throw error;
    });

  return postDependentDataResult;
};
