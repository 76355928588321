import { useEffect } from 'react';
import { OneLineTextInput } from '../../../components';
import { sharedValidators, noSpaceInput } from '../../../config/constants';
import i18n from '../../../translations';
import { useTranslation } from 'react-i18next';

type AddressProps = {
  address: any;
  formControls: any;
  classes: any;
  index: number;
};
export const Address: React.FC<AddressProps> = ({
  address,
  index,
  formControls,
  classes,
}) => {
  const { register, unregister, errors } = formControls;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      unregister(`address.${index}`);
    };
  }, []);

  return (
    <>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('address')}
          type="text"
          formErrors={errors}
          defaultValue={address?.line[0]}
          {...register(`address.${index}.line`, {
            required: `${t('address_required')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('address_required')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={`${i18n.t('city')}`}
          type="text"
          defaultValue={address?.city}
          formErrors={errors}
          {...register(`address.${index}.city`, {
            required: `${t('city_required')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('city_required')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={`${i18n.t('state')}`}
          defaultValue={address?.state}
          type="text"
          formErrors={errors}
          {...register(`address.${index}.state`, {
            required: `${t('state_required')}`,
            pattern: {
              value: noSpaceInput,
              message: `${t('state_required')}`,
            },
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('zip_code')}
          type="text"
          defaultValue={address?.postalCode}
          formErrors={errors}
          {...register(`address.${index}.postalCode`, {
            required: `${t('zip_required')}`,
            ...sharedValidators.zipCode,
          })}
        />
      </div>
    </>
  );
};
