import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  ContentBox,
  Modal,
  RadioList,
  ThemedButton,
} from '../../../components';
import {
  CardBody,
  PageHeader,
  SectionSecondary,
} from '../../../components/TextComponents';
import {
  selectOnboarding,
  setOnboarding,
} from '../../dashboard/dashboardSlice';
import { useVerifyIdentityStyles } from './styles';
import { getQuestions } from './utils';

export const VerifyIdentity = () => {
  const { t } = useTranslation();
  const classes = useVerifyIdentityStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { confirmInformation } = useAppSelector(selectOnboarding);
  const [isLoading, setIsLoading] = useState(true);
  const [questionPool, setQuestionPool] = useState([]);
  const [errorCount, setErrorCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [questions, setQuestions] = useState([]);

  const handleFailure = () => {
    setOpenModal(false);
    window.sessionStorage.clear();
    history.replace('');
  };

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = data => {
    const answers = Object.keys(data).map(key => data[key]);
    if (answers.includes(null)) {
      //An option wasn't selected
      setErrorMessage(t('verify_identity_select_both'));
      return;
    }
    const answeredFalse = answers.includes('false');
    if (answeredFalse) {
      if (errorCount === 0) {
        setErrorMessage(t('verify_identity_incorrect_answer'));
        setQuestions([questionPool[2], questionPool[3]]);
        reset();
        setErrorCount(1);
      } else {
        // Show Modal
        setOpenModal(true);
      }
    } else {
      //Navigate to create login
      dispatch(setOnboarding({ progressPercent: 50 }));
      history.push('createLogin');
    }
  };

  useEffect(() => {
    let active = true;

    const fetchData = async () => {
      try {
        const questionsFromApi = await getQuestions(confirmInformation);
        if (!active) {
          return;
        }
        setQuestionPool(questionsFromApi);
        setQuestions([questionsFromApi[0], questionsFromApi[1]]);
        setIsLoading(false);
      } catch (error) {
        setApiErrorMessage(t('verify_identity_an_error'));
        setIsLoading(false);
        return;
      }
    };

    fetchData();
    return () => {
      active = false;
    };
  }, []);

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('verify_your_identity')} slimTitle>
        {apiErrorMessage.length ? (
          <div className={classes.errorMessage}>{apiErrorMessage}</div>
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <CardBody>{t('verify_identity_1')}</CardBody>
            {!!errorMessage.length && (
              <div className={classes.errorMessage}>{errorMessage}</div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              {questions?.map((item, index) => (
                <div className={classes.questionContainer}>
                  <RadioList
                    title={item.questionText}
                    optionsArr={item.answerOptions}
                    register={{
                      name: `${index}`,
                      register: register,
                    }}
                  />
                </div>
              ))}
              <div className="buttonContainer" style={{ paddingTop: '12px' }}>
                <ThemedButton title={t('continue')} type="submit" />
              </div>
            </form>
          </>
        )}
      </ContentBox>
      <Modal open={openModal} hideControls onClose={handleFailure}>
        <div className={classes.modalContent}>
          <PageHeader>{t('verify_identity_thanks')}</PageHeader>
          <SectionSecondary>{t('verify_identity_too_many')}</SectionSecondary>
          <div style={{ maxWidth: '50%' }}>
            <ThemedButton title={t('okay')} onClick={handleFailure} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
