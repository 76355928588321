import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import {
  ContentBox,
  Dropdown,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import { ROUTES, sharedValidators } from '../../../config/constants';
import { InputType } from '../../../config/SharedTypes';
import { DateFormats, toIsoString } from '../../../config/utilities';
import i18n from '../../../translations';
import { setOnboarding } from '../../dashboard/dashboardSlice';
import { styles } from './styles';

const useConfirmInformationStyles = createUseStyles(styles);

export type ConfirmInformationFormData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ssn: string;
  address: string;
  city: string;
  state: string;
  gender: 'Male' | 'Female' | 'Other';
  idType: string;
  idNumber?: string;
  idExpiration?: string;
};

const formFields = [
  {
    placeholder: i18n.t('first_name_title_case'),
    formType: InputType.TEXT,
    name: 'firstName',
    validate: { required: i18n.t('enter_first_name') },
  },
  {
    placeholder: i18n.t('last_name_title_case'),
    formType: InputType.TEXT,
    name: 'lastName',
    validate: { required: i18n.t('enter_last_name') },
  },
  {
    placeholder: i18n.t('dob_title_case_format'),
    formType: InputType.TEXT,
    name: 'dateOfBirth',
    validate: {
      required: i18n.t('enter_dob'),
      ...sharedValidators.longDate,
    },
  },
  {
    placeholder: i18n.t('street_number'),
    formType: InputType.TEXT,
    name: 'address',
    validate: { required: i18n.t('enter_street_address') },
  },
  {
    placeholder: i18n.t('city'),
    formType: InputType.TEXT,
    name: 'city',
    validate: { required: i18n.t('enter_city') },
  },
  {
    placeholder: i18n.t('state'),
    formType: InputType.TEXT,
    name: 'state',
    validate: { required: i18n.t('enter_state') },
  },
  {
    placeholder: i18n.t('zip_code'),
    formType: InputType.TEXT,
    name: 'zipCode',
    validate: {
      required: i18n.t('enter_zip_code'),
      ...sharedValidators.zipCode,
    },
  },
  {
    placeholder: i18n.t('last_4_of_ssn'),
    formType: InputType.TEXT,
    name: 'ssn',
    validate: {
      required: i18n.t('enter_ssn'),
      ...sharedValidators.last4SSN,
    },
  },
  {
    placeholder: i18n.t('license_number'),
    formType: InputType.TEXT,
    name: 'idNumber',
    validate: { required: `Please enter an identification number.` },
  },
  {
    placeholder: i18n.t('license_expiration'),
    formType: InputType.TEXT,
    name: 'idExpiration',
    validate: {},
  },
  {
    placeholder: i18n.t('gender'),
    formType: InputType.DROPDOWN,
    name: 'gender',
    optionsList: [
      i18n.t('gender_male'),
      i18n.t('gender_female'),
      i18n.t('other'),
    ],
    validate: { required: i18n.t('select_gender') },
  },
  {
    placeholder: i18n.t('organ_donor'),
    formType: InputType.DROPDOWN,
    name: 'organDonor',
    optionsList: [i18n.t('yes'), i18n.t('no')],
    validate: {},
  },
];

export const ConfirmInformation = () => {
  const { t } = useTranslation();
  const classes = useConfirmInformationStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ConfirmInformationFormData>();

  const onSubmit = data => {
    // TODO: Do Stuff on Form Submit
    const update = {
      confirmInformation: {
        ...data,
        dateOfBirth: toIsoString(data.dateOfBirth, DateFormats.MMDDYYYY),
      },
      progressPercent: 25,
    };
    dispatch(setOnboarding(update));
    history.push(ROUTES.verifyIdentity);
  };

  const onError = (errors, e) => {
    // TODO: Do Stuff on Form Error
    console.error(errors);
    console.error(e);
  };

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('complete_info_title')} slimTitle>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <>
            {formFields.map(element => {
              if (element.formType === InputType.DROPDOWN) {
                return (
                  <Controller
                    name={element.name as any}
                    control={control}
                    rules={element.validate}
                    render={({ field }) => (
                      <Dropdown
                        field={field}
                        placeholder={element.placeholder}
                        optionsList={element.optionsList}
                        formErrors={errors}
                      />
                    )}
                  />
                );
              } else if (element.formType === InputType.TEXT) {
                return (
                  <OneLineTextInput
                    placeholder={element.placeholder}
                    type="text"
                    formErrors={errors}
                    {...register(element.name as any, {
                      ...element.validate,
                    })}
                  />
                );
              } else return <></>;
            })}
          </>

          <div className="buttonContainer" style={{ paddingTop: '12px' }}>
            <ThemedButton
              title={t('continue')}
              type="submit"
              onClick={() => {}}
            />
          </div>
        </form>
      </ContentBox>
    </div>
  );
};
