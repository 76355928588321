import { create } from 'apisauce';

const apiClient = create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
});

export default apiClient;
