import { createListenerMiddleware } from '@reduxjs/toolkit';
import { ROUTES } from '../../config/constants';
import { loginActionCreator, setSelectedUser } from '../login/loginSlice';

import {
  addItems,
  dismissFhirSection,
  dismissSection,
  getProfileThunk,
  setProfileThunk,
  updateAddress,
  updateFhirItem,
  updateInfoSection,
  updateItem,
  updateImmunization,
  deleteImmunization,
  deleteFhirItem,
} from './dashboardSlice';

// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

const callSetProfile = async (action, listenerApi) => {
  // Run whatever additional side-effect-y logic you want here
  listenerApi.dispatch(setProfileThunk());
};

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
const actionsToCallSetProfileFor = [
  addItems,
  updateFhirItem,
  updateItem,
  updateInfoSection,
  updateAddress,
  dismissFhirSection,
  dismissSection,
  updateImmunization,
  deleteImmunization,
  deleteFhirItem,
];

const actionsToCallGetProfileFor = [loginActionCreator, setSelectedUser];

export const listenersToSet = actionsToCallSetProfileFor.map(action => {
  return listenerMiddleware.startListening({
    actionCreator: action,
    effect: callSetProfile,
  });
});

export const listenersToGet = actionsToCallGetProfileFor.map(action => {
  return listenerMiddleware.startListening({
    actionCreator: action,
    effect: async (action, listenerApi) => {
      // Run whatever additional side-effect-y logic you want here
      listenerApi.dispatch(getProfileThunk()).then(() => {
        if (window.location.href.includes('edit')) {
          //If the user is editing something when they switch profiles, navigate them to the dashboard
          window.location.replace(ROUTES.dashboard);
        }
      });
    },
  });
});
