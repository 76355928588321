import { useEffect, useMemo } from 'react';

import { Grid, CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { arrowLeft } from '../../../assets/images/icons';
import { AboutMeTitle, InfoHeader } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { expiredStyles } from './styles';
import { getMyShared, SharedObject } from '../../../api/share';
import { useRequest } from '../../../app/hooks';
import SharedWithMe from './components/SharedWithMe';
import { ShareItem } from './ShareHome';
import { compareCreatedDate } from './utils';
export const ShareExpired: React.FC = () => {
  const classes = expiredStyles();
  const history = useHistory();

  const {
    request: getMySharedRequest,
    data: getMySharedData,
    loading: getMySharedLoading,
  } = useRequest(getMyShared);

  useEffect(() => {
    getMySharedRequest();
  }, []);

  const mutateItem = (item: SharedObject) => {
    const { expires, sharer, contract, receiver } = item;
    const expired = dayjs(expires).format('DD/MM/YYYY');
    let photoUri = '';
    let sharerName = '';
    if (typeof sharer === 'object' && sharer?.info) {
      photoUri = sharer?.info?.fhirData?.photo?.[0]?.url || '';
      sharerName = sharer?.info?.fhirData?.name?.[0]?.family || '';
    }
    if (typeof receiver === 'object' && receiver?.info) {
      photoUri = receiver?.info?.fhirData?.photo?.[0]?.url || '';
      sharerName = receiver?.info?.fhirData?.name?.[0]?.family || '';
    }
    const itemResult: ShareItem = {
      ...item,
      photoUri,
      sharerName,
      expired,
      id: contract,
    };
    return itemResult;
  };

  const getItems = (list: SharedObject[]) =>
    list
      .reduce((previusValue: ShareItem[], currentValue) => {
        if (dayjs(currentValue.expires).isBefore(dayjs())) {
          const item = mutateItem(currentValue);
          previusValue.push(item);
        }
        return previusValue;
      }, [])
      .sort((a, b) => compareCreatedDate(a.created, b.created));

  const { sharedItems } = useMemo(() => {
    const { shared } = getMySharedData?.with || {
      received: [],
      shared: [],
    };
    return {
      sharedItems: getItems(shared),
    };
  }, [getMySharedData]);

  return (
    <Grid container xs={8} className={classes.container}>
      <Grid item xs={12} className={classes.backContainer}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.share)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_action_center')}</AboutMeTitle>
        </div>
      </Grid>
      <Grid item xs={12} marginTop="1.625rem">
        <div className={classes.sectionName}>
          <InfoHeader>{t('expired_access')}</InfoHeader>
        </div>
      </Grid>
      <Grid item xs={12} marginTop="1.625rem">
        <div className={classes.sectionSubtitle}>
          {t('expired_access_subtitle')}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.infoContainer}>
        <Grid container xs={12} rowGap={3}>
          <Grid container xs={5}>
            <Grid item xs>
              <div className={classes.whoIShareWithSwitch}>
                {t('who_i_share_with')}
              </div>
            </Grid>
            <Grid item xs>
              <div className={classes.whoSharesWithMeSwitch}>
                {t('who_shares_with_me')}
              </div>
            </Grid>
          </Grid>
          <Grid container xs={7} display="flex" justifyContent="flex-end" />
          <Grid container xs={12}>
            <div className={classes.selectedInfo}>{t('my_data_expired')}</div>
          </Grid>
          <Grid container xs={12} className={classes.dataContainer}>
            {getMySharedLoading ? (
              <CircularProgress size={20} />
            ) : sharedItems.length ? (
              sharedItems.map(item => {
                const { expired, sharerName, id, photoUri } = item;
                return (
                  <Grid
                    key={id}
                    container
                    xs={12}
                    className={classes.dataSubContainer}
                  >
                    <SharedWithMe
                      expired={expired}
                      sharerName={sharerName}
                      id={id}
                      photoUri={photoUri}
                      onShareDetails={() =>
                        history.push(ROUTES.shareDetail, {
                          itemSelected: item,
                        })
                      }
                    />
                  </Grid>
                );
              })
            ) : (
              t('no_data_shared')
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
