import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import postsApi from '../../api/posts';

export const getPosts = createAsyncThunk('posts/getPosts', async () => {
  const response = await postsApi.getPosts();
  return response.ok ? response.data : null;
});

export const createPost = createAsyncThunk('posts/createPost', async post => {
  const response = await postsApi.createPost(post);
  return response.ok ? response.data : null;
});

export const updatePost = createAsyncThunk('posts/updatePost', async post => {
  const response = await postsApi.updatePost(post);
  return response.ok ? response.data : null;
});

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    list: [],
    status: null,
  },
  reducers: {},
  extraReducers: {
    [getPosts.pending]: state => {
      state.status = 'loading';
    },
    [getPosts.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.status = 'success';
    },
    [getPosts.rejected]: state => {
      state.status = 'failed';
    },
    [createPost.pending]: state => {
      state.status = 'loading';
    },
    [createPost.fulfilled]: state => {
      state.status = 'success';
    },
    [createPost.rejected]: state => {
      state.status = 'failed';
    },
    [updatePost.pending]: state => {
      state.status = 'loading';
    },
    [updatePost.fulfilled]: state => {
      state.status = 'success';
    },
    [updatePost.rejected]: state => {
      state.status = 'failed';
    },
  },
});

export default postsSlice.reducer;
