export const Colors = {
  white: '#FFF',
  grey: '#CCC',
  white70: '#FFFFFFB3',
  backgroundGrey: '#F8F9FC',
  accent2: '#ED9B43',
  accent3: '#EFC245',
  accent5Purple: '#3A5BBB',
  accent5Transparent: 'rgba(58, 91, 187, 0.4)',
  lightgray: '#D3D3D3',
  disabledGrey: '#C4C4C4',
  gradientOrange: '#DC5813',
  gradientLighterOrange: '#EA8049',
  gradientYellow: '#E3AB0D',
  gradientLighterYellow: '#EFC245',
  black: '#000000',
  buttonPurple: '#5C74EC',
  errorRed: '#FF0000',
  errorRedDegraded: '#FF000070',
  errorRed20Percent: '#FF3A444a',
  unverifiedRed: '#FF3B47',
  clear: '#ffffff00',
  linkPurple: '#5A70EE',
  lightPurple: '#E3E7FF',
  checkboxGray: '#C4C4C4',
  accent10: '#C4C4C4',
  radioListText: '#294072',
  textInputBorder: '#274070',
  primaryText: '#274070',
  secondaryText: '#575F70',
  contentTitleBlue: '#294072',
  dropdownTextGray: '#767676',
  iconHoverBorder: '#5C6578',
  sidebarBackground: '#EFF1F5',
  formBackgroundWhite: 'rgba(255, 255, 255, 0.45)',
  idTextGray: '#85888D',
  progressYellow: '#EFC245',
  notificationBackground: '#F6F9FF',
  notificationTitles: '#274071',
  notificationBorder: '#E3E7FF',
  barBackground: '#F3E7C4',
  idIconBackground: '#3A5BBB',
  logoutBorderGray: '#A7ACC3',
  logoutButtonText: '#636C93',
  horizontalBreakGray: '#BDBDBD',
  accent8: '#4CC4B4',
  accent8Transparent: 'rgba(76, 196, 180, 0.18)',
  boxShadow: 'rgba(109, 109, 109, 0.25)',
  gradientDarkPurple: '#5C4DB4',
  disabledText: '#949494',
  tableHeaderGray: '#828282',
  accent1Transparent: 'rgba(234, 128, 73, 0.36)',
  yellowTagText: '#F1B100',
  yellowTagTextTransparent: 'rgba(239, 194, 69, 0.31)',
  accent2Transparent: 'rgba(231, 159, 19, 0.35)',
  accent4: '#44459D',
  accent4Transparent: 'rgba(68, 69, 157, 0.33)',
  shadow: 'rgba(116, 116, 116, 0.25)',
  shadow2: 'rgba(172, 172, 172, 0.25)',
  accent11: '#E3E7FF',
  accent3Transparent: '#EFC24538',
};
