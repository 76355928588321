import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AliveLogo from '../../assets/images/AliveLogo.svg';
import { PlusWhiteCircle, yellowStar } from '../../assets/images/icons';
import Gears from '../../assets/images/icons/GearSix.svg';
import ProfileImage from '../../assets/images/jane.png';
import { ROUTES } from '../../config/constants';
import { DashboardSections } from '../../features/dashboard/DashboardSections';
import { selectSection } from '../../features/dashboard/dashboardSlice';
import {
  FlatUser,
  selectCurrentUser,
  setSelectedUser,
} from '../../features/login/loginSlice';
import i18n from '../../translations';
import { DependentBanner } from '../DependentBanner/DependentBanner';
import { UserProfileCarousel } from '../UserProfileCarousel/UserProfileCarousel';
import { styles } from './styles';

const useStyles = createUseStyles(styles);

type UserProfileSwitcherProps = {
  hideSettings?: boolean;
  notifications?: number;
};

export const UserProfileSwitcher: React.FC<UserProfileSwitcherProps> = ({
  hideSettings,
  notifications = 0,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { index: currentUserIndex } = useAppSelector(selectCurrentUser);
  const mainUser: FlatUser = useAppSelector(
    selectSection(DashboardSections.MAIN_USER)
  );

  const dependents =
    useAppSelector(selectSection(DashboardSections.DEPENDENTS)) || [];
  const dispatch = useAppDispatch();

  const switchDependent = depIndex => {
    if (depIndex === 0) {
      //Switch to Main User
      const { id } = mainUser;
      dispatch(setSelectedUser({ index: depIndex, id }));
    } else if (depIndex === dependents.length + 1) {
      //End of the spinner, Add Dependent, Do Nothing
    } else {
      const depId = dependents[depIndex - 1].id;
      dispatch(setSelectedUser({ index: depIndex, id: depId }));
    }
  };

  const mainUserProfile = (
    <div className={classes.profileContainer}>
      {!!notifications && (
        <div className={classes.notificationContainer}>{notifications}</div>
      )}
      <img
        src={mainUser?.photo?.length ? mainUser?.photo : ProfileImage}
        alt="Profile"
        className={classes.profilePicture}
      />
      <div className={classes.username}>
        {mainUser?.preferredName || mainUser?.firstName}
      </div>
    </div>
  );

  const addDependentButton = (
    <div className={classes.profileContainer}>
      <img
        src={PlusWhiteCircle}
        alt="Profile"
        onClick={() => {
          history.push(ROUTES.createDependent);
        }}
        style={{ border: 'none', cursor: 'pointer' }}
        className={classes.profilePicture}
      />
      <div className={classes.username}>{i18n.t('Add a star')}</div>
    </div>
  );

  const children = dependents.map(dep => (
    <div className={classes.profileContainer} title="Click to switch users">
      <img
        src={dep.photo?.length ? dep.photo : ProfileImage}
        alt="Profile"
        className={classes.profilePicture}
      />
      <div className={classes.username}>
        <img
          src={yellowStar}
          alt=""
          height="15"
          style={{ marginRight: '4px' }}
        />
        {dep?.preferredName || dep.firstName}
      </div>
    </div>
  ));

  return (
    <>
      <div className={classes.userProfileComponent}>
        <div
          className={classes.logoContainer}
          onClick={() => history.push(ROUTES.dashboard)}
        >
          <img src={AliveLogo} alt="Alive" />
        </div>
        <UserProfileCarousel
          items={[mainUserProfile, ...children, addDependentButton]}
          selectedIndex={currentUserIndex}
          onSelect={switchDependent}
        />
        <div>
          {!hideSettings && (
            <img
              src={Gears}
              alt="Settings"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.accountSettings)}
            />
          )}
        </div>
      </div>
      <DependentBanner />
    </>
  );
};
