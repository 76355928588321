import { Notification, NotificationType } from './notificationSlice';

export const getNotifications = async userId => {
  return new Promise<{ data: Notification[] }>(resolve => {
    console.log(`getNotifications called with : ${userId}`);
    //do network call here
    const dummyNotifications: Notification[] = [
      {
        id: '100',
        title: 'Add your health Insurance',
        message: 'Scan your insurance card to add healthcare data.',
        type: NotificationType.REMINDER,
        path: 'healthInsurance',
        actionText: 'Add Insurance',
      },
      {
        id: '101',
        title: 'Add your health details',
        message: 'Add your personal health data & family history.',
        type: NotificationType.REMINDER,
        path: 'healthDetails',
        actionText: 'Add Health Details',
      },
      {
        id: '102',
        title: 'Add to your vault',
        message:
          'Upload and store important documents and credntials in your vault.',
        type: NotificationType.REMINDER,
        path: 'vault',
        actionText: 'Add To Vault',
      },
      {
        id: '103',
        title: 'Add to your about me',
        message: 'Add additional personal information.',
        type: NotificationType.REMINDER,
        path: 'aboutMe',
        dismissedUntil: Date.now().toString(),
        actionText: 'Add To About Me',
      },
    ];
    setTimeout(() => resolve({ data: dummyNotifications }), 1000);
  });
};
