import { Colors } from '../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../config/constants';
import { Typography } from '../../config/typography';

export const styles = {
  dashboard: {
    height: '100vh',
    width: '100vw',
    backgroundColor: Colors.backgroundGrey,
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `
      'nav'
      'content'`,
    [DESKTOP_VIEW_BREAKPOINT]: {
      gridTemplateColumns: '.5fr 1fr 1fr .25fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `'nav content content gutter'`,
    },
  },
  nav: {
    gridArea: 'nav',
    backgroundColor: Colors.sidebarBackground,
    boxShadow: '3px -6px 4px rgba(181, 181, 181, 0.17)',
  },
  allDataTitle: {
    color: Colors.primaryText,
  },
  content: {
    gridArea: 'content',
    backgroundColor: Colors.backgroundGrey,
  },
  navContent: {
    padding: '20px 50px 0 50px',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '20px 33px 0 32px',
    },
  },
  starProgressSection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.linkPurple,
    color: Colors.white,
    padding: '15px 40px',
    margin: '10px 0',
    textAlign: 'left',
  },
  progressLabelSection: {
    marginBottom: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  xBox: {
    width: '14px',
    height: '14px',
    display: 'flex',
    jusifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bar: {
    position: 'relative',
    width: '95%',
    height: '7px',
    left: '-16px',
    top: '14px',
    background: Colors.white,
    borderRadius: '45px',
    zIndex: 0,
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  personalInfoHeader: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.primaryText,
    margin: '27px 0 14px',
  },
  idIcon: {
    background: Colors.idIconBackground,
    width: '47px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    marginRight: '14px',
  },
};

export const redesignStyles = {
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  dashboard: {
    height: '100vh',
    width: '100vw',
    backgroundColor: Colors.backgroundGrey,
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `
      'nav'
      'content'`,
    [DESKTOP_VIEW_BREAKPOINT]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: '.02fr 1fr',
      gridTemplateAreas: `'header'
      'content'
      `,
    },
  },
  header: {
    gridArea: 'header',
    backgroundColor: Colors.sidebarBackground,
  },
  content: {
    gridArea: 'content',
    color: Colors.primaryText,
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  youAreViewingContainer: {
    ...Typography.navSubHeader1,
    margin: '15px 0 55px 0',
    width: '100vw',
    backgroundColor: Colors.gradientLighterOrange,
    color: Colors.white,
    padding: '15px 0 10px 0',
    boxShadow: `0px 0px 6px 0px ${Colors.shadow}`,
    textAlign: 'center',
  },
  actionContent: {
    marginTop: '33px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  welcomeMessage: {
    paddingBottom: '15px',
  },
  actions: {
    display: 'flex',
    columnGap: '32px',
    marginBottom: '50px',
  },
  actionCenter: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
  },
  actionTiles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: '25px',
  },
  quickLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '13px',
    alignItems: 'center',
  },
  drawer: {
    border: `1px solid ${Colors.linkPurple}`,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 4px rgba(100, 100, 100, 0.25)',
    borderRadius: '10px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.3px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '14px',
  },
  navContent: {
    padding: '20px 50px 0 50px',
    textAlign: 'left',
    [DESKTOP_VIEW_BREAKPOINT]: {
      padding: '20px 20px 0 20px',
    },
  },
  starProgressSection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.linkPurple,
    color: Colors.white,
    padding: '15px 40px',
    margin: '10px 0',
  },
  bar: {
    position: 'relative',
    width: '80%',
    height: '7px',
    left: '-16px',
    top: '14px',
    background: Colors.white,
    borderRadius: '45px',
    zIndex: 0,
  },
};
