import { useState } from 'react';
import { RadioList, OneLineTextInput, ThemedButton } from '../../../components';
import { FormPrompt } from '../../../components/TextComponents/FormPrompt';
import { RetireStatusEnum } from '../../../config/SharedTypes';
import i18n from '../../../translations';
import { useTranslation } from 'react-i18next';
import { sharedValidators } from '../../../config/constants';

type RetireStatusProps = {
  classes: any;
  defaultValue: string;
  register: any;
  onSave: any;
  setValue: any;
};

export const RetirementStatus: React.FC<RetireStatusProps> = ({
  classes,
  defaultValue,
  register,
  onSave,
  setValue,
}) => {
  const { t } = useTranslation();
  const [radioValue] = useState(defaultValue === '12/31/9999' ? 'N' : 'Y');
  const [selected, setSelected] = useState(RetireStatusEnum[radioValue]);
  return (
    <div className={classes.formContainer}>
      <div className={classes.prompt}>
        <FormPrompt>{i18n.t('retirement_prompt')}</FormPrompt>
      </div>
      <div className={classes.inputContainer}>
        <RadioList
          title=""
          defaultValue={radioValue}
          optionsArr={[
            { value: 'Y', label: i18n.t('yes') },
            { value: 'N', label: i18n.t('no') },
          ]}
          onChange={e => {
            setSelected(e.target.value || '');
            setValue('retirementDate', e.target.value);
          }}
          isHorizontal
        />
        {selected?.includes('Y') && (
          <OneLineTextInput
            defaultValue={defaultValue === '12/31/9999' ? '' : defaultValue}
            placeholder={i18n.t('retirement_date_format')}
            type="text"
            {...register('retirementDate', {
              required: `${t('retire_required')}`,
              ...sharedValidators.longDate,
            })}
          />
        )}
      </div>
      <div className={classes.buttonContainer}>
        <ThemedButton
          title={i18n.t('save')}
          onClick={() => {
            onSave();
          }}
        />
      </div>
    </div>
  );
};
