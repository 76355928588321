import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';
import { textPageStyles } from '../../../config/sharedStyles';

export const styles = {
  ...textPageStyles,
  cardContainer: {
    display: 'flex',
    margin: '30px 10px',
    width: '80%',
    [DESKTOP_VIEW_BREAKPOINT]: {
      margin: '15px 26px',
      width: 'unset',
    },
  },
  progressBar: {
    backgroundColor: Colors.white,
    height: '.1em',
    marginBottom: '32px',
    borderRadius: '9px',
  },
  asteriskNote: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgb(255, 255, 255, 0.70)',
    marginLeft: '35px',
  },
};
