import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  ArrowDown,
  infoIcon,
  LightMagnifyingGlass,
} from '../../../assets/images/icons';
import { LinkComponent, OneLineTextInput } from '../../../components';
import {
  AboutMeTitle,
  FormBody,
  FormPrompt,
  HorizontalBreak,
  TableHeader,
} from '../../../components/TextComponents';
import { VaultDocTableCard } from '../../../components/VaultDocTableCard/VaultDocTableCard';
import { selectVaultDocs } from '../dashboardSlice';
import { customSelectStyles, useVaultDocsDashStyles } from './styles';
import { flattenVaultDoc } from './utils';

type VaultDocsDashProps = {
  user: string;
  displayOnly?: boolean;
};

export const VaultDocsDash: React.FC<VaultDocsDashProps> = ({
  displayOnly,
}) => {
  const { t } = useTranslation();
  const classes = useVaultDocsDashStyles();
  const { detailList } = useAppSelector(selectVaultDocs);
  const vaultDocs = detailList || [];
  const flatDocs = vaultDocs.map(doc => flattenVaultDoc(doc));
  const [filteredDocs, setFilteredDocs] = useState(flatDocs);
  const [selectedTag, setSelectedTag] = useState(t('all_tags'));
  // const showPlaceholder: boolean = !!selectedTag; ToDo: Need this for styling eventually

  const filterDocs = searchText => {
    const lowerSearchText = searchText.toLowerCase();
    if (!lowerSearchText?.length) {
      setFilteredDocs(flatDocs);
    }
    const matchingDocs = flatDocs.filter(doc =>
      doc.title.toLowerCase().includes(lowerSearchText)
    );
    setFilteredDocs([...matchingDocs]);
  };
  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  if (displayOnly || isShareUser) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
        {filteredDocs?.length ? (
          filteredDocs?.map((document, index) => (
            <VaultDocTableCard key={index} document={document} displayOnly />
          ))
        ) : (
          <FormBody>{t('nothing_to_share')}</FormBody>
        )}
      </div>
    );
  }

  return (
    <>
      {vaultDocs?.length ? (
        <div className={classes.tableContainer}>
          <HorizontalBreak />
          <div className={classes.headers}>
            <div className={classes.nameAndSearch}>
              <div className={classes.titleAndArrow}>
                <TableHeader>{t('name').toUpperCase()}</TableHeader>
                <img src={ArrowDown} alt="arrowDown" width="10px" />
              </div>
              <div className={classes.searchBar}>
                <OneLineTextInput
                  placeholder="Search"
                  icon={LightMagnifyingGlass}
                  onChange={e => filterDocs(e.target.value)}
                  small
                />
              </div>
            </div>
            <div className={classes.dateHeader}>
              <div className={classes.titleAndArrow}>
                <TableHeader>{t('date_uploaded').toUpperCase()}</TableHeader>
                <img src={ArrowDown} alt="arrowDown" width="10px" />
              </div>
            </div>
            <div className={classes.tagsAndFilter}>
              <TableHeader>{t('tags').toUpperCase()}</TableHeader>
              <div className={classes.filterContainer}>
                <Select
                  options={[
                    { label: t('all_tags'), value: t('all_tags') },
                    { label: t('receipt'), value: t('receipt') },
                    { label: t('care_summary'), value: t('care_summary') },
                    { label: t('policy'), value: t('policy') },
                    { label: t('legal'), value: t('legal') },
                    { label: t('account_info'), value: t('account_info') },
                  ]}
                  defaultValue={t('all_tags')}
                  styles={customSelectStyles()}
                  placeholder={selectedTag}
                  isDisabled={true}
                  onChange={(e: any) => {
                    setSelectedTag(e);
                  }}
                />
              </div>
            </div>
            <div className={classes.dnHeader}>
              <div className={classes.titleAndArrow}>
                <TableHeader>{t('dn')}</TableHeader>
                <img src={infoIcon} alt="infoIcon" width="16px" />
                <img src={ArrowDown} alt="arrowDown" width="10px" />
              </div>
            </div>
          </div>
          {filteredDocs &&
            filteredDocs?.map(document => (
              <VaultDocTableCard document={document} />
            ))}
        </div>
      ) : (
        <div className={classes.emptyDocsText}>
          <AboutMeTitle>{t('alive_stores_your_docs')}</AboutMeTitle>
          <div className={classes.middleText}>
            <FormPrompt>{t('vault_storage_examples')}</FormPrompt>
          </div>
          <LinkComponent
            label={t('upload_a_document')}
            onClick={() => console.log('TODO: add path to upload doc page')}
          />
        </div>
      )}
      {/* <ConfirmDeleteModal
        open={showDelete}
        handleClose={handleClose}
        handleDelete={deleteDocument}
        confirmMessage={`Are you sure you want to delete ${
          docToDel?.title || 'this document'
        }?`}
      /> */}
      {/* <Dialog
        open={showDelete}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            `Are you sure you want to delete ${docToDel?.title || 'this document'}?`
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDocument}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
