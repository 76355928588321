import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  cardDetail2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.3px',
    display: 'flex',
  },
});
export const CardDetail2: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cardDetail2}>{children}</div>;
};
