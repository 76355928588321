import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ContentBox, ThemedButton } from '../../../components';
import { FormHeader, FormSecondary } from '../../../components/TextComponents';
import { ROUTES } from '../../../config/constants';
import { DashboardSections } from '../DashboardSections';
import { getProfileThunk, selectSection } from '../dashboardSlice';
import { useCreateSuccessStyles } from './styles';
import { ShareArtwork } from '../../../assets/images';
import { useEffect } from 'react';
import { selectCreateDependent, setSelectedUser } from '../../login/loginSlice';

export const CreateDependentSuccess: React.FC = () => {
  const classes = useCreateSuccessStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { dependentInfo } = useAppSelector(selectCreateDependent);
  const dependents = useAppSelector(
    selectSection(DashboardSections.DEPENDENTS)
  );

  useEffect(() => {
    dispatch(getProfileThunk());
  }, []);

  const goToNewStar = () => {
    const depIndex = dependents?.length - 1;
    const depId = dependents[depIndex].id;
    dispatch(setSelectedUser({ index: depIndex, id: depId }));
    history.push(ROUTES.dashboard);
  };

  const { firstName, lastName } = dependentInfo;

  return (
    <div className={classes.container}>
      <div className={classes.contentBox}>
        <ContentBox title={''} dropshadow>
          <div className={classes.cardContent}>
            <>
              <div className={classes.shareArtwork}>
                <img src={ShareArtwork} alt="" style={{ width: '80%' }} />
              </div>
              <div className={classes.successText}>
                <FormHeader>{t('star_is_born')}</FormHeader>
                <FormSecondary>
                  {t('create_star_success', { firstName, lastName })}
                </FormSecondary>
              </div>
              <div className={classes.continueButton}>
                <ThemedButton
                  title={t(`continue_to_names_star`, { name: firstName })}
                  onClick={() => {
                    goToNewStar();
                  }}
                />
                <ThemedButton
                  title={t(`back_to_my_dash`)}
                  onClick={() => history.push(ROUTES.dashboard)}
                  isInverted
                />
                <ThemedButton
                  title={t(`add_another_star`)}
                  onClick={() => history.push(ROUTES.createDependent)}
                  isInverted
                />
              </div>
            </>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};
