import { createUseStyles } from 'react-jss';
import { LinkComponent } from '../../components/LinkComponent/LinkComponent';
import { styles } from './styles';
import { useRouteMatch, Switch, useHistory } from 'react-router-dom';
import AppStoreBadge from '../../assets/images/app-store-badge.svg';
import GooglePlayBadge from '../../assets/images/googleplay.svg';
import Route from '../../routes/route';
import { LoginPage } from './login-page/LoginPage';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../config/constants';
import { useAppSelector } from '../../app/hooks';

const useLandingPageStyles = createUseStyles(styles);

export const LandingPage = () => {
  const classes = useLandingPageStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const signedIn = useAppSelector(state => state.session.loggedIn);

  if (signedIn) {
    history.push(ROUTES.dashboardHome);
  }

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <Switch>
          <Route
            path={path}
            exact
            component={() => (
              <>
                <div className={classes.alive}>{t('landing_title_alive')}</div>
                <div className={classes.secondText}>
                  {t('landing_title_secondary')}
                </div>
                <div className={classes.thirdText}>
                  {t('landing_title_tertiary')}
                </div>
                <div className={classes.badgeContainer}>
                  <img
                    src={AppStoreBadge}
                    alt=""
                    className={classes.badgeImg}
                  />
                  <img
                    src={GooglePlayBadge}
                    alt=""
                    className={classes.badgeImg}
                  />
                </div>
              </>
            )}
            isPrivate={false}
          />
        </Switch>
      </div>
      <div className={classes.formGridContainer}>
        <div className={classes.form}>
          <Switch>
            <Route path={'/'} exact component={LoginPage} isPrivate={false} />
          </Switch>
        </div>
      </div>
      <div className={classes.footer}>
        <LinkComponent
          label={t('privacy')}
          onClick={() => console.log('link clicked')}
          secondary
        />
        <LinkComponent
          label={t('security')}
          onClick={() => console.log('link clicked')}
          secondary
        />
        <LinkComponent
          label={t('terms_of_service')}
          onClick={() => console.log('link clicked')}
          secondary
        />
      </div>
    </div>
  );
};
