import { Classes } from 'jss';
import { Colors } from '../../config/colors';
import { createUseStyles } from 'react-jss';

export const useButtonStyles: (
  data?: {
    isDisabled: boolean;
    gradientStart: string;
    gradientEnd: string;
    isSmall: boolean;
    theme: Jss.Theme;
  } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'button' | 'icon' | 'iconContainer' | 'titleContainer'> =
  createUseStyles({
    button: ({ gradientStart, gradientEnd, isDisabled, isSmall }) => ({
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '10px',
      border: 'none',
      backgroundImage: isDisabled
        ? ''
        : `linear-gradient(to right, ${gradientStart}, ${gradientEnd})`,
      backgroundColor: isDisabled ? Colors.disabledGrey : '',
      color: Colors.white,
      minHeight: isSmall ? '58px' : '69px',
      minWidth: '220px',
      padding: '10px',
      height: '100%',
      width: '100%',
      fontSize: '1rem',
      fontWeight: '600',
      boxShadow: '0px 0px 4px rgba(183, 183, 183, 0.25)',
    }),
    icon: {
      paddingRight: '10px',
    },
    iconContainer: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    titleContainer: {
      marginRight: 'auto',
      display: 'flex',
    },
  });
