import { Switch, useRouteMatch } from 'react-router-dom';
import Route from '../../../routes/route';
import { useInsuranceStyles } from './styles';
import { InsuranceSummary } from './InsuranceSummary';
import { InsuranceScanCard } from './InsuranceScanCard';
import { EditInsurance } from './EditInsurance';
import { InsuranceDashboard } from './InsuranceDashboard';

export const HealthInsurance = () => {
  const classes = useInsuranceStyles();
  const { path } = useRouteMatch();

  return (
    <div className={classes.container}>
      <Switch>
        <Route
          path={`${path}/summary`}
          component={InsuranceSummary}
          isPrivate={false}
        />
        <Route
          path={`${path}/scanCard`}
          component={InsuranceScanCard}
          isPrivate={false}
        />
        <Route
          path={`${path}/edit`}
          component={EditInsurance}
          isPrivate={false}
        />
        <Route
          path={`${path}/`}
          component={InsuranceDashboard}
          isPrivate={false}
        />
      </Switch>
    </div>
  );
};
