import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';
import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const useVaultStyles: (
  data?: { isOnDocsPath: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'container'
  | 'backToAction'
  | 'arrowContainer'
  | 'titleSection'
  | 'titleAndIcon'
  | 'iconBackground'
  | 'buttonsBox'
  | 'buttonContainer'
  | 'dataListView'
  | 'dataViewSwitchers'
  | 'docsSwitch'
  | 'credsSwitch'
  | 'emptyDocsText'
  | 'middleText'
> = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: Colors.primaryText,
    [DESKTOP_VIEW_BREAKPOINT]: {
      marginTop: '25px',
      width: '90%',
    },
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowContainer: {
    display: 'flex',
    marginRight: '2px',
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  titleAndIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  iconBackground: {
    background: Colors.progressYellow,
    borderRadius: '4px',
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsBox: {
    display: 'flex',
    gap: '22px',
  },
  buttonContainer: {
    width: '348px',
    height: '58px',
  },
  dataListView: {
    background: Colors.white,
    borderRadius: '10px',
    boxShadow: `0px 4px 22px 5px ${Colors.boxShadow}`,
    marginTop: '25px',
    width: '100%',
    paddingBottom: '35px',
  },
  dataViewSwitchers: {
    display: 'flex',
    margin: '19px 0 0 30px',
  },
  docsSwitch: ({ isOnDocsPath }) => ({
    background: isOnDocsPath
      ? Colors.lightPurple
      : Colors.notificationBackground,
    color: isOnDocsPath ? Colors.primaryText : Colors.disabledText,
    width: '160px',
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px 0 0 10px',
    cursor: 'pointer',
  }),
  credsSwitch: ({ isOnDocsPath }) => ({
    background: isOnDocsPath
      ? Colors.notificationBackground
      : Colors.lightPurple,
    color: isOnDocsPath ? Colors.disabledText : Colors.primaryText,
    width: '160px',
    height: '49px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 10px 10px 0',
    cursor: 'pointer',
  }),
  emptyDocsText: {
    margin: '180px 0 270px 0',
  },
  middleText: {
    margin: '32px 0 34px',
  },
});

export const useVaultDocsDashStyles = createUseStyles({
  tableContainer: {
    padding: '7px 25px 0 25px',
  },
  headers: {
    display: 'flex',
    color: Colors.tableHeaderGray,
  },
  nameAndSearch: {
    marginLeft: '14px',
    width: '23.4%',
  },
  titleAndArrow: {
    display: 'flex',
    gap: '10px',
    height: '15px',
  },
  searchBar: {
    borderRadius: '2px',
    width: '202px',
    height: '31px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '11px',
    paddingLeft: '10px',
  },
  dateHeader: {
    width: '23.4%',
  },
  tagsAndFilter: {
    textAlign: 'left',
    width: '35%',
  },
  filterContainer: {
    marginTop: '11px',
  },
  dnHeader: {
    width: '17.8%',
  },
  emptyDocsText: {
    margin: '180px 0 270px 0',
  },
  middleText: {
    margin: '32px 0 34px',
  },
});

export const customSelectStyles = () => ({
  placeholder: provided => ({
    ...provided,
    color: Colors.disabledGrey,
  }),
  container: provided => ({
    ...provided,
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
  }),
  control: provided => ({
    ...provided,
    fontFamily: 'Inter',
    minHeight: '30px',
    maxWidth: '202px',
    height: '38px',
    paddingLeft: '8px',
    backgroundColor: Colors.white,
  }),
  option: provided => ({
    ...provided,
  }),
  singleValue: provided => ({
    ...provided,
    color: Colors.primaryText,
  }),
  valueContainer: provided => ({
    ...provided,
  }),
  menu: provided => ({
    ...provided,
    zIndex: '2',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    opacity: '0',
  }),
  indicatorContainer: provided => ({
    ...provided,
    '&:hover': {
      color: Colors.buttonPurple,
    },
  }),
});

export const useCredRowStyles = createUseStyles({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0 36px',
  },
  rowTitle: {
    marginBottom: '16px',
    color: '#828282',
  },
  credColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '30%',
  },
  credValue: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.015em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
export const useAddVaultDocStyles: (
  data?: {
    receiptClicked: boolean;
    legalClicked: boolean;
    policyClicked: boolean;
    careSummaryClicked: boolean;
    accountInfoClicked: boolean;
    theme: Jss.Theme;
  } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'titleSection'
  | 'cancel'
  | 'uploadPageContainer'
  | 'leftSide'
  | 'docNameField'
  | 'sensitiveContainer'
  | 'tagSection'
  | 'receiptTag'
  | 'careSummaryTag'
  | 'policyTag'
  | 'legalTag'
  | 'accountInfoTag'
  | 'tagTitleContainer'
  | 'addAnotherTag'
  | 'newTagField'
  | 'saveButton'
  | 'rightSide'
  | 'uploadDocButton'
  | 'dragAndDropBox'
  | 'bottomText'
  | 'documentContainer'
  | 'uploadedImg'
> = createUseStyles({
  titleSection: {
    textAlign: 'left',
  },
  cancel: {
    marginBottom: '22px',
    cursor: 'pointer',
  },
  uploadPageContainer: {
    display: 'flex',
    background: Colors.white,
    borderRadius: '10px',
    marginTop: '31px',
    width: '100%',
    textAlign: 'left',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    padding: '46px 0 45px 38px',
  },
  docNameField: {
    width: '490px',
  },
  sensitiveContainer: {
    margin: '29px 0 34px 0',
  },
  tagSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '21px',
    margin: '21px 0 29px',
  },
  tagTitleContainer: {
    marginTop: '62px',
  },
  receiptTag: ({ receiptClicked }) => ({
    padding: '8px 0 9px',
    width: '157px',
    color: receiptClicked ? Colors.white : Colors.accent8,
    background: receiptClicked ? Colors.accent8 : Colors.white,
    border: `2px solid ${Colors.accent8}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  careSummaryTag: ({ careSummaryClicked }) => ({
    padding: '8px 0 9px',
    width: '157px',
    color: careSummaryClicked ? Colors.white : Colors.accent5Purple,
    background: careSummaryClicked ? Colors.accent5Purple : Colors.white,
    border: `2px solid ${Colors.accent5Purple}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  policyTag: ({ policyClicked }) => ({
    padding: '8px 0 9px',
    width: '157px',
    color: policyClicked ? Colors.white : Colors.gradientLighterOrange,
    background: policyClicked ? Colors.gradientLighterOrange : Colors.white,
    border: `2px solid ${Colors.gradientLighterOrange}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  legalTag: ({ legalClicked }) => ({
    padding: '8px 0 9px',
    width: '157px',
    color: legalClicked ? Colors.white : Colors.accent3,
    background: legalClicked ? Colors.accent3 : Colors.white,
    border: `2px solid ${Colors.accent3}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  accountInfoTag: ({ accountInfoClicked }) => ({
    padding: '8px 0 9px',
    width: '157px',
    color: accountInfoClicked ? Colors.white : Colors.accent2,
    background: accountInfoClicked ? Colors.accent2 : Colors.white,
    border: `2px solid ${Colors.accent2}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  addAnotherTag: {
    display: 'flex',
    alignItems: 'center',
    gap: '27px',
  },
  newTagField: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    height: '57px',
  },
  saveButton: {
    width: '21.2rem',
    height: '3.2rem',
    margin: '20px 0 0 17px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    padding: '37px 0px 42px 94px',
  },
  uploadDocButton: {
    width: '21.2rem',
    height: '3.2rem',
  },
  dragAndDropBox: {
    width: '338px',
    height: '442px',
    background: Colors.notificationBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.accent10,
    margin: '26px 0 13px 0',
  },
  bottomText: {
    color: Colors.accent10,
    width: '336px',
  },
  documentContainer: {
    maxHeight: '480px',
    maxWidth: '350px',
  },
  uploadedImg: {
    marginTop: '10px',
    width: '97%',
    height: '97%',
    objectFit: 'contain',
    backgroundSize: 'cover',
  },
});
