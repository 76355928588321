import { createUseStyles } from 'react-jss';
import { Classes } from 'jss';
import { Colors } from '../../config/colors';

export const useRadioListStyles: (
  data?: { isHorizontal: boolean; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<'container' | 'title' | 'optionsList' | 'option'> =
  createUseStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: Colors.radioListText,
      fontSize: '20px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontWeight: '500',
    },
    optionsList: ({ isHorizontal }) => ({
      color: Colors.radioListText,
      fontSize: '15px',
      display: 'flex',
      flexDirection: isHorizontal ? 'row' : 'column',
      textAlign: 'left',
      justifyContent: 'space-between',
    }),
    option: {
      cursor: 'pointer',
      marginRight: '1em',
      marginBottom: '10px',
    },
  });
