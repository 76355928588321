import React from 'react';
import { withTranslation } from 'react-i18next';

type ErrorStateProps = {
  supportText: string;
};
const ErrorState: React.FC<ErrorStateProps> = ({ supportText }) => {
  return (
    <div style={{ backgroundColor: 'red', color: 'white' }}>{supportText}</div>
  );
};

type ErrorBoundaryProps = {
  supportText?: string;
  logger?: VoidFunction;
  FallbackView?: React.FC;
  t: any;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  logger: (error: any) => void;
  state: { error: any };

  constructor(props) {
    super(props);
    this.state = { error: false };
    this.logger = error => console.error(error); //Eventually this should be passed in, whatever logging service we use
  }

  componentDidCatch(error) {
    this.logger(error);
    this.setState({ error: true });
  }

  render() {
    const { FallbackView, supportText, t } = this.props;
    const { error } = this.state;
    if (error) {
      return FallbackView ? (
        <FallbackView />
      ) : (
        <ErrorState supportText={supportText || t('sorry_error')} />
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
