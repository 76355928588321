import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  h3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '0.015em',
  },
});
export const H3Header: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.h3}>{children}</div>;
};
