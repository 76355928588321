import { createUseStyles } from 'react-jss';
import { cardContainer } from '../styles';
import { errorText } from '../../../config/sharedStyles';

export const useLoginPageStyles = createUseStyles({
  cardContainer,
  errorText,
  errorTextLink: {
    ...errorText,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  errorContainer: {
    display: 'flex',
    gap: '5px',
  },
  rememberMe: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    margin: '10px 0 10px 0',
  },
  inputCheckbox: {
    height: '20px',
    width: '20px',
    borderRadius: '50px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 10px 5px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
});
