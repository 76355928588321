import { createUseStyles } from 'react-jss';
import { Switch, useRouteMatch } from 'react-router-dom';
import { LinkComponent } from '../../components/LinkComponent/LinkComponent';
import { SubRoute } from '../../config/SharedTypes';
import Route from '../../routes/route';
import { AccountSelect } from './account-select/AccountSelect';
import { AccountSelectText } from './account-select/AccountSelectText';
import { ConfirmInformation } from './confirm-information/ConfirmInformation';
import { ConfirmInformationText } from './confirm-information/ConfirmInformationText';
import { CreateLogin } from './create-login/CreateLogin';
import { CreateLoginText } from './create-login/CreateLoginText';
import { useTranslation } from 'react-i18next';
import { IdCapture } from './id-capture/IdCapture';
import { IdCaptureText } from './id-capture/IdCaptureText';
import { ProfilePhoto } from './profile-photo/ProfilePhoto';
import { ProfilePhotoText } from './profile-photo/ProfilePhotoText';
import { VerifyPhone } from './verify-phone/VerifyPhone';
import { VerifyPhoneText } from './verify-phone/VerifyPhoneText';
import { styles } from './styles';
import { VerifyEmail } from './verify-email/VerifyEmail';
import { VerifyEmailText } from './verify-email/VerifyEmailText';
import { VerifyIdentity } from './verify-identity/VerifyIdentity';
import { VerifyIdentityText } from './verify-identity/VerifyIdentityText';

const useCreateAccountPageStyles = createUseStyles(styles);

const subRoutes: (path: string) => SubRoute[] = path => [
  {
    path: `${path}`,
    exact: true,
    isPrivate: false,
    component: AccountSelect,
    textComponent: AccountSelectText,
  },
  {
    path: `${path}/createLogin`,
    exact: true,
    isPrivate: false,
    component: CreateLogin,
    textComponent: CreateLoginText,
  },
  {
    path: `${path}/verifyEmail`,
    exact: true,
    isPrivate: false,
    component: VerifyEmail,
    textComponent: VerifyEmailText,
  },
  {
    path: `${path}/idCapture`,
    exact: true,
    isPrivate: false,
    component: IdCapture,
    textComponent: IdCaptureText,
  },
  {
    path: `${path}/confirmInformation`,
    exact: true,
    isPrivate: false,
    component: ConfirmInformation,
    textComponent: ConfirmInformationText,
  },
  {
    path: `${path}/verifyIdentity`,
    exact: true,
    isPrivate: false,
    component: VerifyIdentity,
    textComponent: VerifyIdentityText,
  },
  {
    path: `${path}/profilePhoto`,
    exact: true,
    isPrivate: false,
    component: ProfilePhoto,
    textComponent: ProfilePhotoText,
  },
  {
    path: `${path}/verifyPhone`,
    exact: true,
    isPrivate: false,
    component: VerifyPhone,
    textComponent: VerifyPhoneText,
  },
];

export const CreateAccountPage = () => {
  const { t } = useTranslation();
  const classes = useCreateAccountPageStyles();
  let { path } = useRouteMatch();

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <Switch>
          {subRoutes(path).map(({ path, textComponent, isPrivate, exact }) => (
            <Route
              path={path}
              component={textComponent}
              isPrivate={isPrivate}
              exact={exact}
            />
          ))}
        </Switch>
      </div>
      <div className={classes.formGridContainer}>
        <div className={classes.formFlex}>
          <div className={classes.form}>
            <Switch>
              {subRoutes(path).map(({ path, component, isPrivate, exact }) => (
                <Route
                  path={path}
                  component={component}
                  isPrivate={isPrivate}
                  exact={exact}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <LinkComponent
          label={t('privacy')}
          onClick={() => console.log('link clicked')}
          secondary
        />
        <LinkComponent
          label={t('security')}
          onClick={() => console.log('link clicked')}
          secondary
        />
        <LinkComponent
          label={t('terms_of_service')}
          onClick={() => console.log('link clicked')}
          secondary
        />
      </div>
    </div>
  );
};
