/* eslint-disable import/no-anonymous-default-export */
import client from './client';

const endpoint = '/posts';

const getPosts = () => client.get(endpoint);
const createPost = post => client.post(endpoint, post);
const updatePost = post => client.put(`${endpoint}/${post.id}`, post);

export default {
  getPosts,
  createPost,
  updatePost,
};
